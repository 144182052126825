import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc1TropicalRainforest(props) {
  const headerImage = "tropical-rainforest-hero.jpg";
  const thermalsImage1 = "tropical-rainforest-sun-90-degrees.jpg";
  const thermalsImage2 = "tropical-rainforest-thermals.jpg";
  const thermalsImage3 = "tropical-rainforest-resulting-rain.jpg";
  const sunAngleImage1 = "tropical-rainforest-sun-angle-march.jpg";
  const sunAngleImage2 = "tropical-rainforest-sun-angle-june.jpg";
  const sunAngleImage3 = "tropical-rainforest-sun-angle-september.jpg";
  const sunAngleImage4 = "tropical-rainforest-sun-angle-december.jpg";
  const GlobalDistributionMap = "Tropical-Rainforest-Global-Distribution.jpg";
  const ClimateGraphsImage = "tropical-rainforest-climate-graphs.jpg";
  const FrenchGuianaImage = "tropical-forest-guiana.jpg";
  const PalmOilPlantationImage = "tropical-rainforest-palm-oil-plantation-cifor.jpg";

  const thermalsImages = [
    { src: thermalsImage1, caption: "Intense sunlight strikes the equator at or around 90 degrees" },
    { src: thermalsImage2, caption: "Surface heating produces thermals, inrush of moist air, rising to form clouds" },
    { src: thermalsImage3, caption: "Moisture laden thunderclouds release torrential rain" },
  ];

  const sunAngleImages = [
    { src: sunAngleImage1, caption: "Sun angle at the equator in March" },
    { src: sunAngleImage2, caption: "Sun angle at the equator in June" },
    { src: sunAngleImage3, caption: "Sun angle at the equator in September" },
    { src: sunAngleImage4, caption: "Sun angle at the equator in Decemeber" },
  ];

  const images6 = [
    { src: FrenchGuianaImage, caption: "Tropical Rainforest in French Guiana, South America" },
    { src: PalmOilPlantationImage, caption: "Palm oil plantation in Indonesia" },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="1"
      heading="Tropical Rainforest"
      subheading="KOPPEN CODE: Af"
      documentTitle="The Tropical Rainforest Climate - Koppen Climate Classification: Af"
      headerImageSrc={headerImage}
      youTubeCode="wq0asIXcW7w"
      chapterPrevText="Introduction"
      chapterPrevUrl="/climate/secrets-of-world-climate-introduction"
      chapterNextText="Tropical Monsoon &amp; Savannah"
      chapterNextUrl="/climate/tropical-monsoon-and-tropical-savannah"
    >
      <p>
        Imagine a place with no seasons. Where every day seems like the last. A place where it rains almost every day. A place of constant
        heat, and humidity. Some might call such a place a hell, while for others, it might be paradise.
      </p>
      <p>
        It is a place on earth with more species of plants and animals than anywhere else. Because of the oxygen generated by its millions
        of trees, it has been dubbed the lungs of the world.
      </p>
      <p>You have arrived at the band encircling the centre of the Earth. Welcome to the Tropical Rainforest.</p>
      <h3>Description and Dynamics</h3>
      <p>We begin our journey through Earth’s climate zones here, because here is the very centre of the Earth, the equator.</p>
      <p>
        And it is no accident that such a hot and wet climate exists on the equator, because it is here that the sun is strongest, striking
        the earth directly at 90 degrees. And as it does so, it causes the hot air to rise, pulling in moisture from the surrounding seas.
      </p>
      <ChapterImageList images={thermalsImages} />
      <p>
        As this humid air rises up into the high atmosphere, it cools. But cool air cannot hold onto moisture like warm air can, and so that
        moisture precipitates out into vast, towering thunder clouds, which then finally release as torrential rain.
      </p>
      <p>
        And it is because of its central position, on the equator, that little to no change in temperature is experienced throughout the
        year. While more northerly and southerly latitude experience the colder and hotter <A to="/climate/seasons">seasons</A>, life on the
        equator is about watching the sun drift slightly north and slightly south of being directly overhead. In any position, it is still
        beating down, producing that heat, and the thermals that produce all that rain.
      </p>
      <ChapterImageList images={sunAngleImages} />
      <p>
        There is more complexity to this of course… some areas on the equator experience wet and dry seasons, but we’ll cover and explain
        this more in the following chapter, which concerns the{" "}
        <A to="tropical-monsoon-and-tropical-savannah">Tropical Monsoon and Tropical Savannah</A>.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global Distribution of the Tropical Rainforest Climate" />
      <h3>Where in the world do we find the Tropical Rainforest Climate?</h3>
      <p>
        So where in the world can we find these forests? Well, naturally, they’re all within the tropical zone. The most famous of these is
        of, course, the Amazon river basin of South America which stretches across many countries most notably Brazil, Peru, Colombia,
        Ecuador, Venezuela and the Guyanas. The Pacific Coast of Colombia on the other side of the Andes mountains is also home to this
        climate. And then there’s the Congo basin of Africa mostly within the country of the Congo Zaire, but also including parts of
        Uganda. The last of the three main rainforest zones is in South-East Asia, with almost all of Indonesia and Malaysia falling within
        this climate type, along New Guinea and the southern and eastern stretches of the Philippines. Many islands of the Pacific
        experience the same climate, including the Solomon Islands, Fiji and French Polynesia.
      </p>
      <p>
        So enough chatter – I know you’re all dying to see some temperature and rainfall graphs, right? Well here they are, across three
        continents and oceans, all remarkably the same. High and almost unchanging temperature throughout the year, and high rainfall
        throughout the year with no dry seasons.
      </p>
      <ChapterImage left src={ClimateGraphsImage} caption="Climate Graphs for the Tropical Rainforest Climate" />
      <p>
        We should mention something about the graph of Hilo in Hawaii. At almost 20 degrees north of the equator, it’s pretty far from it,
        and yet still possesses a very wet and warm climate. The year-round rainfall is due to it being on the windward side of the large
        volcanoes on the Big Island of Hawaii. North-easterly winds, known as trade winds, blow moist warm air off the Pacific all year
        round, and when this moist air hits the mountainous slopes, it rises, cools and dumps huge amounts of rain upon the city.
      </p>
      <p>
        There are some other parts of the tropics where this <em>Trade Wind Coast</em> variant of the Tropical Rainforest climate is found,
        including the Caribbean coasts of Central America and some Caribbean islands, including the Dominican Republic and Puerto Rico as
        well as some eastern coastal areas of Brazil and Madagascar. There will be more on the trade winds in the next couple of chapters –
        they are key also to explaining the patterns of wet and dry seasons in other parts of the tropics.
      </p>
      <h3>Landscapes, Vegetation and Land Use</h3>
      <p>
        So, what kind of vegetation do we find here? Well… <A to="/biomes/tropical-forests">tropical rainforest</A>, funnily enough. But
        what is it? Rainforests occur in other parts of the world – forests that grow in areas of very high rainfall. But the tropical
        rainforest has a unique combination of high rainfall and high temperature, which results in the most vigorous plant growth to be
        found anywhere on the planet.
      </p>
      <p>
        This growth most notably occurs in the form of evergreen hardwood trees, meaning dense, hard wood, like mahogany, teak, sandalwood
        and rosewood. But unlike the hardwoods of more temperate latitudes, these trees retain their leaves, and grow, all year round. A
        diverse array of plants grow in the shade below the thick tree canopy, and this further leads to a huge number of animal species in
        all classes - insects, arachnids, amphibians, reptiles, birds and mammals. Spend a night in a tent here and… well you’ll soon get to
        know some of these guys.
      </p>
      <ChapterImageList images={images6} />
      <p>
        And what about agriculture? Crops that favour the year round rain and high temperatures include two of the world’s favourites –
        bananas and coconuts, and they have been farmed commercially in these regions for a century or more, primarily for export to the
        developed world. More recently, the demand for palm oil, which has favourable properties for use in cooking, has increased
        considerably, leading to controversial clearing of rainforests, especially in Malaysia and Indonesia.
      </p>
      <p>
        Agriculture throughout history, and across the world has always been the principal enemy of forests. But at this time, with so much
        global attention on the importance of maintaining the biodiversity of the tropical rainforests, finding an equitable solution
        between this and the economic livelihood of the people that live in these regions seems elusive as ever.
      </p>
      <h3>Notable Cities</h3>
      <p>
        Lastly, we come to major world cities. Are any found in the tropical rainforest? Well only two feature, and these are just hours
        apart from each other on the southern tip of Malaysia. Kuala Lumpur, the capital of Malaysia and famous for the Petronas Towers… and
        Singapore, a highly developed global financial, shipping and aviation hub.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>
          How does the temperature and rainfall distribution vary throughout the year in the Tropical Rainforest climate, and why is this?
        </li>
        <li>What is the Koppen Climate Classification code for this climate?</li>
        <li>Why does so much rain fall in this climate?</li>
        <li>List out some regions, countries and cities where this climate zone occurs.</li>
        <li>Explain what is meant by the "Trade Wind Coast" variant of this climate.</li>
        <li>What type of natural vegetation occurs in this climate?</li>
        <li>What is meant by "biodiversity", and how does it relate to this climate?</li>
        <li>Describe some of the challenges that habitats are facing in these regions.</li>
      </ol>
    </Chapter>
  );
}

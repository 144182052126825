import React, { useEffect } from "react";
import A from "./A";
import Support from "./support";

export default function About(props) {
  useEffect(() => {
    document.title = "About B.J.Ranson, the Creator of Geodiode - The Ultimate Educational Resource on Climate and Biomes"
 }, []);

  return (
    <div className="about">
      <h1>About Geodiode</h1>
      <img src={`${process.env.REACT_APP_IMAGES_DIR}/bjranson-portrait.jpg`} alt="B.J.Ranson portrait" />
      <img src={`${process.env.REACT_APP_IMAGES_DIR}/emblem-bitcoin.jpg`} alt="Bitcoin Logo" />
      <p>Geodiode is the creation of writer and 'walking encyclopedia' B.J.Ranson</p>
      <p>
        I first learned about climate, like many of us do, in high school. I took home a geography textbook on the subject called "Man In
        His Environment", and I was fascinated by the maps of the various climate zones around the world, and how the various parts of our
        planet could look so different due to climatic influence.
      </p>
      <p>
        And since a teenager, I have enjoyed creating and editing videos, making a series of comedy productions, as well as promotional material
        for charitable organisations.
      </p>
      <p>
        Additionally, I always wanted to be a writer, and wrote my first novel shortly after completing my engineering degree in 1992. I
        wrote another two novels in the following two decades, and you can find them here in Kindle form or paperback:{" "}
        <a href="http://bjranson.com" target="_blank" rel="noreferrer">
          bjranson.com
        </a>{" "}
      </p>
      <p>
        More recently, observing the spectacular growth of user-created content on YouTube, I decided that I could combine all three
        interests into one, and in February 2018, with the launch of my{" "}
        <A to="/climate/secrets-of-world-climate-introduction">Secrets of World Climate</A> introduction video, the project <b>Geodiode</b> was born.
      </p>
      <p>
        At the request of teachers and students, I decided to finally put together this website to act as an aid and accompaniment to my
        video series, and so in April 2021, <b>geodiode.com</b> went live. I hope you find it useful.
      </p>      
      <p>&nbsp;</p>
      <Support />
    </div>
  );
}

import React from "react";

export default function Share(props) {
  return (
    <div className="center-cont">
      {/* Go to www.addthis.com/dashboard to customize your tools */}
      <h2>SHARE THIS PAGE:</h2>
      <div className="addthis_inline_share_toolbox_h54q">
        <p><a href={`${window.location.href}`}>{window.location.href}</a></p>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import NavPanelThumb from "../components/nav-panel-thumb";
import Share from "../components/share";

export default function Sowc(props) {
  useEffect(() => {
    document.title = "Secrets of World Climate - A course on the Koppen Climate Classification System";
  }, []);

  const IntroThumbPic = "SowCIntroThumb.jpg";
  const AfThumbPic = "AfThumb.jpg";
  const AmAwThumbPic = "AmAwThumb.jpg";
  const CwbThumbPic = "CwbThumb.jpg";
  const BhThumbPic = "BhThumb.jpg";
  const CfaThumbPic = "CfaThumb.jpg";
  const CsThumbPic = "CsThumb.jpg";
  const CfbThumbPic = "CfbThumb.jpg";
  const BkThumbPic = "BkThumb.jpg";
  const DabThumbPic = "DabThumb.jpg";
  const DcdThumbPic = "DcdThumb.jpg";
  const ETThumbPic = "ETThumb.jpg";
  const EFThumbPic = "EFThumb.jpg";

  //const thisSeries = { name: "Secrets of World Climate", to: "/climate/secrets" };
  return (
    <>
      <div className="title-block">
        <div className="title">Secrets of World Climate</div>
        <div className="summary">
          A full course for beginners to climate science, covering in detail every climate zone of earth. Includes the full video for each
          climate zone, in addition to the full script, for reference, along with key diagrams, exclusive hi-resolution maps and charts not
          found anywhere else, and sample coursework questions to test your understanding.{" "}
        </div>
        <div className="summary">
          It recommended that you study the course in order, beginning with "Introduction" and follow the link through on each page to take
          you to the next.
        </div>
        <div className="chapters-heading">Chapters</div>
      </div>
      <div className="chapters-list">
        <NavPanelThumb src={IntroThumbPic} to="/climate/secrets-of-world-climate-introduction" title="0" subtitle="Introduction" />
        <NavPanelThumb src={AfThumbPic} to="/climate/tropical-rainforest" title="1" subtitle="Tropical Rainforest" />
        <NavPanelThumb
          src={AmAwThumbPic}
          to="/climate/tropical-monsoon-and-tropical-savannah"
          title="2"
          subtitle="Tropical Savannah &amp; Monsoon"
        />
        <NavPanelThumb src={CwbThumbPic} to="/climate/subtropical-highlands" title="3" subtitle="Subtropical Highlands" />
        <NavPanelThumb src={BhThumbPic} to="/climate/hot-deserts" title="4" subtitle="Hot Deserts" />
        <NavPanelThumb src={CfaThumbPic} to="/climate/humid-subtropical" title="5" subtitle="Humid Subtropical" />
        <NavPanelThumb src={CsThumbPic} to="/climate/mediterranean" title="6" subtitle="Mediterranean" />
        <NavPanelThumb src={CfbThumbPic} to="/climate/oceanic" title="7" subtitle="Oceanic" />
        <NavPanelThumb src={BkThumbPic} to="/climate/cool-deserts" title="8" subtitle="Cool Deserts" />
        <NavPanelThumb src={DabThumbPic} to="/climate/continental" title="9" subtitle="Continental" />
        <NavPanelThumb src={DcdThumbPic} to="/climate/subarctic" title="10" subtitle="Subarctic" />
        <NavPanelThumb src={ETThumbPic} to="/climate/tundra" title="11" subtitle="Tundra" />
        <NavPanelThumb src={EFThumbPic} to="/climate/icecap" title="12" subtitle="Icecap" />
      </div>
      <Share />
    </>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import { Link } from "react-router-dom";

export default function CC3Seasons(props) {
  const headerImage = "seasons-hero.jpg";
  const ImageHoursOfDaylight = "seasons-hours-of-daylight.jpg";
  const ImageModeratingInfluence = "seasons-moderating-influence.jpg";
  const ImageSunAngleMidLatitudes = "seasons-sun-angle-mid-latitudes.jpg";
  const ImageSunSpread = "seasons-sun-spread.jpg";
  const ImageTropicsTemp = "seasons-tropics-temperature.jpg";
  const ImageEarthWobble = "seasons-earth-wobble.jpg";
  const ImageItczJan = "AmAw-itcz-january.jpg";
  const ImageItczJul = "AmAw-itcz-july.jpg";
  const ImageLag = "seasons-lag.jpg";
  const ImageEquinox = "seasons-equinox.jpg";
  const ImageCapricorn = "seasons-capricorn.jpg";
  const ImageCancer = "seasons-cancer.jpg";
  const ImageAxialTiltEarth = "seasons-axial-tilt-earth.jpg";
  const ImageAllPlanets = "seasons-all-planets.jpg";
  const ImageOrbitWinter = "seasons-orbit-sun-angle-winter.jpg";
  const ImageOrbitSummer = "seasons-orbit-sun-angle-summer.jpg";
  const ImageAxialTilt = "seasons-axial-tilt.jpg";
  const Graph = "Seasons-Across-The-World-by-Koppen-Climate-Type.jpg";

  const imagesOrbit = [
    {
      src: ImageAxialTilt,
      caption:
        "Axial Tilt is the angle around which a planet rotates about its axis compared with its orbital plane around its parent star",
    },
    {
      src: ImageOrbitSummer,
      caption: "In summer rays from the parent star hit the ground at a steep angle, producing a large heating effect",
    },
    {
      src: ImageOrbitWinter,
      caption:
        "In winter rays from the parent star hit the ground at the same spot at a shallower angle, producing a smaller heating effect",
    },
    {
      src: ImageAllPlanets,
      caption: "Every planet in the universe with an atmosphere that orbits a star will have seasons",
    },
  ];

  const imagesTropics = [
    {
      src: ImageAxialTiltEarth,
      caption: "Earth has an Axial Tilt of just over 23°",
    },
    {
      src: ImageCancer,
      caption: "The Tropic of Cancer is that line around the earth at just over 23°N that sees the sun at the zenith at noon on June 21st",
    },
    {
      src: ImageCapricorn,
      caption:
        "The Tropic of Capricorn is that line around the earth at just over 23°S that sees the sun at the zenith at noon on December 21st",
    },
    {
      src: ImageEquinox,
      caption: "At the equator, the sun is at the zenith at noon on March 21st and September 21st",
    },
  ];

  const images3 = [
    {
      src: ImageLag,
      caption: "Peak temperatures lag a month or so behind the summer solstice due to slow oceanic heating",
    },
    {
      src: ImageEarthWobble,
      caption: "While the current axial tilt of the Earth is 23.4° it will cycle over a very long period between 22° and 24.5°",
    },
  ];

  const imagesItcz = [
    {
      src: ImageItczJul,
      caption:
        "In July, the ITCZ, with its accompanying thunderstorms, is north of the equator and brings the wet season to the northern tropics",
    },
    {
      src: ImageItczJan,
      caption:
        "In January, the ITCZ, with its accompanying thunderstorms, is south of the equator and brings the wet season to the southern tropics",
    },
  ];

  const imagesSunAngle = [
    {
      src: ImageSunSpread,
      caption:
        "A shallower sun angle means the heat from the sun is spread over a wider area, diluting its power, and reducing heating effect",
    },
    {
      src: ImageTropicsTemp,
      caption: "The angle of the sun varies little in the tropics, and so temperatures changes between summer and winter are small",
    },
    {
      src: ImageSunAngleMidLatitudes,
      caption:
        "At the mid latitudes, the change in sun angle between winter and summer is much greater, leading to bigger temperature changes",
    },
  ];

  const images6 = [
    {
      src: ImageModeratingInfluence,
      caption: "Proximity to oceans has a moderating influence on seasonal temperature changes",
    },
    {
      src: ImageHoursOfDaylight,
      caption: "The tropics see little seasonal change between day and night duration, while polar regions see huge changes",
    },
  ];

  return (
    <Chapter
      series="casebook"
      heading="Seasons"
      subheading="ASTRONOMICAL ORIGINS"
      documentTitle="Seasons, their astronomical origins and how they vary across climate zones"
      headerImageSrc={headerImage}
      youTubeCode="fRbM8p8DT1g"
      chapterPrevText="Asian Monsoon"
      chapterPrevUrl="/climate/asian-monsoon"
      chapterNextText="Climate &amp; Population"
      chapterNextUrl="/climate/population"
    >
      <p>Searing heat. Freezing ice. Monsoon rains and dusty droughts. Spring. Summer. Autumn. Winter.</p>
      <p>
        For hundreds of millions of years they have they have defined the rhythm of nature on our planet. They underpin the daily existence
        of billions of us today. A product of the clockwork of the heavens. These are the seasons of Planet Earth.
      </p>
      <h3>Astronomical Origins</h3>
      <p>
        When talking about climate, one cannot ignore the concept of the seasons. It’s the variation in temperature and precipitation
        throughout the year that defines each climate zone on our planet. Each zone has a unique combination of seasonal patterns. And these
        patterns determine the biome – the characteristic of vegetation – of the land in that zone, what agriculture can be sustained there,
        and ultimately whether it can support human population.
      </p>
      <p>
        But what are the seasons? What is their origin? Why do they always repeat year after year? Why are they different in the tropics
        than in the higher latitudes? Why do they reverse in the southern hemisphere?
      </p>
      <p>All these questions are answered in the relationship between our planet and its parent star – the sun.</p>
      <p>
        When a planet orbits a star, it traces an elliptical path that is always in the same horizontal plane – the orbital plane. But the
        planet itself will rotate on its own axis at some other angle – what is known as axial tilt. The result of this is that as the
        planet moves around the star, the angle at which the star’s light strikes any particular point on that planet will change. As
        surface and atmospheric heating is directly related to the angle of the star’s light, the position of the orbit will therefore
        influence temperature for that region of the planet. So every planet in the galaxy with an atmosphere will have some combination of
        seasons, and so the phenomenon shouldn’t be thought of as unique to our planet. In fact the planets Mars, Saturn, Uranus, and
        Neptune and Saturn’s moon Titan all have distinct seasons that have been observed.
      </p>
      <ChapterImageList images={imagesOrbit} />
      <p>
        There are four significant points in any planet’s orbit in this regard – the first two being when each of the planet’s poles is
        pointing closest toward the star, and the second two being when the poles are exactly at right angles to the star.
      </p>
      <p>
        In the case of our planet, our axial tilt is just over 23°. So on the day when the North Pole is facing closest toward the sun, June
        21st to be precise, every point on a line encircling the earth 23° north of the equator will experience a midday sun that is exactly
        overhead. This is midsummer in the northern hemisphere, when heating from sunlight is at its greatest.
      </p>
      <p>
        Exactly 6 months later, on December 21st, when the South Pole is facing closest toward the sun, every point on Earth 23° south of
        the equator will have a noonday sun overhead. This is midsummer in the southern hemisphere.
      </p>
      <p>
        These two lines are respectively the Tropics of Cancer and Capricorn, called such because the sun is in those zodiac constellations
        on those special days, which are known as the summer and winter solstices. The word solstice comes from Latin [sol + stit], meaning
        “sun stationary”, and literally describes the stop and reversal of the sun’s highest or lowest daily point in its seasonal
        progression.
      </p>
      <p>
        Exactly in between the solstices, on March 21 and September 21, the Earth’s poles are at an exact right angle to the sun, which
        means that on these days, the sun at noon at the equator is directly overhead. These days are called equinoxes - from the Latin
        [aequus + nox], meaning “equal night”. The hours of day and night across every point on earth are the same at 12 hours each on these
        special days.
      </p>
      <ChapterImageList images={imagesTropics} />
      <p>
        In practice, midsummer temperature peaks lag behind midsummers day by a month or so – most will experience that July and August are
        the hottest months in the Northern Hemisphere, and not June. And the same goes for winter, with January and February being the
        coldest, and not December. This is called “seasonal lag” and occurs because it takes time for the earth and oceans particularly to
        warm up in that area.
      </p>
      <p>
        This cycle repeats itself near-exactly, year in year out, because the Earth returns back to the same point exactly one year later,
        as regular as clockwork (if human-made clocks could ever be so precise). I say near exactly, because the Earth is in fact wobbling
        by a few degrees on its axis, in the same way a spinning top might wobble. But this wobble has a cycle of almost 26 thousand years,
        so… you’re not going to notice anything in your lifetime.
      </p>
      <ChapterImageList images={images3} />
      <h3>Atmosphere</h3>
      <p>
        Ok, so that explains the origin of the seasons, but the effects of this on daily weather depend upon whether you live inside or
        outside of the Tropics of Cancer and Capricorn. Within the Tropics, the changes in the angle of sunlight from one solstice to the
        other has little or no influence on surface heating and so the tropics see very little change in temperatures across the year.
      </p>
      <p>
        Seasons in the tropics are in fact determined by the presence or absence of rain. When the sun is at right angles to the earth,
        heating is at its greatest, leading to thermals of hot moist air, which, when rising into the upper atmosphere, cool, losing their
        moisture, and depositing rain in the form of thunderstorms. As the sun tracks back and forth across the equator throughout the year,
        this band of rain follows it. This band is called the doldrums, or Inter-Tropical Convergence Zone (ITCZ), as it is the convergence
        of tropical winds, known as trade winds, being sucked into it from surrounding areas. When these winds carry moisture, they also
        bring rain, and so this complex pattern determines what areas will be wet or dry during what season. Only where the ITCZ is stable,
        or where trade winds carry moisture regardless of direction do we have the Tropical Rainforest, where rainfall and temperature are
        near constant – the only climate on earth without seasons.
      </p>
      <ChapterImageList images={imagesItcz} />
      <p>
        Outside the tropics though, these sunlight angles become important. If you know anything about trigonometry, then you’ll get the
        idea that as the angle of sunlight to the surface becomes shallower, there is an acceleration of the effect of that heat spreading
        out across the surface and being diluted in power. So that by the time you reach the poles, the heating effect of the sunlight is
        only a tiny fraction of what it is at the equator.
      </p>
      <ChapterImageList images={imagesSunAngle} />
      <p>
        In the mid latitudes, the change in seasonal angles is significant enough to produce large changes in temperature. In summer the sun
        is high above the horizon, concentrating heat and delivering warm or hot days. In winter, it’s much closer to the horizon and so
        surface heating is much less, with the result of significantly colder days.
      </p>
      <p>
        This variation in temperature in the mid-latitudes is diluted, however, by the proximity of oceans. Being a large fluid mass,
        kilometres deep, the oceans do not heat as much in summer as the land, and do not cool as much in winter. So land areas near an
        ocean have much smaller winter/summer temperature ranges than those in a continental interior. This difference in coastal and
        interior continental temperatures is at its greatest in the mid-latitudes and is the essence of why we have more climate types in
        this region of earth than any other.
      </p>
      <ChapterImageList images={images6} />
      <p>
        In the polar regions, the effect of axial tilt is at its extreme. Above the polar circle at 67°N, there is at least one day each
        winter when the sun does not even climb above the horizon, and at least one day each summer when the sun never sets. As one
        approaches the pole, these number of days increase until at the North Pole, the sun doesn’t rise for 6 months in winter, and stays
        above the horizon for 6 months in summer. The same occurs at the opposite time of year in Antarctica.
      </p>
      <h3>Global Seasons and Climate in One Chart</h3>
      <ChapterImage right src={Graph} caption="Seasonal Changes in Temperature and Precipitation by Climate Type" />
      <p>
        In the Koppen climate classification system, we have categorised patterns of temperature and rainfall determined by the seasons. You
        can learn more about this in my <Link to="koppen-classification">intro article</Link> or by watching my{" "}
        <Link to="secrets">Secrets of World Climate series</Link> which covers each zone in detail. What you almost certainly haven’t seen
        before, is a representation of seasons across all Koppen zones in one image. And here it is.
      </p>
      <p>
        For readability, I have grouped similar zones together or omitted rare or isolated ones. This list shows the most important and
        distinct zones, starting at the equator at the top and finishing at the pole at the bottom of the page. For each zone I have
        depicted the change in temperature across the year in a colour spectrum, from red as the hottest to purple as the coldest. Below
        each temperature progression is a progression of precipitation, with rain in blue, snow in white, and intensity of such
        precipitation as proportional shading. These patterns are for the northern hemisphere and will naturally be reversed for the
        southern.
      </p>
      <p>
        Up top we see the unchanging single hot and wet season of the Tropical Rainforest. Next we see the change in rainfall across the
        year in the Tropical Monsoon and Savannah climates, and a slight bump in temperature just before the wet season.
      </p>
      <p>
        After this we see the unique Subtropical Highland climate, where temperatures are reduced due to altitude. Note that there is a form
        of this climate where the rain is constant year round, but is not shown here.
      </p>
      <p>Then we come to the Hot Desert, where there is little to no rain, but intensely hot conditions in summer.</p>
      <p>
        We then have the unique three season climate of the Subtropical Monsoon - a mild dry winter, then a short and very hot dry summer,
        followed by hot wet season.
      </p>
      <p>
        As we move into the mid latitudes, we get our first cool winter temperatures in the Humid Subtropical, followed by the unique dry
        summer /wet winter pattern of the Mediterranean climate zones. This latter, together with its westerly ocean facing sibling, the
        Oceanic, demonstrate mild temperature ranges from winter to summer.
      </p>
      <p>
        By contrast the Continental climate zones that follow show much more extreme temperature ranges, with subdivisions of these being
        the cool deserts, with absence of rain, Humid Continental, where we see snow and rain defining seasonal precipitation and
        Continental Monsoon, where this pattern is varied by an absence of winter precipitation. The final continental climate of the
        Subarctic is then seen with extreme temperature ranges featuring very cold winters yet warm summers.
      </p>
      <p>
        As we reach the poles, freezing temperatures dominate with only a brief cool summer in the tundra bringing any respite to these
        forbidding lands.
      </p>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc5HumidSubtropical(props) {
  const headerImage = "humid-subtropical-hero.jpg";
  const SunAngleJulyImage = "humid-subtropical-sun-angle-july.jpg";
  const SunAngleJanuaryImage = "humid-subtropical-sun-angle-january.jpg";
  const CfaGraphImage = "humid-subtropical-Cfa.jpg";
  const CwaGraphImage = "humid-subtropical-Cwa.jpg";
  const OceanWindsImage1 = "humid-subtropical-ocean-currents-and-temperature.jpg";
  const OceanWindsImage2 = "humid-subtropical-warm-moist-ocean-winds.jpg";
  const OceanWindsImage3 = "humid-subtropical-contrast-dry-ocean-winds-to-desert.jpg";
  const ImageChinaLandscape = "humid-subtropical-china-landscape.jpg";
  const ImageGeorgiaWetlands = "humid-subtropical-georgia-wetlands.jpg";
  const ImageArgPampas = "humid-subtropical-arg-pampas.jpg";
  const GlobalDistributionMap = "Humid-Subtropical-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-humid-subtropical.gif";

  const imagesSunAngle = [
    {
      src: SunAngleJulyImage,
      caption: "The angle of the sun to the land in Humid Subtropical summer is high, producing much surface heating",
    },
    {
      src: SunAngleJanuaryImage,
      caption: "The angle of the sun to the land in Humid Subtropical winter is low, producing little surface heating",
    },
  ];

  const imagesVariantComparison = [
    {
      src: CfaGraphImage,
      caption: "The Humid Subtropical Cfa variant has year-round rain",
    },
    {
      src: CwaGraphImage,
      caption: "The Subtropical Monsoon Cwa variant has rain only or mostly during the summer",
    },
  ];

  const imagesOceanWinds = [
    {
      src: OceanWindsImage1,
      caption: "Current circulation always produces warm water on the western fringe of an ocean at the mid-latitudes",
    },
    {
      src: OceanWindsImage2,
      caption: "Warm moist air blows onto the continental eastern margin by high pressure, producing rain",
    },
    {
      src: OceanWindsImage3,
      caption:
        "By contrast, cold water in the eastern ocean margins blow dry air onto continental western margins producing no rain and deserts",
    },
  ];

  const imagesExamples = [
    {
      src: ImageChinaLandscape,
      caption: "Countryside near Yangshou, South China",
    },
    {
      src: ImageGeorgiaWetlands,
      caption: "Wetlands in Georgia, USA",
    },
    {
      src: ImageArgPampas,
      caption: "Gaucho ranchers in the Pampas of Argentina",
    },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="5"
      heading="Humid Subtropical"
      subheading="KOPPEN CODES: Cfa &amp; Cwa"
      documentTitle="The Humid Subtropical Climate - Koppen Climate Classification: Cfa Cwa"
      headerImageSrc={headerImage}
      youTubeCode="FHj71X_k8h0"
      chapterPrevText="Hot Deserts"
      chapterPrevUrl="/climate/hot-deserts"
      chapterNextText="Mediterranean"
      chapterNextUrl="/climate/mediterranean"
    >
      <p>
        Hot summers, cool winters, and plenty of rain. You could live in a place like this, right? Well millions would agree with you.
        Because out of all the climate zones on earth, this is the one with the largest number of highly developed world cities.
      </p>
      <p>It’s the only climate zone on every inhabited continent on Earth.</p>
      <p>
        And you’ve probably never heard or thought of it until now. It has been dubbed the “China” type, the Natal type, the Southern United
        States type, or the Warm Temperate Eastern Margin. But in this series we’ll call it the Humid Subtropical.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        In our journey from the equator toward the poles in this series, it is now time to review the Temperate band, where we find most of
        the developed world. The climate zone in this band nearest the equator lies on the eastern boundaries of all the major continents
        between about 20 and 40 degrees latitude, and is called by most climatologists the Humid Subtropical.
      </p>
      <p>
        What defines this climate is the generous presence of rain and a hot and humid summer where temperatures approach that of the
        tropics. But to distinguish it from such tropical zones, it has a cool winter where temperatures typically drop to between zero and
        ten degrees celsius. This seasonal variation is due to the increased latitude compared to the tropics. In summer the sun is close to
        overhead at noon, whereas in winter, it is much closer to the horizon, and so sunlight strikes the earth at an angle, spreading its
        heat over a wider area and diluting its power.
      </p>
      <ChapterImageList images={imagesSunAngle} />
      <p>
        There are two subdivisions of this climate within the Koppen Climate Classification both of which share the seasonal variation in
        temperature just described, and differing only by rainfall pattern. The first, and most geographically widespread is Cfa, which
        experiences rain all year round. The second, Cwa, has rain mostly or only during the summer, and because of this similarity to the
        Tropical Monsoon, this is known as the <em>Subtropical Monsoon</em>, owing to its cooler winter temperatures when compared to its
        Tropical cousin.
      </p>
      <ChapterImageList images={imagesVariantComparison} />
      <p>
        Now those of you paying attention in the last chapter on <A to="hot-deserts">Hot Deserts</A> may be saying 'Wait a minute, that band
        just above the tropics should be desert, due to the descending columns of air originating from the tropical uplift!'… or something
        similar. So why, on the eastern edges of the continents at these latitudes do we get a wet climate instead of desert? Well, as usual
        in weather and climate, the full explanation is complex, but a simpler answer is that the oceans on the eastern fringes of the
        continents have warm water compared to the western fringes that have cold water, due to ocean current circulation. Warm ocean means
        lots of moist air above it, and this is pushed onto the land by the high pressure ridge that is concentrated to the east of each
        continent. When the warm moist air goes over land, it releases its moisture as rain. By contrast, the deserts at the same latitudes
        have air moving over cold water onto land, which at best produces fog, or no wind at all due to high pressure directly above them.
      </p>
      <ChapterImageList images={imagesOceanWinds} />
      <h3>Where in the world is there a Humid Subtropical climate?</h3>
      <ChapterImage
        right
        src={GlobalDistributionMap}
        caption="Global Distribution of Humid Subtropical and Subtropical Monsoon Climate Zones"
      />
      <p>
        So where in the world do we find the Humid Subtropical climate? Well, the short answer is, on every continent excluding Antarctica,
        and it’s the only climate type to claim such an accolade.
      </p>
      <p>
        Starting as we do in the Western Hemisphere, and moving eastward, we first encounter this climate in the south-eastern United
        States, where it covers almost a quarter of the entire country. From Missouri down to Texas in the west, across all the “southern
        states” out to the eastern seaboard, where it extends north all the way to New York City in the north and to all but the southern
        coast of Florida in the south. Further south on this continent, in Mexico, there are two bands of the monsoonal variant lying on
        each side of the central highlands.
      </p>
      <p>
        In South America, we find another large stretch of the middle eastern part of this continent dominated by the Humid Subtropical.
        From south-eastern Brazil, including its largest city Sao Paolo, this region stretches out to encompass most of Paraguay, all of
        Uruguay, and the most populated regions of north eastern Argentina, including its capital Buenos Aires.
      </p>
      <p>
        With this climate, we see our first entry into the continent of Europe. Specifically, in the northern and eastern parts of Italy,
        sheltered around the Alps to the north and the Apennine mountains to the south, we have humid summers and cool winters instead of
        the Mediterranean climate that otherwise dominates the larger region - more on that one{" "}
        <A to="mediterranean">in the next chapter</A>. Pockets of this climate type occur further east in the Balkans, along with some of
        the coasts of the Black Sea.
      </p>
      <p>
        In Africa, we find a band across the southern part of the continent under the influence of the Cwa monsoon variant of this climate,
        while in the extreme south east, in the Kwazulu- Natal region of South Africa, we get the all year round rain of the Cfa type.
      </p>
      <p>
        In Asia, the Cwa monsoonal variant dominates a large stretch of northern India, including the capital of Delhi and most of the
        heavily populated Ganges river system. This band of the subtropical monsoon extends eastward into northern Vietnam and southern and
        mid China including the hugely populated Pearl River Delta, with cities such as Guangzhou, Shenzhen, Macau and Hong Kong.
      </p>
      <p>
        The Cfa year-round rain variant in Asia occurs over a wider area in the main part of central and eastern China, including a host
        large cities such as the megacity of Shanghai. Moving off the coast of that continent, we have the whole of Taiwan, and the southern
        and eastern parts of Japan that form the major population centres of that nation, including the world’s largest urban area of Tokyo,
        that nation’s capital.
      </p>
      <p>
        The last continent which has the Humid Subtropical climate is Australia, specifically the central eastern coast, including the
        cities of Brisbane, and that nation’s largest city – Sydney.
      </p>
      <p>
        Despite its dispersal across all continents of the globe, the Humid Subtropical climate is proven as a particular and consistent
        type by comparing temperature and rainfall graphs across each of these areas.
      </p>
      <h3>Landscapes and Vegetation</h3>
      <ChapterImageList images={imagesExamples} />
      <p>So what kind of landscapes and vegetation do we find in the Humid Subtropical climate?</p>
      <p>
        Low lying plains are the most common type of topography in this climate zone, and in their natural state, such plains would be
        covered by either <A to="/biomes/temperate-forests">mixed woodland</A> or <A to="/biomes/grasslands">grassland</A>. Being a
        temperate climate, this is the first that we see with <A to="seasons">four seasons</A>, easily defined by the deciduous trees that
        mark this zone, whether in their natural habitat or included within the cities here – blossom in spring, green leaves in summer, the
        yellow and red falling leaves of autumn and bare in winter.
      </p>
      <p>
        Because this moderate climate favours a diverse array of crops, however, most of it has given way to agriculture – rice, nuts,
        wheat, ranching, cotton, tobacco, and livestock ranching, depending upon the region.
      </p>
      <h3>Population and Cities</h3>
      <ChapterImage right src={ClimateGraphs} caption="Notable cities with Humid Subtropical and Subtropical Monsoon Climates" />
      <p>
        With its relatively mild temperatures and plentiful rain supporting so much agriculture, high populations can be supported,
        especially the rice-supporting regions in China and India, meaning this climate zone is home to more people than any other. And
        being in one of the cities of the Humid Subtropical, under more tolerable conditions compared to the tropics, allows for large and
        sophisticated cities to develop on every continent – again more than any other climate type. The adjacent graphs provide just a
        sample of these.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>Why does the Humid Subtropical climate experience high summer but low winter temperatures?</li>
        <li>
          Why does the eastern margin of each continent experience rain at the subtropical latitudes, but the western margin does not?
        </li>
        <li>What are the Koppen codes for Humid Subtropical climates? How do they differ from each other?</li>
        <li>Why does this climate support such large populations?</li>
        <li>List out some countries, regions and cities that experience Subtropical Highland climates.</li>
      </ol>
    </Chapter>
  );
}

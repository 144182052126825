import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc7Oceanic(props) {
  const headerImage = "oceanic-hero.jpg";
  const ImageCompareWithCfaCsa = "oceanic-compare-cfa-csa.jpg";
  const ImageGraphsAcrossRegion = "oceanic-graphs-across-region.jpg";
  const ImageCompareWithCfc = "oceanic-compare-cfb-cfc.jpg";
  const ImageJetStream = "oceanic-jet-stream.jpg";
  const ImageCoriolisEffect = "oceanic-coriolis-effect.jpg";
  const ImageJetStreamWinter = "oceanic-jet-stream-winter.jpg";
  const ImageJetStreamSummer = "oceanic-jet-stream-summer.jpg";
  const ImageLandscapeAustralia = "oceanic-landscape-australia.jpg";
  const ImageLandscapeNz = "oceanic-landscape-nz.jpg";
  const ImageLandscapeChile = "oceanic-landscape-chile.jpg";
  const ImageLandscapeFrance = "oceanic-landscape-france-verdun.jpg";
  const ImageLandscapeIreland = "oceanic-landscape-ireland.jpg";
  const ImageLandscapeNorway = "oceanic-landscape-norway.jpg";
  const GlobalDistributionMap = "Oceanic-Climate-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-oceanic.gif";

  const imagesDynamics = [
    {
      src: ImageJetStream,
      caption: "The Polar Jet Stream that drives westerly winds at the mid-latitudes",
    },
    {
      src: ImageCoriolisEffect,
      caption: "Low and High pressure systems spin in opposite directions, strengthening the jet stream",
    },
    {
      src: ImageJetStreamWinter,
      caption: "Winter sees both Oceanic and Mediterranean climates experiencing wet westerly winds",
    },
    {
      src: ImageJetStreamSummer,
      caption: "In summer, only the Oceanic climate is affected by the jet stream, with the Mediterranean unaffected and remaining dry",
    },
  ];

  const images2 = [
    {
      src: ImageCompareWithCfc,
      caption: "Classic Oceanic (Cfb) has warmer year round temperatures than Subpolar Oceanic (Cfc)",
    },
    {
      src: ImageGraphsAcrossRegion,
      caption:
        "Valentia in the west of Ireland has much wetter but milder conditions, while Frankfurt has more continental influences than London in the middle of this climate zone",
    },
  ];

  const imagesLandscapes1 = [
    {
      src: ImageLandscapeAustralia,
      caption: "Countryside in Victoria, Australia",
    },
    {
      src: ImageLandscapeNz,
      caption: "Countryside in New Zealand",
    },
    {
      src: ImageLandscapeChile,
      caption: "Southern Chile - Araucaria Trees against one of the many prominent volcanoes here",
    },
  ];

  const imagesLandscapes2 = [
    {
      src: ImageLandscapeFrance,
      caption: "Mixed forest in Eastern France",
    },
    {
      src: ImageLandscapeIreland,
      caption: "Countryside in Western Ireland",
    },
    {
      src: ImageLandscapeNorway,
      caption: "Lofoten in Northern Norway - one of the few spots with the Subpolar Oceanic (Cfc) climate",
    },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="7"
      heading="Oceanic"
      subheading="KOPPEN CODES: Cfb &amp; Cfc"
      documentTitle="The Oceanic (Maritime West Coast) Climate - Koppen Climate Classification: Cfb Cfc"
      headerImageSrc={headerImage}
      youTubeCode="vlNybvaGPpg"
      chapterPrevText="Mediterranean"
      chapterPrevUrl="/climate/mediterranean"
      chapterNextText="Cool Deserts"
      chapterNextUrl="/climate/cool-deserts"
    >
      <p>
        Cool oceans. Westerly winds. Storm-driven rains. Half-way between equator and pole, the lands on the westerly fringes of the
        continents are dominated by their neighbouring seas.
      </p>
      <p>
        Mild winters. Mild summers. If you excuse the constant ocean storms, cloudy skies and rain, then these are not lands of extremes.
        Having some of the most dramatic coastlines on earth, these lands carry also idyllic green countryside that has been the inspiration
        of poets and authors down the ages.
      </p>
      <p>
        These are the lands that birthed not one, but three global empires. Defined by the cool ocean winds, this is the Marine West Coast,
        the lands of the Oceanic Climate.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        As we continue our journey in this series from equator to poles, it’s now time to look at the last, and most northerly of the three
        climates in the temperate band – the Oceanic Climate.
      </p>
      <ChapterImage
        right
        src={ImageCompareWithCfaCsa}
        caption="The Oceanic climate borrows elements from the other two temperate climate types"
      />
      <p>
        In some ways, this climate is a combination of the <A to="humid-subtropical">Humid Subtropical</A> and{" "}
        <A to="mediterranean">Mediterranean</A> climates that it shares the temperate band with. It has the year round rain and occasional
        humid summer heat of the Humid Subtropical, but with winters similar to the cool Mediterranean, with whom it shares its westerly
        winds. Like the Mediterranean climate, it also has a very mild temperature range between summer and winter, but is a good 10 degrees
        cooler than its sunnier and hotter counterparts in summer due to its more polar latitude. Averaged across the day-night cycle the
        temperature is above freezing (32°F) in winter, but below 22 degrees Celsius (72°F) in summer.
      </p>
      <p>
        This does not prevent deep night frosts in winter cold snaps, or peak day temperatures from reaching 30° or more during a summer
        heat wave. But these events occur infrequently in this land of mild oceanic influence.
      </p>
      <p>
        In comparison to its southerly neighbour, the Mediterranean, the people that live in these lands love to complain about the most
        distinctive feature of this climate when compared to others, the lack of sun. While other lands, especially in the tropics are
        wetter, the Oceanic lands are dogged by frequent cloud cover, with at least half the days throughout the year seeing cloudy or rainy
        days.
      </p>
      <p>
        The Marine West Coast yet has a more favourable superlative, in that it exhibits much milder winters compared to all other regions
        at the same latitude. While it is 20 below freezing in Siberia, it is 5 degrees above in London. This “anomaly” is at its most
        extreme in the north of Norway, where in Lofoten, it is above the Arctic Circle, and yet stays above freezing in winter, due to the
        presence of warm currents of the Gulf Stream and North Atlantic Drift bringing warm waters from the tropics.
      </p>
      <p>
        This climate is dominated by Westerly winds blowing in from the adjoining oceans. Most pronounced in autumn and winter, these winds
        bring in storm after storm, battering the coasts of these areas with heavy rain and gales, while more inland areas, spared the
        worst, nonetheless experience wind and rain on a regular basis. Spring and especially summer see less of the westerly wind
        conditions, but weather can remain unpredictable even during these seasons, as any Brit or Kiwi will tell you when anxiously
        planning a weekend barbeque.
      </p>
      <ChapterImageList images={imagesDynamics} />
      <p>
        At the heart of these westerlies is a meteorological phenomenon known as the Polar Jet Stream. This is a narrow band of high
        altitude winds that frequently exceed 100mph, and circles the north and south pole at a latitude of between 50 and 60 degrees,
        travelling from west to east. It occurs at these latitudes, since it is in this region that winds from the tropics and poles
        converge. Due to a complex mechanism known as the Coriolis effect, air moving between high and low pressure is forced to spin. In
        the case of the more equatorial high pressures, the poleward sides of these travel west, in the same direction as the equatorial
        sides of more polar low pressure areas, providing a constant momentum to this jet of air. In winter, the jet stream travels toward
        the equator with the retreating high pressures, impacting both the Oceanic and Mediterranean west coasts. In summer it retreats back
        toward the pole, leaving alone the Mediterranean climates but still brushing the Oceanic zones. This explains why the Mediterranean
        zones only get rain in winter, while Oceanics get rain all year round.
      </p>
      <p>
        There are two subdivisions of the Oceanic climate, separated only by summer temperatures. The most significant in terms of land area
        and population is designated with the Koppen climate code Cfb, which sees maximum summer day temperatures above 17°C (63°F). This is
        known as the Marine West Coast, or Marine Mild Winter, or simply the standard Oceanic climate. The other, Cfc, known as the Subpolar
        Oceanic climate does not see summer maxima above 17°C. Since both have similar winter temperatures, the Cfc variant therefore has a
        significantly narrower variation in seasonal temperatures.
      </p>
      <p>
        The Oceanic climate is, like the Humid Subtropical, a true four <A to="/climate/seasons">seasons</A> climate, with the usual pattern
        of blossom in spring, green leaves in the growth season of summer, yellow and falling leaves in the autumn and bare branches in
        winter. Snowfalls usually occur at least once per winter, but usually melts within a few days. Cold snaps of below freezing
        conditions lasting a week or more are rare but not unknown.
      </p>
      <ChapterImageList images={images2} />
      <p>
        The influence of the ocean can be seen in this sample of graphs stretching across Northwest Europe, which is the most significant
        part of the world under the Cfb Oceanic climate in terms of population. The west coast of Ireland has the narrowest summer/winter
        temperature range. while moving east into England, and then into Germany, the temperature range increases as we head to the border
        of the Continental climate zone further east.
      </p>
      <h3>Where in the world do we find the Oceanic climate?</h3>
      <p>
        So, where in the world do we find the Oceanic climate areas? Well, they mostly lie on the western coasts of continents where they
        extend beyond 45 degrees of latitude. Starting in North America, the extreme north-west of American State of Washington, the west
        coast of Canadian British Columbia and the southern half of the Alaskan panhandle come under its influence. Due to the closeness of
        the Rocky Mountains, this influence does not extend far inland, but nonetheless, covers the key regional city of Vancouver, which
        experiences the mildest winter temperatures in Canada.
      </p>
      <ChapterImageList images={imagesLandscapes1} />
      <p>
        A mirror image of this pattern occurs in South America, where the southern third of Chile is covered by first Cfb and then Cfc
        variants of this climate as one heads toward the southern tip of the continent and Antarctica. Again, an extensive mountain range,
        the Andes, is immediately adjacent to the coast, thus limiting the influence within the narrow margin of this longest of countries.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of Oceanic climate zones" />
      <p>
        Skipping all the way, now, across to Australia, and we find the south-eastern margin of this island continent containing among the
        warmest of the Oceanic climates with all of Tasmania, most of Victoria and the southern coastal fringe of New South Wales under its
        influence, including the state capitals of Melbourne and Hobart. At first this might seem a contradiction to the “westerly coasts”
        model of this climate, but in actual fact, this is the only part of Australia that extends far enough south to have year-round
        westerly winds.
      </p>
      <p>
        Travelling further east still, and we reach the most isolated country in the developed world and the two islands of New Zealand.
        Both islands are dominated by Westerlies year round since they are firmly in the latitude of the “Roaring Forties” and so all but
        the most mountainous parts of this country lie firmly within the Oceanic climate zone. Like all such zones, temperatures are mild in
        winter, but summer temperatures are significantly warmer in the north compared to the south.
      </p>
      <p>
        Lastly, we come to Europe, where this climate zone most strongly leaves its mark, both in terms of land area, and population. In the
        far north and west, we have the island of Iceland, land of the midnight sun, so far north is it that it touches the arctic circle.
        The lower lying western parts of this island, including the capital Reykjavik are under the Polar Cfc variant of the Oceanic
        climate, experiencing cool summers, but also mild winters due to being firmly within the warm Gulf Stream ocean current. At a
        similar latitude, we have the long and broken coastline of Norway, where in the north the Cfc Polar Oceanic variant keeps winters
        mild and summers cool, but as we travel further south along this coast, this turns to the standard Cfb Oceanic variant.
      </p>
      <p>
        Hopping across the North Sea, and we arrive in the British Isles, where both islands of Great Britain and Ireland are firmly within
        the Cfb Oceanic zone. The Western coasts of Ireland and Scotland have the most rain, and mildest temperature ranges, being directly
        washed by the warm Gulf stream ocean current. Further east, on the leeward side of the mountain ranges of Wales and Scotland and
        throughout England, winters in England are cooler and summers warmer. It could be argued that the British are masters of weather
        complaining, being forever lashed by Atlantic storms, and yet also, when a particularly cold winter snap or summer heat wave
        strikes, we also complain, since such events lie outside the normal mild range that we in these islands are so used to. I, of
        course, am speaking from native’s perspective…
      </p>
      <ChapterImageList images={imagesLandscapes2} />
      <p>
        Heading south across the English channel and Bay of Biscay and we arrive on the main continent of Europe, where the north coast of
        Spain is visited by Atlantic storms year round. Heading north and east, and we find that most of France is under the Oceanic
        influence, and like Victoria in Australia, has some of the warmest summers that this climate has to offer. As we continue to move
        north and east along the North European plain, we find few obstructions in the form of mountains to block westerly winds and so this
        climate extends east into north and west Switzerland, then north into the low countries of Belgium and the Netherlands, and further
        east into Germany, where it covers the bulk of that country, from Bavaria in the south to North Sea coasts in the north. Parts of
        Denmark are also under the Oceanic influence, but, like Eastern Germany, this country lies on the border of the continental climate
        of more seasonal extremes, which <A to="continental">we’ll take up in that future chapter</A>.
      </p>
      <p>
        Despite being spread across so many continents, the mild Oceanic climate is shown as a particular and consistent type by comparing
        temperature and rainfall graphs across each of these areas.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Notable cities with Oceanic Climates" />
      <h3>Notable Cities</h3>
      <p>
        The Oceanic climate is host to many well known cities, since it covers the large population centres of North-West Europe. However,
        lets not forget the other parts of the world where this climate is mirrored, including cities in Canada, Australia and New Zealand.
        The adjacent chart shows the most notable of these.
      </p>
      <h3>Landscapes and Vegetation</h3>
      <p>
        The Oceanic climate has some of the most enviable landscapes in the world. An upside to the year round rain, and not subject to
        intense, burning sun of less temperate climes, the lands of the Oceanic climate are green in a way that no others are. Broken,
        mountainous coasts with deep valleys, known as Fjordlands are a hallmark of this climate. From British Columbia, to Southern Chile,
        South Island New Zealand, and the country that gave its name to such landscapes, Norway, no other climate zone possesses these
        particular natural gems in such quantity. At the other extreme, we have the plains and rolling country of South Eastern Australia
        and Europe, that under human hands over centuries have produced the archetypal form of what we would call “countryside”.
      </p>
      <p>
        In its natural state the Oceanic climate is covered in <A to="/biomes/temperate-forests">thick woodland</A>, either of a deciduous
        or coniferous nature, depending on the region. In some small surviving enclaves we can find areas of temperate rainforest, where so
        much rain falls that these lush woodlands are thick with trees, moss and ferns, producing a very rich green bioscape.
      </p>
      <h3>Agriculture</h3>
      <p>
        Most of the natural forest in Europe has given way to farmland, where a vast array of crops are grown, from apples and strawberries
        to wheat and grapes, including world famous French wine growing regions such as Champagne, to a host of different vegetables and
        even flowers. The Netherlands, at the heart of the Oceanic climate zone has some of the richest farmland in the world and is one of
        the few countries which exports more food than it eats, so much so that it has been called the Market Garden of the World. Some of
        the finest cattle breeds have emerged in this area, including the Frisians or Holsteins of Holland and North Germany, the world’s
        most productive dairy breed, plus the Aberdeen Angus of Scotland, considered the world’s finest beef cattle by many. Northwest
        Europe, with its rich soil suited to the cultivation of barley, also produces the widest array of beers in the world including most
        of the world’s famous brands, with Ireland, England, France, Belgium, Holland, Germany and Denmark all contributing significantly.
      </p>
      <p>
        The region’s finest breeds and seeds have been exported all around the world, and there is perhaps no other area more influential in
        terms of agricultural diversity in the developed world. An example where this has occurred is in the identical climate zone covering
        New Zealand. Here, the Kiwis have exploited this mirror image of the European climate, where the seasons are reversed, allowing them
        to sell to Europe kiwi fruit, apples and lamb during the Northern hemisphere’s off-seasons of autumn and winter.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>What is the dominant airstream in the Oceanic climate? How is it generated and what are its effects?</li>
        <li>Why does the Oceanic climate experience year-round rain, but the adjacent Mediterrean climate sees only rain in winter?</li>
        <li>How do lands in the Oceanic climate compare in temperatures to other places at the same latitude? Why is this?</li>
        <li>What are the Koppen codes for Oceanic climates? How do they differ from each other?</li>
        <li>Describe both natural and human-influenced landscapes in this climate zone.</li>
        <li>How important is agriculture in this climate and describe its influence on the rest of the world?</li>
        <li>List out some countries, regions and cities that experience Oceanic climates.</li>
      </ol>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";

import A from "../components/A";

export default function BiomesGrasslands() {
  const headerImage = "grasslands-hero.jpg";
  const ImageKoppen = "grasslands-koppen.jpg";
  const ImageNames = "grasslands-names.jpg";
  const ImageRoots = "grasslands-roots.jpg";
  const ImageSpecies1 = "grasslands-species-1.jpg";
  const ImageSpecies2 = "grasslands-species-2.jpg";
  const ImageSpecies3 = "grasslands-species-3.jpg";
  const ImageWitw1 = "grasslands-canada-alberta-prairie.jpg";
  const ImageWitw2 = "grasslands-usa.jpg";
  const ImageWitw3 = "grasslands-usa-farmland.jpg";
  const ImageWitw4 = "grasslands-bolivia-puna.jpg";
  const ImageWitw5 = "grasslands-argentina.jpg";
  const ImageWitw6 = "grasslands-russia.jpg";
  const ImageWitw7 = "grasslands-mongolia.jpg";
  const ImageWitw8 = "grasslands-nz.jpg";

  const GlobalDistributionMap = "Biomes-World-Map-Grassland.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";

  const images1 = [
    {
      src: ImageKoppen,
      caption: "There is no strong correlation between climate type and grassland biome presence, indicating other factors are dominant",
    },
    { src: ImageHoldridge, caption: "In the Holdridge Lifezones chart, we find grasslands in the middle as 'Steppe'" },
  ];

  const images2 = [
    { src: ImageNames, caption: "Grasslands around the world have a variety of different local names" },
    { src: ImageRoots, caption: "Grasses bind strongly to the soil, with many species having roots as deep as they are tall above ground" },
  ];

  const imagesWitw1 = [
    { src: ImageWitw1, caption: "Very little of Canada's natural prairie, like this scene in Alberta, remains" },
    {
      src: ImageWitw2,
      caption: "Montana, USA - in the foreground, the prairie is now farmed, but some original grassland can be seen in the distance",
    },
    { src: ImageWitw3, caption: "Most of the original prairie of the USA is now given over to the cultivation of wheat and corn" },
  ];

  const imagesWitw2 = [
    { src: ImageWitw4, caption: "The 'Puna' highland grassland of NW Bolivia. Image courtesy of Thomas Noisel" },
    {
      src: ImageWitw5,
      caption:
        "The 'Pampas' grasslands of Northern Argentina once extensive, but now mostly given over to farmland. Image courtesy of Ricardo Lescano",
    },
  ];

  const imagesWitw3 = [
    { src: ImageWitw8, caption: "Many parts of Eastern New Zealand have 'Tussock' grasslands" },
    { src: ImageWitw6, caption: "The longest stretch of grassland in the world is in the Russian Steppes" },
    { src: ImageWitw7, caption: "Most of North Mongolia has pristine short grassland or 'Steppe'" },
  ];

  const imagesSpecies1 = [
    {
      src: ImageSpecies1,
      caption: "Some North American grass species",
    },
    {
      src: ImageSpecies2,
      caption: "Some Eurasian grass species",
    },
    {
      src: ImageSpecies3,
      caption: "Some grass species families found globally",
    },
  ];

  return (
    <Chapter
      series="biomes"
      seriesChapter="5"
      heading="Grasslands"
      subheading="STEPPE, PRAIRIE, MEADOW, PAMPAS &amp; VELDT"
      documentTitle="The Grassland Biome"
      headerImageSrc={headerImage}
      youTubeCode="Yy191KVBNP0"
      chapterPrevText="Deserts"
      chapterPrevUrl="/biomes/deserts"
      chapterNextText="Temperate Forests"
      chapterNextUrl="/biomes/temperate-forests"
    >
      <p>
        Grass. If there is one plant that has come to dominate our world, it’s this. Occupying every biome on earth except the icesheets,
        grasses have colonised every patch of soil, from tropical to temperate forests, savannah to steppe, scrubland to desert. Humans have
        taken these grasses and shaped them over generations into crops that feed the world today. Their homelands are the seas of grass we
        call Meadow, Prairie, Pampas, Veldt and Steppe - the world’s Grasslands.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        Grasslands are one of only two biomes on our planet to be completely dominated by a single class of plant. But what is grass? A
        simple definition is a non-woody plant containing a hollow stem for strength, bladed leaves for photosynthesis and a flowering
        bundled seed head for reproduction. This simple but highly successful formula has evolved since the end of the Cretaceous period,
        about 66 million years ago to form over 12,000 species today, and grass’s ability to colonise poor soils, survive fire, and turf out
        competing plants has allowed them to spread to almost every corner of earth.
      </p>
      <p>
        The prairies and steppes are the biomes where this class of plant is fully dominant. This is in comparison to the Savannahs of the
        tropics, so often confused with grasslands, where we find a mix of grasses, shrubs and trees. Grassland areas occur in relatively
        temperate drier climates, either in the mid-latitudes, or in high-altitude regions such as the Himalayas and Andes, where rainfall
        is insufficient for tree growth, or where historical conditions, such as pervasive fire or large animal grazing, have prevented
        trees from gaining a foothold.
      </p>
      <p>
        There is no one <A to="/climate/koppen-classification">Koppen climate type</A> that matches the global grassland distribution, with
        continental and cool semi-arid zones, along with humid and highland subtropical zones and even oceanic areas having grasslands. This
        indicates that the coupling between grassland and climate is not as strong as in the other biomes so far looked at, with major
        Koppen groups B, C and D being represented here.
      </p>
      <ChapterImageList images={images1} />
      <p>
        If we consult our Holdridge Lifezones chart, we can find the grasslands in the centre as “steppe”, where conditions are neither
        particularly cold or hot, but where overall rainfall is on the drier side, indicating that grasslands can dominate in these more
        arid areas where trees might struggle.
      </p>
      <p>
        So you’ve probably heard the terms Prairie and Steppe, and possibly Pampas and Veldt also. These terms are more regional in usage
        than technical. Prairie is almost exclusively used to refer to the grasslands of North America, and is a word borrowed from the
        early French settlers to this region, meaning simply “meadow” in that language. Steppe refers mostly to the grasslands of Eurasia,
        and is taken directly from the Russian word for this biome. Pampas is the most common word for the extensive grasslands of
        mid-southern South America and is from the Quechua word for plain. Veldt, the Afrikaans word for field, is a loose term to describe
        the open upland country of eastern South Africa that is sometimes grassland but might also be scrub.
      </p>
      <ChapterImageList images={images2} />
      <p>
        Beyond the vernacular terms, grasslands vary more technically in the height of grass, and in the absence of grazing animals, this
        relationship is determined by the amount of rainfall and soil drainage. Where the soil is wetter, the grass will grow taller, with
        some species growing up to 4m in height, while more arid conditions lead to shorter grass. Anyone who has tried to dig up the turf
        or sod of their lawn will know how well grasses bind to the soil, and the tallgrass species also follow this pattern, with roots
        burrowing into the earth as deep as their structures are above ground.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of Grasslands" />
      <p>
        As grasses are flowering plants, meadows burst into vivid colour during the season of plant reproduction which occurs in spring in
        the mid-latitudes, and at the beginning of the wet season in subtropical highland areas.
      </p>
      <h3>Where in the World</h3>
      <p>
        So where in the world do we find the grasslands? The two northern continents of North America and Eurasia form most of the land area
        of this biome with the great plains of their heartlands being made up of prairie and steppe. The southern hemisphere by comparison
        has more isolated areas.
      </p>
      <p>
        The North American prairies extend unbroken in vast plains from Alberta, Saskatchewan and Manitoba in Canada south and east into the
        central part of the USA, including the states of Montana, the Dakotas, Minnesota, Wyoming, Nebraska, Iowa, Kansas, Missouri,
        Oklahoma, New Mexico and Texas. In these regions, the eastern parts receive more rain and are naturally tallgrass prairies which
        blend into the temperate forests of the eastern part of that continent. As we travel west toward the Rockies, less rainfall leads to
        shorter grass until semi-arid and scrub conditions occur. Parts of the “High Desert” of Washington, Oregon, Idaho and Nevada have
        grassland, as does the low-lying central valley of California. In practically all these areas, however, the excellent soils and
        suitability for wheat and corn farming have led to decimation of natural prairie, with very few areas remaining.
      </p>
      <ChapterImageList images={imagesWitw1} />
      <p>
        South America has three areas of grassland. The first, known as Puna, can be found in upland subtropical areas of the Andes, where
        much of this remains preserved in its natural state. It’s a different story for the Pampas of North-Eastern Argentina, Uruguay and
        Southern Brazil, however, where the extensive Pampas has been converted almost entirely to farming, with ranching dominating.
        Lastly, the eastern tip of this continent, from Tierra del Fuego to Southern Patagonia, as well as the Falkland Islands, are
        dominated by grasslands in the most polar latitudes of any in the world.
      </p>
      <ChapterImageList images={imagesWitw2} />
      <p>
        In Africa, that continent dominated by the tropical Savannah, only a small portion of it in eastern South Africa is considered a
        part of the true grassland biome, found in uplands, where it is known as the Veldt.
      </p>
      <p>
        We find a surprising area of natural grassland on both islands of New Zealand, a country otherwise dominated by temperate forests
        owing to its Oceanic climate. The causes of these “Tussock” grasslands are poor soils in the centre of the North Island, and a rain
        shadow in the South Island east of the Southern Alps that reduces rainfall significantly.
      </p>
      <p>
        In the Middle East, we have relatively isolated pockets of grassland in upland areas of Turkey and the southern Caucasus, while many
        subtropical areas surrounding the Tibetan plateau have this biome in a similar way to those of the Andes.
      </p>
      <p>
        However, these areas pale in comparison to the largest area of grassland on Earth – the vast Steppe plains of Eurasia. Running in an
        unbroken line at around 45°N in latitude, the steppe separates the temperate and boreal forests of the north from the deserts of the
        south. Starting in the west of the continent, in Eastern Europe, they encompass the whole of the Ukraine and the southern half of
        European Russia, where most of the natural steppe has been transformed into the Wheat Belt that feeds these countries in a similar
        way to that of the North American prairies. Further east, the steppe runs along the boundary of Russia and Kazakhstan, and then east
        again, ending with the border of Mongolia, where the northern half that country is dominated by steppe still mostly in its natural
        state.
      </p>
      <ChapterImageList images={imagesWitw3} />
      <h3>Species</h3>
      <p>
        Species diversity in natural grasslands is high. Region by region, we find in the North American Prairie, among others, Big
        bluestem, Indiangrass and Switchgrass species. In the Eurasian Steppe, common species families include Stipa, Festuca and Agropyron.
        The eponymous Pampas grass is found in that South American grassland, while the equally eponymous Veldtgrass (Ehrharta calycina) is
        from South Africa, and in the New Zealand tussock grasslands, Chionochloa is common.
      </p>
      <ChapterImageList images={imagesSpecies1} />
      <p>
        Like the savannahs of Africa, the presence of large areas of edible grass in the prairies and steppes has resulted in the support of
        vast herds of grazing animals. In North America there were tens of millions of Bison roaming the prairie before the mass slaughter
        of the 1800s. And let’s not forget that the grasslands of North America and Eurasia led to the evolution of perhaps the most
        important animal in human history – the horse.
      </p>
      <p>
        As mentioned, most of these areas of natural grassland have vanished, being replaced by agriculture, such is the quality of their
        soils and climatic suitability for cereal crops. But unlike other biomes that have been diminished by human activity, the grasslands
        have, really, just been adjusted, because what has replaced these grasslands is… just more grass. The domestication of various
        species of the grass family into cereal crops is regarded as one of the key developments of human civilisation. Wheat, corn, rice,
        barley, oats, millet, sugar and many more crops that directly, or through fodder for livestock, feed us, are all grasses. And so
        this humble family of plants that colonised the world and later went onto feed ours can be thought of in many ways as the most
        important in our world.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>What is grass?</li>
        <li>Where are grasses (and not just grasslands) found in the world?</li>
        <li>Is there a Koppen climate type strongly associated with grasslands?</li>
        <li>What are some other names for grasslands?</li>
        <li>List out some countries and regions that contain grasslands.</li>
        <li>Are grasses important for humanity? Explain.</li>
      </ol>
    </Chapter>
  );
}

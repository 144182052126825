import React from "react";
import { Link } from "react-router-dom";

export default function A(props) {
  return (
    <Link to={props.to} >      
    {/* target="_blank" */}
      {props.children}
    </Link>
  );
}

import React from "react";
import { Link } from "react-router-dom";

import Menu from "./menu";
import Util from "./util";

export default function Header(props) {
  const logo = "logo.png";

  return (
    <div className="header">
      <div className="logo">
        <Link to="/">
          <img src={`${process.env.REACT_APP_IMAGES_DIR}/${logo}`} alt="Geodiode Logo: Earth globe stamped with a 'G'" />
        </Link>
      </div>
      <div className="titles">
        <div className="title">Geodiode</div>
        <div className="sub-title">The Ultimate Educational Resource for Climate and Biomes</div>
      </div>
      <Util />
      <Menu />
    </div>
  );
}

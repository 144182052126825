import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function CC6Habitability(props) {
  const headerImage = "habitability-hero.jpg";
  const ImageIcecap = "habitability-icecap.jpg";
  const ImageTundra = "habitability-tundra.jpg";
  const ImageDcd = "habitability-subarctic.jpg";
  const ImageAf = "habitability-tropical-rainforest.jpg";
  const ImageCfb = "habitability-oceanic.jpg";
  const ImageCfbSH = "habitability-subtropical-highland.jpg";
  const ImageBk = "habitability-cool-desert.jpg";
  const ImageCs = "habitability-mediterranean.jpg";
  const ImageDab = "habitability-continental.jpg";
  const ImageBh = "habitability-hot-desert.jpg";
  const ImageAmAw = "habitability-tropical-wet-dry.jpg";
  const ImageCfa = "habitability-humid-subtropical.jpg";
  const ImageGlobal = "habitability-global.jpg";

  return (
    <Chapter
      series="casebook"
      heading="Habitability"
      subheading="CLIMATE &amp; POPULATION DENSITY"
      documentTitle="Habitability - The Effect of Climate Type on Population Density"
      headerImageSrc={headerImage}
      youTubeCode="sXpH4dEIkZA"
      chapterPrevText="Climate Land Areas"
      chapterPrevUrl="/climate/land-areas"
      chapterNextText="Lima: Tropical Desert Metropolis"
      chapterNextUrl="/climate/lima"
    >
      <p>
        In two of the previous climate casebook chapters, we looked at how global population was distributed across each climate zone, along
        with the relative sizes of these zones. Armed with this information, we can now reveal perhaps the one of the most interesting
        metrics in human geography in relation to climate – the relationship between climate and population density – the key index of our
        planet’s habitability.
      </p>
      <p>
        Following the <A to="population">chapter on population distributions across climate zones</A>, there were a number of requests to
        reveal perhaps the most telling of statistics, how climate might determine the number of people that can be supported in a given
        unit area. This would reveal which climate zones are favourable to human settlement, and which ones are adverse. In short, the
        respective habitability of each climate zone.
      </p>
      <p>So without further ado, I now present the list of least and most densely populated climate zones on Earth.</p>
      <ChapterImage right src={ImageIcecap} caption="Icecap population density" />
      <p>
        At number twelve, it is no surprise to find the <A to="icecap">Icecap</A> as the least densely populated climate on our planet. With
        temperatures constantly below freezing, a continuous covering of ice and snow prevents any plant growth, with penguins being the
        only wildlife present on the desolate continent of Antarctica. With a “population” in the low thousands being all within the
        scientific community, these people are concentrated within isolated research stations, leaving the overwhelming majority of the ice
        sheet areas of Greenland and Antarctica being completely uninhabited.
      </p>
      <ChapterImage left src={ImageTundra} caption="Tundra population density" />
      <p>
        At number eleven, and we have the next warmest zone, that of the frigid <A to="tundra">tundra</A> wastes, where only a brief cool
        summer acts as a respite against otherwise perpetually frozen conditions. Naturally such an inhospitable climate supports a very low
        population, comprising mostly of Inuit in the north of Canada and Alaska, the Sami of northern Norway and Finland, and the Nenets of
        northern Russia. The other significant population is split between Chile and Argentina in Tierra del Fuego at the southernmost tip
        of South America in what is really a colder form of the Oceanic climate, without the frigid winters of the Arctic tundra. Spread
        over such a vast area, this naturally produces a very low population density, but still orders of magnitude up from the Icecap. Note
        that the values of this and the Icecap are inverse – showing the number of square kilometres per person, and not the usual persons
        per square kilometre.
      </p>
      <ChapterImage right src={ImageDcd} caption="Subarctic population density" />
      <p>
        At number ten, and, once again we progress to the next warmer zone, that of the <A to="subarctic">Subarctic</A>. Spread over a vast
        area of North America and Eurasia, its population is in the millions, so the density is orders of magnitude higher than the Tundra.
        Although this figure is deceptive, as the vast majority of these millions live within the few cities in this zone, leaving most of
        the endless wilderness of the boreal forests just that – empty.
      </p>
      <ChapterImage left src={ImageBk} caption="Cool Desert population density" />
      <p>
        At number nine, with just over 20 people per square km, and we increase our population density again by at least an order of
        magnitude, with the inhabitants of the <A to="cool-deserts">Cool Desert</A> regions deep within the northern continents and on the
        dry sides of mountains. With arid conditions combined with freezing winters, it’s no surprise that this is least hospitable climate
        zone outside of the polar regions of our planet as measured by population density. Still, the total population does number a third
        of a billion, and so these vast regions are home to a significant proportion of Earth’s population.
      </p>
      <ChapterImage right src={ImageBh} caption="Hot Desert population density" />
      <p>
        At number eight and we have the other form of deserts on our planet, those with searing summer temperatures, but without the
        freezing winters – the largest of all climate zones – the <A to="hot-deserts">Hot Deserts</A>. Remarkably, almost a billion people
        are spread out over the hottest and driest parts of the world, a testament to the ingenuity of our species. However, as always,
        average figures can be deceptive, as most of this population is confined to densely packed areas around rivers such as the Nile,
        Indus and Tigris/Euphrates, leaving the vast majority of the desert areas devoid of habitation.
      </p>
      <ChapterImage left src={ImageAf} caption="Tropical Rainforest population density" />
      <p>
        Next on our list, and we go from among the driest to the wettest of all climates, with the{" "}
        <A to="tropical-rainforest">Tropical Rainforest</A>. Only a fraction of the size of the hot deserts, the rainforests support also a
        fraction of the population, and so are only marginally higher in terms of population density. The steamy jungles of the equatorial
        regions is not a biome that is suitable for human habitation, being filled with disease, biting critters and a thick undergrowth
        that only understands the blade of a machete knife.
      </p>
      <ChapterImage right src={ImageDab} caption="Continental climate population density" />
      <p>
        Onto number six, now, and we are into our final half, where we find the most densely populated zones on earth. The first of these is
        the <A to="continental">Continental</A> climate of the interior of the northern continents, characterised by cold winters but warm
        to hot summers. The heartlands of the United States, Canada and Russia are within this large zone, which sports a large population
        also, providing a population density double that of the Tropical Rainforest. Unlike the preceding zones, here the population is not
        just confined to cities, but also spread out across the vast plains in the form of farms and market towns as this zone is the first
        in our list to be dominated by agriculture.
      </p>
      <ChapterImage left src={ImageAmAw} caption="Tropical Savannah &amp; Monsoon population density" />
      <p>
        As we move to number five, we increase the population density again by half, with the{" "}
        <A to="tropical-monsoon-and-tropical-savannah">Tropical Wet and Dry Climates</A> – the monsoon and savannah. This is both the second
        largest and second most populated of all zones, and so is among the most important planet wide, covering a huge number of countries
        across the tropics. Fertile conditions for growing crops such as rice and corn are, however, mitigated by the presence of tropical
        diseases, which push this zone down in the list of population density as a result.
      </p>
      <ChapterImage right src={ImageCfb} caption="Oceanic population density" />
      <p>
        Onto number four, now, and the next three zones are the three smallest, starting with the <A to="oceanic">Oceanic</A> of the
        maritime continental west coasts. Cool winters and warm summers together with year-round rain yield the perfect conditions for a
        rich array of agriculture, but also free from tropical diseases. Most of the people of this zone are packed within one of the most
        densely populated regions on the planet – North-West Europe.
      </p>
      <ChapterImage left src={ImageCs} caption="Mediterranean climate population density" />
      <p>
        With a slightly denser population, at number three, and we have the Oceanic’s hotter and drier neighbour, the{" "}
        <A to="mediterranean">Mediterranean</A> climate zone. With its unique dry summer conditions, agriculture is more of a challenge than
        the Oceanic, but with the right irrigation flourishes under the sunnier days. Perhaps this more desirable climate has offset any
        agricultural challenges in giving this zone a more than equal population density compared to its wetter northern neighbour.
      </p>
      <ChapterImage right src={ImageCfbSH} caption="Subtropical Highland population density" />
      <p>
        At number two and we have the last in the trio of small climate zones, that of the{" "}
        <A to="subtropical-highlands">Subtropical Highlands</A>. Nestled in the uplands of the tropics, enjoying year round mild
        temperatures and being free from much of the diseases of the tropics, it’s no wonder that this zone sports a population density four
        times that of the Tropical Rainforest and fifty percent more than the Tropical Wet and Dry climates. Major cities such as Mexico
        City, Bogotá, Quito and Addis Ababa are surrounded by fertile farmland where the majority of the world’s coffee and tea are grown.
      </p>
      <ChapterImage left src={ImageCfa} caption="Humid Subtropical population density" />
      <p>
        And so we come to the most densely populated climate zone of all, and it’s really in a league of its own. It’s the zone with the
        largest population, but having a land area only half that of the Tropical Wet and Dry. It is a climate type that most of you new to
        this channel would not have heard of but which has now achieved somewhat of a celebrity status here – it’s the{" "}
        <A to="humid-subtropical">Humid Subtropical</A> of hot summers, cool winters and plenty of rain. Occupying every eastern continental
        margin just above the Tropics, it’s no surprise that this zone, having fifty percent higher density than the next highest zone, tops
        out this list, with the enormous populations of China and India packed into relatively small areas along the Yangtze, Pearl and
        Ganges Rivers. In these regions the most important of all world crops, rice, can grow easily. But unlike in other zones where this
        crop thrives, the cool winters kill off much of the disease that plagues its more tropical neighbours, improving life expectancy,
        and so boosting population density.
      </p>
      <ChapterImage right src={ImageGlobal} caption="Global climate zone population density" />
      <p>
        So with all our zones now calculated, let’s perform an overall comparison in our final graphic, which is probably the most
        interesting and telling in all of the articles I have yet written. Because in it we can see a clear relationship of how human
        habitability is affected by climate. The most densely populated zone, the Humid Subtropical is two million times greater in density
        than that of the least, the Icecap. That’s an enormous leap in orders of magnitude. Most of these orders are consumed within the
        polar regions of our planet, however, leaving most climate zones in the same order of magnitude, from the Cool Desert up.
      </p>
      <p>
        It is clear that a lack of temperature and water are the enemies of human habitation, with the deserts and polar regions all being
        at the bottom. However the effect of cold temperatures is much, much greater than that of aridity, which is an interesting finding
        in and of itself, perhaps because technology is able to arrange for the channelling of water much more easily than the rather
        impossible task of warming the air and ground around you.
      </p>
      <p>
        The top four most habitable zones are all temperate, featuring mild temperatures and sufficient rainfall, escaping the diseases of
        the tropics, supporting rich agricultural systems, and generally being pleasant to human experience. On that very last statement, I
        know some of you have already made plain your complaints of the “pleasantness” of the Humid Subtropical, but I bet that if we did a
        survey, three out of the top four climate zones listed here would all be in the top of your lists. You’re all free to share these in
        the comments, by the way – it’ll be an interesting exercise in human perception of climate.
      </p>
    </Chapter>
  );
}

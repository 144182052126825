import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function CC7Lima(props) {
  const headerImage = "lima-hero.jpg";
  const ImageLocation = "lima-location.jpg";
  const GlobalDistributionMap = "Hot-Deserts-Global-Distribution.jpg";
  const ImageAndesBlockTradeWinds = "lima-andes-block-moist-trade-winds.jpg";
  const ImageTropicalSavannah = "lima-tropical-savannah.jpg";
  const ImageHumboldtCurrent = "lima-humboldt-current.jpg";
  const ImageSalvador = "lima-salvador.jpg";
  const ImageSunshineHours = "lima-sunshine-hours.jpg";
  const ImageWarmAirHoldsMoreMoisture = "lima-warm-air-holds-more-moisture-than-cold.jpg";
  const ImageFogPrecipitation = "lima-fog-precipitation.jpg";
  const ImageRainFormation = "lima-rain-formation.jpg";
  const ImageSeaBreezesOrigin = "lima-sea-breezes-origin.jpg";
  const ImagePeruFogs = "lima-coastal-fog.jpg";
  const ImageCairo = "lima-cairo.jpg";
  const ImageBWn = "lima-bwn.jpg";
  const ImageFogNets = "lima-fog-nets.jpg";

  const imagesLocation = [
    {
      src: ImageLocation,
      caption: "Lima, situated half-way along the coast of Peru is only 12°S of the equator and so is firmly within the tropics",
    },
    {
      src: GlobalDistributionMap,
      caption: "Deserts are not usually found within the Tropics, but in Subtropical areas beyond 23° north and south of the equator",
    },
  ];

  const imagesCauses = [
    {
      src: ImageAndesBlockTradeWinds,
      caption: "The high Andes mountains block moist trade winds...",
    },
    {
      src: ImageTropicalSavannah,
      caption: "...that would otherwise lead to a Tropical Savannah climate with a wet and dry season",
    },
    {
      src: ImageHumboldtCurrent,
      caption: "The strong Humboldt ocean current brings cold waters up from Pacific polar waters to the equator, via the coast of Peru",
    },
  ];

  const imagesConsequences = [
    {
      src: ImageSalvador,
      caption: "Lima has temperatures lower than typically found that this latitude",
    },
    {
      src: ImageSunshineHours,
      caption: "Lima is one of the cloudiest cities in the world, having only half the sun of other cities at the same latitude",
    },
  ];

  const imagesCloudFormation = [
    {
      src: ImageWarmAirHoldsMoreMoisture,
      caption: "Warmer air holds more moisture than cooler air",
    },
    {
      src: ImageFogPrecipitation,
      caption: "As cooling air approaches 100% humidity, the water vapour begins to condense into tiny droplets - this is a cloud or fog",
    },
    {
      src: ImageRainFormation,
      caption:
        "Disturbance of the water droplets through strong currents results in the fog coalescing into bigger water droplets which then fall to earth as rain",
    },
  ];

  const imagesFogs = [
    {
      src: ImageSeaBreezesOrigin,
      caption: "Tropical sun heats the land more than the sea, resulting in thermals that pull in air from the ocean",
    },
    {
      src: ImagePeruFogs,
      caption:
        "The coastal fog, once pushed further inland, is warmed, dissolving the droplets back into the air as vapour, producing clear air again",
    },
  ];

  const imagesKoppen = [
    {
      src: ImageCairo,
      caption: "Lima has much cooler summers compared to other cities with the 'Hot Desert' (Koppen: Bwh) climate type",
    },
    {
      src: ImageBWn,
      caption:
        "Lima's official Koppen code is BWh. BWn is an unofficial code to describe a 'mild desert' but is not recognised in peer-reviewed academic papers",
    },
  ];

  return (
    <Chapter
      series="casebook"
      heading="Lima"
      subheading="THE TROPICAL DESERT METROPOLIS"
      documentTitle="The Climate of Lima, Peru - The Tropical Desert Metropolis"
      headerImageSrc={headerImage}
      youTubeCode="YvkuNMAUQQ4"
      chapterPrevText="Habitability"
      chapterPrevUrl="/climate/habitability"
      chapterNextText="Microclimates"
      chapterNextUrl="/climate/microclimates"
    >
      <p>
        A nation's capital, a sprawling city of 10 million people. This city lies between some of the highest peaks in the world, and the
        planet's largest ocean. The world's only major city to exist in a desert within the tropics, it is host to one of the world's most
        peculiar climates. A city of mild temperatures, rolling fogs and almost no rain, this is the tropical desert metropolis. This is
        Lima.
      </p>
      <p>
        Lima is the capital of Peru, founded in 1535 during the Spanish conquest, and has a population in its metropolitan area of just over
        10 million. It is located midway along that country's coast with the Pacific Ocean and being at a latitude of only 12 degrees south,
        is firmly within the tropics. However, due to a number of causes as we shall explore, the city experiences virtually no rain, and so
        is unlike any other major city within the tropical band that normally experiences heavy rain, either year-round, or interspersed
        with a dry season. While the city is home to typically tropical high humidity, it only experiences frequent low cloud and fog. And
        tropical deserts are rare. Hot deserts mostly occur in subtropical regions just beyond the tropics of Capricorn and Cancer, while
        cool deserts are present in the middle of continental land masses or in the rain shadow of mountains.
      </p>
      <ChapterImageList images={imagesLocation} />
      <p>
        So what is going on here? Well, in essence, it is down to two main factors. The first of these is that the Andes mountains, rising
        above 6,300m (20,000 feet) to the east of the city, block almost all of the moist trade winds that blow down in a south-westerly
        direction from the equator during summer. In the absence of these mountains, Lima would have experienced a rainy season during the
        summer, and a <A to="tropical-monsoon-and-tropical-savannah">Tropical Savannah</A> climate typical of such a latitude. But the Andes
        prevent this wet season.
      </p>
      <ChapterImageList images={imagesCauses} />
      <p>
        The second factor is the presence of the Humboldt ocean current, also known as the Peru current, which brings cold water up from the
        mid-latitudes of the Pacific all the way to the equator via the coast of Peru. Such is the strength and volume of this current, that
        water temperatures near the equator at the border with Ecuador are around 16°C, ten degrees lower than waters typically found at
        this central latitude.
      </p>
      <p>The consequences of unusually cool waters just off the Peruvian coast lead to a number of phenomena.</p>
      <p>
        The first of these is simple, in that the coast is much cooler than would be expected at such a tropical latitude, with winter
        temperatures falling to 14°C instead of the low twenties and summer highs averaging only 26°C instead of the low thirties. The
        second factor is further denial of potential rain for the city coming off the Pacific. This is despite high relative humidities, of
        80 percent or more, found year round. Instead, frequent low cloud and fog is the norm, especially during winter. In fact Lima is the
        one of the cloudiest cities to be found outside of mid-latitude <A to="oceanic">Oceanic</A> or <A to="tundra">Polar</A> climates,
        with average sunshine hours being only 1,230 per year. By contrast, Bangkok, at a similar latitude north of the equator, has double
        that number.
      </p>
      <ChapterImageList images={imagesConsequences} />
      <p>
        In order to understand why this humidity does not precipitate into rain, we need to understand a bit more about the formation of
        rain and unstable versus stable air. Air at higher temperatures can hold more water vapour than colder air. So a cubic metre of air
        at 30 degrees with a relative humidity of 80%, for instance, will contain more water vapour than the same block of air at 15
        degrees. Typically, the means by which air can be cooled in weather is by lifting the air higher into the atmosphere, since higher
        altitudes are cooler. This is done by either unstable air circulation, in the form of storm systems or thunderstorms, or through the
        air being pushed up over a mountain range. As the air is cooled, the relative humidity increases as the capacity of the air to hold
        the same amount of water vapour is reduced. When the relative humidity approaches 100%, the water will precipitate out of the air to
        form tiny water droplets. This is what a cloud is, and a cloud that forms near the ground is simply referred to as fog. In
        turbulent, moving air, these water droplets collide and coalesce, and eventually form much bigger droplets, which then fall to earth
        as rain.
      </p>
      <ChapterImageList images={imagesCloudFormation} />
      <p>
        In the case of Lima, the land, heated by the high tropical sun, is almost always warmer than the nearby cold ocean during the
        daytime. This can lead to sea breezes that pull moist air from the ocean onto the warmer land. But this is the exact opposite of
        what we need in order to form rain, since any moisture in such air can comfortably remain as water vapour as the air warms up. If
        this air is saturated, with a relative humidity of 100%, then it will blow onto the land in the form of fog, and sometimes a light
        drizzle, before its heated enough several miles inland to dissolve back into the air as water vapour. So such fogs, which occur all
        along the Peruvian coast, are localised to just a few miles from the ocean.
      </p>
      <ChapterImageList images={imagesFogs} />
      <p>
        Such conditions - the presence of the Andes blocking trade winds, and the cold ocean along the coast, lead to very stable
        atmospheric conditions, and the inability of the tiny water droplets to coalesce into rain. And so we have a desert.
      </p>
      <p>
        In terms of Koppen Climate Classification, Lima has a "Hot Desert Climate", with the code "BWh", B for "Dry Climate Types", "W" for
        true desert and not semi-arid, and "h" to distinguish it from cool deserts, having a mean annual temperature over 18°C. However, as
        just described, summer temperatures are lower than a typical hot desert climate, as can be seen in the comparison with this other
        well-known desert city. In my <A to="hot-deserts">Hot Deserts article</A>, I alluded to the Koppen code "BWn" for Lima, describing
        it as a "mild desert", but this code is unofficial and is not found in peer-reviewed academic papers such as those of Beck et al of
        2018.
      </p>
      <ChapterImageList images={imagesKoppen} />
      <ChapterImage right src={ImageFogNets} caption="Remote coastal communities use nets to catch coastal fog for potable water" />
      <p>
        Lima is not alone in being a large city within a desert, as it's clear from the list of cities revealed in my Hot Deserts article.
        The civic authorities have been able to source sufficient water to provide for the city's needs, in this case through underground
        springs in the Andean foothills and from rivers further into the Andes, and pumped down to the coast. This has not acted as a brake
        to the city's continued growth - Lima is now the third or fourth largest city on the continent, depending upon how city boundaries
        are decided.
      </p>
      <p>
        Unable to rely upon piped water systems, more remote communities along the desert coast of Peru have resorted to more ingenious
        means of obtaining clean water. Nets are used to collect the tiny droplets of fog. When trapped, these droplets build up and
        eventually coalesce to form drops of water which then fall, like man-made rain, into gutters below. So through the ingenuity of
        humans, the fogs of coastal Peru, at last, bring rain.
      </p>
    </Chapter>
  );
}

import React from "react";
import { BrowserRouter } from "react-router-dom";

import Content from "./components/content";
import Footer from "./components/footer";
import Header from "./components/header";

function App() {
  return (    
      <BrowserRouter>
        <div className="App">
          <Header />
          <Content />
          <Footer />
        </div>
      </BrowserRouter>    
  );
}

export default App;

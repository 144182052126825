import React from "react";
import ChapterImage from "./chapter-image";

export default function ChapterImageList(props) {
  return (
    <div className="chapter-image-list">
      {props.images && props.images.map((image) => <ChapterImage key={image.caption} src={image.src} caption={image.caption} />)}
    </div>
  );
}

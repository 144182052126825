import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function CC0Koppen(props) {
  const ClimateGraphs = "climate-graphs-from-every-koppen-zone.gif";
  const headerImage = "0-ALL.jpg";
  const ImageA = "A.jpg";
  const ImageB = "B.jpg";
  const ImageC = "C.jpg";
  const ImageD = "D.jpg";
  const ImageE = "E.jpg";
  const ImageAllCodes = "Koppen-Tiles.gif";
  const ImageExampleCodes = "koppen-codes-example.jpg";
  const ImageInfoSheetAf = "InfoSheetAf.jpg";
  const ImageInfoSheetAm = "InfoSheetAm.jpg";
  const ImageInfoSheetAw = "InfoSheetAw.jpg";
  const ImageInfoSheetBWk = "InfoSheetBWk.jpg";
  const ImageInfoSheetBSk = "InfoSheetBSk.jpg";
  const ImageInfoSheetBWh = "InfoSheetBWh.jpg";
  const ImageInfoSheetBSh = "InfoSheetBSh.jpg";
  const ImageInfoSheetCfa = "InfoSheetCfa.jpg";
  const ImageInfoSheetCwa = "InfoSheetCwa.jpg";
  const ImageInfoSheetCsa = "InfoSheetCsa.jpg";
  const ImageInfoSheetCfb = "InfoSheetCfb.jpg";
  const ImageInfoSheetCfbSH = "InfoSheetCfbSH.jpg";
  const ImageInfoSheetCsb = "InfoSheetCsb.jpg";
  const ImageInfoSheetCwb = "InfoSheetCwb.jpg";
  const ImageInfoSheetCfc = "InfoSheetCfc.jpg";
  const ImageInfoSheetDfaDfb = "InfoSheetDfaDfb.jpg";
  const ImageInfoSheetDwaDwb = "InfoSheetDwaDwb.jpg";
  const ImageInfoSheetDsaDsb = "InfoSheetDsaDsb.jpg";
  const ImageInfoSheetDfcDfd = "InfoSheetDfcDfd.jpg";
  const ImageInfoSheetDwcDwd = "InfoSheetDwcDwd.jpg";
  const ImageInfoSheetDscDsd = "InfoSheetDscDsd.jpg";
  const ImageInfoSheetET = "InfoSheetET.jpg";
  const ImageInfoSheetEF = "InfoSheetEF.jpg";

  const imagesAtoE = [
    {
      src: ImageA,
      caption: "Tropical Climate Zones - Koppen Family 'A'",
    },
    {
      src: ImageB,
      caption: "Dry Climate Zones - Koppen Family 'B'",
    },
    {
      src: ImageC,
      caption: "Temperate Climate Zones - Koppen Family 'C'",
    },
    {
      src: ImageD,
      caption: "Continental Climate Zones - Koppen Family 'D'",
    },
    {
      src: ImageE,
      caption: "Polar Climate Zones - Koppen Family 'E'",
    },
  ];

  const imagesCodes = [
    {
      src: ImageAllCodes,
      caption: "All Koppen codes in one place",
    },
    {
      src: ImageExampleCodes,
      caption: "Example of how three-digit Koppen codes work",
    },
  ];

  const imagesInfoSheetA = [
    {
      src: ImageInfoSheetAf,
      caption: "Tropical Rainforest Climate Info Sheet",
    },
    {
      src: ImageInfoSheetAm,
      caption: "Tropical Monsoon Climate Info Sheet",
    },
    {
      src: ImageInfoSheetAw,
      caption: "Tropical Savannah Climate Info Sheet",
    },
  ];

  const imagesInfoSheetB = [
    {
      src: ImageInfoSheetBWh,
      caption: "Hot Desert Climate Info Sheet",
    },
    {
      src: ImageInfoSheetBSh,
      caption: "Hot Semi Arid Climate Info Sheet",
    },
    {
      src: ImageInfoSheetBWk,
      caption: "Cool Desert Climate Info Sheet",
    },
    {
      src: ImageInfoSheetBSk,
      caption: "Cool Semi Arid Climate Info Sheet",
    },
  ];

  const imagesInfoSheetC = [
    {
      src: ImageInfoSheetCfa,
      caption: "Humid Subtropical Climate Info Sheet",
    },
    {
      src: ImageInfoSheetCwa,
      caption: "Subtropical Monsoon Climate Info Sheet",
    },
    {
      src: ImageInfoSheetCsa,
      caption: "Hot Summer Mediterranean Climate Info Sheet",
    },
    {
      src: ImageInfoSheetCsb,
      caption: "Warm Summer Mediterranean Climate Info Sheet",
    },
    {
      src: ImageInfoSheetCwb,
      caption: "Subtropical Highland (Dry Winter) Climate Info Sheet",
    },
    {
      src: ImageInfoSheetCfbSH,
      caption: "Subtropical Highland (No Dry Season) Climate Info Sheet",
    },
    {
      src: ImageInfoSheetCfb,
      caption: "Oceanic Climate Info Sheet",
    },
    {
      src: ImageInfoSheetCfc,
      caption: "Subpolar Oceanic Climate Info Sheet",
    },
  ];

  const imagesInfoSheetD = [
    {
      src: ImageInfoSheetDfaDfb,
      caption: "Continental Climate Info Sheet",
    },
    {
      src: ImageInfoSheetDwaDwb,
      caption: "Continental Monsoon Climate Info Sheet",
    },
    {
      src: ImageInfoSheetDsaDsb,
      caption: "Dry Winter Continental Climate Info Sheet",
    },
    {
      src: ImageInfoSheetDfcDfd,
      caption: "Subarctic Climate Info Sheet",
    },
    {
      src: ImageInfoSheetDwcDwd,
      caption: "Subarctic Monsoon Climate Info Sheet",
    },
    {
      src: ImageInfoSheetDscDsd,
      caption: "Dry Winter Subarctic Climate Info Sheet",
    },
  ];

  const imagesInfoSheetE = [
    {
      src: ImageInfoSheetET,
      caption: "Tundra Climate Info Sheet",
    },
    {
      src: ImageInfoSheetEF,
      caption: "Icecap Climate Info Sheet",
    },
  ];

  return (
    <Chapter
      series="casebook"
      heading="The Koppen-Geiger Climate Classification"
      subheading="A SUMMARY GUIDE"
      documentTitle="The Koppen-Geiger Climate Classification - A Summary or Quick Guide"
      headerImageSrc={headerImage}
      youTubeCode="BsOL9Fafo2w"
      chapterNextText="Pacific Northwest Climate"
      chapterNextUrl="/climate/pacific-northwest"
    >
      <p>
        In pretty much every area of interest, human beings love to put things into boxes. They love to categorise and classify. From
        artistic subjects such as music and literary genres, to science including stars, chemical elements and animals. Our planet’s climate
        is no exception to this. And one at first might ask why is it necessary to put a label on weather patterns from one part of the
        globe to another. Knowing the patterns of temperature and rainfall is important mostly for understanding how the earth can support
        population in that area through farming. But general knowledge of this also impacts on other areas such as tourism and migration.
        Regardless, humans will be humans, and in the absence of any perceived application to such knowledge will still classify, for the
        love of science, or to improve understanding.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Climate Graphs from a variety of Koppen zones" />
      <p>
        The primary classification of earth climate is known as the Koppen-Geiger system. It has evolved over the past 150 years to define
        precisely the nature of weather patterns on every part of our planet. Each of these climate zones has been covered in detail in my
        previous series – Secrets of World Climate, in which I show real places in each climate zone and explain why the patterns of
        rainfall and temperature are the way they are.
      </p>
      <p>
        In this new series, I’ll be picking up on aspects of global and local climate that transcend these zones, case by case, in what will
        be known as the Climate Casebook.
      </p>
      <p>
        The first in this series is intended as a quick reference work, summarising each of the Koppen Zones, so that in the articles that
        follow, anyone new to the subject can get a quick grasp of what on earth I’m talking about.
      </p>
      <p>
        Overall, the Koppen-Geiger system has five broad categories, which describe a general pattern and roughly start at the equator and
        end at the pole. These are always the capital first letter of the shorthand codes that every climate zone has, and are as follows.
      </p>
      <p>
        A. TROPICAL – the zones around the equator, which are hot all year round and either have heavy rain all year round or during some
        part of the year.
      </p>
      <p>
        B. DRY – subtropical (meaning just outside the tropics) or continental (meaning deep within continental land masses) areas with very
        little rain year round.
      </p>
      <p>C. TEMPERATE – areas which have relatively mild temperature ranges year round, and occurring at the mid-latitudes near coasts.</p>
      <p>
        D. CONTINENTAL – areas of high temperature ranges occurring usually deep within the northern continents, experiencing cold or very
        cold winters.
      </p>
      <p>E. POLAR – areas which are cold all year around, occurring near or at the poles of our planet.</p>
      <ChapterImageList images={imagesAtoE} />
      <p>
        Each of these broad categories is further subdivided, to account for more precise differences in temperatures or rainfall, and these
        give us the full two or three digit Koppen-Geiger Codes. These codes at first might seem like they’re just hieroglyphics, but a
        quick look can reveal the logic on 80% of them.
      </p>
      <p>
        In all the temperate and continental codes, the second letter tells us when there is a dry season. “s” means the dry season is in
        summer, “w” the dry season is winter, and “f” for no dry season.
      </p>
      <ChapterImageList images={imagesCodes} />
      <p>The third letter tells us how warm or hot it is, with “a” being the hottest and “d” being the coldest.</p>
      <p>So, armed with this knowledge, lets get straight into looking at each and every one of the Koppen zones.</p>
      <p>
        Af – <A to="tropical-rainforest">The Tropical Rainforest</A>, also known as the Equatorial Climate, or Selva (which is Spanish for
        “jungle”). Occurring only within the tropics at low elevations, the key defining characteristic of this climate is rain and hot
        temperatures all year round. This produces the famous rainforest that the zone is named after and the largest biodiversity on Earth.
      </p>
      <p>
        Am – <A to="tropical-monsoon-and-tropical-savannah">The Tropical Monsoon</A>. Found also exclusively within the tropics, this
        climate zone experiences heavy rain during part or most of the year, but also has a relatively short dry, or longer drier season,
        due to the shifting direction of trade winds from land to sea and vice versa.
      </p>
      <p>
        Aw – Tropical Savannah. A close sibling of the Tropical Monsoon, and covering much larger areas in the tropics, here the dry season
        is either completely dry, or is dry over a longer period. Both the Savannah and Monsoon have a mix of woodland and grassland
        depending upon the amount of rain and local topography. Both support enormous populations and many large cities due to the
        combination of heat and rainfall without the human health issues of the rainforests.
      </p>
      <ChapterImageList images={imagesInfoSheetA} />
      <p>
        BWh – <A to="hot-deserts">Hot Desert</A>. Lying on the border of the tropics, these areas experience very little rain, and searing
        summer temperatures which hold the records for the highest on Earth. Little or no vegetation exists here, with only bare rock and
        sand as landscape.
      </p>
      <p>
        BSh – Hot Semi Arid. Lying next to Hot Deserts, these areas have a little more rain to support scrub-like vegetation but are still
        marginal in terms of sustainable human settlement.
      </p>
      <p>
        BWk – <A to="cool-deserts">Cool Desert</A>. Lying deep within the continents far from the ocean, or on the leeward side of mountains
        sheltering them from moist winds, these areas are as parched and desolate as the hot deserts, but experience winter temperatures
        that often fall below freezing point.
      </p>
      <p>
        BSk – Cool Semi Arid. Like the Cool Deserts only with more rain, but not enough to be classified as temperate or continental. Like
        their Hot Semi-Arid counterparts, they support shrub-like vegetation or hardy grasses.
      </p>
      <ChapterImageList images={imagesInfoSheetB} />
      <p>
        Cfa – <A to="humid-subtropical">Humid Subtropical</A>. Lying at a similar latitude to the Hot Deserts, these areas on the eastern
        fringes of the continents, however, are next to warm oceans that bring in plenty of moisture. They experience tropical summers, but
        cool winters, and rain all year round. They are among the most important areas for human civilisation, housing enormous populations
        and many great world cities. Typical natural vegetation ranges from forest to swamp and grassland, but much of these areas have been
        given over to farming.
      </p>
      <p>
        Cwa – Subtropical Monsoon. A sibling of the Humid Subtropical, and found mainly in Asia at the same latitudes, this varies only in
        that it has a winter dry season instead of rain year round due to the presence of the Asian Monsoon.
      </p>
      <p>
        Csa – Hot Summer <A to="mediterranean">Mediterranean</A>. Lying just above the Hot Deserts in latitude, and always on the western
        fringes of continents, these areas experience the hot and dry conditions of deserts in summer, but significant rain from oceanic
        westerly winds in winter. Natural vegetation is limited by the desert-like conditions of summer, and is typically shrub-like.
      </p>
      <p>
        Csb – Warm Summer Mediterranean. Always lying above their Csa siblings on the continental western coasts, these areas have warm dry
        summers instead of hot, and usually even heavier rainfall in winter. With less harsh winters, natural vegetation is usually in the
        form of coniferous forest, including the Redwoods and Sequoias of California, the tallest in the world.
      </p>
      <p>
        Cwb – <A to="subtropical-highlands">Subtropical Highland</A>. Occurring in tropical latitudes, the heat of the rainforest and
        savannah is reduced significantly through being at high altitude. The result is year round mild temperatures, with a pronounced dry
        season. Found mainly in Latin America, East Africa and the foothills of the Himalayas. Natural vegetation is a mix of forest and
        grassland.
      </p>
      <p>
        Cwc – Cool Subtropical Highland. At even higher altitudes in the tropics, the mild temperatures of its sibling Cwb zone gives way to
        noticeably chilly weather, year round. It is not common.
      </p>
      <p>
        Cfb – <A to="oceanic">Oceanic</A> and Subtropical Highland. One of the more confusing Koppen codes, due to an accident of nature
        creating very similar conditions for completely different reasons. Small upland areas near the equator, such as in Colombia and
        Ecuador, have this mild year round climate which also experiences rain year round. But over much larger areas in mid-latitudes, this
        climate exists next to western coasts, where prevailing westerlies moderate temperatures year round such that warm summers and cool
        winters are the norm. In both cases, limited seasonal temperature range and year round rain are its defining hallmark. Natural
        vegetation is mixed woodland, but these areas are heavily farmed.
      </p>
      <p>
        Cfc – Subpolar Oceanic. lying in certain areas higher in latitude or elevation to the Oceanic, these areas still have mild winters,
        but cooler summers.
      </p>
      <ChapterImageList images={imagesInfoSheetC} />
      <p>
        Dfa/Dfb – The first of the six <A to="continental">Continental</A> climates, occurring only within the great land masses of the
        Northern Continents. All these climates experience high temperature ranges between summer and winter, and all have cold winters,
        routinely falling below freezing. These first two experience rain year round, with Dfa experiencing hot summers, and Dfb
        experiencing somewhat cooler but still warm summers, and are the most prevalent, occurring across wide continental areas. Natural
        vegetation is usually in the form of grassland, but these areas are heavily farmed.
      </p>
      <p>
        Dwa/Dwb – The same as Dfa/Dfb except that there is little to no rain during the winter. These occur exclusively in Eastern Asia due
        to the influence of the Asian Monsoon.
      </p>
      <p>
        Dsa/Dsb – The same as the other Continental climates, except that the summer is dry instead of winter. These areas are not common,
        and usually occur inward from Mediterranean climate areas.
      </p>
      <p>
        Dfc/Dfd – The first of the six Subarctic climates. These areas, lying to the north of and similar to the Continental climates,
        except winters are much, much colder. These two zones have rain or snow year round. Like all the subarctic zones, the vegetation is
        dominated by coniferous forest, known by the Russian word Taiga, and farming is almost non-existent. Dfc is by far the most dominant
        of all the Subarctic zones in terms of land area.
      </p>
      <p>
        Dwc/Dwd – These <A to="subarctic">Subarctic</A> zones are identical to the first two except they have dry winters – they occur only
        in Eastern Siberia.
      </p>
      <p>Dsc/Dsd – These very rare Subarctic zones are like the others except that the dry season occurs in summer.</p>
      <ChapterImageList images={imagesInfoSheetD} />
      <p>
        ET – <A to="tundra">Tundra</A>. Even further North than the Subarctic, the true Arctic has the treeless waste of the Tundra, where
        summer temperatures are just too cold to support tree growth, a situation compounded by the presence of permanently frozen soil just
        below the surface, known as permafrost. Only hardy grasses and shrubs survive here. Tundra also occurs in comparatively small alpine
        areas, and around the Straights of Magellan between South America and Antarctica.
      </p>
      <p>
        EF – <A to="icecap">Icecap</A>. Below freezing point all year round, these areas produce ice sheets several kilometres thick and are
        confined to Greenland and Antarctica. Nothing can grow in these harshest of conditions.
      </p>
      <ChapterImageList images={imagesInfoSheetE} />
      <p>
        So that was your rapid-fire summary of the Koppen Climate Zones of Earth. I hope you found it useful in introduction to this system,
        or to link the various zones together into a general pattern.
      </p>
    </Chapter>
  );
}

import React, { useState, useEffect } from "react";

import ImageFader from "./fader-image";

export default function HomeHero(props) {
  const img0 = "bwh-dubai.jpg";
  const img1 = "cfc-lofoten.jpg";
  const img2 = "tropical-forest-guiana.jpg";
  const img3 = "temperate-forests-araucaria-chile.jpg";
  const img4 = "grasslands-montana-usa.jpg";
  const img5 = "savannah-masai-mara-kenya.jpg";
  const img6 = "shrublands-greece-corfu.jpg";
  const img7 = "taiga-finland.jpg";
  const img8 = "iceland-tundra.jpg";
  const img9 = "deserts-uluru.jpg";
  const img10 = "Af-Singapore.jpg";
  const img11 = "tropical-monsoon-miami.jpg";
  const img12 = "tropical-savannah-panama-canal.jpg";
  const img13 = "cool-deserts-grand-canyon.jpg";
  const img14 = "subtropical-highlands-quito.jpg";
  const img15 = "humid-subtropical-koboyama-park.jpg";
  const img16 = "temperate-forests-aspen-colorado.jpg";
  const img17 = "hot-deserts-taj-mahal-agra.jpg";
  const img18 = "cool-deserts-utah.jpg";
  const img19 = "humid-subtropical-delhi.jpg";
  const img20 = "mediterranean-los-angeles.jpg";
  const img21 = "mediterranean-porto.jpg";
  const img22 = "oceanic-london-trafalgar-square.jpg";
  const img23 = "oceanic-milford-sound-new-zealand.jpg";
  const img24 = "tropical-monsoon-rio.jpg";
  const img25 = "continental-hero.jpg";
  const img26 = "continental-toronto.jpg";
  const img27 = "continental-beijing-forbidden-city.jpg";
  const img28 = "icecap-south-pole-station.jpg";

  const assets = [
    {
      img: img0,
      heading: "Dubai",
      sub: "KOPPEN CLIMATE CODE: BWh (HOT DESERT)",
      route: "climate/hot-deserts",
    },
    {
      img: img1,
      heading: "Lofoten, Norway",
      sub: "KOPPEN CLIMATE CODE: Cfc (COOL SUMMER OCEANIC)",
      route: "climate/oceanic",
    },
    {
      img: img2,
      heading: "French Guiana",
      sub: "BIOME: TROPICAL EVERGREEN FOREST",
      route: "biomes/tropical-forests",
    },
    {
      img: img3,
      heading: "Southern Chile",
      sub: "BIOME: TEMPERATE FOREST",
      route: "biomes/temperate-forests",
    },
    {
      img: img4,
      heading: "Montana, USA",
      sub: "BIOME: GRASSLAND",
      route: "biomes/grasslands",
    },
    {
      img: img5,
      heading: "Masai Mara, Kenya",
      sub: "BIOME: SAVANNAH",
      route: "biomes/savannah",
    },
    {
      img: img6,
      heading: "Corfu, Greece",
      sub: "BIOME: SHRUBLAND",
      route: "biomes/shrublands",
    },
    {
      img: img7,
      heading: "Northern Finland",
      sub: "BIOME: TAIGA",
      route: "biomes/taiga",
    },
    {
      img: img8,
      heading: "Central Iceland",
      sub: "BIOME: TUNDRA",
      route: "biomes/polar-biomes",
    },
    {
      img: img9,
      heading: "Uluru, Australia",
      sub: "BIOME: SEMI-ARID DESERT",
      route: "biomes/deserts",
    },
    {
      img: img10,
      heading: "Singapore",
      sub: "KOPPEN CLIMATE CODE: Af (TROPICAL RAINFOREST)",
      route: "climate/tropical-rainforest",
    },
    {
      img: img11,
      heading: "Miami, Florida, USA",
      sub: "KOPPEN CLIMATE CODE: Am (TROPICAL MONSOON)",
      route: "climate/tropical-monsoon-and-tropical-savannah",
    },
    {
      img: img12,
      heading: "Panama Canal",
      sub: "KOPPEN CLIMATE CODE: Aw (TROPICAL SAVANNAH)",
      route: "climate/tropical-monsoon-and-tropical-savannah",
    },
    {
      img: img13,
      heading: "Grand Canyon, Arizona, USA",
      sub: "KOPPEN CLIMATE CODE: BWk (COOL DESERT)",
      route: "climate/cool-deserts",
    },
    {
      img: img14,
      heading: "Quito, Ecuador",
      sub: "KOPPEN CLIMATE CODE: Cfb (SUBTROPICAL HIGHLAND)",
      route: "climate/subtropical-highlands",
    },
    {
      img: img15,
      heading: "Koboyama, Japan",
      sub: "KOPPEN CLIMATE CODE: Cfa (HUMID SUBTROPICAL)",
      route: "climate/humid-subtropical",
    },
    {
      img: img16,
      heading: "Aspen, Colorado, USA",
      sub: "BIOME: TEMPERATE FOREST",
      route: "biome/temperate-forests",
    },
    {
      img: img17,
      heading: "Taj Mahal, Agra, India",
      sub: "KOPPEN CLIMATE CODE: BSh (HOT SEMI-ARID)",
      route: "climate/hot-deserts",
    },
    {
      img: img18,
      heading: "Utah, USA",
      sub: "KOPPEN CLIMATE CODE: BWk (COOL-DESERT)",
      route: "climate/cool-deserts",
    },
    {
      img: img19,
      heading: "Delhi, India",
      sub: "KOPPEN CLIMATE CODE: Cwa (SUBTROPICAL-MONSOON)",
      route: "climate/humid-subtropical",
    },
    {
      img: img20,
      heading: "Los Angeles, California, USA",
      sub: "KOPPEN CLIMATE CODE: Csa (HOT SUMMER MEDITERRANEAN)",
      route: "climate/mediterranean",
    },
    {
      img: img21,
      heading: "Porto, Portugal",
      sub: "KOPPEN CLIMATE CODE: Csb (WARM SUMMER MEDITERRANEAN)",
      route: "climate/mediterranean",
    },
    {
      img: img22,
      heading: "London, England",
      sub: "KOPPEN CLIMATE CODE: Cfb (OCEANIC)",
      route: "climate/oceanic",
    },
    {
      img: img23,
      heading: "Milford Sound, New Zealand",
      sub: "KOPPEN CLIMATE CODE: Cfb (OCEANIC)",
      route: "climate/oceanic",
    },
    {
      img: img24,
      heading: "Rio de Janeiro, Brazil",
      sub: "KOPPEN CLIMATE CODE: Am (TROPICAL MONSOON)",
      route: "climate/tropical-monsoon-and-tropical-savannah",
    },
    {
      img: img25,
      heading: "Moscow, Russia",
      sub: "KOPPEN CLIMATE CODE: Dfb (WARM SUMMER CONTINENTAL)",
      route: "climate/continental",
    },
    {
      img: img26,
      heading: "Toronto, Canada",
      sub: "KOPPEN CLIMATE CODE: Dfa (HOT SUMMER CONTINENTAL)",
      route: "climate/continental",
    },
    {
      img: img27,
      heading: "Beijing, China",
      sub: "KOPPEN CLIMATE CODE: Dwa (HOT SUMMER CONTINENTAL MONSOON)",
      route: "climate/continental",
    },
    {
      img: img28,
      heading: "South Pole Station, Antarctica",
      sub: "KOPPEN CLIMATE CODE: EF (ICECAP)",
      route: "climate/icecap",
    },
  ];

  const [assetsShown, setAssetsShown] = useState([]);

  const getRandomAsset = () => {
    if (assetsShown.length === assets.length - 1) {
      setAssetsShown([]);
      return;
    }
    let newAsset;
    do {
      newAsset = assets[Math.floor(Math.random() * 27)];
    } while (assetsShown.find((as) => as.heading === newAsset.heading));
    setAssetsShown((assetsShown) => [...assetsShown, newAsset]);
    return newAsset;
  };

  const [assetA, setAssetA] = useState(null);
  const [assetB, setAssetB] = useState(null);
  const [fadeStateA, setFadeStateA] = useState("fade-in");
  const [fadeStateB, setFadeStateB] = useState("fade-out");
  const [cycleIndex, setCycleIndex] = useState(0);

  const switchToA = () => {
    return new Promise((resolve) => {
      setAssetA(getRandomAsset());
      setFadeStateA("fade-in");
      setFadeStateB("fade-out");
      setTimeout(() => {
        resolve();
        return;
      }, 2000);
    });
  };

  const switchToB = () => {
    return new Promise((resolve) => {
      setAssetB(getRandomAsset());
      setFadeStateB("fade-in");
      setFadeStateA("fade-out");
      setTimeout(() => {
        resolve();
        return;
      }, 2000);
    });
  };

  const holdA = () => {
    return new Promise((resolve) => {
      setFadeStateA("");
      setFadeStateB("hidden");
      setTimeout(() => {
        resolve();
        return;
      }, 4000);
    });
  };

  const holdB = () => {
    return new Promise((resolve) => {
      setFadeStateA("hidden");
      setFadeStateB("");
      setTimeout(() => {
        resolve();
        return;
      }, 4000);
    });
  };

  useEffect(() => {
    async function doSwitches() {
      await switchToA();
      await holdA();
      await switchToB();
      await holdB();
      setCycleIndex(cycleIndex + 1);
    }
    doSwitches();
  }, [cycleIndex]);

  return (
    <div className="fader-cont">
      <ImageFader asset={assetA} faderClass={fadeStateA} zIndexClass="above" />
      <ImageFader asset={assetB} faderClass={fadeStateB} zIndexClass="below" />
    </div>
  );
}

import React, { useEffect } from "react";
import NavPanelFull from "../components/nav-panel-full";
import Share from "../components/share";
//import SowcImg = "tropical-rainforest-climate-graphs.jpg";
//import SowcVid from "../videos/AfThumb.mp4";
//import AfThumbVideo from "../videos/BhThumb.mp4";

export default function Climate(props) {
  useEffect(() => {
    document.title = "Climate Series - Secrets of World Climate, The Climate Casebook";
  }, []);

  const KoppenThumbPic = "0-ALL.jpg";
  const ImageClimateCasebook = "Koppen-Tiles.gif";

  return (
    <>
      <div className="course-list">
        <NavPanelFull
          src={KoppenThumbPic}
          to="/climate/secrets"
          title="Secrets of World Climate"
          summary="A full video and written course for beginners to climate science, covering in detail every climate zone of earth"
        />
        <NavPanelFull
          src={ImageClimateCasebook}
          to="/climate/casebook"
          title="The Climate Casebook"
          summary="A series of individual topics on particular places around the world or more advanced concepts relating to climate"
        />
      </div>
      <Share />
    </>
  );
}

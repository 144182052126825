import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function CC4Population(props) {
  const headerImage = "population-hero.jpg";
  const ImageTundra = "pops-tundra.jpg";
  const Image2Dcd = "pops-subarctic-countries.jpg";
  const Image1Dcd = "pops-subarctic-codes.jpg";
  const ImageAf = "pops-tropical-rainforest.jpg";
  const Image1Cfb = "pops-oceanic-codes.jpg";
  const Image2Cfb = "pops-oceanic-countries.jpg";
  const Image1CfbSH = "pops-subtropical-highland-codes.jpg";
  const Image2CfbSH = "pops-subtropical-highland-countries.jpg";
  const Image1Bk = "pops-cool-desert-codes.jpg";
  const Image2Bk = "pops-cool-desert-countries.jpg";
  const Image1Cs = "pops-mediterranean-codes.jpg";
  const Image2Cs = "pops-mediterranean-countries.jpg";
  const Image1Dab = "pops-continental-codes.jpg";
  const Image2Dab = "pops-continental-countries.jpg";
  const Image1Bh = "pops-hot-desert-codes.jpg";
  const Image2Bh = "pops-hot-desert-countries.jpg";
  const Image1AmAw = "pops-tropical-wet-dry-codes.jpg";
  const Image2AmAw = "pops-tropical-wet-dry-countries.jpg";
  const Image1Cfa = "pops-humid-subtropical-codes.jpg";
  const Image2Cfa = "pops-humid-subtropical-countries.jpg";
  const ImageGlobal = "pops-global.jpg";

  const imagesDcd = [
    {
      src: Image2Dcd,
      caption: "Subarctic population by countries",
    },
    {
      src: Image1Dcd,
      caption: "Subarctic population by climate sub-type",
    },
  ];

  const imagesCfb = [
    {
      src: Image2Cfb,
      caption: "Oceanic population by countries",
    },
    {
      src: Image1Cfb,
      caption: "Oceanic population by climate sub-type",
    },
  ];

  const imagesCfbSH = [
    {
      src: Image2CfbSH,
      caption: "Subtropical Highland population by countries",
    },
    {
      src: Image1CfbSH,
      caption: "Subtropical Highland population by climate sub-type",
    },
  ];

  const imagesBk = [
    {
      src: Image2Bk,
      caption: "Cool Desert population by countries",
    },
    {
      src: Image1Bk,
      caption: "Cool Desert population by climate sub-type",
    },
  ];

  const imagesCs = [
    {
      src: Image2Cs,
      caption: "Mediterranean population by countries",
    },
    {
      src: Image1Cs,
      caption: "Mediterranean population by climate sub-type",
    },
  ];

  const imagesDab = [
    {
      src: Image2Dab,
      caption: "Continental population by countries",
    },
    {
      src: Image1Dab,
      caption: "Continental population by climate sub-type",
    },
  ];

  const imagesBh = [
    {
      src: Image2Bh,
      caption: "Hot Desert population by countries",
    },
    {
      src: Image1Bh,
      caption: "Hot Desert population by climate sub-type",
    },
  ];

  const imagesAmAw = [
    {
      src: Image2AmAw,
      caption: "Tropical Monsoon &amp; Savannah population by countries",
    },
    {
      src: Image1AmAw,
      caption: "Tropical Monsoon &amp; Savannah population by climate sub-type",
    },
  ];

  const imagesCfa = [
    {
      src: Image2Cfa,
      caption: "Humid Subtropical population by countries",
    },
    {
      src: Image1Cfa,
      caption: "Humid Subtropical population by climate sub-type",
    },
  ];

  return (
    <Chapter
      series="casebook"
      heading="Population"
      subheading="AND ITS DEPENDENCE ON CLIMATE"
      documentTitle="Population and Climate Type"
      headerImageSrc={headerImage}
      youTubeCode="L6ke-8m7zD8"
      chapterPrevText="Seasons"
      chapterPrevUrl="/climate/seasons"
      chapterNextText="Land Areas"
      chapterNextUrl="/climate/land-areas"
    >
      <p>
        Humans. They are an interesting species aren’t they? At the time of this production, there are estimated to be 7.7 billion of them
        roaming around the surface, and that figure is still rising.
      </p>
      <p>
        There are many reasons why people live in the places that they do. On the coast, or along rivers, for trade. Security in the hills,
        away from invaders. Occasionally, rulers arbitrarily create new capitals in the centre of their realms.
      </p>
      <p>
        But the main determinant for human settlement is the availability of clean, fresh water. Because without it, crops cannot grow,
        livestock cannot be raised. Without those, there is no food. Without food, there is no population.
      </p>
      <p>
        And what determines the availability of running water? Rainfall and temperature. Too little rain, and you have a desert. Too cold,
        and you have frozen ground, ice. Too much rain and heat, and you could have disease.
      </p>
      <p>
        So climate and population are inextricably linked. And if you know a bit about climate classification, then you’ve probably wondered
        which climate types have the least and most populations. Well it’s time to wonder no more, because after extensive original research
        and tabulation this data is about to be presented, in this the latest article in my Climate Casebook.
      </p>
      <p>
        If you’ve viewed any of my other articles then you should be familiar with the Koppen climate system, which breaks down the world
        into zones of climate types based on temperature and rainfall patterns. If you haven’t, then I recommend you check out my{" "}
        <A to="secrets">Secrets of World Climate series</A>, or for a quick overview, <A to="koppen-classification">this summary article</A>
        .
      </p>
      <p>
        There are 29 Koppen zones in total, but I’ve grouped most of these into the twelve more distinct categories that formed each chapter
        in my <A to="secrets">Secrets of World Climate series</A>. So, let’s get right into which climate zones support what populations.
        You’re probably wondering which zone has the biggest population, but to keep you in suspense, we’re going to go in reverse order,
        starting with the most empty of human life.
      </p>
      <p>
        At number twelve there are no surprises for guessing that the least populated climate zone is the <A to="icecap">Icecap</A>. Since
        temperatures never rise above freezing, no self-sustaining settlements are possible, and so research stations make up the
        “population” here. The number of scientists varies depending on the season with summer being higher than winter, and the vast
        majority of this population numbering in the thousands is in Antarctica with typically less than one hundred on the Greenland ice
        sheet at any given time.
      </p>
      <ChapterImage right src={ImageTundra} caption="Populations in the Tundra" />
      <p>
        At number eleven, we have the next coldest zone – that of the <A to="tundra">Tundra</A>. In these bleak, treeless lands, agriculture
        is impossible, and any settlements here either have to rely on supplies from outside, or on hunting wildlife. Total tundra
        population worldwide is estimated to be in the hundreds of thousands – a tiny, tiny fraction of the total human population. These
        populations are scattered worldwide with about half spread along the coasts of the Arctic and the other half concentrated in the
        southern tip of South America. Some of you may take issue with the inclusion of Tierra del Fuego, since it’s not true tundra in that
        it does not experience very cold winters like the arctic, and is in fact a slightly cooler version of the Subpolar Oceanic variety
        where summer temperatures rarely exceed 10°C. But it would be an omission to not mention it.
      </p>
      <p>
        At number ten, we have the next coldest zone – the vast <A to="subarctic">subarctic</A> band that spreads across northern North
        America, Europe and Asia. Now we are getting into the millions in terms of population, but still less than a fifth of one percent of
        humans live in the vast boreal forests that experience warm summers but very cold winters here. This population is mostly found in
        Russia, which is to be expected as it contains the largest land area under this climate type, including the vast stretches of
        Siberia. There are a number of Koppen subtypes in the Subarctic. Most people live in the standard Subarctic of cold winters and year
        round precipitation, with a smaller fraction in the east of Asia where only light snow falls in winter, with more consistent rain in
        summer. A much smaller fraction live in the area of very cold winters in north-east Siberia.
      </p>
      <ChapterImageList images={imagesDcd} />
      <ChapterImage right src={ImageAf} caption="Populations in the Tropical Rainforest" />
      <p>
        At number nine, and we switch from the coldest zones to one of the hottest – the year round rain and warmth of the{" "}
        <A to="tropical-rainforest">Tropical Rainforest</A>. With a global population of just over a quarter billion, we are at last into an
        area that is home to a significant percentage of total human numbers. Almost half of them live in just one country – Indonesia, with
        most of the others also within SE Asia, and significantly fewer numbers in South America and Africa.
      </p>
      <p>
        At number eight and we head to the mid-latitudes, and the western fringes of the continents under the sway of maritime westerly
        winds – the <A to="oceanic">Oceanic</A>, characterised by rain year round and relatively mild temperatures between winter and
        summer. Of the two hundred and seventy million souls living in this zone, the vast majority are within the densely populated and
        fertile lands of northwest Europe, with the three larger countries there accounting for almost three quarters of the global Oceanic
        population. There are two Koppen subtypes in the Oceanic – the standard and the subpolar variety, the latter of which experiences
        cooler summers and is akin to a perpetual autumn. With this description it’s perhaps not surprising that only a comparatively tiny
        number live in this sub-zone.
      </p>
      <ChapterImageList images={imagesCfb} />
      <p>
        Onto number seven now, and with just over 300 million people, we’re back in the tropics, but this time at the cooler elevations of
        upland areas, providing year round spring like temperatures in the <A to="subtropical-highlands">Subtropical Highland</A> climate.
        Populations are split across all continents here, with high population countries with significant upland areas like Ethiopia,
        Mexico, China and Colombia topping this list. Koppen listed three different variants of this climate type. Two monsoon forms
        separated by warm or cool temperatures which both have wet and dry seasons, and an all year warm and wet form. The warm monsoon form
        dominates in terms of population. By the way, when you see the word “Humid” in climate classification, it is simply designating year
        round rain, and is not a comment on the clamminess of air.
      </p>
      <ChapterImageList images={imagesCfbSH} />
      <p>
        Up to a third of a billion now as we reach number six and the <A to="cool-deserts">cool deserts</A>. In terms of population, Central
        Asia, with its vast continental deserts of hot summers and cold winters, dominates, with all but two countries in this list from
        that area. China’s contribution comes from its north-western dry region that is part of the central Asian desert area, while most of
        Iran’s large population lies in this climate zone due to the high elevation of that country that would otherwise have put it in the
        predominately hot desert Middle East. There are two Koppen subtypes here, the semi-arid steppe, and the true cool desert. Since the
        latter is one of the harshest climates on earth, it’s no surprise that the steppe dominates in terms of population.
      </p>
      <ChapterImageList images={imagesBk} />
      <p>
        At number five in our countdown toward the most populous climate zone, and we reach the <A to="mediterranean">Mediterranean</A>, and
        the copies of its unique dry summer rainfall pattern across most continents, home to almost four hundred million people. But true to
        form, and the actual Mediterranean dominates the population list with only the extensive dry summer pattern of the western coast of
        the United States providing numbers to counter those of the populous countries with Mediterranean shorelines. There are two Koppen
        subtypes within this climate, separated by summer temperatures, and the “classic” Mediterranean Csa hot summer type dominates in
        terms of population as it does land area.
      </p>
      <ChapterImageList images={imagesCs} />
      <p>
        We step a gear in terms of population sizes now as we reach number four in our list, the <A to="continental">Continental</A>{" "}
        climate. Defined by big temperature ranges between summer and winter and plenty of rain and snow, these regions, which occur only in
        North America and Asia, are home to almost eight hundred million souls. China is the largest contributor here, with its densely
        populated north eastern region centred around its capital Beijing lying within the wet summer variants of this climate. Next is
        Russia, where the majority of that vast country’s population is housed on the rich and fertile plains of Eastern Europe, as Ukraine
        and Poland demonstrate also in this list. A significant chunk of the USA’s population is within this zone, and almost all of
        Canada’s. There are six different Koppen types here with the classic “Humid” form of year round precipitation and warm summers
        dominating population as it does land area. Almost all of the population of the “Monsoon” types are from China, with the remainder
        from Korea. The dry summer variants demonstrate their rarity in this chart, making up only a tiny fraction of the whole.
      </p>
      <ChapterImageList images={imagesDab} />
      <p>
        Onto number three now in our most populated climate zone list, and one of the biggest surprises. Close to one billion people on our
        planet live in <A to="hot-deserts">hot desert</A> or hot semi-arid conditions. The Indian Subcontinent dominates here, with the dry
        region of Western India and Pakistan being inhabited in great numbers in this generally highly populated part of the world. Common
        with Pakistan and two other countries on the list – Egypt and Iraq – is the presence of major rivers flowing through an otherwise
        completely barren region – the Nile in Egypt, the Indus in Pakistan and the Tigris/Euphrates in Iraq. These rivers provide for vast
        areas of irrigation, allowing huge bounties in crops in the ever-present sunshine. Other significant populations occur across the
        Sahel semi-arid region immediately south of the Sahara desert. In terms of Koppen climate types, the true desert and semi-arid forms
        are fairly evenly split in terms of population.
      </p>
      <ChapterImageList images={imagesBh} />
      <p>
        We’re now down to the final two - the two most populated climates on Earth. It was actually a close call in terms of the numbers.
        Both have enormous populations. If you’ve watched my other videos you’ll have heard clues about which ones they are, if you’ve not
        already worked them out now by a process of elimination.
      </p>
      <p>
        Ok, so the second most highly populated region of earth is…{" "}
        <A to="tropical-monsoon-and-tropical-savannah">The Tropical Wet and Dry</A> zones – the Tropical Monsoon and Savannah. Spreading out
        across a vast swathe of the Tropics, and encompassing such well known populous nations as India, Indonesia, Nigeria, Bangladesh and
        Brazil, it’s no surprise that this climate type is topping out in terms of population. Two... billion… people, ladies and gentleman.
        That’s over one in four of all people on our planet. The reason for this is simple – the combination of all year round warmth and
        plenty of rain, albeit in a seasonal pattern, allows for bountiful crop harvests, often multiple times per year, feeding vast
        numbers. India, with its vast population in its southern half, makes up a full quarter of the total in this climate zone at half a
        billion alone. In the Koppen classification, the Monsoon and Savannah separate out the Tropical Wet and Dry climates, with the
        Monsoon being wetter with less distinct dry seasons than the Savannah. As the Savannah accounts for more land area than the Monsoon,
        it makes up the majority of population also.
      </p>
      <ChapterImageList images={imagesAmAw} />
      <p>
        And so we come to number one on our list. The climate zone with more people in it than any other. This is the subject of{" "}
        <A to="humid-subtropical">Chapter 5</A> in my <A to="secrets">Secrets of World Climate series</A>, when at that time I mentioned its
        large populations and awarded it with the most number of major world cities than any other. Lying just beyond the tropics, and found
        on the easterly margins of every continent of earth, it is home to a whopping 2.2 billion people. It is defined by hot and wet
        summers, but comparatively cool winters… And unless you follow climate, you’ve probably never heard of it. It is the Humid
        Subtropical.
      </p>
      <ChapterImageList images={imagesCfa} />
      <p>
        The vast population of China – almost two thirds of that country’s population are in this zone – a billion people! The equally vast
        population of India – a third of Indians live in this zone – half a billion again. And what about the United States? Over half of
        all Americans live in this zone – that was a surprise even to me. Almost ninety percent of Japanese live here in their overcrowded
        islands. It is probably the most culturally and geographically diverse of any climate zone.
      </p>
      <ChapterImage right src={ImageGlobal} caption="Global Population by Climate Type" />
      <p>
        Something about this climate type allows it to support such vast populations. With plenty of rain, either throughout the year, or
        during the summer, there is plenty of water. But unlike the tropics, the winters are cool – perhaps this is the clue – in that
        tropical diseases are much less common here, allowing for healthier populations. It is unclear. But the population densities are
        higher here than in any other zone, on practically every continent.
      </p>
      <p>
        The two subtypes are broken down by rainfall pattern, Cfa for year round rain, and Cwa for a summer monsoon peak. Interestingly they
        both contribute near equally to the huge population numbers at a billion each.
      </p>
      <p>
        So, with our list complete, let’s now make a comparison of our twelve grouped zones, in our final graphic. This chart shows just how
        dominant the two largest climate types are, with over half the people in our planet living in them. The next quarter of our planet’s
        population live in the next two, while all other climate types fill out the remaining quarter. In some cases these differences in
        population score are a reflection of the relative land areas taken up by such zones – for instance Mediterranean zones occupy a much
        smaller land area than, say the Tropical Savannah. But in other cases, such as the subarctic, it can be seen that such vast areas
        support comparatively little population. This demonstrates that the most populous regions of earth are indeed a result of climates
        that offer plenty of clean, running water. It’s not really any more complex than that.
      </p>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc12Icecap(props) {
  const headerImage = "icecap-hero.jpg";
  const ImageCircumpolarCurrent = "icecap-antarctica-circumpolar-current.jpg";
  const ImageSunlightSummer = "icecap-antarctica-sunlight-reflected.jpg";
  const ImageSunlightWinter = "icecap-antarctica-no-sunlight-in-winter.jpg";
  const ImageSizeSummer = "icecap-antarctica-summer-size.jpg";
  const ImageSizeWinter = "icecap-antarctica-winter-size.jpg";
  const ImageComparison = "icecap-comparison-of-greenland-antarctica.jpg";
  const ImageGlacierFlow = "icecap-glacier-flow.jpg";
  const ImageHeightOfIcesheet = "icecap-height-of-icesheet.jpg";
  const ImageKatabaticWinds = "icecap-katabatic-winds.jpg";
  const GlobalDistributionMap = "Icecap-Climate-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-icecap.gif";
  const ImageLandscapeMcMurdo = "icecap-mcmurdo.jpg";

  const imagesIceSheetDynamics = [
    {
      src: ImageHeightOfIcesheet,
      caption:
        "The icesheets of Greenland and Antarctica have built up over thousands of years of snowfall to several kilometers in thickness, resulting in even colder temperatures at the surface",
    },
    {
      src: ImageGlacierFlow,
      caption: "Over decades, the ice 'flows' like a river from the icesheet down to the coast as glaciers",
    },
  ];
  const imagesAntarcticSunlight = [
    {
      src: ImageSunlightSummer,
      caption:
        "In summer, the sun is very low on the horizon, and much of this weak sunlight's energy is reflected back into space by the white of the icesheet",
    },
    {
      src: ImageSunlightWinter,
      caption: "In winter, the sun is below the horizon for months at a time, providing no warmth at all",
    },
  ];
  const imagesAntarcticSeasonGrowth = [
    {
      src: ImageCircumpolarCurrent,
      caption: "The Circumpolar Current prevents any warmth from more temperate seas penetrating Antarctica",
    },
    {
      src: ImageSizeSummer,
      caption: "Antarctic sea ice extent in summer",
    },
    {
      src: ImageSizeWinter,
      caption: "Antarctic sea ice extent in winter",
    },
  ];
  return (
    <Chapter
      series="secrets"
      seriesChapter="12"
      heading="Icecap"
      subheading="Koppen Code: EF"
      documentTitle="The Icecap Climate - Koppen Climate Classification: EF"
      headerImageSrc={headerImage}
      youTubeCode="pRZgNGXmQoQ"
      chapterPrevText="Tundra"
      chapterPrevUrl="/climate/tundra"
      // chapterNextText="Summary"
      // chapterNextUrl="/climate/summary"
    >
      <ChapterImage
        right
        src={GlobalDistributionMap}
        caption="The Icecap climate is only found in any quantity in Greenland and Antarctica"
      />
      <p>
        Scenes from another world, surely? No towns or cities. No greenery. Almost no life at all. Virtually all of the world’s fresh water
        is here, locked up in ice sheets over a mile in thickness.
      </p>
      <p>
        The harshest of all climates, these lands rarely see temperatures above freezing, and have the lowest on record anywhere on our
        planet. A land of ice, snow, glaciers, freezing winds and bitter cold. This is the icecap.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        In our series looking at climate zones from equator to pole, we have at last reached journey’s end, and the finale is anything if
        not dramatic, because it is here, in the most northerly and southerly landmasses of Greenland and Antarctica, that we find the most
        extreme of all climates, what is known by climatologists as the Icecap.
      </p>
      <p>
        The climatic definition of Icecap is simple. Average temperatures never rise above freezing. The consequence of this will seem
        obvious. There is no rain, no running water, just snow, usually blowing in from warmer regions around it, which builds up over
        thousands, even millions of years into an ice sheet a mile deep or more, meaning the surface of the ice is high above sea level,
        ensuring even lower temperatures and further guaranteeing the icecap’s survival. This process of thickening cannot go on
        indefinitely, of course, gravity demands that the ice be pulled down to the sea, and as a large mass, over enough time, the
        seemingly solid ice acts like a liquid, flowing toward the sea as glaciers. When these glaciers reach the coast, then break off into
        ice bergs, to eventually melt back into the ocean.
      </p>
      <ChapterImageList images={imagesIceSheetDynamics} />
      <p>
        In the highest mountains of Earth, there are places where the temperature never rises above freezing point, and so glaciers form
        here, in the Rockies, the Alps, the Caucasus, the Himalayas and the Andes. But by land area and amount of ice held, these areas are
        insignificant compared to the two major ice sheets of the world – Greenland and Antarctica. Despite qualifying under the temperature
        requirements, for the purposes of climate classification, the Arctic around the North Pole is not usually included in the icecap
        climate, since there is no actual land there, just frozen sea ice up to several metres thick at most.
      </p>
      <ChapterImage right src={ImageComparison} caption="The Antarctic icesheet is about ten times the size of Greenland's" />
      <p>
        Between them, Greenland and Antarctica account for 99% of the world’s fresh water, with the vast majority of that share being in
        Antarctica. The Antarctic ice sheet is almost ten times the size of Greenland’s, taking up an area equivalent to the United States
        and Mexico combined. If all of this ice was to melt, the sea level globally would rise by between 60 and 70 metres (approx. 200
        feet).
      </p>
      <h3>Greenland</h3>
      <p>
        In the case of Greenland, it’s at first not clear why this land is covered in ice, when the surrounding islands are tundra. But
        unlike the surrounding lands, a wall of high mountains makes up what is believed to be a giant set of three islands. Millions of
        years ago, an ice age would have blanketed the whole area in a mile-thick ice sheet, but as the ice age retreated, it is believed
        the mountains prevented this ice from escaping quickly, and the high altitude of the ice cap maintained the very low temperatures
        necessary to preserve its survival into the present day.
      </p>
      <p>
        On the western coast of Greenland, there are a number of gaps in the mountains where the ice flows out glacially, and on reaching
        the sea, these break off into icebergs which flow down into the North Atlantic. The ice berg that sank the Titanic began its life
        many thousands of years ago as snow falling on the ice sheet that covers the world’s largest island.
      </p>
      <h3>Antarctica</h3>
      <p>
        In the case of Antarctica, there are multiple reasons why this giant continent stays covered in ice, and why it is the coldest place
        on our planet. The first is obvious – it sits right over the south pole. In summer, sunlight strikes the earth here at an oblique
        angle, providing little warmth, and most of what sunlight does arrive here is reflected back from the blinding white ice. In winter,
        the sun remains below the horizon for months at a time, with consequently no chance of any input of heat.
      </p>
      <ChapterImageList images={imagesAntarcticSunlight} />
      <p>
        Secondly, Antarctica is surrounded by the unbroken Southern Ocean, allowing the prevailing westerlies and the circumpolar ocean
        current at these latitudes uninterrupted travel, preventing any movement of tropical air or warm ocean currents into the Antarctic
        region.
      </p>
      <p>
        Lastly, Antarctica has had millions of years to build up its ice sheet, with the result that most of the continent’s surface of ice
        is above 2000m (6500?ft) in altitude, keeping temperatures even lower than they would be at sea level.
      </p>
      <ChapterImageList images={imagesAntarcticSeasonGrowth} />
      <p>
        In terms of <A to="/climate/seasons">seasons</A>, temperatures in Antarctica stay below zero throughout the year, but the continent
        “grows” and “shrinks” in terms of area with the summer and winter, as sea ice melts and freezes over. During winter, only the
        extreme north of the Antarctic peninsula is directly accessible by sea, with all other coasts blocked by frozen ocean.
      </p>
      <ChapterImage
        right
        src={ImageKatabaticWinds}
        caption="Hurricane force 'Katabatic' winds regularly strike out from the centre to the coasts of Antarctica"
      />
      <h3>Winds and Precipitation</h3>
      <p>
        In both Greenland and Antarctica, these high altitude ice sheets produce a special phenomenon whereby the cold mass of air above the
        ice sheet seeks to fall into warmer air at the coasts, since cold air is more dense than warm. This leads to near constant winds,
        known as Katabatic winds, flowing out from the centre of these land masses to the sea, sometimes reaching hurricane strength. As if
        these places weren’t grim enough already.
      </p>
      <p>
        In relative terms, despite the abundance of fresh water locked up the ice, this climate zone is technically a desert, with most
        places experiencing less than 250mm of precipitation per year (rain equivalent, not snowfall). This is because the air is so cold
        that it can carry very little moisture. Most of the snow that forms on the great ice sheets of Greenland and Antarctica blows in
        from either adjoining tundra regions, or relatively warmer marginal icecap areas near the coasts. In places in Antarctica, this
        combination of low precipitation and high winds causes certain dry valleys to be scoured of all ice and snow. In other parts,
        snowfall has never been recorded or suspected, suggesting that there hasn’t been precipitation in centuries or possibly millenia.
        This would make these places even drier than the Atacama desert in South America, which is otherwise regarded as the driest on our
        planet.
      </p>
      <h3>Is there any life in the Icecap?</h3>
      <p>
        Since average temperatures never rise above freezing, plant growth is impossible, and in any case, the ground is covered with a
        moving layer of ice. In some parts of the periphery of the ice sheets, moss and lichen grow on sunward facing rocks, but this is the
        limit of any vegetation. Despite this, however, a very famous set of animals have made Antarctica their home – these little fellas,
        and these… big fellas… Some species of penguins live in the Antarctic for the simple reason that there are no other species here –
        no predators to interrupt their breeding cycles. With the waters surrounding the continent being rich in fish, there is plenty of
        food to sustain sometimes gigantic colonies, and these conditions seem to be worth paying the price of living in the most
        inhospitable region on earth.
      </p>

      <h3>Human Settlement - Research Stations</h3>
      <ChapterImage
        left
        src={ImageLandscapeMcMurdo}
        caption="McMurdo Station on the coast of Antarctica is the largest base on the continent. Image courtesy of Gaelen Marsden"
      />
      <p>
        Human settlement is confined to research stations on both the Greenland ice sheet, and in Antarctica. The largest of these is
        McMurdo station on the coast of the Ross Sea in Antarctica, which is accessible by ship in summer time, and among other functions
        acts as a supply base for other research stations on the continent.
      </p>
      <p>
        Then there is the Amundsen-Scott South Pole station, at the geographic bottom of our planet. But this is not the coldest place on
        earth.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="A sampling of climate data from Antarctic research stations" />
      <p>
        The official lowest recorded temperature at a surface based weather station occurred at the Russian Vostok research station in East
        Antarctica. On 21 Jul 1983, a temperature of -89.2°C (-128.°F) was recorded.
      </p>
      <p>
        More recently, satellites recorded a temperature at the surface near Dome Argus, a snow dome which is the highest part of Antarctica
        at an altitude of 4093m, and on top of an ice sheet estimated to be at least 3km deep. The temperature observed by the satellite (on
        10 August, 2010) was -93.2°C (-135.8°F). Compare this with the average surface temperature of Mars, of -63°C, and you can see why
        the icecap is the most other-worldly of all of Earth’s climate zones.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>What is the simple temperature range definition of the Icecap climate?</li>
        <li>How does year-round freezing temperatures affect the overall landscape in the Icecap climate?</li>
        <li>What are the two main places in which the Icecap climate exists? How do they compare in size?</li>
        <li>List the three reasons as to why Antarctica is so much colder than anywhere else on Earth.</li>
        <li>What are Katabatic Winds?</li>
        <li>Is there any life in the Icecap climate? If so, describe.</li>
        <li>Is Antarctica colder than Mars? Discuss.</li>
      </ol>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";
import A from "../components/A";

export default function Sowc0Intro(props) {
  const headerImage = "0-ALL.jpg";
  const imageZonesA = "A.jpg";
  const imageZonesB = "B.jpg";
  const imageZonesC = "C.jpg";
  const imageZonesD = "D.jpg";
  const imageZonesE = "E.jpg";
  const ClimateGraphs = "climate-graphs-from-every-koppen-zone.gif";

  const broadCategoryImages = [
    { src: imageZonesA, caption: "Tropical Climate Zones" },
    { src: imageZonesB, caption: "Dry Climate Zones" },
    { src: imageZonesC, caption: "Temperate Climate Zones" },
    { src: imageZonesD, caption: "Continental Climate Zones" },
    { src: imageZonesE, caption: "Polar Climate Zones" },
  ];
  return (
    <Chapter
      series="secrets"
      heading="Secrets of World Climate"
      subheading="Introduction"
      documentTitle="Secrets of World Climate - An introduction to the course on the Koppen Climate Classification System"
      headerImageSrc={headerImage}
      youTubeCode="Y5NcKTFb3ck"
      chapterNextText="Tropical Rainforest"
      chapterNextUrl="/climate/tropical-rainforest"
    >
      <p>
        Planet Earth. Our home. Among all the worlds in the universe, it’s the only one we know that harbours life. A spinning globe of
        blue, green, gold and white. A thing of beauty. But for many of us, a thing of questions. Many of these concern that thing which
        impacts us every day of our lives - the weather.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Climate Graphs from Various Koppen Climate Zones" />
      <p>
        In this global age, so many of us have travelled beyond our own borders, to lands that were hotter, colder, drier or wetter than our
        own. Or perhaps even gone half way around the world, and yet experienced weather very similar to the country of our origin. And
        perhaps some of us asked the question, why is this so?
      </p>
      <p>
        All these questions have been answered, and they all come under one subject. That of <em>CLIMATE</em>.
      </p>
      <p>
        Climate is defined as <em>“The weather conditions prevailing in an area in general or over a long period.”</em>
      </p>
      <p>
        While in one region, it might rain one day, and be sunny the next, or cool one week, and hot the next, it’s the long term pattern of
        rainfall and temperature, day after day, which will determine how that region will look, whether it will become a desert, or support
        grasslands or even forests in their many different forms.
      </p>
      <p>
        And, more importantly, in our human-centred world, these patterns of moisture and temperature will determine what crops can be grown
        or what livestock can be supported, to feed ourselves.
      </p>
      <p>
        It has shaped the rise and fall of empires… And last but not least, climate has shaped even the way that we look, ourselves, as
        humans.
      </p>
      <p>
        The secret code of our planet’s various climates was cracked long ago by the founder of modern climatology, Wladimir Koppen. He
        developed a system based around observation of vegetation in different parts of the world, and how the rainfall and temperature
        varied in these regions throughout the year. And he broke them down into five broad categories of climate which roughly started at
        the equator and moved out toward the poles. These run like this:
      </p>
      <ul>
        <li>Tropical</li>
        <li>Dry</li>
        <li>Temperate</li>
        <li>Continental</li>
        <li>Polar</li>
      </ul>
      <ChapterImageList images={broadCategoryImages} />
      <p>
        With later refinements, these broad categories were further categorised into additional sub-categories based on distribution of
        rainfall and temperature through a full year, to give us the <A to="koppen-classification">Koppen-Geiger Climate Classification</A>{" "}
        system of today.
      </p>
      <p>
        In this series, we’re going to take a look at each of these different climate types, showing you real places in the world where they
        exist, why they are that way, and revealing the exact climate type that each and every major world city lies in.
      </p>
      <p>
        So if you want to know what to wear on your next holiday, or… you really do want to learn more about our world, then this series is
        for you.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>Define the term "climate" in your own words, and how it differs from the word "weather".</li>
        <li>
          Name a climate zone that exists in both the northern and southern hemispheres and on different continents, and the countries or
          regions involved.
        </li>
        <li>Who is considered to be the father of modern climatology?</li>
        <li>List out the five broad categories of climate.</li>
      </ol>
    </Chapter>
  );
}

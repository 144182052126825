import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";
import A from "../components/A";

export default function BiomesShrublands(props) {
  const headerImage = "shrublands-hero.jpg";
  const ImageKoppenCompare1 = "shrublands-koppen-subtropical.jpg";
  const ImageKoppenCompare2 = "shrublands-koppen-mediterranean.jpg";
  const ImageWitw1 = "shrublands-st-texas.jpg";
  const ImageWitw2 = "shrublands-st-brazil.jpg";
  const ImageWitw3 = "shrublands-st-paraguay-gran-chaco.jpg";
  const ImageWitw4 = "shrublands-st-south-sudan.jpg";
  const ImageWitw5 = "shrublands-med-california-big-sur.jpg";
  const ImageWitw6 = "shrublands-med-spain.jpg";
  const ImageWitw7 = "shrublands-med-france.jpg";
  const ImageWitw8 = "shrublands-med-croatia.jpg";
  const ImageWitw9 = "shrublands-med-greece-crete.jpg";
  const ImageWitw10 = "shrublands-med-south-africa.jpg";
  const ImageWitw11 = "shrublands-med-aus.jpg";
  const ImageSpecies1 = "shrublands-species-1.jpg";
  const ImageSpecies2 = "shrublands-species-2.jpg";
  const ImageSpecies3 = "shrublands-species-3.jpg";
  const ImageSpecies4 = "shrublands-species-4.jpg";
  const ImageSpecies5 = "shrublands-species-5.jpg";
  const ImageSpecies6 = "shrublands-species-6.jpg";
  const ImageSpecies7 = "shrublands-species-7.jpg";
  const ImageSpecies8 = "shrublands-species-8.jpg";
  const ImageSpecies9 = "shrublands-species-9.jpg";
  const ImageSpecies10 = "shrublands-species-10.jpg";
  const ImageSpecies11 = "shrublands-species-11.jpg";
  const ImageSpecies12 = "shrublands-species-12.jpg";
  const ImageSpecies13 = "shrublands-species-13.jpg";
  const ImageChaps = "shrublands-chaps.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";
  const GlobalDistributionMap = "Biomes-World-Map-Scrub.jpg";

  const imagesWitw1 = [
    { src: ImageWitw1, caption: "Subtropical scrub in SW Texas, USA" },
    { src: ImageWitw2, caption: "'Caatinga' subtropical scrub in NE Brazil. Image courtesy of Terra Negra" },
    { src: ImageWitw3, caption: "Subtropical scrub in 'El Gran Chaco', Paraguay. Image courtesy of Gmmv80" },
    { src: ImageWitw4, caption: "Subtropical scrub in South Sudan. Image courtey of SOS Sahel UK" },
  ];

  const imagesWitw2 = [
    { src: ImageWitw5, caption: "Mediterranean scrub in Big Sur, California, USA" },
    { src: ImageWitw6, caption: "Mediterranean scrub ('Mattoral') in Catalonia, Spain" },
    { src: ImageWitw7, caption: "Mediterranean scrub ('Garrigue') in Southern France" },
    { src: ImageWitw8, caption: "Mediterranean scrub in Croatia" },
  ];

  const imagesWitw3 = [
    { src: ImageWitw9, caption: "Mediterranean scrub ('Phyrgana') in Crete, Greece" },
    { src: ImageWitw10, caption: "Mediterranean scrub in Western Cape, South Africa" },
    { src: ImageWitw11, caption: "Mediterranean scrub ('Mallee') in South Australia" },
  ];

  const imagesKoppenCompare = [
    { src: ImageKoppenCompare1, caption: "Subtropical scrub is a transition between Savannah and Desert areas" },
    { src: ImageKoppenCompare2, caption: "Mediterranean scrub comes from the stresses on plants of a hot dry summer in temperate areas" },
  ];

  const imagesSpeciesAmericasSubtropical = [
    {
      src: ImageSpecies1,
      caption: "North American subtropical scrub plant species",
    },
    {
      src: ImageSpecies2,
      caption: "Brazilian scrub plant species",
    },
    {
      src: ImageSpecies3,
      caption: "El Gran Chaco scrub plant species",
    },
  ];

  const imagesSpeciesOtherSubtropical = [
    {
      src: ImageSpecies4,
      caption: "African Sahel scrub plant species",
    },
    {
      src: ImageSpecies5,
      caption: "Indian scrub plant species",
    },
    {
      src: ImageSpecies6,
      caption: "Northern Australian scrub plant species",
    },
  ];

  const imagesSpecies3 = [
    {
      src: ImageSpecies7,
      caption: "Southern California scrub plant species",
    },
    {
      src: ImageSpecies8,
      caption: "Chilean scrub plant species",
    },
    {
      src: ImageSpecies9,
      caption: "South African scrub plant species",
    },
    {
      src: ImageSpecies10,
      caption: "Southern Australian scrub plant species",
    },
  ];

  const imagesSpecies4 = [
    {
      src: ImageSpecies11,
      caption: "Mediterranean basin oaks in the scrub ",
    },
    {
      src: ImageSpecies12,
      caption: "Mediterranean basin scrub plant species",
    },
    {
      src: ImageSpecies13,
      caption: "The Olive Tree of the Mediterranean basin - perhaps the most famous of all scrub plants",
    },
  ];

  return (
    <Chapter
      series="biomes"
      seriesChapter="3"
      heading="Shrublands"
      subheading="SUBTROPICAL &amp; MEDITERRANEAN SCRUB"
      documentTitle="The Shrubland Biome or Scrub Biome"
      headerImageSrc={headerImage}
      youTubeCode="wcYWS-5iUcc"
      chapterPrevText="Savannah"
      chapterPrevUrl="/biomes/savannah"
      chapterNextText="Deserts"
      chapterNextUrl="/biomes/deserts"
    >
      {/* <ChapterImage right src={Image} caption="" />
      <ChapterImageList images={images} /> */}

      <p>
        Often overlooked, these relatively arid regions of earth take second or third place to forests or grasslands when it comes to beauty
        contests. Too dry for trees to flourish, we’re left with a mix of shrubs and grasses, spanning the subtropics to the temperate
        latitudes. Having a surprising biodiversity considering their parched appearance, these areas are known by an equally diverse set of
        names, from scrub or scrubland, to bush, brush, heath, matorral and chaparral and more. A place of heat and drought, yet still
        abundant life, these are the shrublands of our planet.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        Shrublands are a relatively open form of country that regularly experiences drought. The defining vegetation is shrubs and grasses.
        Shrubs, like trees, are woody plants that possess lignin that allows the plant to retain the growth of previous years and build upon
        it with new growth the following year, as opposed to grasses and other plants that die back and must start again from rootstock or
        even new seed in the next growing season. In contrast to trees, however, shrubs are stunted in growth, and typically do not exceed 5
        metres in height from a single stem, or 10 metres if multi-stemmed. But where shrubs lack height, they make up for in hardiness, and
        in comparison to trees are much more drought-tolerant, allowing them to thrive in many regions of the world where trees are absent.
      </p>
      <p>
        These areas are always the result of a severe dry <A to="/climate/seasons">season</A>, or irregular patterns of rain that lead to
        very long droughts, but occur in two very different climate types.
      </p>
      <p>
        In the case of the subtropics, shrublands are simply transitions between the savannah and desert, where alternate wet and dry
        seasons exist, but where the wet season is of insufficient weight or length to allow tree growth. Such Koppen climate zones in this
        case would be in the crossover of <A to="/climate/tropical-monsoon-and-tropical-savannah">Tropical Savannah (Aw)</A>, and{" "}
        <A to="/climate/hot-deserts">Hot Semi Arid (BSh)</A> areas.
      </p>
      <p>
        But shrublands are best known for being found in the temperate latitudes of the Mediterranean basin and in other parts of the world
        that have the <A to="/climate/mediterranean">Mediterranean climate</A> of a hot, dry summer and a cool wet winter (Csa). Despite
        being temperate, this unique climate, the only one with a dry summer and wet winter, creates a particular stress on plants, where
        the dry season occurs at the hottest time of the year - evapotranspiration from the plants is greatest when the least amount of
        water is present in the soil. No trees are able to survive these conditions, despite the winter rain, and so instead, we find a mix
        of shrubs and grasses.
      </p>
      <ChapterImageList images={imagesKoppenCompare} />
      <ChapterImage
        right
        src={ImageHoldridge}
        caption="The Holdridge Lifezones chart shows shrublands as desert scrub on the margin of desert across tropical and temperate latitudes"
      />
      <p>
        If we consult our Holdridge Lifezones chart, we can find these areas as desert scrub, spanning the tropics through to more temperate
        regions, but always where the annual rainfall is relatively low.
      </p>
      <p>
        Shrubs can also exist as the principal vegetation in the transition between boreal forest and tundra in the far polar latitudes. But
        as these conditions are so different to the other two mentioned, and are principally a result of insufficient summer temperatures,
        we’ll discuss these areas in the <A to="polar-biomes">Polar Biomes</A> chapter.
      </p>
      <p>
        With such severe dry seasons, it’s no surprise that fires are common in these regions, and at some point all of us will have seen on
        the news brushfires threatening the fringes of Los Angeles or in parts of the Mediterranean. Growing up in Adelaide, Australia,
        which is within this biome, I personally recall seeing a blackened sky on the horizon as fires would engulf the surrounding brush
        after a long dry summer.
      </p>
      <h3>Where in the world - Subtropical</h3>
      <p>
        So where in the world do we find the shrublands? Well, as mentioned, they occur in the subtropical transition zones between savannah
        and desert, and also in the Mediterranean climate zones of the temperate latitudes.
      </p>
      <p>
        We’ll first take a look at the subtropical scrub areas, starting with the band that surrounds the Sierra Madre mountains of Mexico,
        from Texas down to central Mexico on the eastern side, and from Chihauhua down to Jalisco in the west.
      </p>
      <p>
        On the NE tip of Brazil we find an extensive thorn scrub known as Caatinga or “White Forest”, which is brought about due to fast
        draining soils and irregular rainfall patterns and droughts, and is the driest part of this large country.
      </p>
      <p>
        In the heart of South America, running down the eastern side of the Andes in a wide plain from Bolivia, through Paraguay to
        Argentina, we have the extensive scrubland known as El Gran Chaco, probably the largest such contiguous area on Earth.
      </p>
      <ChapterImageList images={imagesWitw1} />
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of the Subtropical and Mediterranean Shrublands" />
      <p>
        Onto Africa and subtropical scrub acts as a transition between the Savannah to the South and the Sahara desert to the north in a
        narrow band that runs almost exactly east-west. Southern Ethiopia, Somalia and northern Kenya also have extensive scrub areas, as do
        parts of Botswana and Namibia, again acting as transitions between Savannah and desert – in this case the Namib and Kalahari.
      </p>
      <p>
        In India – we have two distinct bands of scrub, one in West India which is a transition between the Thar desert of the west, and the
        more fertile plains of the centre. The other runs down the central and eastern part of the Deccan plateau, and is formed due to rain
        shadow from the prevailing SW Monsoon.
      </p>
      <p>
        The last area that features subtropical scrub occurs in northern Australia, again acting as a transition zone between the northern
        savannah and semi-arid heart of the island continent.
      </p>
      <h3>Where in the world - Mediterranean</h3>
      <p>
        In the five Mediterranean climate zones of our planet we have the other, temperate, form of shrublands, resulting from hot and dry
        summers.
      </p>
      <p>
        In Southern California, this is known as Chaparral, and still extensively covers the hills from Santa Barbara, through Los Angeles,
        and San Diego and down past Tijuana into the northern Baja peninsula.
      </p>
      <p>
        In Central Chile we have a narrow strip of scrub between the Pacific Ocean and the Andes mountains known as Mattoral, named by
        settlers after the equivalent biome found in Spain.
      </p>
      <ChapterImageList images={imagesWitw2} />
      <p>
        In the Mediterranean basin itself, we have the largest area of temperate scrub, running from southern Portugal in the West, into the
        Mattoral that covers large parts of Spain, while in north Africa, the northern parts of Morocco, Algeria and Tunisia are dominated
        by this biome. In Southern France, the scrub is known as Maquis and Garrigue, depending on elevation and vegetation density.
        Sardinia, Sicily and the west coast of Italy also have this biome, where it is known as Macchia. Greece is dominated by scrub, where
        it is known as Phrygana (Φρύγανα), and this Mediterranean scrub extends into significant parts of Turkey, as well as Cyprus and
        finally Lebanon and Israel at the sea’s eastern end.
      </p>
      <p>
        Most of the southern cape of Africa has narrow band of temperate scrub where it is known as Karoo or Fynbos, which is the most
        biodiverse of all scrubland areas, and possessing thousands of unique species found nowhere else.
      </p>
      <p>
        Lastly the south-western and south-central parts of Australia have their own form of temperate scrub in large areas known as Mallee
        and Kwongan, where dwarf forms of eucalyptus dominate.
      </p>
      <ChapterImageList images={imagesWitw3} />
      <h3>Species - Subtropical</h3>
      <p>
        Plant species diversity in the shrublands is high. If we look first at the subtropical scrub areas, starting in Texas and Mexico, to
        the east of the Sierra Madre common species such as Mesquite, Yucca and Prickly Pear cacti can be found. In the Caatinga "White
        Forest" of NE Brazil, drought-hardened species such as Joazeiro, Bombacae and various cacti species such as Facheiro are common. In
        the vast Chaco of Bolivia, Paraguay and Northern Argentina, we find parklike vegetation of palms mixed with grasses in the wetter
        parts, while in the drier areas, drought hardened shrubs like senna, as well as a number of cacti species can be found.
      </p>
      <ChapterImageList images={imagesSpeciesAmericasSubtropical} />
      <p>
        In the Sahel of Africa, the long band of scrub to the south of the Sahara, we find dwarf acacias being the dominant species, while
        other shrubs include myrrh, and spiny grasses such as Panicum. East of the Deccan plateau in India, thorny scrub dominates, with
        Acacias, being the most common family also. Palms can be found in the wetter parts, while the driest areas include hardy species
        such as Euphorbia. In northern Australia, the band of scrub separating the Savannah and desert interior is dominated again by
        Acacias, especially the species Mulga while spinifex and triodia grasses cover the ground.
      </p>
      <ChapterImageList images={imagesSpeciesOtherSubtropical} />
      <h3>Species - Mediterranean</h3>
      <p>
        In terms of species and biodiversity, the temperate shrublands are greater than the subtropical forms, and remarkably so when
        considering the challenging drought conditions experienced each summer. Because the temperate scrub areas are isolated from each
        other, each of these five zones has developed unique species that do not exist in the other zones, although there are some common
        relations between the areas within each hemisphere – for instance California and Mediterranean areas both have oaks, while Acacias
        can be found in Chile, South Africa and Australia.
      </p>
      <p>
        Looking in detail at each of these five areas, and in California we can find a variety of oaks that are stunted due to the summer
        drought conditions, such as the Coast Live Oak, as well as a variety of Manzanitas or Bearberrys, Garrya, Rhus and Ceanothus.
      </p>
      <p>In Chile we find Acacia, Baccharis and Prosopis, the latter occurring also on the other side of the Andes in the Chaco.</p>
      <p>
        As mentioned previously, the Fynbos biome of the South African Cape has the largest number of scrubland species anywhere, with
        prominent families including Aspalathus (from which Rooibos tea is made), Senecio and Erica heathers.
      </p>
      <ChapterImageList images={imagesSpecies3} />
      <p>
        South-west and south-central Australia are dominated by the mallee - dwarf eucalyptus which invariably grow as multi-stemmed plants
        from underground tubers. Other prominent species include Banksia and Conospermum.
      </p>
      <p>
        Lastly, in Europe and the Mediterranean basin itself, we find a variety of dwarf oaks such as the Cork and Kermes Oaks, the Arbutus
        family that includes the strawberry tree (no they aren’t actual strawberries), Cytisus and Pistacia.
      </p>
      <ChapterImageList images={imagesSpecies4} />
      <p>
        And finally, found throughout the Mediterranean is the most famous of all scrubland plants – the olive tree (Olea Europaea). These
        “trees” are technically shrubs, since they rarely grow beyond 10 metres in height, and are famed for their fruit which produces oil
        consumed by humans for millennia. The plants are highly drought resistant, and can live for thousands of years – that you could see
        some of the trees that Caesar or Aristotle may have taken shade under is something to wonder at.
      </p>
      <p>
        Since shrubs have adapted to become highly drought and fire tolerant, their foliage is not very nutritious and often contains toxic
        chemicals, so animal numbers in these parts are typically low compared to Savannah or forest.
      </p>
      <ChapterImage
        right
        src={ImageChaps}
        caption="The leather leg coverings called 'Chaps' provided protection from the dense thorny shrub thickets in the 'Chapparal' scrub of Southern California"
      />
      <p>
        So while we might not hold the shrublands as dearly as their big brother forests, these biomes nonetheless get plenty of airtime. If
        you’ve ever watched any American television or movies, chances are you’ll have seen scrubland in outdoor shots, as so many movies of
        the past were filmed out of Hollywood, and the countryside surrounding Los Angeles is the type of Mediterranean scrub referred to as
        chaparral. This, incidentally gives us the word for “chaps”, because the chaparral, like most shrublands can often be a giant
        thicket of thorny shrubs, and leather protection to the legs was necessary to prevent being scraped to death while on foot or
        horseback.
      </p>
      <p>
        And the shrubs of these regions, due to their manageable size compared to trees, and their hardiness, have become favourites of the
        average domestic garden not just in regions native to this biome, but in cooler and wetter climates such as those of Great Britain,
        a long way from the parched and baked lands of the scrub.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>What is scrub or shrubland composed of?</li>
        <li>What is the principle climatic reason for scrub vegetation?</li>
        <li>What are the two main types of scrub, and in what regions of the world are they found?</li>
        <li>List out some countries where scrub is found and what parts of those countries its found in.</li>
        <li>List out some scrub species that you have already heard of.</li>
      </ol>
    </Chapter>
  );
}

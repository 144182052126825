import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Share from "./share";

export default function Chapter(props) {
  const [series, setSeries] = useState({});
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = props.documentTitle;
    let series = { name: "Secrets of World Climate", to: "/climate/secrets" };
    if (props.series === "biomes") {
      series = { name: "Biomes", to: "/biomes" };
    }
    if (props.series === "casebook") {
      series = { name: "The Climate Casebook", to: "/climate/casebook" };
    }
    setSeries(series);
  }, []);

  return (
    <div className="chapter">
      <div className="series-title">
        {series.to && (
          <Link className="btn" to={series.to}>
            {series.name} SERIES
          </Link>
        )}
      </div>
      <div className="hero">
        <div className="text">
          <div className="series-title">{series.name}</div>
          {props.seriesChapter && <div className="series-chapter">Chapter {props.seriesChapter}</div>}
          <div className="heading">{props.heading}</div>
          <div className="sub">{props.subheading}</div>
        </div>
        <div className="crop-height">
          <img src={`${process.env.REACT_APP_IMAGES_DIR}/${props.headerImageSrc}`} alt={props.heading} />
        </div>
      </div>
      <div className="video-heading">
        <h1>WATCH THE VIDEO</h1>
      </div>
      <div className="video-cont">
        <iframe src={`https://www.youtube.com/embed/${props.youTubeCode}`} title="Geodiode on YouTube" allowFullScreen></iframe>
      </div>
      {props.children}
      <div className="chapter-navigation">
        {props.chapterPrevUrl ? (
          <Link className="btn" to={props.chapterPrevUrl}>
            Prev: {props.chapterPrevText}
          </Link>
        ) : (
          <span>&nbsp;</span>
        )}
        {props.chapterNextUrl && (
          <Link className="btn" to={props.chapterNextUrl}>
            Next: {props.chapterNextText}
          </Link>
        )}
      </div>

      <Share />
    </div>
  );
}

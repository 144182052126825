import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc4HotDeserts(props) {
  const headerImage = "hot-deserts-hero.jpg";
  const HadleyCellImage = "hot-deserts-hadley-cell.jpg";
  const DesertCauseImage1 = "hot-deserts-tropical-uplift-and-rain.jpg";
  const DesertCauseImage2 = "hot-deserts-air-moves-out-from-equator.jpg";
  const DesertCauseImage3 = "hot-deserts-downward-air-produces-deserts.jpg";
  const ImageDubaiDunes = "hot-deserts-sand-dunes-dubai.jpg";
  const ImageAzCacti = "hot-deserts-arizona-cacti.jpg";
  const GlobalDistributionMap = "Hot-Deserts-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-hot-deserts.gif";

  const desertCausesImages = [
    { src: DesertCauseImage1, caption: "Solar heating near the equator produces thermals that lose their moisture as rain" },
    { src: DesertCauseImage2, caption: "Tropical uplift of air must go somewhere so it moves out toward the poles" },
    {
      src: DesertCauseImage3,
      caption: "The now dry air from the tropics falls down in subtropical areas that, denied rain, lead to deserts",
    },
  ];

  const imagesExamples = [
    { src: ImageDubaiDunes, caption: "Sand dunes in Dubai" },
    { src: ImageAzCacti, caption: "Cacti in Arizona" },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="4"
      heading="Hot Deserts"
      subheading="KOPPEN CODES: BWh &amp; BSh"
      documentTitle="The Hot Desert Climate - Koppen Climate Classification: BWh BSh"
      headerImageSrc={headerImage}
      youTubeCode="itnYTE-3u-o"
      chapterPrevText="Subtropical Highlands"
      chapterPrevUrl="/climate/subtropical-highlands"
      chapterNextText="Humid Subtropical"
      chapterNextUrl="/climate/humid-subtropical"
    >
      <p>
        Water. Without it, life cannot exist. The places on our Earth denied it, are empty, barren places. And yet even these have a stark,
        almost unearthly beauty. They are the hottest regions on our planet. They have been the setting for legendary civilisations of the
        past. And today, against all expectations, they possess large, highly developed cities. Where heat and water are in extreme
        imbalance… These are the hot and dry deserts of Planet Earth
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        In the first three chapters of this series we’ve been looking at the climates of the Tropics, the central band of the earth around
        the equator. We now journey out of this zone, heading towards the poles, but first we must pass through the barrier of remarkably
        aridity, the zone of the hot deserts.
      </p>
      <p>
        Why do these bands of dryness exist? The main reason is to do with the way in which hot and cold air hold onto moisture, and how
        this is tied into the greater cycle of air movement in the Tropics. In the equatorial region, intense solar radiation leads to
        heating of warm moist air, which lifts up high into the atmosphere, depositing most of its moisture as rain as it cools. But this
        air has to go somewhere. In fact, it moves out toward the poles. And it must come down to earth again. And in the places where it
        does, we get deserts. This is because this air is mostly empty of moisture, but also because it is going from the cool upper
        atmosphere, to the heat near the ground. Warm air can hold onto more moisture than cold, so any moisture in this descending air
        remains locked in, and won’t produce rain.
      </p>
      <ChapterImageList images={desertCausesImages} />
      <p>
        These huge and heavy descending columns of air generate high pressure, which causes further heating of the air through compression.
        In combination with the constant sun, this causes the highest temperatures to be found anywhere on earth, even more than in the
        tropics. And the high pressure regions keep out storms or other atmospheric disturbances. In short the air is very stable, producing
        cloudless, sunny skies, very high temperatures, and little to no rain.
      </p>
      <ChapterImage
        right
        src={HadleyCellImage}
        caption="The Hadley Cell of Tropical and Subtropical air circulation explains Tropical Rainforest, Tropical Wet &amp; Dry and Hot Desert climates"
      />
      <p>
        Most of this air then re-circulates back down to the equator where it forms the Trade Winds that I spoke about in{" "}
        <A to="tropical-monsoon-and-tropical-savannah">Chapter 2</A>. This whole circular airflow is known as a Hadley Cell. It explains how
        the Tropical Rainforest, Monsoon, Savannah and Deserts are produced, and is one of the great Secrets of World Climate to be
        revealed.
      </p>
      <p>
        There are other places on earth with little to no rain – what we’d call the cool deserts, such as the Gobi or Atacama. But the
        mechanism of those is different to described here, and <A to="cool-deserts">we’ll pick these up in a later chapter</A>.
      </p>
      <p>
        In hot and dry climates, there are two main classifications within the Koppen system, distinguished simply by how little rain falls.
      </p>
      <p>
        BWh, the true hot desert, has the least amount of rain, while BSh, known as hot semi-arid, or hot steppe, has some rain, but not
        enough to produce anything more than stunted, shrub like vegetation.
      </p>
      <p>
        There are basically two <A to="/climate/seasons">seasons</A> in these climates, defined principally by temperature, since rain
        doesn’t feature significantly, or indeed at all. They are a long, very hot summer, and a shorter mild or cool winter. The seasonal
        variation in temperature depends upon latitude and how far away from the ocean the place is. Places further away from the equator or
        ocean have greater seasonal variation than those that are closer to one or the other. Compare, for instance the graph of Baghdad to
        Mogadishu – two desert cities you’ve probably heard of, but for all the wrong reasons. Baghdad is pretty far from the equator – 33
        degrees to be precise, and far from any ocean, and has a pretty extreme summer to winter range. While Mogadishu on the Indian Ocean
        coast of Somalia, and only 2 degrees north of the equator experiences very little seasonal temperature differences. Deserts in
        general also feature high day/night changes in temperature – this is because the air is dry and so holds little heat when the sun
        goes down. That, and the lack of cloud cover too, which would otherwise keep heat in.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global Distribution of Hot Desert Climate Zones" />
      <h3>Where in the World Do We Find the Hot Desert Climate Zones?</h3>
      <p>
        So where in the world do we find significant areas of hot and dry conditions? Starting in the Americas, we have the Mojave desert of
        the Southwest US, covering inland areas of Southern California, Southern Nevada and much of Arizona. This desert region continues
        south into north-west Mexico, where it becomes the Sonora desert, plus all of the Baja peninsula. Another desert, the Chihuahuan,
        covers much of north-east Mexico.
      </p>
      <p>
        In South America, we most notably have <A to="lima">the extremely dry coastline of Peru</A> which experiences a great deal of fog
        due to the cold ocean current that brushes that shoreline. Then there is the Chaco of north-west Argentina.
      </p>
      <p>
        Moving on to Africa, and here we have the largest and most famous desert of all – the Sahara. Stretching across the entire northern
        continent, this desert is larger than all the others combined. This enormous region of aridity extends into the Middle East, where
        it encompasses the entirety of the Arabian Peninsula, south into the Horn of Africa, and further east into southern Iran, Pakistan,
        ending in the Thar Desert of west India.
      </p>
      <p>
        In Southern Africa, we have the large Namib desert, which also experiences extensive coastal fogs. This desert extends inland where
        it becomes the Kalahari.
      </p>
      <p>
        Lastly we come to Australia, where the vast majority of that island continent is covered by desert or semi-arid conditions. The “Red
        Heart” of Australia it encompasses many named deserts such as the Great Sandy, Victoria and Gibson.
      </p>
      <h3>Landscapes and Biomes</h3>
      <ChapterImageList images={imagesExamples} />
      <p>
        So what kind of <A to="/biomes/deserts">landscapes and vegetation</A> do we find in the hot and dry climates? Well, not much, when
        it comes to vegetation. But this lack, exposes the earth underneath, and is one of the few places where we get to see the rocky
        “bones” of our planet. In the absence of binding plants and soils, winds pulverise the rock into grains of sand, and these sands can
        be gathered by those winds into vast dunes. So true deserts are a mix of exposed rock, baked earth and dunes.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Climate graphs of notable cities in Hot Desert climate regions" />
      <p>
        Where there is some rain, the plants that do exist are hardy shrubs that are spaced out with bare earth between. And of course,
        there are cacti. Uniquely suited to a climate where it might only rain once every few years, these iconic desert plants have evolved
        to hold onto moisture for years within their tough, fleshy bodies.
      </p>
      <h3>Notable Cities</h3>
      <p>
        Considering water is so short in these regions, there are a surprising number of large cities contained within the Hot Deserts, and
        these are provided in the adjacent chart.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>How does the uplift of tropical air at the equator relate to the formation of hot deserts in subtropical regions?</li>
        <li>
          What is the Hadley Cell and what role does it play in determining various climatic conditions in tropical and subtropical regions?
        </li>
        <li>Explain why hot deserts, despite being at higher latitudes, can have higher temperatures than equatorial regions.</li>
        <li>Why is it that some coastal areas of hot deserts feature frequent fogs?</li>
        <li>What are the Koppen codes for these climates? How do they differ from each other?</li>
        <li>What types of natural landscapes do we find in hot deserts?</li>
        <li>Name five hot deserts and describe their location.</li>
        <li>List out some countries and cities that experience hot desert climates.</li>
      </ol>
    </Chapter>
  );
}

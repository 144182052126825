import React from "react";
import { Link } from "react-router-dom";
export default function NavPanelThumb(props) {
  return (
    <Link to={props.to}>
      {/* <video
        loop
        preload="metadata"
        muted
        onMouseOver={(e) => {
          e.target.play();
          console.log("Playing video " + props.src);
        }}
        onMouseOut={(e) => {
          e.target.pause();
          e.target.currentTime = 0;
          console.log("Pausing video " + props.src);
        }}
      >
        <source src={props.src} type="video/mp4" />
        Your browser is not supported!
      </video> */}
      <img src={`${process.env.REACT_APP_IMAGES_DIR}/thumbs/${props.src}`} alt={props.title} />
      <div className="title-block">
        <div className="title">{props.title}</div>
        <div className="subtitle">{props.subtitle}</div>
      </div>
    </Link>
  );
}

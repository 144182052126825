import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import A from "./A";
import Support from "./support";

export default function Contact(props) {
  useEffect(() => {
    document.title = "Contact Geodiode";
  }, []);

  //const [answer, setAnswer] = useState(0);
  const [showEmail, setShowEmail] = useState(false);
  const answers = ["af", "bwh", "cfa", "cfb", "csa", "et"];
  const questionIndex = Math.floor(Math.random() * 6);

  let question = "What is the Koppen code for the climate type ";
  switch (questionIndex) {
    case 0:
      question += "Tropical Rainforest?";
      break;
    case 1:
      question += "Hot Desert?";
      break;
    case 2:
      question += "Humid Subtropical?";
      break;
    case 3:
      question += "Oceanic?";
      break;
    case 4:
      question += "Hot Summer Mediterranean?";
      break;
    case 5:
      question += "Tundra?";
      break;
    default:
      break;
  }

  const checkAnswer = (e) => {
    if (e.target.value.toLowerCase() === answers[questionIndex]) {
      setShowEmail(true);
    }
  };
  return (
    <div className="contact">
      <h1>Contact Me</h1>
      <p>
        If you have enjoyed my videos or using this website and would like to leave a success story, or you're a teacher with suggestions or
        reporting of errors on course content, or you wish to discuss a business opportunity then please use the form below to get in touch.
      </p>
      <p>
        <b>PLEASE NOTE - STUDENTS:</b> I cannot answer any specific questions or help on course content. If you have a question or comment
        on a particular module, please direct your question to your teacher, or use the comments section of the relevant YouTube video for
        that module so that others may see my response or offer help themselves.
      </p>

      {showEmail ? (
        <>
          <p>
            <a href="mailto:geodiode@studio34.nl">Click here to send email</a> or copy this address into your mail application:
          </p>
          <p>
            <b>geodiode@studio34.nl</b>
          </p>
        </>
      ) : (
        <>
          <h2>Response Form: Bot Challenge</h2>
          <p>Please answer the following question so that I know you're human.</p>
          <p>
            <b>{question}</b>
          </p>
          <p>
            (Refer <A to="/climate/koppen-classification">here</A> for a reminder)
          </p>
          <p>
            Answer: <input type="text" onChange={checkAnswer} />
          </p>
        </>
      )}
      <p>&nbsp;</p>
      <Support />
    </div>
  );
}

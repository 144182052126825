import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc9Continental(props) {
  const headerImage = "continental-hero.jpg";
  const ImageGraphsAcrossRegion1 = "continental-dry-summer.jpg";
  const ImageGraphsAcrossRegion2 = "continental-dry-winter.jpg";
  const ImageGraphsAcrossRegion3 = "continental-year-round-rain.jpg";
  const ImageExtensionOceanic = "continental-extension-of-oceanic.jpg";
  const ImageExtensionCfa = "continental-extension-of-humid-subtropical.jpg";
  const ImageMonsoonWinter = "continental-monsoon-january.jpg";
  const ImageMonsoonSummer = "continental-monsoon-july.jpg";
  const ImageLandscapeMoscow = "continental-moscow.jpg";
  const ImageLandscapePrague = "continental-prague.jpg";
  const ImageLandscapeChina = "continental-china-great-wall.jpg";
  const ImageLandscapeJapan = "continental-japan-sapporo.jpg";
  const ImageLandscapeCanada = "continental-canada-alberta.jpg";
  const ImageLandscapeUsa = "continental-usa-wheat-fields.jpg";
  const GlobalDistributionMap = "Continental-Climate-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-continental.gif";

  const imagesGraphs = [
    {
      src: ImageGraphsAcrossRegion1,
      caption: "Continental Dry Summer has Koppen codes Dsa and Dsb, and is relatively rare",
    },
    {
      src: ImageGraphsAcrossRegion2,
      caption: "Continental Dry Winter has Koppen codes Dwa and Dwb, and is found only in Eastern Asia due to the Asian Monsoon",
    },
    {
      src: ImageGraphsAcrossRegion3,
      caption: "Classic Continental with rain year-round has Koppen codes Dfa and Dfb, and is the most widespread of Continental climates",
    },
  ];

  const imagesExtension = [
    {
      src: ImageExtensionOceanic,
      caption:
        "Westerly winds blowing over the flat landscapes of Northern Europe and European Russia bring year-round rain far into Russia in a continental extension of the Oceanic climate",
    },
    {
      src: ImageExtensionCfa,
      caption:
        "Westerlies in North America are blocked by the rockies so much of the continental climate here can be considered to be an extension of the Humid Subtropical climate",
    },
  ];

  const imagesMonsoon = [
    {
      src: ImageMonsoonSummer,
      caption: "The Eastern Asian Monsoon in summer brings moist Pacific winds onto the continent, and plenty of rain",
    },
    {
      src: ImageMonsoonWinter,
      caption: "The Eastern Asian Monsoon in winter is dry since the prevailing wind blows out from Siberia with little moisture",
    },
  ];

  const imagesLandscape1 = [
    {
      src: ImageLandscapeCanada,
      caption: "Natural Prairie in Alberta, Canada",
    },
    {
      src: ImageLandscapeUsa,
      caption: "Much of this climate in the USA and Canada is given over to huge farms of wheat and corn",
    },
    {
      src: ImageLandscapePrague,
      caption: "Most of Eastern Europe is in the Continental Climate zone Dfb, include Czechia's capital Prague",
    },
  ];

  const imagesLandscape2 = [
    {
      src: ImageLandscapeMoscow,
      caption: "Russia's capital Moscow, lies firmly within the Dfb Continental type",
    },
    {
      src: ImageLandscapeChina,
      caption: "Much of Northern China, including this section of the Great Wall is in the Continental Monsoon climate zone (Dwa, Dwb)",
    },
    {
      src: ImageLandscapeJapan,
      caption:
        "Most of Northern Japan is in the Dfb climate type, with Sapporo being the snowiest major city in the world. Image courtesy of Eckhard Pecher",
    },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="9"
      heading="Continental"
      subheading="KOPPEN CODES: Dsa, Dsb, Dfa, Dfb, Dwa &amp; Dwb"
      documentTitle="The Continental Climate - Koppen Climate Classification: Dsa Dsb Dfa Dfb Dwa Dwb"
      headerImageSrc={headerImage}
      youTubeCode="lE1Z5PtkzXQ"
      chapterPrevText="Cool Deserts"
      chapterPrevUrl="/climate/cool-deserts"
      chapterNextText="Subarctic"
      chapterNextUrl="/climate/subarctic"
    >
      <p>
        The great plains of the northern continents. Lands of hot summers, but cold winters. The bread baskets of the developed world, they
        are also home to many great cities and are the heartlands for the two largest countries on earth.
      </p>
      <p>
        In a band encircling the earth of the mid-latitudes… Between the forbidding arctic cold to the north, and the deserts and humid
        plains to the south… these are the lands of the Continental Climates.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        In our journey from equator to pole, we’ll be leaving the southern hemisphere for a time as we set our focus on the great northern
        continents. Being so far from the moderating influence of the oceans, these continents produce a variety of climate types that have
        one thing in common – big temperature ranges from summer to winter.
      </p>
      <p>
        There are two distinct biomes that occur within North America and Eurasia. The colder and more northerly of these is the endless
        sub-arctic pine forest – known as the Taiga. We’ll take this up in the <A to="subarctic">next chapter</A>. In this one, we’ll look
        at the warmer of these, which are the mixed woodlands and grasslands that are home to hundreds of millions of people and countless
        cities stretching across Canada, the United States, Northern and Eastern Europe, Russia and the northern Far East.
      </p>
      <ChapterImage
        right
        src={GlobalDistributionMap}
        caption="Global distribution of Continental climate zones with the six Koppen codes"
      />
      <p>
        In the Koppen Climate Classification, there are, at first a seemingly bewildering number of climates here, six in total. They are
        subdivided by rainfall pattern and temperature. Where there is a hot summer, with the average day/night temperature being above
        22°C, the designation ends in “a”. Where there is a cooler, but still warm, summer below 22°C, the designation ends in “b”.
      </p>
      <p>
        All continental climates by definition must have the average day/night temperature in winter below -3°C (or 0°C by some
        climatologists), otherwise they fall under the Oceanic, Mediterranean or Humid Subtropical climates.
      </p>
      <p>
        Where there are wet winters and dry summers (Koppen: Dsa/Dsb), this is an extension of the Mediterranean climate into adjoining
        higher altitude areas, and is the least common of the Continental climates, being limited to the interiors of the USA's Pacific
        North-West and Turkey, along with the western slopes of the Altai mountains in Central Asia.
      </p>
      <p>
        When such a rainfall pattern is reversed, with dry winters and wet summers (Koppen: Dwa/Dwb), we have a form of monsoon. In fact the
        only place where this occurs is in Eastern Asia, where the <A to="asian-monsoon">Asian Monsoon</A> extends far into the north –
        Eastern Siberia, Northern China and Korea.
      </p>
      <p>
        The most common continental climate is where the rainfall is distributed relatively evenly throughout the year. Dfa is the
        continental humid hot summer, while Dfb is the continental humid warm summer variant. These two make up the bulk of the population
        centres in the mid-latitudes of the Northern Continents and so are especially important.
      </p>
      <ChapterImageList images={imagesGraphs} />
      <p>
        Now those of you paying attention in the last chapter on <A to="cool-deserts">Cool Deserts</A>, might recall this concept of
        “Continentality”, where land is so far from the ocean that it receives little rain. So how is it that these continental interiors
        can support so much biosphere and population? Well, firstly, they are, on the whole, further north than the cool deserts, which
        reduces evaporation, due to a weaker sun, and so for a given amount of rain, more plants can grow.
      </p>
      <p>
        This more northerly latitude also brings much of western and central Eurasia under the sway of wet westerly winds blowing in from
        the Atlantic, with no mountain ranges to stop them from the Baltic all the way to Siberia. In this way, these areas, mostly of the
        Dfb warm humid summer variant, can be thought of as a continental form of the <A to="oceanic">Oceanic climate</A> that we looked at
        in Chapter 7.
      </p>
      <ChapterImageList images={imagesExtension} />
      <p>
        In the case of North America, the moisture from the westerlies is all but blocked by the Rocky Mountains, so much of the rain comes
        from the humid south. In this respect, this climate can be viewed as simply a colder and drier form of the{" "}
        <A to="humid-subtropical">Humid Subtropical climate</A> that we looked at in Chapter 5. As this warm southern air mixes with the
        very different colder, drier arctic air over these areas, it often gives rise to extreme weather events in the form of heavy
        thunderstorms, tornados and hailstorms.
      </p>
      <p>
        Eastern Asia, like all climates in that region, is subject to the <A to="asian-monsoon">Asian Monsoon</A>, where due to the enormity
        of this continent and the presence of the Tibetan plateau, the doldrums of tropical wind convergence is p ushed way to the north
        over Mongolia in summer, producing low pressure that pulls in wet air from the nearby Pacific as far north as Eastern Siberia. In
        winter, with high pressure over Mongolia, the wind direction is reversed, with dry air now flowing over Northern China, Korea and
        Eastern Siberia. The result is wet summers and dry winters. Compared to more southerly monsoon areas, though, the total rainfall is
        much lower. And in general, all the continental climates are relatively dry compared to all but the desert climates.
      </p>
      <ChapterImageList images={imagesMonsoon} />
      <p>
        The Continental Climate, with its wide temperature range, has the most distinct of the traditional <A to="seasons">four season</A>{" "}
        patterns found in the temperate bands. Winters are always cold and snowy, and summers are marked by long days with plenty of warm to
        hot sun, while Spring and Autumn are found between with their own well known characters.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of Continental climate zones" />
      <h3>Where in the world do we find the Continental climates?</h3>
      <p>
        So, where in the world are the continental climate zones? Well, due to the lack of continental land mass below the equator, they all
        occur in the North.
      </p>
      <p>
        Beginning just to the east of the Rockies in Canada, the provinces of Alberta and Saskatchewan are dominated by the Cfb warm humid
        summer variant. Moving east, and this band of Cfb gradually moves south to incorporate the Dakotas, Minnesota, Wisconsin, Michigan,
        the whole of the Great Lakes region, upper New York state, and all of New England, as well as all the main population centres of
        Ontario, Quebec and the Maritime Provinces of Canada.
      </p>
      <p>
        Further south, and the heart of the Mid-West comes under the sway of the hot summer continental Cfa variant, including the states of
        Nebraska, Kansas, Iowa, Missouri and Illinois. As we head east, this band narrows between the Cfb continental climate to the north
        and the Humid Subtropical climate to the south, with Indiana, Ohio and Pennsylvania, sheltered from the warm Atlantic by the
        Appalachian mountains, experiencing cold winters and hot summers.
      </p>
      <p>
        Skipping across the Atlantic, and past the Oceanic climate zone of North-West Europe, we encounter again a huge area of the Cfb
        variant of warm summers and cold winters, from Eastern Germany and Southern Scandinavia in the West, moving through all of Eastern
        Europe, the Baltic States, Ukraine, Belarus and encompassing much of European Russia. This band continues to extend for thousands of
        miles deep into the heart of Asia and Siberia before finally being squeezed between the sub-arctic forests of the north and the
        deserts to the south.
      </p>
      <ChapterImageList images={imagesLandscape1} />
      <p>
        In Eastern Asia, the monsoon Dwa and Dwb variants dominate, with much of northern China and Eastern Siberia and Korea experiencing
        hot or warm wet summers, and cold dry winters. Due to its extreme altitude, we find the most southerly of all continental climates
        in Tibet, where much of this plateau experiences cold, dry winters, and a mild to hot wet summer in a similar way.
      </p>
      <p>
        Lastly, the north of Japan, including the island of Hokkaido, being surrounded by water, has the year round rain of the Dfb variant,
        while the northern half of Honshu island has the hotter summer Dfa variant. Because of the bitterly cold winter wind blowing off
        from Siberia over the warm moist Sea of Japan, this results in massive snowfalls over northern Japan – in fact Sapporo, Hokkaido’s
        capital, is the snowiest city in the world.
      </p>
      <ChapterImageList images={imagesLandscape2} />
      <h3>Landscapes, Vegetation, Agriculture and Cities</h3>
      <p>
        The natural vegetation of the continental climate zones is a mixture of <A to="/biomes/grasslands">grasslands</A> and{" "}
        <A to="/biomes/temperate-forests">forests</A> of both deciduous and coniferous varieties, depending on how much rain falls. On the
        fringes between this and the semi-arid climate, grasslands dominate. On the border with the sub-arctic climates to the North,
        deciduous woodland gradually gives way to uniform pine forest that we call the <A to="/biomes/taiga">taiga</A>.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Notable cities with Continental Climates" />
      <p>
        Large scale mechanised agriculture dominates these areas. In North America, the endless plains are covered by wheat and corn fields
        as far as the eye can see. Soybeans and barley are also common. Ukraine and Southern Russia is similarly dominated, while Eastern
        Europe and Southern Scandinavia, where the climate is milder from bordering on the Oceanic climate, allows for a greater variety of
        crops and livestock.
      </p>
      <p>
        Instead of rice which otherwise dominates this country, Northern China sees much of the countryside, like the climatic counterparts
        in Europe and North America, also given over to wheat, corn and barley production.
      </p>
      <p>
        Between these endless plains, of course, lie many great and famous cities. Presented in the adjacent graphs are the most notable.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>Why are the Continental climates found only in the northern hemisphere?</li>
        <li>
          Explain the logic behind the six Continental Koppen climate codes, detailing the three rainfall distribution letters and two
          temperature range letters.
        </li>
        <li>Summarise the differences between the Continental climates in North America, Europe and Eastern Asia.</li>
        <li>What are the natural biomes and landscapes in these climate zones?</li>
        <li>How important is agriculture in this climate?</li>
        <li>List out some countries, regions and cities that experience Continental climates.</li>
      </ol>
    </Chapter>
  );
}

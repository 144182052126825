import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function CC5LandAreas(props) {
  const headerImage = "land-areas-hero.jpg";
  const ImageIcecap = "land-area-icecap.jpg";
  const ImageTundra = "land-area-tundra.jpg";
  const Image2Dcd = "land-area-subarctic-countries.jpg";
  const Image1Dcd = "land-area-subarctic-codes.jpg";
  const ImageAf = "land-area-tropical-rainforest.jpg";
  const Image1Cfb = "land-area-oceanic-codes.jpg";
  const Image2Cfb = "land-area-oceanic-countries.jpg";
  const Image1CfbSH = "land-area-subtropical-highland-codes.jpg";
  const Image2CfbSH = "land-area-subtropical-highland-countries.jpg";
  const Image1Bk = "land-area-cool-desert-codes.jpg";
  const Image2Bk = "land-area-cool-desert-countries.jpg";
  const Image1Cs = "land-area-mediterranean-codes.jpg";
  const Image2Cs = "land-area-mediterranean-countries.jpg";
  const Image1Dab = "land-area-continental-codes.jpg";
  const Image2Dab = "land-area-continental-countries.jpg";
  const Image1Bh = "land-area-hot-desert-codes.jpg";
  const Image2Bh = "land-area-hot-desert-countries.jpg";
  const Image1AmAw = "land-area-tropical-wet-dry-codes.jpg";
  const Image2AmAw = "land-area-tropical-wet-dry-countries.jpg";
  const Image1Cfa = "land-area-humid-subtropical-codes.jpg";
  const Image2Cfa = "land-area-humid-subtropical-countries.jpg";
  const ImageGlobal = "land-area-global.jpg";

  const imagesDcd = [
    {
      src: Image2Dcd,
      caption: "Subarctic land area by countries",
    },
    {
      src: Image1Dcd,
      caption: "Subarctic land area by climate sub-type",
    },
  ];

  const imagesCfb = [
    {
      src: Image2Cfb,
      caption: "Oceanic land area by countries",
    },
    {
      src: Image1Cfb,
      caption: "Oceanic land area by climate sub-type",
    },
  ];

  const imagesCfbSH = [
    {
      src: Image2CfbSH,
      caption: "Subtropical Highland land area by countries",
    },
    {
      src: Image1CfbSH,
      caption: "Subtropical Highland land area by climate sub-type",
    },
  ];

  const imagesBk = [
    {
      src: Image2Bk,
      caption: "Cool Desert land area by countries",
    },
    {
      src: Image1Bk,
      caption: "Cool Desert land area by climate sub-type",
    },
  ];

  const imagesCs = [
    {
      src: Image2Cs,
      caption: "Mediterranean land area by countries",
    },
    {
      src: Image1Cs,
      caption: "Mediterranean land area by climate sub-type",
    },
  ];

  const imagesDab = [
    {
      src: Image2Dab,
      caption: "Continental land area by countries",
    },
    {
      src: Image1Dab,
      caption: "Continental land area by climate sub-type",
    },
  ];

  const imagesBh = [
    {
      src: Image2Bh,
      caption: "Hot Desert land area by countries",
    },
    {
      src: Image1Bh,
      caption: "Hot Desert land area by climate sub-type",
    },
  ];

  const imagesAmAw = [
    {
      src: Image2AmAw,
      caption: "Tropical Monsoon &amp; Savannah land area by countries",
    },
    {
      src: Image1AmAw,
      caption: "Tropical Monsoon &amp; Savannah land area by climate sub-type",
    },
  ];

  const imagesCfa = [
    {
      src: Image2Cfa,
      caption: "Humid Subtropical land area by countries",
    },
    {
      src: Image1Cfa,
      caption: "Humid Subtropical land area by climate sub-type",
    },
  ];

  return (
    <Chapter
      series="casebook"
      heading="Land Areas"
      subheading="THE RELATIVE SIZES OF EARTH'S CLIMATE ZONES"
      documentTitle="The Relative Sizes of Earth's Climate Zones"
      headerImageSrc={headerImage}
      youTubeCode="tNUzVWwnFJk"
      chapterPrevText="Climate &amp; Population"
      chapterPrevUrl="/climate/population"
      chapterNextText="Habitability"
      chapterNextUrl="/climate/habitability"
    >
      <p>
        The Amazon rainforest, the African Savannah, the sands of the Sahara, the prairies of North America , the forests of Siberia, the
        ice sheet of Antarctica. It can be easy living in cities, as most of us do, to neglect how much land is on our planet. The
        continents and islands of our planet cover almost thirty percent of the globe, and most of that fraction is empty wilderness. What
        determines how these areas look is a combination of topography, and climate. We’ll now focus on the effect that climate has on these
        areas, and rank each climate zone by size, in this, the latest article in my Climate Casebook.
      </p>
      <p>-</p>
      <p>
        If you’ve viewed any of my other articles then you should be familiar with the Koppen climate system, which breaks down the world
        into zones of climate types based on temperature and rainfall patterns. If you haven’t, then I recommend you check out my{" "}
        <A to="secrets">Secrets of World Climate series</A>, or for a quick overview, the{" "}
        <A to="koppen-classification">summary article on the Koppen-Geigen Climate Classification</A>.
      </p>
      <p>
        There are 29 Koppen zones in total, but I’ve grouped most of these into the twelve more distinct categories that formed each chapter
        in my <A to="secrets">Secrets of World Climate series</A>. So with that out the way, let’s get right into which climate zones are
        the smallest and the largest, in ascending order.
      </p>
      <p>
        At number twelve the smallest of the world’s climate types is the <A to="subtropical-highlands">Subtropical Highlands</A> – located
        in the tropics, but experiencing mild temperatures year round thanks to the cooling effect of altitude. These areas are distributed
        relatively equally across Latin America, Africa and around the Himalayas in Asia. The monsoon variety of this climate, featuring wet
        and dry seasons dominates over the year-round variant, with the higher altitude cool summer variant extremely rare.
      </p>
      <ChapterImageList images={imagesCfbSH} />
      <p>
        At number eleven, we have the next largest zone – that of the <A to="oceanic">Oceanic</A> – the mild, wet climate found on the west
        coasts of the continents at temperate latitudes. Most of the land area is found in North-West Europe, with a significant proportion
        in Oceania. The main variant of warm summers dominates, with the cool summer Subpolar variant restrict to rocky coasts in the far
        north and south.
      </p>
      <ChapterImageList images={imagesCfb} />
      <p>
        At number ten, we have the Oceanic’s southern neighbour, the <A to="mediterranean">Mediterranean</A> climate of dry summers along
        the west coasts of the temperate latitudes. The country with the largest single land area here is, interestingly the United States,
        whose long west coast is almost exclusively of this type. Australia and Chile have some areas here, but the vast majority is given
        over to the countries around the sea to which this climate owes its name. The relative land areas of the hot and warm variants of
        this climate are pretty even, with Csa being slightly more widespread.
      </p>
      <ChapterImageList images={imagesCs} />
      <ChapterImage right src={ImageAf} caption="Tropical Rainforest land area by country" />
      <p>
        The next largest climate type, at number nine, is the <A to="tropical-rainforest">Tropical Rainforest</A>, covering selected parts
        of the equatorial regions of our planet where rain is constant year round. Almost half of this area is within Brazil and Indonesia,
        with the remaining countries scattered across South America, Africa and SE Asia.
      </p>
      <ChapterImage left src={ImageTundra} caption="Tundra land area by country" />
      <p>
        Moving onto number eight now, and we switch to the opposite end of the Earth and opposite temperatures, with the frigid wastes of
        the <A to="tundra">Tundra</A>. Most of this land is in the Arctic, with over half of it in just two countries – Russia and Canada.
        You might be wondering what China’s contribution is all about here – well that’s the extensive Tibetan plateau, which, being at an
        altitude of over 20,000 feet is predominantly alpine tundra. The United States fraction is almost all from one state – Alaska.
      </p>
      <p>
        Onto number seven in our climate land areas list, and we have the climate zone that has the largest population of any other, the{" "}
        <A to="humid-subtropical">Humid Subtropical</A> of hot summers and cool winters mixed with plenty of rain, occurring on the eastern
        fringes of each continent. Almost half of the global land area of this type occurs in just two countries, the United States and
        China, with the rest scattered across other continents. The year-round rain variant is the larger of the two, with the smaller being
        the monsoon form with dry winters.
      </p>
      <ChapterImageList images={imagesCfa} />
      <p>
        Into the final six now and we have the <A to="continental">Continental</A> climate types, which stretch across the vast plains of
        North America and Eurasia at the mid latitudes, characterised by warm to hot summers and cold winters, but with adequate rain to
        support large populations. This climate type forms the heartlands of three countries – Russia, the United States and Canada, which
        together make up over half of all global area for this climate. China’s densely populated north-eastern part features also – with
        the remainder being in Eastern Europe and NE Asia. There are many subtypes to this climate, varying by summer temperatures, and
        annual rainfall distribution. A single type dominates, that with year round precipitation – rain in summer, snow in winter, together
        with a warm summer.
      </p>
      <ChapterImageList images={imagesDab} />
      <p>
        The fifth largest climate zone is the <A to="cool-deserts">Cool Desert</A>, stretching across the rain-starved continental
        interiors, where large temperature ranges in combination make for a tough existence. Most of this area is covered by the countries
        of Central Asia – with most of western China in this category. Similarly, most of the western half of the United States is comprised
        of this climate zone. The remainder are scattered across the other continents. The true desert and semi-arid forms of this climate
        are equally matched in prevalence.
      </p>
      <ChapterImageList images={imagesBk} />
      <ChapterImage right src={ImageIcecap} caption="Icecap land area by region" />
      <p>
        Onto number four, and no it has not been forgotten, it’s the most forbidding of all climate zones – the <A to="icecap">Icecap</A>.
        Almost the entirety of the large continent of Antarctica is covered in ice year round, and this continent alone makes up the vast
        majority of the land area of this climate type, with the remainder being in Greenland, and a comparatively tiny scrap as glaciers
        across the highest mountains in the rest of Earth.
      </p>
      <p>
        As we move to number three, and we have the climate zone with the next coldest winters, the vast boreal forests of the{" "}
        <A to="subarctic">Subarctic</A> that cover twelve percent of the entire land area of earth. Dominating the northern continental
        interiors of North America and Eurasia, it is no surprise that two countries make up the vast majority of this land area – Russia
        and Canada. The variant with cold winters and year-round precipitation dominates this climate type, with monsoon and very cold
        winter forms comparatively isolated.
      </p>
      <ChapterImageList images={imagesDcd} />
      <p>
        Onto number two in our climate land areas list, and we are back in the tropics with the wet and dry seasonal climates – the{" "}
        <A to="tropical-monsoon-and-tropical-savannah">Tropical Monsoon and Savannah</A>. These lands dominate the tropics, making up almost
        75% of land area in this region of earth, with the Tropical Rainforest making up most of the remainder. Although the vast savannah
        of Brazil makes up almost a quarter of this climate’s land area, the remainder is distributed across very large number of countries
        across Central and South America, Africa and SE Asia. The Savannah variant of more pronounced dry seasons dominates over the wetter
        Monsoon form.
      </p>
      <ChapterImageList images={imagesAmAw} />
      <p>
        And so we come to the world’s largest climate zone. By a process of elimination you might have guessed what it is. And it’s a fact
        that might bring you some concern, with all the talk of climate change. Because the most extensive climate regions on Earth are the{" "}
        <A to="hot-deserts">Hot Deserts</A>. Making up a full fifth of all continental areas, the Sahara itself being half of it, the
        scorching arid lands of the subtropical regions of our planet can appear deceptively smaller than the subarctic regions on a map
        because of the errors in projection that cause more polar regions to appear larger. When looking at the distribution of these lands,
        it’s not surprising to find the countries adjoining the Sahara dominating, although the single largest contributor is the land down
        under, whose continental bulk is made up predominantly of desert. The true hot desert dominates over its slightly wetter semi-arid
        form as this chart shows.
      </p>
      <ChapterImageList images={imagesBh} />
      <ChapterImage right src={ImageGlobal} caption="Global land area by climate type" />
      <p>
        So, with our list complete, let’s now make a comparison of our twelve grouped zones, in our final graphic. There are two notable
        patterns here. The first is that almost half of the land on earth is practically unusable by humans, in the form of arid desert,
        ice-sheets or tundra. The second is that the three smallest zones combined are barely bigger than the next largest, and yet these
        zones contribute enormously to global agriculture, from the fruits of the Mediterranean and the cattle of the Oceanic, to the corn
        of the highlands of Central and South America. So if you’re from one of these regions, like I am, don’t feel bad. Remember, small is
        beautiful!
      </p>
    </Chapter>
  );
}

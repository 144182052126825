import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";
import A from "../components/A";

export default function Sowc10Subarctic(props) {
  const headerImage = "subarctic-hero.jpg";
  const ImageVerkhoyansk = "subarctic-verkhoyansk.jpg";
  const GlobalDistributionMap = "Subarctic-Climate-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-subarctic.gif";
  const ImageLandscapeAlaska = "subarctic-alaska.jpg";
  const ImageLandscapeFinland = "subarctic-finland.jpg";

  const imagesLandscape = [
    {
      src: ImageLandscapeAlaska,
      caption: "Subarctic landscape in Alaska, USA, with the ubiquitous Boreal Forest (Taiga)",
    },
    {
      src: ImageLandscapeFinland,
      caption: "Taiga in Finland. Most of this country is within the Subarctic climate type",
    },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="10"
      heading="Subarctic"
      subheading="KOPPEN CODES: Dsc, Dsd, Dfc, Dfd, Dwc &amp; Dwd"
      documentTitle="The Subarctic Climate - Koppen Climate Classification: Dsc Dsd Dfc Dfd Dwc Dwd"
      headerImageSrc={headerImage}
      youTubeCode="PtavJ3Tt9XE"
      chapterPrevText="Continental"
      chapterPrevUrl="/climate/continental"
      chapterNextText="Tundra"
      chapterNextUrl="/climate/tundra"
    >
      <p>
        Warm summers… but long, dark, cold winters. Here in the far north of the major continents, we find the widest temperature ranges to
        be found anywhere on earth.
      </p>
      <p>
        One of the last great wildernesses, the few cities that are here are the coldest on the planet. A place of snow, swamp, lake and
        trees. These are the lands of the boreal forest. The taiga. Welcome to the subarctic.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        In our journey from equator to pole, we have spent the last two chapters looking at the three climates found within the great
        continents, the <A to="cool-deserts">cool deserts</A> and the mixed woods and grasslands of the{" "}
        <A to="continental">humid continental</A>. It’s now time to take a look at the last of these, the coldest and most northerly of
        them, the climate of the endless coniferous forests of North America and Eurasia.
      </p>
      <p>
        The climate itself is referred to as subarctic by most climatologists, but because of the almost exclusive biome of coniferous
        forest that mark these zones, it often goes by the name of such forests, which are called boreal forest or <em>taiga</em>, the
        latter word coming from Russian, and which should not be confused with… tiger. Incidentally, tigers live in the taiga – the Siberian
        Tiger is the largest cat on the planet… But I digress... :)
      </p>
      <p>
        This climate zone houses the world’s largest extent of forests – with almost a third of all trees planetwide found here. They cover
        in a virtually unbroken sea of dark green, the entire width of the northern continents, from Alaska to Labrador in North America,
        and from Norway to Siberia and Kamchatka in Eurasia. But opposite to their equatorial cousins - the Tropical Rainforests - these
        regions have the lowest biodiversity of anywhere on earth, being dominated by often single species trees, such as larch, fir, pine
        and spruce.
      </p>
      <p>
        This climate type also occurs in comparatively tiny pockets in more southerly alpine areas, as a result of altitude cooling,
        specifically in the Rocky Mountains in North America, the Pyrenees, Alps and Carpathians of Europe and the Tibetan plateau in Asia.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of Subarctic climate zones with the six Koppen codes" />
      <p>
        In the <A to="koppen-classification">Koppen Climate Classification</A>, there are, like the humid continental climates to their
        immediate south, six divisions of the subarctic climate, separated by patterns of rain and snow and winter temperature. Dominating
        these six is the one called Dfc, which has year round precipitation – rain in summer, snow in winter, and where at least one full
        month of the year has an average day/night temperature of 10°C (50°F). This variant accounts for almost 90% of subarctic climate
        coverage, spanning the entire width of the northern continents.
      </p>
      <p>
        In the northern Far East, Dfc is supplanted by Dwc, having similar temperatures, but with a monsoon influence pattern of
        precipitation, with dry winters and wet summers. Yes, the Asian Monsoon makes it this far north, such is the extent of the world’s
        largest weather system.
      </p>
      <p>
        10°C might seem like a chilly summer day, but this is a day/night average, and the peak daytime temperature can often exceed 20°C,
        equivalent to that of the Oceanic climates such as Britain.
      </p>
      <p>
        Winters are a different story, however. Far from the ocean, and so far north that winter days can be very short, and the nights
        long, temperatures plummet to -20°C (-4°F) or below as a 24-hour average.
      </p>
      <p>
        And things only get worse in north-eastern Siberia. Here, we need a colder set of Koppen designations – Dfd and Dwd, because it is
        here that the winter temperatures fall to the lowest recorded anywhere outside Antarctica. In this classification, winter day/night
        averages must be below -38°C (-36.4°F), and night lows in winter often fall below -50°C (-58°F). Yet, due to continentality, and
        long hours of subarctic sunlight, summers here are still surprisingly warm, with peak day temperatures above 23°C (73°F).
      </p>
      <ChapterImage
        right
        src={ImageVerkhoyansk}
        caption="Verkhoyansk in NE Siberia has the highest annual temperature range in the world"
      />
      <p>
        There is a town in north eastern Siberia that you’ve probably never heard of. But to anyone familiar with climate study, it is well
        known. Its name is Verkhoyansk, and it is home to the widest recorded temperature range on the planet. Here you can see its rather
        impressive graph, with a temperature range of summer day to winter night being a staggering 72°C. But this only shows averages. The
        highest ever recorded temperature here was 38°C (100.4°F) in June 2020, while the lowest was -67.6°C (-89.7°F) – that’s a range of
        almost 106°C!
      </p>
      <p>
        The amount of rain falling within these climate zones is usually low in comparison to others, due to continentality. But because of
        the low average annual temperatures, evaporation of water is low, allowing the growth of hardened coniferous trees which have
        specialised in their evolution to thrive in these extreme climates. If thrive could be said, because the growing season in these
        cold climes is limited to just a few short months in summer, and so the trees can take decades to grow to any reasonable size, and
        usually live for centuries.
      </p>
      <h3>Where in the world do we find the Subarctic climates?</h3>
      <p>
        So, where in the world are the subarctic climate zones? In short they dominate the northern hemisphere between 50-70 degrees in
        latitude.
      </p>
      <p>
        In North America, all but the north coast of Alaska is covered by this climate, along with most of the northern half of the great
        Canadian plains, from the Yukon in the west to Labrador in the east.
      </p>
      <p>
        In Europe, this climate dominates Scandinavia, with most of Iceland, all but the oceanic west coast in Norway, the northern two
        thirds of Sweden and all but the southern fringe of Finland coming under its sway. Iceland is the only notable exception to the rule
        of the subarctic being covered with pine forest. Once, this island was forested in the same way, but the dependency on the human
        settlements here on local materials resulted in almost total deforestation of the land centuries ago, leaving a bleak landscape in
        its place.
      </p>
      <ChapterImageList images={imagesLandscape} />
      <p>
        As we continue east into Russia, the majority of this enormous country is blanketed by the taiga, with the band widening from the
        northern half of European Russia in the west to encompass all but the frigid northern coasts in the centre and east, in one of the
        most famous and forbidding regions in the world – Siberia. The subarctic climate extends all the way to the Kamchatka peninsula in
        the Far East before finishing at the Bering Straits, a stone’s throw from Alaska, where we began.
      </p>
      <h3>Landscapes and Vegetation</h3>
      <p>
        As mentioned, and obvious from all you have seen in this chapter so far, this climate is virtually synonymous with the{" "}
        <A to="/biomes/taiga">boreal forest</A>, or taiga. Patterns in this seemingly homogenous forest do exist, however. Different species
        of trees tend to dominate different regions - spruces in North America, for example, while eastern Siberia and its extreme winter
        temperatures is dominated by the hardiest conifers of all… the Siberian Larch.
      </p>
      <p>
        In the south the taiga gradually gives way to mixed woodland and deciduous forest, such as in Eastern Canada, or to grasslands where
        there is more aridity, such as in Central Russia or east of the Rockies. In the north, even the trees eventually give way to the
        barren grasses of the Tundra, which we’ll <A to="tundra">take up in the next chapter</A>.
      </p>
      <p>
        Due to the low evaporation rates in this land of weak sun, the rain and snow that does fall in the flat basins that dominate the
        upper latitudes tends to persist, forming large swamps, bogs or lakes. If you’ve ever looked at a map of Finland or Northern Canada,
        then you’ll have noticed this. Such areas of standing water are havens for mosquito breeding, and much of Siberia is particularly
        plagued by such creatures in summer, just in case you were thinking this respite from the frozen conditions might make for a
        pleasant camping trip.
      </p>
      <p>
        Because of the long winters, the ground is permanently frozen below a certain depth. This is known as permafrost. The depth at which
        it is frozen depends on latitude – with more southerly regions having soil with a thinner layer of ice at a deeper level compared to
        more northerly areas where the ice is thicker and closer to the surface. On average, the ground stays frozen just a few feet below
        the surface. We’ll talk more about the permafrost in the next chapter.
      </p>
      <p>
        Farming in such conditions is extremely difficult and there is almost no farming on any scale worth mentioning in these bleak
        regions.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Notable places with Subarctic Climates" />
      <h3>Human Settlement</h3>
      <p>
        These factors in combination make the subarctic a forbidding place in regard to human settlement, and so these areas are
        consequently very sparsely populated, the exceptions being where the location has specific strategic advantage or where rich natural
        resources are found.
      </p>
      <p>
        There are only four cities of any size worthy of mention here. Anchorage Alaska has grown over the last hundred years as a rail and
        aviation hub, and with its coastal location, the climate is relatively moderate under the maritime influence. Murmansk, a
        significant strategic naval base on the arctic coast of Russia by Scandinavia, and the largest city above the arctic circle, is
        similarly moderated by an ice-free sea.
      </p>
      <p>
        This is not true for the other two major cities in the subarctic. Norilsk, a metallurgical mining centre in Northern Siberia is the
        most northerly city above 100,000 population and is regarded as the coldest city in the world by its average temperature year round
        of nearly minus 10 degrees celsius. Lastly, Yakutsk, a diamond mining centre in Eastern Siberia has the coldest winters of any major
        city in the world with a day/night average in January of almost -39°C (-38°F). Fun times.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>What are the main differences between the subarctic climate and the continental climates to the south?</li>
        <li>
          Explain the logic behind the six Continental Koppen climate codes, detailing the three rainfall distribution letters and two
          temperature range letters. Which is the most important of the six?
        </li>
        <li>Why do places like Verkhoyansk in NE Siberia have such extreme annual temperature ranges?</li>
        <li>What is the dominant form of vegetation in the subarctic?</li>
        <li>List out some countries and areas that experience subarctic climates.</li>
      </ol>
    </Chapter>
  );
}

import React from "react";
// import Chapter from "../components/chapter";
// import ChapterImageList from "../components/chapter-image-list";
// import ChapterImage from "../components/chapter-image";

// const headerImage = "lima-hero.jpg";
// const ImageLocation = "lima-location.jpg";

export default function CC8Microclimates(props) {
  // const imagesLocation = [
  //   {
  //     src: ImageLocation,
  //     caption: "Lima, situated half-way along the coast of Peru is only 12°S of the equator and so is firmly within the tropics",
  //   },
  //   {
  //     src: GlobalDistributionMap,
  //     caption: "Deserts are not usually found within the Tropics, but in Subtropical areas beyond 23° north and south of the equator",
  //   },
  // ];

  return (
    <div className="coming-soon">
      <h1>Microclimates</h1>
      <h3>Coming soon!</h3>
    </div>
    // <Chapter
    //   heading="Microclimates"
    //   subheading="INSERT_HERE"
    //   headerImageSrc={headerImage}
    //   documentTitle="Microclimates"
    //   youTubeCode="YvkuNMAUQQ4"
    //   chapterPrevText="Habitability"
    //   chapterPrevUrl="/climate/habitability"
    //   chapterNextText="Microclimates"
    //   chapterNextUrl="/climate/microclimates"
    // >
    //   <ChapterImageList images={imagesLocation} />
    //   <ChapterImage right src={ImageFogNets} caption="Remote coastal communities use nets to catch coastal fog for potable water" />
    // </Chapter>
  );
}

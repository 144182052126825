import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";
import A from "../components/A";

export default function Sowc11Tundra(props) {
  const headerImage = "tundra-hero.jpg";
  const GlobalDistributionMap = "Tundra-Climate-Global-Distribution.jpg";
  const ClimateGraphs = "Tundra-Climate-Graphs.gif";
  const ImageLandscapeAlaska = "tundra-alaska.jpg";
  const ImageLandscapeNuuk = "tundra-nuuk.jpg";
  const ImageLandscapeCapeHorn = "tundra-cape-horn.jpg";

  const imagesLandscape = [
    {
      src: ImageLandscapeAlaska,
      caption: "Tundra in summer, near Nome, Alaska. Image courtesy of IBWOVids",
    },
    {
      src: ImageLandscapeNuuk,
      caption: "Nuuk, the capital of Greenland, the largest city in the Tundra. Image courtest of Reimund Langgaard",
    },
    {
      src: ImageLandscapeCapeHorn,
      caption:
        "Cape Horn, at the tip of South America, has a type of Tundra where summer temperatures barely reach 10°C, but unlike the Arctic Tundra, has no winter frosts",
    },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="11"
      heading="Tundra"
      subheading="Koppen Code: ET"
      documentTitle="The Tundra Climate - Koppen Climate Classification: ET"
      headerImageSrc={headerImage}
      youTubeCode="pNxgY4Ru3gs"
      chapterPrevText="Subarctic"
      chapterPrevUrl="/climate/subarctic"
      chapterNextText="Icecap"
      chapterNextUrl="/climate/icecap"
    >
      <p>
        The treeless Arctic. A desolate region, where few things grow. Yet, rich in fauna, both on the land, and at sea, and is the home of
        an iconic animal in this world of climatic uncertainty.
      </p>
      <p>
        A famous people survive here toughing it out in the most hostile populated climate on earth. A barren land of ice, lake, swamp and
        grass. This is the northern tip of our planet… this is the tundra.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        In the <A to="subarctic">last chapter</A> we looked at the subarctic lands dominated by the boreal forest, the taiga. Now, as we
        travel even further north in our journey from equator to pole, we arrive in the true Arctic itself, and this region is dominated by
        the treeless expanse known as the tundra.
      </p>
      <p></p>
      <p>
        Our word for this biome, like the Taiga of the Sub-Arctic, is also borrowed from Russian, with roots in the Sami word for "treeless
        uplands". But whereas the Taiga is dominated by enormous forest, the tundra, by contrast, and by definition, is devoid of trees, and
        is a bleak land consisting only of dwarf shrubs, hardy grasses, mosses and lichen.
      </p>
      <p>
        The reason that trees cannot grow here is simple - it's too cold. And by this, specifically, we mean that summer temperatures must
        be above 10 degrees C for at least a month in summer in order for trees to complete their necessary seasonal cycles. But for
        anything to grow at all, temperatures must be above freezing. And these two temperature points give us the climatic definition of
        the tundra - summer temperatures must be between 0 and 10 degrees.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of the Tundra climate zone" />
      <p>
        Interestingly, winter temperatures are not as extreme as some of those found in the sub-arctic regions of north-eastern Siberia.
        This is because the tundra areas are never that far from the coasts of the arctic ocean, and so are moderated somewhat, even though
        this ocean is ice covered for half the year or more.
      </p>
      <p>
        Average temperatures in the tundra, like in the sub-arctic, are cold enough to produce a layer of permanently frozen soil throughout
        the year, known as permafrost. This is another factor limiting the growth of more substantial plants which would otherwise demand
        deeper root structures.
      </p>
      <p>
        The tundra is very low in precipitation, comparable to desert and semi-arid regions closer to the equator. But because of the low
        evaporation rates from such low temperatures, and the barrier of the permafrost preventing proper drainage, water stays around,
        allowing many dwarf species of plants to grow.
      </p>
      <h3>Where in the world do we find the Tundra climate?</h3>
      <p>
        So, where in the world can we find the tundra? The classic tundra, with cool summers and very cold winters that produces the
        permafrost, is found only in the arctic, and includes the northern coast of Alaska, almost all the northern coast and islands of
        Canada, the coasts of Greenland, Svalbard - a large island north of Norway, and most of the northern coasts of Russia.
      </p>
      <ChapterImageList images={imagesLandscape} />
      <p>
        In the uplands of Iceland and Norway, and at high altitudes in the Alps and Himalayas, summer temperatures are so cold, that tree
        growth is not possible, and so these areas have a form of tundra as Alpine meadows, although no permafrost exists here.
      </p>
      <p>
        The southern tip of South America, and the coasts of the Antarctic peninsula also have tundra-like conditions, but without
        permafrost. These areas, however, are really slightly colder versions of the sub-polar <A to="oceanic">Oceanic climate</A> (the
        Koppen Cfc) that we talked about in Chapter 7, because winter temperatures here rarely fall below freezing, despite summer
        temperatures staying below 10°C, due to the heavy moderating influence of the Southern Ocean.
      </p>
      <h3>Landscapes and Vegetation</h3>
      <p>
        So what <A to="/biomes/polar-biomes">landscapes and vegetation</A> do we find in the Arctic? The absence of trees leads to stark
        vistas of empty plains and bare mountains, covered only by hardy low-lying grasses, moss, lichen, and low-lying shrubs, the latter
        occurring in the warmer section of the tundra bordering on the taiga.
      </p>
      <p>
        The permafrost – permanently frozen ground from a few inches to a few feet below the surface in summer and going down to depths of
        up to hundreds of metres, not only inhibits plant root growth, but also prevents drainage, resulting in a patchwork of lakes, bogs
        and swamps in flat areas.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Notable settlements with the Tundra climate" />
      <p>
        Due to the short summer, the growing season lasts only for about two months, so the hardy plants that live here must flower as soon
        as possible after the long freezing winter.
      </p>
      <p>
        Farming in such a harsh climate is non-existent – the few people that live in these regions, such as the Inuit of North America,
        rely upon the hunting of animals – seals, caribou, whales, fish, and even polar bears, among others, to feed themselves.
      </p>
      <p>
        There are no major cities to be found in these regions – the largest settlement being Nuuk (formerly Godthaab), the capital of
        Greenland, with around 18,000 population.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>Why can't trees grow in the Tundra?</li>
        <li>
          How does the Tundra climate differ from the Subarctic? Why are the tundra winters sometimes not as severe compared to the
          subarctic?
        </li>
        <li>What is the permafrost and how does it influence the landscape?</li>
        <li>What are the natural biomes and landscapes in these climate zones?</li>
        <li>List out some regions that experience the Tundra climate.</li>
      </ol>
    </Chapter>
  );
}

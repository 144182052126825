import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";

import A from "../components/A";

export default function BiomesTaiga(props) {
  const headerImage = "taiga-hero.jpg";
  const ImageKoppen = "taiga-koppen.jpg";
  const ImageCompareSemiArid = "biomes-comparison-subarctic-semi-arid.jpg";
  const ImageFire = "taiga-fire.jpg";
  const ImageTaigaTundra = "taiga-tundra-transition.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";
  const ImageSpecies1 = "taiga-species-1.jpg";
  const ImageSpecies2 = "taiga-species-2.jpg";
  const ImageSpecies3 = "taiga-species-3.jpg";
  const ImageSpecies4 = "taiga-species-4.jpg";
  const ImageSpecies5 = "taiga-species-5.jpg";
  const ImageSpecies6 = "taiga-species-6.jpg";
  const ImageSpecies7 = "taiga-species-7.jpg";

  const imagesHoldridge = [
    { src: ImageHoldridge, caption: "In the Holdridge Lifezones chart, Taiga is in the third row - 'Boreal', centre and right" },
    {
      src: ImageCompareSemiArid,
      caption:
        "The Sahel of Africa and most parts of the Taiga have relatively low rainfall. Yet the biomass in the Taiga is much higher, due to lower evapotranspiration rates",
    },
  ];

  const images2 = [
    {
      src: ImageFire,
      caption: "Fires sweep through the Taiga periodically, resulting in no parts having trees more than a couple of centuries old",
    },
    { src: ImageTaigaTundra, caption: "The Taiga transitions gradually into the Tundra through the Lichen Woodland and Tundra Forest" },
  ];

  const imagesSpecies1 = [
    {
      src: ImageSpecies1,
      caption: "Scots Pine is found throughout the Eurasian Taiga",
    },
    {
      src: ImageSpecies2,
      caption: "Spruce can be found in both the North American and Eurasian Taiga",
    },
    {
      src: ImageSpecies3,
      caption:
        "Firs are found on both boreal continents, and this species is most closely related to the Cedars of more temperate climates",
    },
  ];

  const imagesSpecies2 = [
    {
      src: ImageSpecies4,
      caption: "Larches are deciduous, and in Autumn turn golden in the same way as broadleaf trees",
    },
    {
      src: ImageSpecies5,
      caption:
        "Larches in winter lose their needles. The Siberian Larch dominates NE Siberia and is responsible for the 'Seasonal Taiga' biome designation in that area",
    },
  ];

  const imagesSpecies3 = [
    {
      src: ImageSpecies6,
      caption: "Birches, with their distinctive silver bark, can be found mixing with conifers throughout Canada and Northern Eurasia.",
    },
    {
      src: ImageSpecies7,
      caption:
        "Aspens are found in most southerly regions of the Eurasian Taiga, as well as upland parts of the Rocky Mountains of North America..",
    },
  ];

  return (
    <Chapter
      series="biomes"
      seriesChapter="7"
      heading="Taiga"
      subheading="THE BOREAL FORESTS"
      documentTitle="The Taiga Biome or Boreal Forest Biome"
      headerImageSrc={headerImage}
      youTubeCode="OUmHWrF8MnY"
      chapterPrevText="Temperate Forests"
      chapterPrevUrl="/biomes/temperate-forests"
      chapterNextText="Polar Biomes"
      chapterNextUrl="/biomes/polar-biomes"
    >
      {/* 
      <ChapterImageList images={images} /> */}

      <p>
        A sea of the darkest green, stretching unbroken from coast to coast. The continental north is dominated by this, the last great
        wilderness of earth. Only six tree families vie for contention here, making it among the least biodiverse of all biomes. And yet its
        global significance is underpinned by a third of all tree numbers, including two thirds of all conifers globally, being found here.
        A fifth of the biomass of the entire planet, the boreal forest is gigantic, seemingly endless, and forbidding. Pray that you have
        warm clothing and a knowledgeable guide. For you have stepped into the taiga.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        The boreal forest, or taiga, dominates the northern halves of the two northern continents – North America and Eurasia. Consisting
        almost exclusively of coniferous forest, it one of only two biomes so dominated by a single class of plant species – the other being
        grasslands. In fact the taiga is dominated by only four species families – pine, spruce, fir and larch, with some areas also
        featuring broadleaf aspen and birch. And so biodiversity is among the lowest of any biome.
      </p>
      <p>
        The word taiga comes to us from Russian, which is no surprise considering that large country has most of the world’s fraction of
        this biome. It literally means “land of the little sticks”. The other common term for the taiga is boreal forest – boreal is Latin
        for “northern” – as in Aurora Borealis – and so simply means “northern forest”.
      </p>
      <ChapterImage
        right
        src={ImageKoppen}
        caption="Evergreen coniferous forest forms the majority of Taiga, with a section of mixed Aspen/Birch, and seasonal (needle loss in winter) Larch forest. The coupling between this biome and the Subarctic climate is very strong"
      />
      <p>
        In the LONS08 biome classification system, the taiga comprises two types, evergreen and seasonal. Many of you will be familiar with
        the evergreen variety, in which coniferous species retain their leaves year round. This makes up the vast majority of taiga by area.
        The seasonal type, found only in NE Siberia, features mostly Siberian Larch forest which loses its leaves during the extremely cold
        winters there – so even conifers can be deciduous.
      </p>
      <p>
        The relationship between climate and biome is very close, as this comparison shows. It can be seen that the Koppen Subarctic climate
        extent mirrors that of the boreal forest biome almost exactly. The centre of the Siberian subarctic has an incursion of mixed
        conifer and broadleaf aspen and birch, but this is still regarded as taiga. Note that the very cold winter subarctic types overlap
        very closely with the seasonal taiga in NE Siberia, demonstrating this plant adaption in surviving such extreme winters.
      </p>
      <p>
        Since the subarctic and taiga biomes overlap so much, I have covered much of this material in the{" "}
        <A to="/climate/subarctic">Subarctic chapter</A> of my Secrets of World Climate series, linked top right and in the description. So
        I recommend you study that in combination with this chapter, since I don’t want to repeat myself on some details, such as the where
        in the world section.
      </p>
      <p>
        In our Holdridge Lifezones chart, we find the taiga in the third row, with forest in most of this temperature band. A type of cold
        rainforest is found in narrow coastal areas in Alaska and Norway, where the rainfall is very high. But most of the taiga is either
        somewhat wet to moderately dry, which, in combination with low evaporation rates so far north, promotes the perfect conditions for
        coniferous tree growth. The taiga demonstrates perfectly the principle that rainfall alone is not the sole arbiter of whether a
        region will be forested, scrub-like or desert. These two graphs of Siberia and the Sahel of Africa have very similar total rainfall,
        and yet Siberia is forested and the Sahel is near-desert, simply due to dramatic differences in evapotranspiration.
      </p>
      <ChapterImageList images={imagesHoldridge} />
      <p>
        So why do conifers dominate so much in this type of climate? It is simply that they are just better cold-adapted than broadleafs,
        having evolved much earlier in earth’s history, and consequently more experienced in surviving fluctuations in climate, as the Earth
        swung from ice-age to hot-house many times in its history. Conifers photosynthesise more efficiently at lower temperatures than
        broadleafs, and in combination with their evergreen nature, these trees to begin growth early in the spring when temperatures are
        favorable, rather than wasting valuable time to grow new leaves. Needles offer a smaller surface area to volume ratio than leaves,
        and also have a waxy coat known as a cuticle, which together makes them better at withstanding cold and dry conditions. And as we
        know from Christmas trees, most conifers are cone-shaped, which has a number of advantages in the subarctic climate. Firstly, this
        shape prevents snow building up all on the top branches that would otherwise cause them to break, but rather distributes it across
        the body of the tree to the ground. Secondly it allows more of the tree to receive sunlight, which is weak in the far northern
        latitudes.
      </p>
      <p>
        Most of the taiga is thickly forested in this way. The forest floor is dominated by moss - up a third of the ground is covered in
        it. Lichen, a mix of fungus and algae, is also common, covering many tree trunks. Wildflowers can also be found in certain areas,
        and shrubs occur in floodplains where the soil is richer.
      </p>
      <p>
        The soil is heavily acidic and as a result strongly leached of minerals. It lacks invertebrate species numbers such as earthworms
        and millipedes, leading to a build-up of non-decomposed matter. This thick layer causes a cooling of the soil which, in addition to
        the poor penetration of sunlight through the thick canopy promotes permafrost – a permanently frozen layer of soil and ice below the
        surface. This layer of ice acts as a barrier to groundwater drainage, and so we find a great deal of flooded land in the form of
        swamps and bogs.
      </p>
      <ChapterImageList images={images2} />
      <p>
        The layer of undecomposed needles on the forest floor is only cleared away by periodic fires. One would think that such a cold part
        of the world would not be frequented by fires. But the subarctic summers are warm and in general the climate has low rainfall,
        leading to often tinderbox conditions. Combined with the flammable resinous content of conifers, fires can be enormous in extent.
        They are so common here that it is believed that no part of the taiga has trees more than a couple of centuries old since every part
        of it sees a fire that destroys all in its path within that time period. One such incident, the Black Dragon Fire of 1987 straddling
        the border of China and Russia in the far east of Siberia is believed to have been the single biggest fire on Earth in the last few
        centuries.
      </p>
      <p>
        The northern extent of the taiga blends into the tundra in what is known as the lichen woodland, where the canopy of the forest is
        broken, allowing more light onto the forest floor where tundra-like species can grow. Further north again and there is the forest
        tundra, where isolated stands of trees, surrounded by tundra, have survived from warmer climatic periods in the past, protected in
        their isolation from the ravages of fire, and are believed to only still be in existence through non-sexual reproduction as no seeds
        are viable in these conditions. The southern extents blend into either mixed temperate forest in wetter regions, or grasslands in
        the heart of the continents where conditions are drier.
      </p>
      <h3>Species</h3>
      <p>
        So lets have a closer look at the species in the taiga. There are only six families to consider, four coniferous and two broadleaf.
      </p>
      <p>
        Pines (Pinus) are the most famous of all conifers and also the most widely distributed, extending all the way into the tropics as
        well as the North American and Eurasian taiga. The Scots Pine is the most widely distributed pine in the world, found from Scotland
        to the Pacific coast of Siberia.
      </p>
      <p>
        The Spruce (Picea) family is also very widely distributed, occurring throughout North America and Eurasia, with the Siberian spruce
        (Picea obovate) covering all of Siberia from the Urals to the Pacific, although not in the same numbers as pines or larch.
      </p>
      <p>
        Firs (Abies) are found on both boreal continents, and this species is most closely related to the Cedars of more temperate climates.
        Siberian Fir is restricted to NE Siberia, but in general firs dominate the more moist coastal forests of Alaska and Norway.
      </p>
      <ChapterImageList images={imagesSpecies1} />
      <p>
        The Larch (Larix) family is unique among conifers in that it is deciduous. Like broadleafs, larches turn golden in autumn and drop
        their needles entirely in winter. In North America the most common larch is the Tamarack, while Sukaczev’s larch is common in
        Europe. Siberia as a whole is larch dominated, and the extreme cold winter subarctic regions of Yakutia and Sakha provinces NE
        Siberia are exclusively populated by Siberian larches, the hardiest of all conifers. And this is why this region receives the
        specific sub-biome designation of boreal seasonal forest.
      </p>
      <ChapterImageList images={imagesSpecies2} />
      <p>
        The two hardiest broadleaf species families are also found in the taiga. Birches, with their distinctive silver bark, can be found
        mixing with conifers throughout Canada and Northern Eurasia.
      </p>
      <p>
        Aspens, a species within the Poplar family that light up the autumn with fiery colour, are found in most southerly regions of the
        Eurasian Taiga, as well as upland parts of the Rocky Mountains of North America.
      </p>
      <ChapterImageList images={imagesSpecies3} />
      <h3>Fauna and Threats</h3>
      <p>
        In terms of fauna, the taiga is host to many large mammals such as moose, reindeer, caribou and wolves. In fact, the mammals in
        general here are larger than in more temperate regions, since having a larger body allows for better heat retention to survive the
        harsh winters. Many seasonal birds visit during the summer, and the presence of so many swamps leads to enormous populations of
        mosquitoes – as anyone who has visited these regions at that time will be more than willing to tell you.
      </p>
      <p>
        In terms of threats, forestry has led to the loss of some primary forest, although this is dwarfed by the fires that reach most
        areas of the taiga at least every century or so. There are no major human threats due to the inhospitable climate and the sheer
        extent of it. Only about 0.1% of the Siberian Taiga is logged annually. But a word can be said in regard to climate change. The
        northern and southern boundaries of the taiga have shifted north and south over millennia as a result of warming and cooling of the
        planet’s climate. During the last ice age, in fact, the taiga extended as far south the Gulf of Mexico, and as we head into a
        warming phase now, the taiga will populate more of the tundra, and more deciduous species, especially birch and aspen, will vie with
        conifers in the south. Incidentally, the earth would be significantly colder if we had no taiga. This is because the boreal forest
        is the darkest of all biomes and this leads to more absorption of solar radiation, especially during winter, which would otherwise
        be white snow, reflecting heat back into space.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>What are the two most common names for this biome?</li>
        <li>Is all the Taiga evergreen? Explain.</li>
        <li>What climate type is associated with the Taiga?</li>
        <li>Explain why many parts of the Taiga are very dry and yet support such high biomass.</li>
        <li>Why is the Taiga dominated by coniferous forest?</li>
        <li>Is fire common in the Taiga? Explain.</li>
        <li>List out the main tree species families to be found in the Taiga.</li>
      </ol>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
//import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";

import A from "../components/A";

export default function BiomesPolar(props) {
  const headerImage = "polar-hero.jpg";
  const ImageSunlight = "polar-sunlight.jpg";
  const ImageNoWinterSun = "polar-no-sun-in-winter.jpg";
  const ImageTundraExtent = "polar-tundra-extent.jpg";
  const ImageKoppen = "polar-koppen.jpg";
  const ImageBogs = "polar-bogs.jpg";
  const ImageCaribou = "polar-caribou.jpg";
  const ImagePenguins = "polar-penguins.jpg";
  const ImageSpecies1 = "polar-species-1.jpg";
  const ImageSpecies2 = "polar-species-2.jpg";
  const ImageSpecies3 = "polar-species-3.jpg";
  const ImageSpecies4 = "polar-species-4.jpg";
  const ImageSpecies5 = "polar-species-5.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";
  const images1 = [
    {
      src: ImageSunlight,
      caption: "In polar regions sunlight is at an oblique angle to the ground, spreading out its energy and reducing surface heating",
    },
    { src: ImageNoWinterSun, caption: "Polar regions can experience no sun at all for several months each winter" },
  ];

  const images2 = [
    { src: ImageTundraExtent, caption: "The vast majority of Tundra encircles the Arctic Ocean" },
    { src: ImageKoppen, caption: "The Koppen Climate Tundra and Icecap zones very closely match the LONS08 Tundra and Ice biomes" },
  ];

  const images3 = [
    {
      src: ImageHoldridge,
      caption: "The Holdridge Lifezones chart shows the Ice and Tundra biomes in the first and second rows respectively",
    },
    {
      src: ImageBogs,
      caption: "The presence of the permafrost beneath the surface prevents drainage leading to extensive bogs throughout the tundra",
    },
  ];

  const imagesSpecies1 = [
    {
      src: ImageSpecies1,
      caption: "Lichen",
    },
    {
      src: ImageSpecies2,
      caption: "Moss",
    },
    {
      src: ImageSpecies3,
      caption: "Sedge",
    },
    {
      src: ImageSpecies4,
      caption: "Cotton Grass",
    },
    {
      src: ImageSpecies5,
      caption: "Forest Tundra",
    },
  ];

  const imagesFauna = [
    { src: ImageCaribou, caption: "Caribou (Reindeer) are found throughout the Arctic Tundra" },
    { src: ImagePenguins, caption: "Penguins are the only land animals present on the Antarctic mainland throughout the year" },
  ];

  return (
    <Chapter
      series="biomes"
      seriesChapter="8"
      heading="Polar Biomes"
      subheading="TUNDRA &amp; ICE"
      documentTitle="The Tundra Biome and Ice Biome"
      headerImageSrc={headerImage}
      youTubeCode="II15uUa1on0"
      chapterPrevText="Taiga"
      chapterPrevUrl="/biomes/taiga"
      chapterNextText="Wetlands"
      chapterNextUrl="/biomes/wetlands"
    >
      {/* <ChapterImage right src={Image} caption="" />
      <ChapterImageList images={images} /> */}
      <p>
        At the ends of the world, the sun’s reach is weak. Life struggles or is entirely absent. And indeed what life could survive in a
        world of freezing temperatures for most or all of the year? A treeless world of hardy shrubs, lichen and moss dominates the north,
        while a permanent layer of ice covers the south. These are the last of the biomes of our planet. This is the Arctic and Antarctic.
        This is the tundra and ice.
      </p>
      <p>
        The poles of our world receive sunlight at only an oblique angle, bringing little in the way of heat. And for almost half the year
        there is no sun at all. This dynamic of poor solar heating produces the coldest of the biomes, the tundra and ice. What separates
        these two biomes is only ten degrees of Celsius.
      </p>
      <ChapterImageList images={images1} />
      <p>
        In the tundra, summer temperatures rarely exceed 10°C. This prevents the growth of trees, and so marks the boundary between the
        boreal forest or taiga, and the tundra. Only hardy shrubs, herbs, moss and lichen can grow here, covering the bleak landscape when
        it’s not already covered in ice as it is for most of the year. These lands fringe the entirely of the arctic ocean, from North
        America and the coasts of Greenland to the north of Russia.
      </p>
      <p>
        The ice biome permanently covers ground that never sees temperatures rise above 0°C, which… makes sense when you consider that this
        is the point at which liquid water freezes. That liquid is essential to life, and so, in a permanent state of frost, no plant life
        would have been possible even if the ground wasn’t covered in a kilometres-deep layer of solid ice.
      </p>
      <ChapterImageList images={images2} />
      <p>
        The overlap of these two biomes with their respective climate zones of the same names is pretty much total – and so those climate
        zones are not misnomers. Because of this, I have covered both zones in detail in my Secrets of World Climate series in two chapters
        - <A to="/climate/tundra">Tundra</A> and <A to="/climate/icecap">Icecap</A>. For a full treatment of this subject, please view these
        in conjunction with this, as unlike in real-life, I am not going to repeat myself here.
      </p>
      <p>
        In our Holdridge Lifezones chart, we find the tundra and ice in the top two rows. It is worth noting that the term desert is used,
        which is, technically accurate, since the ice is deserted of life. Note also that the cooling of the temperate scale is not just in
        terms of increasing latitude, but also in altitude. And so tundra and ice are found in the high places of the world, above the
        treeline in the Rockies, Andes, Alps and Himalayas. However, in terms of percentage of land area covered, these are tiny in
        comparison to the Arctic and Antarctic regions.
      </p>
      <ChapterImageList images={images3} />
      <p>
        The permafrost, a permanently frozen layer of soil and ice stretching up to a kilometre down, is present in all Arctic tundra, as it
        is in the Taiga. And like in that other biome, leads to extensive bogs and lakes, since liquid water cannot drain into the rocks
        below. The permafrost is all but absent in alpine tundra, and in the small tundra-like areas and islands found around the southern
        ocean, such as Tierra del Fuego, the Falklands, the South Sandwich Islands and so on.
      </p>
      <p>
        Beneath the permanent icecap, no life exists, but in the tundra life does, albeit in a limited form. In the short tundra summer,
        there are only about 50-60 growing days permitted. This is too short for tree species to germinate, and so this realm is dominated
        by plants that can quickly flower and reproduce in such a short window. Lichen – a mix of fungi and algae is the hardiest of all
        plant classes globally, and is found in all parts of the tundra. Mosses are the next-most hardy plant class and these simple and
        ancient plants are also found throughout the biome. Sedges and cotton grass can be found in the often boggy, peaty conditions
        existing in the tundra plains while on slightly elevated sites, low willows, other grasses and rushes can be found. The tallest
        tundra species are found on better drained, sandy soils of river banks, and come from species families that include willow,
        sunflower and legume. Isolated stands of coniferous trees, known as forest tundra, can be found in certain areas. These are
        vestigial remnants of taiga from earlier times when the climate was once warmer, and have survived as clonal colonies, unable to
        reproduce through the normal channel of seed germination. Fungi and bacteria are found throughout the soil and act as essential
        vectors in the breakdown and recycling of dead plant material.
      </p>
      <ChapterImageList images={imagesSpecies1} />
      <p>
        The fauna in the tundra is limited, but where it exists, is well-known. Polar bears patrol the coasts, while inland areas feature
        large herds of grazing giants such as reindeer, caribou, musk-ox, and their accompanying predators, arctic wolves. As in the taiga,
        these species are larger than normal, since a bigger body is easier to keep warm than a small one. But not all tundra mammals are
        large, and arctic hares, foxes and lemmings are also common, with many sporting seasonal white coats as camouflage during the long
        icy winters.
      </p>
      <p>
        Antarctic fauna is well-known, in the form of enormous colonies of penguins, as well as the seals that prey upon them. But strictly
        speaking, these animals are not a part of the tundra or ice biome, as such, since they rely entirely upon the ocean, instead of the
        land, for their survival.
      </p>
      <ChapterImageList images={imagesFauna} />
      <p>
        In terms of threats, the tundra and ice are not directly threatened by human activity, since so few people live in these regions,
        less than several hundred thousand in a planet of billions. However with the advent of climate change, whether man-made or natural,
        the permafrost of the tundra is melting, as are the icecaps of Greenland and Antarctica. The melting of the permafrost leads to the
        release of methane, a potent greenhouse gas, while the melting of the icecaps will lead to sea-levels rising, and global
        consequences.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>Why are the polar regions so cold?</li>
        <li>What is permafrost and how does it affect the landscape?</li>
        <li>Where is the vast majority of the Tundra located? How does this differ from the other areas of Tundra in the world?</li>
        <li>What two places have the Ice Biome?</li>
        <li>List out some non-animal species families that survive in the Tundra.</li>
        <li>Are the polar regions susceptible to changes in Earth's climate? Explain</li>
      </ol>
    </Chapter>
  );
}

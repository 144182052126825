import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc8CoolDeserts(props) {
  const headerImage = "cool-deserts-hero.jpg";
  const ImageContinentality = "cool-deserts-continentality.jpg";
  const DesertCauseImage2 = "cool-deserts-rain-shadow.jpg";
  const DesertCauseImage3 = "cool-deserts-rain-shadow-example-1.jpg";
  const DesertCauseImage4 = "cool-deserts-rain-shadow-example-2.jpg";
  const ImageLandscapeTaklamakan = "cool-deserts-taklamakan.jpg";
  const ImageLandscapeChile = "cool-deserts-chile.jpg";
  const ImageLandscapeGobi = "cool-deserts-gobi.jpg";
  const ImageLandscapeGrandCanyon = "cool-deserts-usa-grand-canyon.jpg";

  const ImageComparisonOfAltitude = "cool-deserts-comparison-at-different-altitudes.jpg";

  const GlobalDistributionMap = "Cool-Deserts-Climate-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-cool-deserts.gif";
  const desertCausesImages = [
    {
      src: DesertCauseImage2,
      caption:
        "Rain Shadow: Deserts are found on the leeward side of mountains as these slopes push any moist air depriving it of moisture as rain on the windward side",
    },
    {
      src: DesertCauseImage3,
      caption: "Rain shadow over the Southern Andes - prevaling westerlies lose moisture over Chile leading to the Patagonian desert",
    },
    {
      src: DesertCauseImage4,
      caption:
        "Rain shadow over the Mid Andes - prevaling trade winds lose moisture over Eastern and Central Boliva leading to the Atacama desert",
    },
  ];

  const imagesLandscapes = [
    {
      src: ImageLandscapeGrandCanyon,
      caption:
        "The Grand Canyon, Arizona, USA. The top is cool desert, due to altitude, but the Colorado River at the bottom, is hot desert",
    },
    {
      src: ImageLandscapeChile,
      caption: "Valle de la Luna, Atacama Desert, Chile. Image courtesy of Thomas Noisel",
    },
    {
      src: ImageLandscapeTaklamakan,
      caption: "The Taklamakan Desert of Central Asia, as seen from space. Image courtesy of NASA.",
    },
    {
      src: ImageLandscapeGobi,
      caption: "Northern fringes of the Gobi desert, Mongolia, where it blends into the Steppe. Image courtesy of Thomas Noisel.",
    },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="8"
      heading="Cool Deserts"
      subheading="KOPPEN CODES: BWk &amp; BSk"
      documentTitle="The Cool Desert Climate - Koppen Climate Classification: BWk BSk"
      headerImageSrc={headerImage}
      youTubeCode="kSzuXA7oQ68"
      chapterPrevText="Oceanic"
      chapterPrevUrl="/climate/oceanic"
      chapterNextText="Continental"
      chapterNextUrl="/climate/continental"
    >
      <p>
        Majestic landscapes. Endless plains. Where a lack of rain combines with cold winters. On the wrong side of mountains, or thousands
        of miles from the ocean. One such place is the driest on our planet.
      </p>
      <p>
        The unlikely centre of the largest land empire in history. And the only world climate to spawn a popular genre of fiction. These are
        the cool deserts of Planet Earth.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        As we continue our journey in this series from equator to poles, we have reached as far north as we can go for the time being with
        the Oceanic climate. So it’s now time head into the interior of the continents at the mid-latitudes. And the first of the climates
        we will look at here are the cool deserts.
      </p>
      <p>
        Like the hot deserts <A to="hot-deserts">that we covered in Chapter 4</A>, the cool deserts are defined by a lack of rain throughout
        the year. But the mechanisms that cause such drought are very different. Found at higher altitudes, deep with the northern
        continents hundreds or even thousands of miles from the moisture of the ocean, or on the leeward side of great mountain ranges,
        these causes are very different to the mass downward movement of tropical air that causes the hot deserts such as the Sahara,
        Kalahari or Mojave.
      </p>
      <ChapterImage
        right
        src={ImageContinentality}
        caption="Continentality: Where an area is so far from the ocean, any winds reaching it have already lost their moisture"
      />
      <p>
        The first of these mechanisms is called “Continentality” – literally being in the middle of a large continent, so far from the
        ocean, that any wind that has penetrated thus far has been sapped of any moisture that it started with. The great deserts of Central
        Asia are a good example of this – these areas are factually the furthest from any ocean on the planet.
      </p>
      <p>
        Another cause of cool deserts is what is known as “Rain Shadow”. Winds originating from the ocean almost always carry moisture, but
        if these winds hit mountains, the air is pushed upward and cooled, causing it to lose its moisture upon the windward slopes of these
        mountains, such that when the winds have passed over, there is nothing but dry air left, and so any land found on this leeward side
        receives little or no rain. Rain shadow is perfectly illustrated by taking a look at a satellite image of lower South America. The
        wet and dry regions can clearly be seen by the presence or absence of green. In the southern part of the continent, the prevailing
        winds are westerly, as we showed in the <A to="oceanic">last chapter on Oceanic climates</A>. So southern Chile, on the windward
        side of the Andes is wet, while Patagonia on the leeward side is dry. Travel further north, however, and the prevailing winds are
        the easterly trade winds of the tropics <A to="tropical-monsoon-and-tropical-savannah">that we covered in Chapter 2</A>. These winds
        are sapped of moisture as they travel over the high altiplano of Bolivia, such that they are bone dry by the time they reach the
        Atacama desert, the driest place on the planet outside of certain valleys in Antarctica.
      </p>
      <ChapterImageList images={desertCausesImages} />
      <p>
        A final factor is where one would normally find a hot desert, but high altitude typically in the form of high plains or plateaux,
        results in cooler temperatures and frosty winters. This is the cause of much of the arid conditions in the Western United States.
      </p>
      <ChapterImage
        right
        src={ImageComparisonOfAltitude}
        caption="Differences in altitude in nearby desert regions can determine either Hot or Cool Desert climate"
      />
      <p>
        The cool deserts differ in terms of recorded climate in one key way, and that is winter temperatures. Cool deserts can get as warm
        in summer as the hot deserts, but in winter, temperatures can plunge to minus 30 degrees or lower, whereas hot deserts rarely touch
        freezing point.
      </p>
      <p>
        In the Koppen climate classification system, cool deserts are subdivided into two climates, depending upon how much rain falls. The
        truly dry cool deserts have the classification BWk – this is the classification of the Atacama and Karakum deserts, where very
        little vegetation grows, and where no major cities are located due to the harshness of the environment. Where more rain falls, the
        classification BSk is ascribed, which is referred to as cool semi-arid, or simply steppe, from the Russian word for short grass,
        since such grasses predominate in this climate in a wide band across central Asia.
      </p>
      <h3>Where in the world do we find Cool Desert Climates?</h3>
      <p>
        So, where in the world are the cool deserts? Starting in North America, we find that most of the inland western half of the United
        States is dominated by arid or semi-arid conditions. In the south, the arid conditions of Nevada, northern Arizona and New Mexico
        are extensions of the hot deserts of the Mojave and Chihuahua, where higher altitudes lead to freezing winters and a cool
        classification. Further north into the eastern parts of Oregon and Washington, and most of the states of Utah, Colorado, Wyoming and
        Montana, we have continentality and rain shadow both contributing to this huge area of dramatic landscapes and big skies that gave
        birth to the books and movies of the Western genre. On the eastern slopes of the Rockies, the plains are lower, but within the rain
        shadow of the Rocky Mountains. But with occasional moist rains bubbling up from the south, dry prairies rich in wheat farmlands are
        possible and dominate the landscape.
      </p>
      <ChapterImageList images={imagesLandscapes} />
      <ChapterImage left src={GlobalDistributionMap} caption="Global Distribution of Cool Desert Climate Zones" />
      <p>
        In South America, we find the famous Atacama, driest of all deserts, a product of the rain shadow of easterlies over the Andes,
        while further south, the desert switches sides with the winds moving to the west, and the result is the dry semi-desert of
        Patagonia.
      </p>
      <p>
        Southern Africa and Australia also have bands of cool arid and semi-arid conditions that border on the hot deserts to their
        immediate north.
      </p>
      <p>
        Much of Eastern and Central Spain is classified as cool semi-arid, due to a combination of moderately high altitude, and weak
        penetration of winterly westerlies into the interior of this wide peninsula at the Mediterranean latitude.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Climate graphs of some places with Cool Desert climates" />
      <p>
        Last we come to Asia, where the sheet enormity of this continent produces a band of arid landscapes so far from the ocean, comparing
        in size to the Sahara. On the western edge, we have the country of Iran which is dominated by cool desert, largely due to its higher
        altitude compared with the rest of the hot and dry Middle East. As we move north and east we continue the high altitude and dry
        mountains into Afghanistan, and north again we are into the flat Karakum desert of the Central Asian republics of Turkmenistan and
        Uzbekistan, also home to the Aral Sea, once one of the biggest freshwater lakes in the world, but now reduced to a salty remnant due
        to over-irrigation. Moving further north and we are into the large semi-arid steppe of Kazakhstan, which then blends into the richer
        grasslands of central Russia. We’ll take up these in the <A to="continental">next chapter</A>. Hopping over the Altai mountains, and
        we arrive in Western China, and the great sandy Taklamakan desert, a product of both continentality and rain shadow, with mountains
        on all sides leading to extremely arid dune covered plains. Our journey ends in perhaps the most famous cool desert of them all, the
        Gobi of Mongolia, birthplace of the largest land empire in history, the Mongols of Genghis Khan. Here the winters are bitterly cold,
        with the land so far north and so far from the moderating ocean, with Mongolia’s capital, Ulaanbaatar, being the coldest in the
        world.
      </p>
      <h3>Landscapes and Vegetation</h3>
      <p>
        Starved of rain, the cool deserts, like their hotter counterparts, have <A to="/biomes/deserts">little in the way of vegetation</A>,
        with the most arid parts such as the Atacama seeing virtually lifeless conditions. In the semi arid parts, stunted shrubs and
        grasses are the most commonplace. With the exception of the western prairies of the United States, where large scale wheat farming
        is possible, agriculture is difficult, and is largely confined to grazing of sheep and cattle.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>What are the three possible factors that lead to cool desert regions? Explain the dynamics of each.</li>
        <li>What are the Koppen codes for these climates? How do they differ from each other?</li>
        <li>
          Name the two most well-known <em>cool</em> deserts. What is the cause of each?
        </li>
        <li>What types of natural landscapes do we find in cool deserts?</li>
        <li>List out some countries and areas that experience cool desert climates.</li>
      </ol>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";

import A from "../components/A";

export default function BiomesSavannah(props) {
  const headerImage = "savannah-hero.jpg";
  const ImageMixed = "savannah-mixed.jpg";
  const ImageKoppen = "savannah-koppen.jpg";
  const ImageRainfallGraphs = "savannah-rainfall-graphs.jpg";
  const ImageTransition = "savannah-transition.jpg";
  const ImageWitw1 = "savannah-florida.jpg";
  const ImageWitw2 = "savannah-venezuela.jpg";
  const ImageWitw3 = "savannah-brazil.jpg";
  const ImageWitw4 = "savannah-mali.jpg";
  const ImageWitw5 = "savannah-masai-mara-kenya-2.jpg";
  const ImageWitw6 = "savannah-masai-mara-open.jpg";
  const ImageWitw7 = "savannah-namibia.jpg";
  const ImageWitw8 = "savannah-south-africa.jpg";
  const ImageWitw9 = "savannah-south-africa-2.jpg";
  const ImageWitw10 = "savannah-madagascar.jpg";
  const ImageWitw11 = "savannah-australia.jpg";
  const ImageSpecies1 = "savannah-species-1.jpg";
  const ImageSpecies2 = "savannah-species-2.jpg";
  const ImageSpecies3 = "savannah-species-3.jpg";
  const ImageSpecies4 = "savannah-species-4.jpg";
  const ImageSpecies5 = "savannah-species-5.jpg";
  const ImageSpecies6 = "savannah-species-6.jpg";
  const ImageSpecies7 = "savannah-species-7.jpg";
  const ImageSpecies8 = "savannah-species-8.jpg";
  const ImageSpecies9 = "savannah-species-9.jpg";
  const ImageSpecies10 = "savannah-species-10.jpg";
  const GlobalDistributionMap = "Biomes-World-Map-Savannah.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";

  const images1 = [
    { src: ImageMixed, caption: "Savannah is not just open grassland, but also a mix of trees and grass" },
    {
      src: ImageKoppen,
      caption: "The Tropical Savannah Koppen climate type is so named as it closely matches in geographic extent the Savannah biome",
    },
  ];

  const images2 = [
    {
      src: ImageHoldridge,
      caption: "In the Holdridge Lifezones chart, Savannah can be found as Thorn Steppe / Woodland, Thorn Woodland and Very Dry Forest",
    },
    { src: ImageRainfallGraphs, caption: "Savannah exists only in tropical areas where there is a marked wet and dry season" },
    { src: ImageTransition, caption: "Savannah is really a transition zone between Tropical Forests and Scrubland or Desert" },
  ];

  const imagesSpeciesAmericas = [
    {
      src: ImageSpecies1,
      caption: "South American Savannah plant species 1",
    },
    {
      src: ImageSpecies2,
      caption: "South American Savannah plant species 2",
    },
    {
      src: ImageSpecies3,
      caption: "South American Savannah grass species",
    },
  ];

  const imagesSpeciesAfrica = [
    {
      src: ImageSpecies4,
      caption: "African Savannah Acacia and Baobab",
    },
    {
      src: ImageSpecies5,
      caption: "African Savannah Bushwillow and Borassus",
    },
    {
      src: ImageSpecies6,
      caption: "African Savannah Brachystegia tree",
    },
    {
      src: ImageSpecies7,
      caption: "African Savannah grass species",
    },
  ];

  const imagesSpeciesAustralia = [
    {
      src: ImageSpecies8,
      caption: "Australian Savannah Eucalyptus",
    },
    {
      src: ImageSpecies9,
      caption: "Australian Savannah Baobab",
    },
    {
      src: ImageSpecies10,
      caption: "Australian Savannah grass species",
    },
  ];

  const imagesWitw1 = [
    { src: ImageWitw1, caption: "The Everglades in Florida are in fact a form of flooded Savannah" },
    { src: ImageWitw2, caption: "Venezuela has extensive Savannah in the central part of the country. Image courtesy of Pedro Matute" },
    {
      src: ImageWitw3,
      caption: "Brazil has some of the most biodiverse Savannah in the world. Image courtesy of Ricardo Madeira de Albuquerque",
    },
    { src: ImageWitw11, caption: "Much of the 'Outback' in Eastern Australia is Savannah" },
  ];

  const imagesWitw2 = [
    { src: ImageWitw4, caption: "Classic broken tree-and-grass-scape in Mali, West Africa" },
    { src: ImageWitw5, caption: "The famous Masai Mara Savannah in Kenya" },
    { src: ImageWitw6, caption: "Fully open Savannah in Masai Mara, Kenya" },
  ];

  const imagesWitw3 = [
    { src: ImageWitw7, caption: "Aerial view of Savannah in Eastern Namibia" },
    { src: ImageWitw8, caption: "Elephants in the Savannah 'bush' of Eastern South Africa" },
    { src: ImageWitw9, caption: "Aerial view of broken tree and grass Savannah in South Africa" },
    { src: ImageWitw10, caption: "The alien landscape of baobab trees in Madagascar. Image courtesy of Rita Willaert" },
  ];

  return (
    <Chapter
      series="biomes"
      seriesChapter="2"
      heading="Savannah"
      subheading="THE MIXED GRASS AND WOODLAND OF THE TROPICS"
      documentTitle="The Savannah Biome"
      headerImageSrc={headerImage}
      youTubeCode="Mle5gmEpYys"
      chapterPrevText="Tropical Forests"
      chapterPrevUrl="/biomes/tropical-forests"
      chapterNextText="Shrublands"
      chapterNextUrl="/biomes/shrublands"
    >
      {/* <ChapterImage right src={Image} caption="" />
      <ChapterImageList images={images} /> */}

      <p>
        The vast open country of the tropics. A patchwork of trees and shrubs on a bed of grass. A place of deluge and drought. It dominates
        the continent at the centre of our world, and of all the world’s habitats, this one supports the earth’s most famous wildlife. The
        home of the safari. This is the Savannah.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        The tropics of our planet have two habitats that are instantly recognisable to us. The first of these we have already covered – the{" "}
        <A to="tropical-forests">tropical rainforest</A>. The other, much drier one is that of the tropical savannah. Spreading out over
        vast plains across three continents, the Savannahs of the tropics make up for any lack of biodiversity compared to their richer
        rainforest cousin in sheer quantity of large mammalian wildlife, feeding on the endless grass, and on the animals that eat that
        grass.
      </p>
      <p>
        When many of you think of Savannah, you probably think of vast uninterrupted plains of grass, such as the Serengeti so often shown
        on our TV screens in wildlife documentaries. But Savannah is in fact a mix of trees, shrubs and grasses. Annually, between wet{" "}
        <A to="/climate/seasons">seasons</A>, conditions of severe drought limit the forest development, widening the gap between trees, and
        allowing plenty of light to reach the ground. This leads to thickets of shrubs or extensive grass cover to fill in the gaps. And as
        in the adjoining tropical seasonal forests, trees here survive the dry season by dropping their leaves.
      </p>
      <p>
        The Koppen climate type that dominates these regions is named after this biome –{" "}
        <A to="/climate/tropical-monsoon-and-tropical-savannah">Tropical Savannah</A>, so closely do the regions match in geographical
        extent.
      </p>
      <ChapterImageList images={images1} />
      <p>
        If we consult our Holdridge Lifezones chart, we can find savannah to the left of tropical forests, where overall rainfall is
        reduced, and where it is described as either thorn steppe or woodland, or very dry forest. But this chart tells only half the story,
        as it is the drought of the long dry season in these areas, rather than the overall amount of rain, which influences their character
        most strongly.
      </p>
      <ChapterImageList images={images2} />
      <p>
        Savannahs are really transition zones between the verdant rainforests of the tropics and the parched hot deserts of the subtropics.
        They don’t suddenly begin or end, but rather merge into the tropical forests on the wetter side, and into scrubland on the drier
        side. The amount of tree canopy cover percentages that determines whether the land can be referred to as Savannah is subjective, but
        can be anywhere between 5 and 80%.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of the Savannah biome" />
      <p>
        With such extensive dry seasons in these parts, it’s no surprise to find brushfires here. In fact all the plant species have evolved
        to survive regular fires in various ways. Such is the effect of fire, that it is believed many of the world’s savannah regions have
        developed as a result of human fire activity over many thousands of years, where, without this, tropical forests would have been
        present.
      </p>
      <h3>Where in the world do we find Savannah?</h3>
      <p>
        If we apply the strict definition of a natural mix of trees, shrubs and grass, then savannah occurs in temperate and subtropical
        latitudes as well as the tropics. But these areas are relatively small compared to the tropical savannah that dominates the tropics,
        in South America, Australia, and above all, Africa. I’ll mention a bit more about these other areas in the chapter on{" "}
        <A to="grasslands">Grasslands</A>. But for now, we’ll continue our focus on the tropics.
      </p>
      <p>
        Region by region, then, Savannahs are found in the Americas, in isolated strips in southern Mexico, some Caribbean islands, in
        particular Cuba, and in Southern Florida, where, in fact, the Everglades are a flooded form of Savannah.
      </p>
      <ChapterImageList images={imagesWitw1} />
      <p>
        South America has more extensive Savannahs, including most of the central part of Venezuela, while central Brazil has vast areas of
        broken woodland, in what is known as the Cerrado. The Brazilian Savannah is the most biodiverse in the world, with an estimated 800
        tree species alone.
      </p>
      <p>
        Skipping over to Australia, and we find extensive parts of the Outback in the form of broken woodland, shrubland and grassland that
        meets the Savannah definition, as a wide transition zone spreading across the north and east of the island continent, between the
        coastal forests and the parched desert interior. Species transition gradually from tropical varieties in the north to more temperate
        forms in the south in the area shown on this map.
      </p>
      <p>
        And last, but not least, we come to Africa, the place that comes to mind with most of us when we hear the word Savannah. This
        continent is dominated by this biome, extending in a wide band across West Africa through to Ethiopia in the north-east. This band
        follows tight lines of latitude, classically representing the transition between the equatorial forests to the south and the vast
        Sahara desert to the north. In East Africa, the presence of wide plateaux across the equator leads to drier conditions and the
        formation of the most famous of all savannahs, the Masai Mara of Kenya and Serengeti of Tanzania.
      </p>
      <ChapterImageList images={imagesWitw2} />
      <p>
        South of the equator and these savannahs spread out around the south of the Congo rainforests to cover Southern Africa from coast to
        coast, with only the deserts of the south-west being exempt. The wet and dry seasons across these regions extend also into
        Madagascar where, as always with this island of unique biodiversity, a Savannah exists here in a form unlike any other.
      </p>
      <ChapterImageList images={imagesWitw3} />
      <h3>Species</h3>
      <p>
        Species of trees, shrubs and grasses vary by continent. In the Americas the most common broad-leaved trees are Curatella,
        locustberries, maricao cimarrons and Bowdichia, while Copernia and Mauritia palms are commonly found in more flooded areas. Cutgrass
        and bahia grass are among the more common ground coverings.
      </p>
      <ChapterImageList images={imagesSpeciesAmericas} />
      <p>
        In Africa, the most iconic of all Savannah trees, the acacia and the baobab are commonly found over wide areas, with Acacias
        dominating in the East, and Baobabs in the South and Madagascar. Bushwillows are most common in the West, while sturdy Borassus
        palms are found in many places. Brachystegia are found in wetter savannahs, while drier areas are often dominated by thickets of
        thorny shrubs. Ground coverings include bluestem, thatching, elephant and kangaroo grasses.
      </p>
      <ChapterImageList images={imagesSpeciesAfrica} />
      <p>
        Australian Savannah is, unsurprisingly, dominated by Eucalyptus trees, which retain most of their leaves throughout the dry season
        by a special adaptation. Acacias and baobabs, however, are also found in North-Western parts. Tall spear grass and shorter kangaroo
        grass form the understory of large areas of moist savannah, while prickly spinifex grasses are prominent in more arid regions.
      </p>
      <ChapterImageList images={imagesSpeciesAustralia} />
      <p>
        Dare we mention fauna? Although this series is about the botany across Earth, no mention of the Savannah can go without its greatest
        claim to fame, that of huge volumes of large mammalian species found in particular in that continent of the safari, Africa. The
        presence of extensive grasslands provide feeding grounds for vast herds of elephants, rhinos, giraffes, wildebeest, zebras, buffalo
        and antelope. And with such herds, predators follow, in the form of lions, cheetahs, hyenas and African wild dogs. Nowhere else on
        earth do we find such a rich quantity of large animals, and this, more than anything, is perhaps the Savannah’s greatest gift.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>Is Savannah just grassland? Describe what it actually is.</li>
        <li>What type of climate results in Savannah landscapes? Mention both temperature and rainfall patterns.</li>
        <li>Savannah is really a transition zone between what other biomes?</li>
        <li>List some countries and regions where Savannah can be found.</li>
        <li>List some Savannah plant species. Include both trees and grasses.</li>
        <li>What types of animals do we find in the Savannah?</li>
      </ol>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";
import A from "../components/A";

export default function BiomesDeserts(props) {
  const headerImage = "deserts-hero.jpg";
  const GlobalDistributionMap = "Biomes-World-Map-Desert.jpg";
  const ImageGraphsHot = "deserts-hot-graphs.jpg";
  const ImageGraphsCool = "deserts-cool-graphs.jpg";
  const ImageKoppen = "deserts-koppen.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";
  const ImageWitw1 = "deserts-utah.jpg";
  const ImageWitw2 = "deserts-california.jpg";
  const ImageWitw3 = "deserts-arizona.jpg";
  const ImageWitw4 = "deserts-chile-atacama.jpg";
  const ImageWitw5 = "deserts-argentina-patagonia.jpg";
  const ImageWitw6 = "deserts-namibia.jpg";
  const ImageWitw7 = "deserts-morocco.jpg";
  const ImageWitw8 = "deserts-sahara-egypt.jpg";
  const ImageWitw9 = "deserts-iran.jpg";
  const ImageWitw10 = "deserts-china-taklamakan.jpg";
  const ImageWitw11 = "deserts-mongolia-gobi.jpg";
  const ImageWitw12 = "deserts-australia.jpg";
  const ImageSpecies1 = "deserts-species-1.jpg";
  const ImageSpecies2 = "deserts-species-2.jpg";
  const ImageSpecies3 = "deserts-species-3.jpg";
  const ImageSpecies4 = "deserts-species-4.jpg";
  const ImageSpecies5 = "deserts-species-5.jpg";
  const ImageSpecies6 = "deserts-species-6.jpg";
  const ImageSpecies7 = "deserts-species-7.jpg";
  const ImageSpecies8 = "deserts-species-8.jpg";
  const ImageSpecies9 = "deserts-species-9.jpg";
  const ImageSpecies10 = "deserts-species-10.jpg";
  const ImageSpecies11 = "deserts-species-11.jpg";
  const ImageSpecies12 = "deserts-species-12.jpg";
  const ImageSpecies13 = "deserts-species-13.jpg";
  const ImageSpecies14 = "deserts-species-14.jpg";
  const ImageSpecies15 = "deserts-species-15.jpg";

  const imagesKoppen = [
    { src: ImageGraphsHot, caption: "Hot Deserts have mild winters and either low levels or almost total absence of rain" },
    { src: ImageGraphsCool, caption: "Cool Deserts have cold winters and either low levels or almost total absence of rain " },
    { src: ImageKoppen, caption: "The correlation between Koppen desert climate types and desert biome extent is very clear" },
    { src: ImageHoldridge, caption: "In the Holdridge Lifezones chart, deserts are found at the left hand side, where rainfall is lowest" },
  ];

  const imagesSpecies1 = [
    {
      src: ImageSpecies1,
      caption: "Some desert species families found globally",
    },
    {
      src: ImageSpecies2,
      caption: "Some desert species families found globally",
    },
    {
      src: ImageSpecies3,
      caption: "Some desert species families found globally",
    },
    {
      src: ImageSpecies4,
      caption: "Species found in the Patagonian desert of Argentina",
    },
  ];

  const imagesSpecies2 = [
    {
      src: ImageSpecies5,
      caption: "Desert species that range from the Sahara to Asia",
    },
    {
      src: ImageSpecies6,
      caption: "Desert species that range from the Sahara to Asia",
    },
    {
      src: ImageSpecies7,
      caption: "Desert species that range from the Sahara to Asia",
    },
    {
      src: ImageSpecies8,
      caption: "Date palms are one of the most recogiseable desert plants, and are found across North Africa, the Middle East and Asia",
    },
  ];

  const imagesSpecies3 = [
    {
      src: ImageSpecies9,
      caption: "Desert species found in the Kalahari and Namib deserts of SW Africa",
    },
    {
      src: ImageSpecies10,
      caption: "Some Australian desert species",
    },
  ];

  const imagesSpecies4 = [
    {
      src: ImageSpecies11,
      caption: "Sagebrush is the most commonly found plant in the western USA",
    },
    {
      src: ImageSpecies12,
      caption: "The Saguaro cactus of the deserts of Mexico and the SW USA is perhaps the most iconic of all desert plants",
    },
    {
      src: ImageSpecies13,
      caption: "The Agave family is found in the USA and Mexico, the latter where its juice is made into drinks",
    },
    {
      src: ImageSpecies14,
      caption: "Joshua Tree and Creosote Bushes in the SW USA - the latter form some of the oldest clonal colonies of plants in the world",
    },
    {
      src: ImageSpecies15,
      caption: "The oldest living organisms on the planet are the Bristlecone Pines found in mountain desert areas of Eastern California",
    },
  ];

  const imagesWitw1 = [
    { src: ImageWitw1, caption: "Much of the Western USA is high altitude desert, such as the Monument Valley, Arizona/Utah" },
    {
      src: ImageWitw2,
      caption: "Death Valley, California, USA, has the record for the highest temperature globally and is part of the wider Mojave Desert",
    },
    { src: ImageWitw3, caption: "Saguaro cactus covers much of the Sonoran desert in Southern Arizona, USA" },
  ];

  const imagesWitw2 = [
    { src: ImageWitw4, caption: "The European Southern Observatory high in the Atacama Desert, the driest in the world" },
    { src: ImageWitw5, caption: "Patagonia, in Southern Argentina is cool desert, due to rain-shadow from the Andes" },
    { src: ImageWitw6, caption: "Namibia in SW Africa has the Namib (arid) and Kalahari (semi-arid) deserts" },
  ];

  const imagesWitw3 = [
    { src: ImageWitw7, caption: "The Eastern Atlas Mountains in Morocco form the NW edge of the Sahara" },
    { src: ImageWitw8, caption: "Egypt marks the NE edge of the Sahara" },
    { src: ImageWitw9, caption: "Most of Iran is hot or cool desert. Image courtesy of AsuhsWorld" },
  ];

  const imagesWitw4 = [
    {
      src: ImageWitw10,
      caption:
        "The Taklamakan Desert in NW China is one of the most forbidding environments on earth, due to its extreme temperatures and aridity",
    },
    {
      src: ImageWitw11,
      caption: "The Gobi Desert of Mongolia is vast, and known for its exceptionally cold winters. Image courtesy of Thomas Noisel",
    },
    { src: ImageWitw12, caption: "Most of Australia is composed of semi-arid desert like this scene" },
  ];

  return (
    <Chapter
      series="biomes"
      seriesChapter="4"
      heading="Deserts"
      subheading="THE BONES OF THE EARTH EXPOSED"
      documentTitle="The Desert Biomes"
      headerImageSrc={headerImage}
      youTubeCode="TSx1wP18hRE"
      chapterPrevText="Shrublands"
      chapterPrevUrl="/biomes/shrublands"
      chapterNextText="Grasslands"
      chapterNextUrl="/biomes/grasslands"
    >
      {/* <ChapterImage right src={Image} caption="" />
      <ChapterImageList images={images} /> */}

      <p>
        This is what the bare earth looks like, the bones of our planet revealed as the skin of vegetation is peeled back. Despite the lack
        of life, these landscapes nonetheless inspire us with their stark beauty. A product of endless sunshine and droughts, life yet still
        finds a way here, for plants to survive in these regions have gotten smart. One of the most extreme biomes on our world, from the
        Sahara to the Sonora, the Gibson to the Gobi, these are the deserts of planet Earth.
      </p>
      <p>
        Deserts are among the most open form of country of earth, where vegetation is sparse or effectively absent. The cause of this is
        simple enough – it’s just a lack of water. Plants, like all forms of life, need that universal solvent, and when it’s sparse, they
        struggle, and in order to survive, they must get creative. Deserts exhibit some of the most interesting and strange forms of plant
        life to be found anywhere in the world.
      </p>
      <p>
        The strict definition of deserts in the biome classification system we’re using in this series, known as LONS08, is any biome
        containing bare earth, either in part between grasses, shrubs or trees, or completely bare. This distinguishes the deserts from
        other desert like biomes, such as scrub, since the latter will always have a complete covering of grass at ground level between the
        shrubs. But while shrubs and grasses, and occasionally even trees, are also often present in deserts, they are separated by sections
        of bare earth.
      </p>
      <p>
        Deserts occur over extensive parts of the continental surface of the earth, present on every continent, and are in fact the largest
        biome in terms of land area. They are basically subdivided into four forms, hot and cold, arid and semi-arid. Arid deserts have
        practically no vegetation, while semi-arid regions contain a mix of plants separated by bare earth as described a moment ago. Hot
        deserts occur in the subtropics and are characterised by mild winters and very hot summers, while cold deserts occur in the
        temperate latitudes and although they still have relatively hot summers, experience winters that can be brutally cold. These
        elements, hot and cold, arid and semi-arid are mixed up in various ways to produce biomes that are unique within the greater sphere
        of deserts.
      </p>
      <p>
        The correlation between hot and cold deserts and their corresponding Koppen climate zones is very clear. Hot desert areas occur in
        regions defined by the Koppen Hot Arid (BWh) and Hot Semi-Arid (BSh) zones, while cold deserts are found in the Koppen Cool Arid
        (BWk) and Cool Semi-Arid (BSk) zones. The climate phenomena and causes of these four basic types are discussed at length in the two
        chapters of my Secrets of World Climate series that relate to deserts - <A to="/climate/hot-deserts">Hot Deserts</A> and{" "}
        <A to="/climate/cool-deserts">Cool Deserts</A>.
      </p>
      <p>
        If we consult our Holdridge Lifezones chart, we can find the deserts at the extreme left of the diagram, where rainfall is at its
        lowest. Note that the deserts span every latitude of earth, from the tropics to the poles. Although parts of Antarctica are the
        driest on Earth, these regions are classified within the Ice biome since the perpetually cold temperatures on that continent are
        considered to be the defining characteristic over any localised absences of precipitation. The aridity index – the ratio of
        evapotranspiration to available water - is at its highest anywhere on earth in the hot deserts of the subtropics, where searing
        temperatures combine with a near-absence of rain to make any plant growing extremely difficult.
      </p>
      <ChapterImageList images={imagesKoppen} />
      <p>
        The deserts are the only biome where, mostly or completely devoid of vegetation, the bones of our planet are laid bare. These
        "bones" come in many forms. Astronomically, earth is regarded as a rocky planet, and beneath any vegetation, or oceans, its crust is
        some form of rock, usually oxides of silicon mixed with other minerals. These other minerals, and the way in which these rocks were
        formed beneath the earth, give them different colours. So where you see deserts, these exposed rocks reveal their many colours, from
        the white of sodium and calcium salts, to the red of iron, the black of basalt, and the beige of sandstone, the colour most commonly
        associated with deserts.
      </p>
      <p></p>
      <p>
        Lacking cloud cover, deserts have high ranges in temperature from night to day. In a process called weathering, these temperature
        changes stress the rock where it is exposed, and eventually break it up, depositing it in heaps of shattered rock below, known as
        scree. Additionally, with little root vegetation to hold onto it, desert winds, laden with sand, further wear at the rocks to create
        more sand, and in places, this sand gathers in enormous dunes. Dunes spread out over a wide area are referred to as ergs, and some
        of these ergs can stretch for thousands of square kilometres, such as those found in the Taklamakan desert of Central Asia.
      </p>
      <p></p>
      <p>
        Salt is a common feature of this biome. Rivers running into deserts very often end there, due to evaporation from the intense sun,
        and as they dry out, they deposit any salt dissolved within. In geological times past, ancient seas once connected to the oceans
        were cut off, and, within the parched desert climate, dried out to form salt lakes. So very often the soil is salty, and as a
        consequence many desert plants have adapted by becoming highly salt tolerant and such plants are called halophytes, after the Greek
        word “halas” for salt.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="The desert biomes collectively are the largest on earth by land area" />
      <p>
        Plants that can withstand extensive droughts, on the other hand, are called xerophytes, after the Greek word “xeros” for dry. The
        mechanisms of how such plants deal with drought is numerous, but all centre around retaining as much moisture as possible from the
        infrequent rains that might come their way. Moisture in plants is lost through leaves, and in particular through stomata, the
        breathing holes in leaves that exchange CO<sub>2</sub> for oxygen. Since sunlight is readily available in deserts, small leaves are
        standard for desert plants, since these leaves provide enough for photosynthesis, but are kept small to reduce moisture loss. Others
        keep their stomata closed during droughts, preventing growth, but also greatly reducing moisture loss.
      </p>
      <p>
        Another adaptation is storing as much water as possible within the plant itself, and this is the defining characteristic of the
        class of plants known as Succulents, which have thick fleshy stems and leaves. The most well-known sub-class of the succulents is,
        of course, the cactus family. Despite being only found only in the Americas, cacti are regarded as the iconic desert plant, and
        could be mistaken for naturally occurring throughout the world, when they are not. Cacti’s excellent ability to store water is
        well-known – in the case of the Saguaro cactus, one of the largest, a fully grown specimen can soak up to 200 gallons of water after
        rainfall.
      </p>
      <p>
        In these brief interludes between the long droughts, when rain comes to the desert, the plants therein frantically attempt to
        reproduce, and so a desert will bloom in a riot of wildflower colour after a rainstorm, transforming the landscape overnight.
      </p>
      <p>
        So where in the world do we find the deserts? Starting in North America, in the Western half of the United States we have the
        extensive Great Basin cold desert of Nevada and Utah, while further south, and at lower altitudes, we have the hot deserts of the
        Mojave, Sonoran and Chihuahuan deserts extending from Southern California, through Arizona and New Mexico, down into most of north
        and western Mexico.
      </p>
      <ChapterImageList images={imagesWitw1} />
      <p>
        In South America, we have two principle desert regions. Practically the entire long coastline of Peru is extremely arid desert,
        second only to the Atacama of Chile, the driest of all deserts. These deserts are so dry due to a combination of being between a
        cold ocean current in the Pacific, which prevents any formation of rain through onshore winds being warmed by the land, and the very
        high Andes, which block moist trade winds coming down from the Amazon. This cold current keeps these subtropical deserts mild,
        rather than hot, and temperatures rarely exceed 30 degrees in the summer. Further south and on the opposite side of the Andes, we
        have the hot semi-arid deserts of North-western Argentina that continue south down to the tip of that continent as the cold desert
        of Patagonia.
      </p>
      <p>
        Skipping across the South Atlantic and we reach Namibia and Western South Africa, where the arid Namib desert of the coast extends
        inland to the more semi-arid landscapes of the Kalahari bushland.
      </p>
      <ChapterImageList images={imagesWitw2} />
      <p>
        Heading north, and in Europe, we can find some localised semi-arid regions in Eastern and Southern Spain, while the Canary Islands
        to the south west are mostly desert at the lower elevations. East of the Canaries and we arrive at the world’s largest, and most
        famous desert, the Sahara. Most of this hot desert is so dry that it supports almost no vegetation, with semi arid conditions
        occurring on the northern and southern fringes. This desert continues east into the Horn of Africa, and the Arabian and Syrian
        deserts of the Middle East, which have alternately arid and semi arid conditions. This region is further connected to the Iranian
        plateau where cool deserts are found, and east again into the Baluchistan desert bordering Pakistan and ending in the Thar desert of
        North-west India. Connected to the North of this region are the cool deserts of Central Asia, with the Karakum of Turkmenistan being
        among the most arid. The semi arid conditions north of here encompass most of Kazakhstan and where these merge into the Russian
        steppes defines the actual border of these two large countries. East over the Altai mountains, and we find the Taklamakan desert of
        Western China, one of the most forbidding of all deserts, such is its aridity and freezing winter temperatures. This desert is
        connected to the north east to the most famous of cold deserts, the Gobi of Mongolia and northern China.
      </p>
      <ChapterImageList images={imagesWitw3} />
      <p>
        Lastly, in Australia, the driest of all continents, we have semi-arid conditions making up most of this country. Extending from the
        coasts of Western Australia, through the “red centre” to the fringes of the savannahs that run down the eastern side of the
        continent.
      </p>
      <ChapterImageList images={imagesWitw4} />
      <p>
        In contrast to the isolated regional development of species in the Shrublands that we discussed in the last chapter, deserts have
        much more extensive contiguous ranges, allowing individual species to spread over wide areas. Several plant families have colonised
        most of the globe including that of the Daisy family and the Frankenia family of flowering shrubs. The Artemisia family are found
        across all northern hemisphere deserts, including sagebrush and wormwood, while the Chenopodium and Atriplex families are both
        referred to, rather confusingly, as saltbushes, and are found in every continent.
      </p>
      <p>
        If we look at regional variants, and firstly in South America, west of the Andes along the Peruvian coast and down to the Atacama
        desert of Chile, rainfall is so minimal that practically nothing grows. East of the Andes in Patagonia, however, we have extensive
        semi-desert populated by low-lying shrubs such as Nassauvia and Chuquiraga, along with Brachyclados daisies and Burkartia.
      </p>
      <ChapterImageList images={imagesSpecies1} />
      <p>
        The largest contiguous desert region connects the Sahara of Africa to the Middle-East, Central Asia and India, and so species here
        tend to be spread across this region, always on the fringes of the extreme desert interiors where very little grows. Wormwood
        (Artemisia), Tamarisk and Nitre bushes are the most common shrubs, while Bean caper, Atriplex and Feathergrass make up other common
        species. Date Palms are perhaps the most significant, being one of the few sources of food for humans in the desert areas of the
        Middle East, as they have been for thousands of years.
      </p>
      <ChapterImageList images={imagesSpecies2} />
      <p>
        In the Namib and Kalahari deserts of South-West Africa, we find many aloes including the Quiver tree (Aloidendron dichotomum) and
        Milk Bush (Euphorbia virosa) and the uniquely strange Weltwischia.
      </p>
      <p>
        In Australia, in addition to Spinifex grasses that were mentioned in the Shrublands, we find the Bush tomato, the Cabbage palm
        (Livistona mariae), and the Desert Oak (Allocasuarina decaisneana).
      </p>
      <ChapterImageList images={imagesSpecies3} />
      <p>
        Lastly, in North America, we find perhaps the most iconic of all desert plants. While Sagebrush (Artemisia) is found all across the
        Great Basin of the American cool desert, further south we find the Saguaro Cactus ranging from Arizona down into the Sonoran desert
        of Mexico. And then there’s the Blue Agave, now grown commercially for its juice that powers that most famous of all Mexican drinks,
        Tequila. In parts of Califonia we find the Joshua Tree, a type of Yucca, while Creosote Bushes are extensively ranged across the hot
        deserts in this region. Clonal colonies of Creosote Bushes are among the oldest living organisms known, with one colony, deriving
        from a single plant thought to be over 11,000 years old. Talking of age, and the high altitude desert of California is home to the
        oldest trees in the world. The Bristlecone Pine is incredibly hardy, and one individual tree, known as Methuselah, is believed to be
        almost 5,000 years old. Just think about that for a second – it was already a thousand years old when the Pharoahs of Egypt rose to
        prominence, three millennia old when Ancient Rome was at its peak, and four and a half thousand when Europeans first travelled to
        the Americas. When it comes to age, we are truly humbled by the trees of this world.
      </p>
      <ChapterImageList images={imagesSpecies4} />
      <p>
        Due to the limited supply of vegetation, deserts do not support a large amount of wildlife, and that which is present is mostly in
        the form of snakes, scorpions and the like. Something to bear in mind if you’re camping out in the desert, and have some unwelcome
        guests snuggling up in your warm sleeping bag at night, or making a home of your boots the following morning.
      </p>
      <p>
        Despite the suppression of plant life by the arid conditions of deserts, this biome has a special place in the minds of so many of
        us, possessing a beauty that is both stark and so different to the rest of the much greener biomes. Deserts have inspired artists
        and writers for centuries, from the Arabian Nights that shaped western story literature, to the innumerable books, TV series and
        movies of the Western genre, and as such, the deserts are the only biome to have become the central feature of a whole class of
        popular culture.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>What is the simple reason for the existence of deserts?</li>
        <li>What must exist in a biome for it to be classified as desert?</li>
        <li>What are the four basic types of desert and how do they relate to Koppen climate zones?</li>
        <li>Why are some desert sands and rocks different in colour from region to region?</li>
        <li>What are sand dunes? How are they formed?</li>
        <li>What are two basic types of plants found in deserts, and how have they adapted to survive here?</li>
        <li>List out five named deserts on the planet and state their location.</li>
        <li>List out some desert plants that you know or have seen before.</li>
      </ol>
    </Chapter>
  );
}

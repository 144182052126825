import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc6Mediterranean(props) {
  const headerImage = "mediterranean-hero.jpg";
  const CauseImage1 = "mediterranean-hadley-cell-pushed-north-in-summer.jpg";
  const CauseImage2 = "mediterranean-hadley-cell-pushed-south-in-winter.jpg";
  const CauseImage3 = "mediterranean-extension-of-winter-westerlies.jpg";
  const ImageConsistentGraphs = "mediterranean-comparison-of-graphs.jpg";
  const ImageSpain = "mediterranean-spain-andalucia.jpg";
  const ImageGreece = "mediterranean-greece-kefalonia.jpg";
  const ImageOregon = "mediterranean-oregon-coast.jpg";
  const ImageCape = "mediterranean-south-africa-cape-winery.jpg";
  const ImageChile = "mediterranean-central-chile-landscape.jpg";
  const ImageWesternAustralia = "mediterranean-western-australia.jpg";
  const GlobalDistributionMap = "Mediterranean-Climate-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-mediterranean.gif";

  const causesImages = [
    {
      src: CauseImage1,
      caption:
        "The subtropical Hadley Cell is pushed poleward in summer, leading to descending air, high pressure and drought over the Mediterranean",
    },
    {
      src: CauseImage2,
      caption:
        "In winter, the Hadley Cell moves back to the equator, allowing low pressure areas and rain carried by westerly winds into the Mediterranean",
    },
    {
      src: CauseImage3,
      caption:
        "The presence of water in the Mediterranean allows extension of moisture bearing westerlies to extend rain all the way to Lebanon and Israel",
    },
  ];

  const imagesExamples1 = [
    {
      src: ImageSpain,
      caption: "Coast of Andalucia, Southern Spain. Koppen Csa (Hot Summer)",
    },
    {
      src: ImageGreece,
      caption: "Island of Kefalonia, Western Greece. Koppen Csa (Hot Summer)",
    },
    {
      src: ImageOregon,
      caption: "Coast of Oregon, USA. Koppen Csb (Warm Summer)",
    },
  ];

  const imagesExamples2 = [
    {
      src: ImageChile,
      caption: "Landscape of Central Chile. Koppen Csb (Warm Summer)",
    },
    {
      src: ImageCape,
      caption: "Winery near Cape Town, South Africa. Koppen Csb (Warm Summer)",
    },
    {
      src: ImageWesternAustralia,
      caption: "Esperance, Western Australia. Koppen Csb (Warm Summer). Image courtesy of S Lui",
    },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="6"
      heading="Mediterranean"
      subheading="KOPPEN CODES: Csa &amp; Csb"
      documentTitle="The Mediterranean Climate - Koppen Climate Classification: Csa Csb"
      headerImageSrc={headerImage}
      youTubeCode="uk9Fyw2Okyw"
      chapterPrevText="Humid Subtropical"
      chapterPrevUrl="/climate/humid-subtropical"
      chapterNextText="Oceanic"
      chapterNextUrl="/climate/oceanic"
    >
      <p>
        The world’s most famous sea. Endless coastlines and beaches. Histories and culture as rich as any on earth. Blessed with one of our
        planet’s finest climates – it’s the only one with a dry summer and wet winter.
      </p>
      <p>
        But you’d be mistaken in thinking that this sea was the only place where such a climate existed. Copied across four other
        continents, this much-desired climate is home to a very famous state, and three very different countries far into the southern
        hemisphere. But in all places, this climate just has one name, the ancient home of the summer sun – the Mediterranean.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        As we continue our journey in this series from equator to poles, it’s now time to consider the second of the three climates
        occupying the temperate areas of the mid-latitudes, the Mediterranean Climate.
      </p>
      <p>
        This climate zone has a similar temperature range to the <A to="humid-subtropical">Humid Subtropical climate</A> that we covered in
        our last chapter – between 20 and 35 degrees in summer, and 5 to 20 degrees in winter. But the most immediate and striking aspect of
        this climate is in its rainfall pattern. Most world climates have their rainfall peak in the hottest months of the year, while
        others have year-round rain. But only one reverses the most common pattern - only the Mediterranean climate has a dry summer and wet
        winter.
      </p>
      <p>
        To explain this unusual pattern, we have to consider both our earlier chapters on the{" "}
        <A to="tropical-monsoon-and-tropical-savannah">Tropical Monsoons</A>, as well as the <A to="hot-deserts">Hot Deserts</A>. You might
        want to review these earlier chapters to really get what’s about to follow.
      </p>
      <p>
        Due to the 23 degree tilt of the earth, the convergence of trade winds in the tropics called the Doldrums shifts north and south
        throughout the year. The band that lies on the poleward side of this band is usually dry, due to extensive high pressures brought on
        by the downward component of the Hadley Cell of tropical air movement. In summer, this band of high pressure is pushed further out
        toward the poles, away from the Hot Deserts and into the temperate zone, causing a long drought in this season.
      </p>
      <p>
        In winter, the Doldrums are either back at the equator or in the other hemisphere, so the high pressure area also follows it back
        that way. This allows more unstable air to return in the form of oceanic westerly winds, which, when blown onto westward facing
        coasts, bring rain. This explains why the Mediterranean climates zones lie on the western fringes of each continent.
      </p>
      <ChapterImageList images={causesImages} />
      <p>
        The presence of the Mediterranean Sea at just the right latitude, allows these westerlies to gather continual moisture as they
        travel east, extending the winter rain all the way to the coasts of Lebanon and Israel at the far end of this sea. The consistency
        of this climate type is well illustrated by comparing city graphs from the west to the east of the Mediterranean.
      </p>
      <ChapterImage
        left
        src={ImageConsistentGraphs}
        caption="Cities all across the Mediterranean show consistent winter rain and summer drought"
      />
      <p>
        We’ll talk more about the Westerlies in the <A to="oceanic">next chapter on the Oceanic climate</A>, since they are the dominant
        component of weather in those regions lying immediately poleward to the Mediterranean areas.
      </p>
      <p>
        Because the Mediterranean summer is uniquely drought-filled, compared to all other climates except deserts, this peak season of heat
        is accompanied by low humidity – what Californians love to call “a dry heat”. As perspiration is more efficient in dry air, this
        heat is a good deal more comfortable than, say, the humid summers of Georgia or Florida at the same latitude.
      </p>
      <p>
        There are two subdivisions of the Mediterranean climate within the Koppen Classification system both of which share the dry summers
        and wet winters just described, and differing only by temperature range. The first, and more common form is Csa, which experiences a
        hot summer of at least 22 degrees averaged across the night-day cycle. This is known as the Hot Summer Mediterranean. Where the
        average summer temperature is below 22 degrees, this is known as the Warm Summer Mediterranean and has the Koppen code Csb.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of Mediterranean climate zones" />
      <h3>Where in the world do we find Mediterranean climate zones?</h3>
      <p>
        So where in the world do we find the Mediterranean climate? Well, the short answer is, on every inhabited continent except Asia,
        which lacks the necessary west coast.
      </p>
      <p>
        We begin in Europe, and the sea from which this climate gains its name. To the west of the Mediterranean, out in the Atlantic, the
        Azores experience warm dry summers and wet winters. Moving east onto the Iberian Peninsula we find the cooler Csb variant of warm
        summers and very wet winters in the north of Portugal and North-Western Spain. Further south, we move into the hot summer Csa
        variant that Portugal’s capital of Lisbon experiences, along with most of Spain, and its capital Madrid. In the extensive interior
        of the Iberian Peninsula, however, some areas are so dry that they are classed as cool semi-desert – we’ll pick these up{" "}
        <A to="cool-deserts">in a future chapter</A>.
      </p>
      <p>
        The wet winters that define the Mediterranean type extend deep into Morocco on the North African coast, and as we move east into the
        western Mediterranean basin, the entire Algerian coast and northern Tunisia also fall within this climate. All the islands in this
        area have this climate - the Balearics, Corsica, Sardinia, Sicily and Malta. The northern coast includes the luxurious Cote d’Azur
        of France and this coast extends east into Italy, the heart of Mediterranean, where the dry summer / wet winter pattern extends
        inland across all western and southern provinces, including the nation’s capital, the Eternal City, Rome.{" "}
      </p>
      <ChapterImageList images={imagesExamples1} />
      <p>
        Continuing east across the Adriatic sea, much of the coast of Croatia and all of Albania are within this climate, along with all of
        Greece, including its legendary capital of Athens, and all of the Greek islands. Now firmly into the Eastern Mediterranean basin, we
        reach the long coastline of Turkey, where the wet winters and dry summers extend well inland, encompassing that country’s largest
        city, the ancient metropolis of Istanbul. Finally, at the eastern end of the Mediterranean, the coast turns south into Syria,
        Lebanon and Israel, including the cities of Damascus, Beirut and Jerusalem.
      </p>
      <p>
        In North America, the entire west coast of the United States comes under the influence of this climate, from the coasts of
        Washington and Oregon in the north, with their very wet winters – this is the largest area on Earth where we experience the warm
        summer Csb variant of this climate. As we head south into California, the winters become shorter and drier, the summers longer and
        hotter, and we have the classic Csa Koppen variant. An exception is San Francisco, which stays much cooler than the surrounding area
        due to the proximity to cold ocean currents which often shroud the city in fog. As we reach Southern California, and the massive
        conurbation of Greater Los Angeles, fog and coastal cloud is less common, and summers hotter, especially as one moves further inland
        away from the cool ocean. The last stop for this climate zone are the cities of San Diego and Tijuana lying on each side of the
        US/Mexico border, before the climate gives way to the hot desert of Baja.{" "}
      </p>
      <ChapterImageList images={imagesExamples2} />
      <p>
        Moving now into the Southern Hemisphere and South America, we encounter this climate in the cooler Csb form, worlds away from its
        namesake, along the mid-coast of that longest of countries, Chile. It’s in this zone where most of that country’s population lies,
        including the main port of Valparaiso, and the capital Santiago.
      </p>
      <p>
        In Africa, south of the Namib Desert, we find the extreme south-west of South Africa caught by the wet winter westerlies that
        typifies the Csa form of the Mediterranean climate, most notably around the city of Cape Town, famous for its dramatic backdrop of
        Table Mountain.
      </p>
      <p>
        The last continent to experience this climate zone is Australia, where it brushes two parts of the south and west facing coasts,
        firstly around Perth, the most isolated city in the world, in the far west, and again, around the mid-southern city of Adelaide.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Notable cities with Mediterranean Climates" />
      <p>
        Despite its dispersal across so many continents of the globe, the Mediterranean climate is proven as a particular and consistent
        type by comparing temperature and rainfall graphs across each of these areas. Note the 6-month switch between the northern and
        southern hemisphere in terms of temperature and rainfall, demonstrating the reversal of <A to="/climate/seasons">seasons</A> on each
        side of the globe.
      </p>
      <h3>Landscapes and Vegetation</h3>
      <p>
        So what kind of landscapes and vegetation do we find in the Mediterranean climate? Throughout the world, plants have adapted to
        survive the drought of summer, which, occurring at the hottest time of the year, places a particular challenge due to very high
        evapotranspiration rates. The Csa Hot Summer areas all have a similar and distinctive look – one similar to a semi-arid desert,
        where <A to="/biomes/shrublands">shrubs form the dominant plant life</A> separated by patches of hardy grasses, bare earth and rock.
        A distinctive hallmark of Mediterranean plants is the greyish-green appearance, a product of the hard, waxy layer of leaves designed
        to hold in moisture during the hot summer drought.
      </p>
      <p>
        In the cooler and wetter Csb areas, shrubland gives way to forest, and it is in such areas within California that we find the
        tallest trees in the world – the sequoias or redwoods. In Australia, the Eucalyptus tree has adapted particularly well to summer
        droughts and is found throughout that continent. So successful is this family of trees in this climate that it has been exported to
        other Mediterranean climate zones where it has thrived.
      </p>
      <h3>Agriculture and... Culture!</h3>
      <p>
        In terms of agriculture, the Mediterranean areas have two world famous crops. Grapes and olives. The drinking of wine, from the
        fermented juice of grapes has gone on in the Mediterranean and Near-East since ancient times, and in the last two centuries has been
        exported to all other Mediterranean climate areas, as wine drinkers will know, from seeing bottles originating from California,
        Chile, South Africa and Australia. The pressing of olives to produce olive oil has also gone on since ancient times, and to this day
        the oil is valued across the world for its health properties as well as its distinctive flavour. Many other crops are grown in these
        regions, of course, particularly wheat and citrus fruits, but these are more common in other climates than grapes and olives.
      </p>
      <p>
        The Mediterranean climate type is an example where climate has directly had an impact on humanity outside the world of agriculture.
        In one case, the Mediterranean itself is the world’s most popular tourist area, seeing a third of a billion visitors per year. With
        its endless coastlines and historical and cultural attractions, tourists come here in the summer knowing that they can enjoy
        guaranteed sunshine and warm weather. A second example is less obvious, and that is the world movie capital of Hollywood,
        California. In the early days of movie production, when film needed bright light, production companies located to Los Angeles where
        for most of the year they could be guaranteed bright sunshine and no rain that would otherwise interrupt the shooting of movies.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>In what important way is the Mediterranean climate unique among world climates?</li>
        <li>
          How does the movement of the Hadley Cell between summer and winter lead to the unusual rainfall patterns seen in Mediterranean
          climate zones?
        </li>
        <li>What are the Koppen codes for these climates? How do they differ from each other?</li>
        <li>What types of natural landscapes do we find in the Mediterranean climate zones?</li>
        <li>Describe how the Mediterranean climate has a direct influence on humanity outside of agriculture.</li>
        <li>List out some countries and cities that experience hot desert climates.</li>
      </ol>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";
import A from "../components/A";

export default function BiomesTemperateForests(props) {
  const headerImage = "temperate-forests-hero.jpg";
  const ImageSperms = "temperate-forests-sperms.jpg";
  const ImageKoppen = "temperate-forests-koppen.jpg";
  const ImageType1 = "temperate-forests-type-1.jpg";
  const ImageType2 = "temperate-forests-type-2.jpg";
  const ImageType3 = "temperate-forests-type-3.jpg";
  const ImageType4 = "temperate-forests-type-4.jpg";
  const ImageType5 = "temperate-forests-type-5.jpg";
  const ImageType6 = "temperate-forests-type-6.jpg";
  const GlobalDistributionMap = "Biomes-World-Map-Temperate-Forests.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";
  const ImageSpecies0 = "temperate-forests-species-0.jpg";
  const ImageSpecies1 = "temperate-forests-species-1.jpg";
  const ImageSpecies2 = "temperate-forests-species-2.jpg";
  const ImageSpecies3 = "temperate-forests-species-3.jpg";
  const ImageSpecies4 = "temperate-forests-species-4.jpg";
  const ImageSpecies5 = "temperate-forests-species-5.jpg";
  const ImageSpecies6 = "temperate-forests-species-6.jpg";
  const ImageSpecies7 = "temperate-forests-species-7.jpg";
  const ImageSpecies8 = "temperate-forests-species-8.jpg";
  const ImageSpecies9 = "temperate-forests-species-9.jpg";
  const ImageSpecies10 = "temperate-forests-species-10.jpg";
  const ImageSpecies11 = "temperate-forests-species-11.jpg";
  const ImageSpecies12 = "temperate-forests-species-12.jpg";
  const ImageSpecies13 = "temperate-forests-species-13.jpg";
  const ImageWitw1 = "temperate-forests-chile.jpg";
  const ImageWitw2 = "temperate-forests-australia-eucalyptus.jpg";
  const ImageWitw3 = "temperate-forests-nz.jpg";
  const ImageWitw4 = "temperate-forests-scotland.jpg";
  const ImageWitw5 = "temperate-forests-france.jpg";
  const ImageWitw6 = "temperate-forests-germany-hohenzollern-castle.jpg";
  const ImageWitw7 = "temperate-forests-portugal.jpg";
  const ImageWitw8 = "temperate-forests-italy.jpg";
  const ImageWitw9 = "temperate-forests-greece-meteora.jpg";
  const ImageWitw10 = "temperate-forests-romania-bran-castle.jpg";
  const ImageWitw11 = "temperate-forests-slovakia.jpg";
  const ImageWitw12 = "temperate-forests-poland.jpg";
  const ImageWitw13 = "temperate-forests-russia.jpg";
  const ImageWitw14 = "temperate-forests-china-karst.jpg";
  const ImageWitw15 = "temperate-forests-china-great-wall.jpg";
  const ImageWitw16 = "temperate-forests-south-korea-seoul.jpg";
  const ImageWitw17 = "temperate-forests-japan-tori.jpg";
  const ImageWitw18 = "temperate-forests-japan-sakura-hanami.jpg";
  const ImageWitw19 = "temperate-forests-canada-ontario.jpg";
  const ImageWitw20 = "temperate-forests-usa-vermont.jpg";
  const ImageWitw21 = "temperate-forests-usa-florida-cypress.jpg";
  const ImageWitw22 = "temperate-forests-colorado-aspen.jpg";
  const ImageWitw23 = "temperate-forests-california-cypress.jpg";
  const ImageWitw24 = "temperate-forests-oregon.jpg";
  const ImageWitw25 = "temperate-forests-california-redwood.jpg";

  const images1 = [
    {
      src: ImageKoppen,
      caption: "Correlation between the areas of temperate forest and warm temperate and continental Koppen climate zones is strong",
    },
    { src: ImageHoldridge, caption: "In the Holdridge Lifezones chart, temperate forests are found to the right in the temperate region" },
  ];

  const imagesTypes = [
    { src: ImageType1, caption: "Classic temperate deciduous broadleaf forest" },
    { src: ImageType2, caption: "Mixed broadleaf and coniferous forest" },
    { src: ImageType3, caption: "Dry summer, mostly coniferous forest" },
    { src: ImageType4, caption: "Subtropical forest whose species tend to differ from the classic broadleaf forests further north" },
    { src: ImageType5, caption: "Australian Eucalyptus-dominated forest" },
    { src: ImageType6, caption: "Temperate Rainforest" },
  ];

  const imagesWitw1 = [
    { src: ImageWitw1, caption: "Southern Chile has extensive and rich forests, where Southern Beech dominates" },
    { src: ImageWitw2, caption: "The forests in SE Australia are dominated by Eucalyptus" },
    { src: ImageWitw3, caption: "New Zealand forests have many local species but are dominated by Southern Beech" },
  ];

  const imagesWitw2 = [
    {
      src: ImageWitw4,
      caption: "Scotland, like most of Great Britain, lost most of its forests thousands of years ago, due to the pressures of farming",
    },
    { src: ImageWitw5, caption: "France still has many forested areas, including this in the south-east" },
    { src: ImageWitw6, caption: "Germany still has many extensive forests, such as this around Hohenzollern Castle" },
  ];

  const imagesWitw3 = [
    { src: ImageWitw7, caption: "Portugal is honored as having one of the most successful reforestation programs in the world" },
    { src: ImageWitw8, caption: "The rugged uplands of the Apennines in Italy are covered in mixed forest" },
    { src: ImageWitw9, caption: "Mountainous areas of Greece are heavily forested, such as this scene near Meteora" },
  ];

  const imagesWitw4 = [
    { src: ImageWitw10, caption: 'Romania is still heavily forested, including around Bran ("Dracula\'s") Castle' },
    { src: ImageWitw11, caption: "Slovakia has many wilderness areas that are heavily forested" },
    { src: ImageWitw12, caption: "Poland has many forests with a mix of conifers and broadleafs" },
    { src: ImageWitw13, caption: "European Russia has large areas of primary woodland with a mix of conifers and broadleaf trees" },
  ];

  const imagesWitw5 = [
    {
      src: ImageWitw14,
      caption:
        "Southern China has different species to that of the north, such as in this subtropical forest clinging to the karst (limestone) landscape",
    },
    {
      src: ImageWitw15,
      caption:
        "The more remote, mountainous regions of Northern China contain many broadleaf forests such as this setting for the Great Wall",
    },
    {
      src: ImageWitw16,
      caption:
        "Much of South Korea has been deforested due to pressures of population, but some woodlands survive such as this overlooking Seoul",
    },
  ];

  const imagesWitw6 = [
    { src: ImageWitw17, caption: "Japan is still mostly covered in rich temperate forests of diverse species" },
    {
      src: ImageWitw18,
      caption: "The festival of 'Hanami' is celebrated every spring in Japan as the cherry and plum trees produce their 'Sakura' blossom",
    },
  ];

  const imagesWitw7 = [
    { src: ImageWitw19, caption: "Southern Ontario marks the northern boundary of the broadleaf forests of Eastern Canada" },
    { src: ImageWitw20, caption: "New England is famous for its autumn colours, as this scene in Vermont shows" },
    {
      src: ImageWitw21,
      caption: "The subtropical forest of the SE of the USA has different species to the NE, such as this cypress in the swamps of Florida",
    },
  ];

  const imagesWitw8 = [
    {
      src: ImageWitw22,
      caption: "Aspens are among the hardiest of all broadleafs, able to survive the cold winters in the mountains of Colorado",
    },
    { src: ImageWitw23, caption: "Monterrey Cypress is only found in this part of the central coast of California" },
    { src: ImageWitw24, caption: "The forests of the Pacific North-west are dominated by coniferous species" },
    { src: ImageWitw25, caption: "The Redwoods and Sequoias of California are the tallest and most massive trees in the world" },
  ];

  const imagesSpecies = [
    {
      src: ImageSpecies0,
      caption:
        "ARAUCARIA: Distinctive conifer with unusual “reptilian” branches. The 'Monkey-Puzzle' of Chile has become a popular ornamental tree in Europe & N. America",
    },
    {
      src: ImageSpecies1,
      caption:
        "JUNIPER: Popular ornamental trees with berries used to flavour foods and liquor. The Bennett Juniper of Northern California may be the oldest tree in the world",
    },
    {
      src: ImageSpecies2,
      caption: "BEECH: Dominates the broadleaf forests of the Northern Hemisphere. Beech firewood kept Europe and Asia warm for millenia",
    },
    {
      src: ImageSpecies3,
      caption:
        "POPLAR &amp; ASPEN: Aspens survive in colder regions otherwise dominated by conifers. Wood of a thousand uses... the Mona Lisa was painted on Poplar boards",
    },
    {
      src: ImageSpecies4,
      caption: "CHERRY: Bearer of the famous fruit and the most famous of all blossoms",
    },
    {
      src: ImageSpecies5,
      caption:
        "CYPRESS: This diverse, global family loves warmer temperate zones. They have been favoured ornamental trees since ancient times",
    },
    {
      src: ImageSpecies6,
      caption: "BIRCH: Its bark is a distinctive silver. The most northerly of the hardwoods, they can survive extreme winters",
    },
    {
      src: ImageSpecies7,
      caption:
        "SOUTHERN BEECH: The dominant species of the Southern Hemisphere temperate forests. At one time mistaken for true Beeches, they are unrelated",
    },
    {
      src: ImageSpecies8,
      caption:
        "YEW: A symbol of death (and rebirth) these ancients are found in many churchyards. Strong but flexible, its wood made the most powerful bows in history",
    },
    {
      src: ImageSpecies9,
      caption: "GUM: Unique to Australia, where it dominates. Its evergreen leaves are the sole diet of the koala",
    },
    {
      src: ImageSpecies10,
      caption:
        "CEDAR: Gigantic and majestic, its wood made the ships of all the great Mediterranean civilisations. The only tree to appear on a national flag - the emblem of Lebanon",
    },
    {
      src: ImageSpecies11,
      caption:
        "MAPLE: Their giant leaves light up the autumn with fiery colours. Its leaf is the national symbol of Canada. Its sap is harvested to produce the famous syrup",
    },
    {
      src: ImageSpecies12,
      caption:
        "PINE: The legend among conifers. It is one of the most globally diverse of all trees surviving in Siberia and the Tropics. Harvested for millenia, its wood is fast growing and has a thousand uses",
    },
    {
      src: ImageSpecies13,
      caption:
        "OAK: A living legend. The backbone of European buildings for centuries. 'Mighty oaks from little acorns grow' - English Proverb",
    },
  ];

  return (
    <Chapter
      series="biomes"
      seriesChapter="6"
      heading="Temperate Forests"
      subheading="THE BROADLEAF &amp; CONIFEROUS WOODLANDS OF THE MID-LATITUDES"
      documentTitle="The Temperate Forest Biomes"
      headerImageSrc={headerImage}
      youTubeCode="K8i0K0pZlCM"
      chapterPrevText="Grasslands"
      chapterPrevUrl="/biomes/grasslands"
      chapterNextText="Taiga"
      chapterNextUrl="/biomes/taiga"
    >
      <p>
        The temperate regions of our planet were once covered in forest, an endless swathe of trees of many different species, stretching
        across North America, Europe and Asia. But of all the biomes of Earth, these forests have fallen prey, more than any other, to the
        developments of mankind. And yet they are still etched in the psyche of the culture of those lands and peoples. Falling leaves, bare
        branches, blossom and green - <A to="/climate/seasons">the four seasons</A> are defined by the behaviour of their trees. And within
        these regions are the tallest and most massive plants of all. These are the woodlands of beech and cypress, pine and eucalyptus,
        redwood and willow, maple and oak. These are the Earth’s temperate forests.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        Trees have colonised the globe. Occurring in both the tropics and temperate zones, they are nature’s most massive statement upon the
        face of the earth. Their ability to grow so tall is the result of lignin, a chemical that is the building block of wood, which first
        appeared in plants as early as 400 million years ago. Lignin provides structural strength and so allows the plant to stay upright
        and maintain its structure outside of the growing season, when it is either too dry or too cold. The following year the plant can
        then build on the existing growth, and the year after that, and so on. They are the longest lived of any life on earth, with some
        individual trees still alive today believed to be over 6,000 years old.
      </p>
      <ChapterImage right src={ImageSperms} caption="Trees today have evolved into these two clear orders" />
      <p>
        For the longest time, conifers dominated the forests of antiquity. Known technically as gymnosperms, they have needle-like leaves
        which are almost always evergreen and reproduce through exposed seeds nestled within cones. This dominance would eventually be
        contested, though. In the Cretaceous period, around a hundred million years ago, flowering plants known as angiosperms began to grow
        in significance. Because of the more efficient and rapid means of reproduction using flowers and fruit, they were more able to adapt
        to different climates, and patterns of climate change, over time and so went on to dominate all but the boreal forests of the planet
        up until this day.
      </p>
      <p>
        And so we have today the two basic types of trees – gymnosperms – the conifers also known as softwoods, and angiosperms, the
        flowering trees known as broadleafs or hardwoods. Broadleafs are often mistakenly referred to as deciduous trees, meaning that they
        lose their leaves in winter. While this is true for almost all species in cold winter climates, hardwoods in subtropical and
        tropical areas retain their leaves year round, such as in the tropical rainforest.
      </p>
      <p>
        Temperate forests require plenty of rainfall and thrive particularly where there is year round rain. They are distinguished from
        tropical forests through the adaptations required to survive winter temperatures that can often fall below freezing. So the
        correlation between the areas of temperate forest and Koppen climate zones is strong.{" "}
        <A to="/climate/humid-subtropical">Humid Subtropical</A>, <A to="/climate/oceanic">Oceanic</A> and{" "}
        <A to="/climate/continental">Continental</A> zones fit this bill, and so such forests will be found naturally dominating these
        areas. Where the temperatures are warmer, broadleaf forests dominate. As temperatures cool, coniferous trees steadily gain advantage
        until, on the subarctic fringe, coniferous forest dominates in the form of boreal forest – the taiga. The southern fringe depends
        upon location. In the Mediterranean, forests grow in upland areas where the absence of high temperatures during the dry summer allow
        mainly coniferous trees to grow instead of the usual scrubland. In China, the deciduous forests blend into evergreen tropical forest
        as one heads south.
      </p>
      <ChapterImageList images={images1} />
      <p>
        On our Holdridge Lifezones chart, we can find temperate forest in the middle of the temperate band and to the right, where moderate
        to extensive rainfall is present. In general, cooler temperatures permit forests to grow with less rain than compared to the
        tropics, owing to reduced evapotranspiration. As just mentioned, cooler or warmer temperatures within the temperate forest band lead
        to different characteristics of forest, as does the variation from dryer to wetter.
      </p>
      <p>
        The structure of a temperate forest is not unlike that of the tropical forest that we looked at in{" "}
        <A to="tropical-forests">Chapter 1 of this series</A>, where we have at the highest reaches, the canopy, where most of the leaf
        growth is. Then as we descend, we pass through the darker understory until we reach the forest floor. In general, the boundary
        between the canopy and understory is more gradual than that of the tropical rainforest. Depending on the thickness of the canopy
        above, the forest floor can either be relatively bare, or covered by low lying shrubs or ferns. In deciduous forests, where the
        leaves are lost in the winter, these build up on the floor, and their decomposition provides an abundance of nutrients that leads to
        complex ecosystems within the rich soil. Coniferous forests, by contrast, have mostly bare floors as any needles that fall to the
        ground acidify the soil, preventing the growth of most ground-dwelling species.
      </p>
      <h3>Types of Temperate Forest</h3>
      <p>We can break down temperate forests into roughly six divisions of character:</p>
      <ul>
        <li>
          The classic temperate broadleaf forests, that make up much of Eastern North America, Europe, Northern China, Korea and Japan, and
          these form when there is sufficient year-round rain and reasonable temperature ranges.
        </li>
        <li>
          The mixed broadleaf and coniferous forest found on the northern fringes of the broadleaf type just described, and acts as a border
          between the temperate forest and the boreal taiga.
        </li>
        <li>
          Mostly coniferous forest found in regions where temperatures are mild, like in the classic broadleaf, but where summer droughts
          occur. These occur along the north to mid Pacific coast of the USA and the northern and upland areas of the Mediterranean.
        </li>
        <li>
          Subtropical forests of the south-eastern United States and Southern China, where warmer temperatures combined with plenty of rain
          lead often to evergreen forests of both coniferous and broadleaf trees usually of different species from the classic broadleaf and
          mixed of more northerly climes.
        </li>
        <li>
          Australia... doing its own thing, once more. This continent is dominated by a single family, Eucalyptus, which is evergreen,
          regardless of the substantial climatic variations found there, and leads to forests unlike any other in character.
        </li>
        <li>
          Where rainfall is so heavy, we have temperate rainforest, the only type outside of the much more well known tropical rainforest.
          Similar to its tropical brother, life is abundant here with the trees covered in moss or creepers, and the forest floor being
          thick in undergrowth, especially ferns. Being relatively rare, they nonetheless occur in small pockets in every continent, with
          the Pacific North-West of the United States and the western coast of South Island New Zealand being the most famous.
        </li>
      </ul>
      <ChapterImageList images={imagesTypes} />
      <ChapterImage right src={GlobalDistributionMap} caption="Global distribution of Temperate Forests" />
      <h3>Where in the World</h3>
      <p>
        So where in the world do we find temperate forests? The vast majority are in the Northern Hemisphere, centred around Eastern North
        America, Europe and East Asia, with much smaller areas along the west coast of the United States, Mexico, the Caucasus, Chile,
        Australia and New Zealand.
      </p>
      <p>
        Starting in South America, and most of Southern Chile is blanketed by forests of deciduous Southern Beech, Pine and local species
        such as Araucaria. Being on the western slopes of the Andes, such forests are blanketed by moist Oceanic winds that are lifted up by
        the mountains, depositing their watery cargo. As a consequence, the Valvidian Rainforest can be found in a wide central part of this
        band.
      </p>
      <p>
        Skipping across to Australia, and the area south of Perth in Western Australia, most of Victoria, the coast of New South Wales, and
        all of Tasmania have temperate forest. This is dominated on the mainland by the Eucalyptus family, but is also present in Tasmania
        where in fact some stands of the Regnens species of this family are close to being the tallest trees in the world. The western part
        of Tasmania is also host to another temperate rainforest, where Southern Beeches dominate over Eucalyptus.
      </p>
      <p>
        In New Zealand, no Eucalyptus are present, and instead the mixed forests that cover most of both islands are again dominated by
        Southern Beech, but accompanied by a wide variety of local species such as Kauri and Rimu. The western coast of South Island
        receives enough rain that extensive rainforests thrive here, with many species not found anywhere else.
      </p>
      <ChapterImageList images={imagesWitw1} />
      <p>
        Moving into the Northern Hemisphere, and we arrive in Europe, where almost all of that continent’s natural vegetation is forest –
        only the hot Mediterranean coastal areas are exempted. Most of this natural forest has been lost under centuries of population
        growth and development in this continent, but that which survives contains a wide diversity of coniferous and deciduous species.
      </p>
      <p>
        Northwest Europe, including the British Isles, France, the low countries, Germany and Denmark, was once completely covered in mixed
        forest, and these areas still have many patches of natural woodland despite extensive deforestation over millennia. These woodlands
        contain a host of species, from oak and birch, alder and linden, to elm and yew, but beech is the most common. A sliver of
        south-eastern Norway and southern Sweden are the northernmost extents of the temperate forests in Europe, where north of here we are
        into the boreal forest.
      </p>
      <ChapterImageList images={imagesWitw2} />
      <p>
        Southern Europe contains forests of a different character, where drier summers lead to more conifers or dwarf forms of the larger
        deciduous trees found to the north. Much of Portugal today is extensively covered in forest, thanks to a reforestation program
        spanning decades, after prior centuries of deforestation, with pines and oaks making up the largest portion. Northern Spain has
        beech and oak dominated forests thanks to the wet climate along the northern coast, while more isolated patches in highland areas
        further south contain pine and juniper.
      </p>
      <p>
        The forests of Italy, Greece and the Turkish Mediterranean are confined to highland areas, as the coasts suffer too much summer
        drought and heat. In upland areas, beech, oak, pine, spruce and cypress provide a rich landscape in these countries otherwise
        thought of as being rather barren and scrublike. The Black Sea coast of Turkey is extensively forested, and this continues east into
        the Caucasus where a mix of oak, alder, fir and pine among others exist.
      </p>
      <ChapterImageList images={imagesWitw3} />
      <p>
        The Balkans and Eastern Europe are the most heavily forested regions in the continent, with a mix of oak, beech, birch, pine and
        spruce varying in dominance depending on altitude. These forests continue east into the Ukraine and European Russia, where the
        harsher winters push the dominant species toward deciduous birch and aspen, along with coniferous pine and spruce. This mixed forest
        runs east for thousands of miles, acting as a border between the boreal forest to the north and the steppe grassland to the south.
        In the middle of Siberia, this is really the taiga, the boreal forest, and not temperate, but this map shows it as mixed due to the
        presence of hardy aspens and birch. We’ll talk more about this deciduous incursion into the taiga{" "}
        <A to="taiga">in the next chapter</A>.
      </p>
      <ChapterImageList images={imagesWitw4} />
      <p>
        In Asia, most of Eastern China was once covered in mostly broadleaf forest, but millennia of high populations in this region has
        resulted in all of the land given over to agriculture or urban development. So now only highland areas of China remain forested,
        often covering dramatic karst landscapes, especially in the south west. In the south the forests are broadleaf evergreen
        subtropical, and blend into tropical monsoon forest, while in the north, there is a mix of deciduous and coniferous. In terms of
        species, maple, oak, fir and larch are dominant in the north, while more southerly forests are highly diverse with no particular
        species dominant. The native and unique Gingko, which is neither confiner nor broadleaf, but in a whole ancient class of its own, is
        planted extensively in cities. Bamboo forest is also present in mid-China, and is noted for its most famous occupant, the Giant
        Panda. Although it should be remembered that bamboo is not a tree of any kind, but in fact, a grass species.
      </p>
      <p>
        Because of the need for fuel during the cold winters, and historically high population, most of Korea’s forests are gone, but at one
        time would have been a mix of maple, oak, alder, birch and pine.
      </p>
      <ChapterImageList images={imagesWitw5} />
      <p>
        By contrast, the mountainous topography of Japan has meant that most of these islands are still covered in forests. Species vary
        from laurels, cedars and cypress in the evergreen subtropical south to the cold north, where oak, birch, beech, maple and cherry can
        be found. That last species is, of course, famous for producing the sakura blossom in spring, which is nationally celebrated.
      </p>
      <ChapterImageList images={imagesWitw6} />
      <p>
        In North America, the eastern half was once covered in a range of broadleaf and coniferous forests, but centuries of human
        development and agriculture have decimated most of the low lying forests, in the same way as Europe and East Asia. In the
        North-East, mixed forests of spruce, fir, maple, beech and birch survive the cold winters, while further south, oaks and hickories
        vie with pines. As we travel west, and annual rainfall reduces, these trees thin out into savannah, mixing with natural prairie,
        until only grass remains.
      </p>
      <p>
        The prairies act as a natural barrier to species transmission, and so we find the forests of the western part of the United States
        to be of a very different character. In the Rockies, pine and fir mix with aspens at the mid-altitudes, while junipers survive the
        drier hotter summers in lower lying areas.
      </p>
      <ChapterImageList images={imagesWitw7} />
      <p>
        For reasons which appear to be related to the drier summers, conifers dominate the western coastal forests, from British Columbia in
        the north, through Washington and Oregon to the uplands of California in the south. In the Pacific Northwest, pine, spruce, cedar,
        fir and hemlock make up the majority of these extensive forests. On the exposed Olympic peninsula in Washington, year round rain
        permits the presence of temperate rainforest.
      </p>
      <p>
        Oak forest is present along many of the lower slopes of Californias uplands, but this state is more famous for the other kind of
        trees that grow here. Unique climatic conditions allow for the growth of the world’s tallest trees, the Redwoods and Sequoias. Both
        species belong to the Sequoia family, with the Coastal Redwood found on the coastal slopes of northern California, while the western
        slopes of the inland Sierra Nevada host the Giant Sequoia. Growing perfectly straight, up to 115m (379 feet), these are the most
        massive single organisms on earth, as well as among the oldest, with some trees up to 1,600 years in age. To be among these giants,
        as I have had the fortune, is a truly humbling experience. The Redwoods are a testimony to the power of nature, reaching from earth
        to sky on such a colossal scale.
      </p>
      <ChapterImageList images={imagesWitw8} />
      <h3>Culture, Fauna and Threats</h3>
      <p>
        The traditional four seasons of Western and East Asian culture have their origin in the behaviour of the broadleaf temperate forests
        that dominate these areas. The flowering plants that are these trees have bare branches in winter, produce blossom in spring, leaves
        and fruit in summer, and lose their leaves in autumn, which is known as the fall in North America, directly named after the falling
        of those lost leaves.
      </p>
      <p>
        Temperate woodlands support a variety of well-known fauna, with mammals from pandas and bears, deer and wolves, down to foxes,
        stoats, rabbits, squirrels, badgers and mice. Bird species are abundant, as one would expect with so many branches to nest in,
        although these species are mostly seasonal, being mostly present only in summer as they escape the cold winters for more tropical
        climes.
      </p>
      <p>
        Of all the biomes on earth, the temperate forest is the one that has been most invaded by humankind. Deforestation of North America,
        Europe and China is, in some places total, and in most areas over seventy percent. The British Isles was heavily deforested at some
        unknown point in antiquity, as even the Romans two thousand years ago described a mixed landscape of forest, field and heath. A
        large part of central eastern China has been so completely deforested for millennia that botanists are not sure what the natural
        species were once in this region, since no historical records point to answers on this. Such deforestation has been the result of
        two main factors – the need for land for agricultural purpose, and the need for the wood itself, for heating, building construction,
        and even shipbuilding. We can lament the loss of these forests, but in that same moment of thought must understand that the very
        nature of our civilisation was developed on the cutting down of these forests. We live as we do today in the developed Western
        world, as we do also in the Far East at the price of those lost forests. If there is any consolation in this, then we have not
        totally abandoned our wooded cousins, for they adorn the streets, parks and private gardens of every city that we live in,
        appreciated daily by millions.
      </p>
      <h3>Species</h3>
      <p>
        The tree species within these forests are perhaps the most well known of any plants anywhere on the globe, and for anyone who has
        grown up in North America, Europe or Eastern Asia, these have become a part of our lives in ways that no other plants have. And
        because of that, I thought I would, in closing, let them speak for themselves…
      </p>
      <ChapterImageList images={imagesSpecies} />
      <h3>Coursework Questions</h3>
      <ol>
        <li>What are trees? How is it that they can grow so much taller than other plants?</li>
        <li>What are the key differences between broadleaf and coniferous trees?</li>
        <li>What type of climate do temperate forests thrive in?</li>
        <li>Describe how the mix of coniferous and broadleaf trees varies with latitude.</li>
        <li>What are some of the different types of temperate forest?</li>
        <li>List some countries that have temperate forests along with the tree species that grow there.</li>
        <li>Briefly describe the historical human impact on temperate forests.</li>
      </ol>
    </Chapter>
  );
}

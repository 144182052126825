import React from "react";

export default function Support(props) {
  return (
    <div className="support center-cont">
      <h2>Please help support the future development of this website, and of my YouTube channel</h2>
      <p>You can become a regular Patreon supporter for as little as $2/month</p>
      <a href="https://www.patreon.com/bePatron?u=9856166" data-patreon-widget-type="become-patron-button">
        Become a Patron!
      </a>      
      <p>Or you can make a one-off donation in Bitcoin:</p>
      <img src={`${process.env.REACT_APP_IMAGES_DIR}/emblem-bitcoin.jpg`} alt="Bitcoin Logo" />
      <p><b>3GNpXYzn9hFAX5xVzwisHXrErivZhGFLXK</b></p>      
      <img src={`${process.env.REACT_APP_IMAGES_DIR}/bitcoin-qrcode.png`} alt="Bitcoin QR Code" />
    </div>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";
import A from "../components/A";

export default function BiomesIntro(props) {
  const headerImage = "biomes-intro-hero.jpg";
  const ImageSampleBiome0 = "biomes-sample-tropical-rainforest.jpg";
  const ImageSampleBiome1 = "biomes-sample-savannah.jpg";
  const ImageSampleBiome2 = "biomes-sample-desert.jpg";
  const ImageSampleBiome3 = "biomes-sample-grassland.jpg";
  const ImageSampleBiome4 = "biomes-sample-temperate-forest.jpg";
  const ImageSampleBiome5 = "biomes-sample-taiga.jpg";
  const ImageClimateZone = "biomes-intro-climate-zone.jpg";
  const ImageEucalyptus = "biomes-intro-eucalyptus.jpg";
  const ImageBamboo = "biomes-intro-bamboo.jpg";
  const ImageEvapo = "biomes-evapotranspiration.jpg";
  const ImageSubarcticSemiArid = "biomes-comparison-subarctic-semi-arid.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";
  const GlobalDistributionMap = "Biomes-World-Map.jpg";

  const imagesSampleBiomes = [
    { src: ImageSampleBiome0, caption: "Tropical Rainforest in French Guiana" },
    { src: ImageSampleBiome1, caption: "Savannah in Kenya" },
    { src: ImageSampleBiome2, caption: "Semi-Arid Desert in Eastern California, USA" },
    { src: ImageSampleBiome3, caption: "Steppe Grassland in Mongolia" },
    { src: ImageSampleBiome4, caption: "Temperate Forest near Mount Fuji, Japan" },
    { src: ImageSampleBiome5, caption: "Taiga (Boreal Forest) in Lappland, Northern Finland" },
  ];

  const imagesClimateVsBiome = [
    { src: ImageClimateZone, caption: "A climate zone is simply the pattern of rainfall and temperature" },
    { src: ImageEucalyptus, caption: "South Eastern Australia, with a Humid Subtropical climate has eucalyptus forest..." },
    { src: ImageBamboo, caption: "... whereas the same climate in South China has bamboo forest" },
  ];

  const imagesHoldridge = [
    { src: ImageHoldridge, caption: "The Holdridge Lifezones chart" },
    { src: ImageEvapo, caption: "Evapotranspiration = direct evaporation from the soil + transpiration from plants" },
    {
      src: ImageSubarcticSemiArid,
      caption: "Comparison of Subarctic and Semi-Desert areas with similar rainfall, but dramatically different levels of biomass",
    },
  ];

  return (
    <Chapter
      series="biomes"
      heading="Introduction"
      subheading="THE LIVING LANDSCAPES OF EARTH"
      documentTitle="An Introduction to Biomes - The Living Landscapes of Earth"
      headerImageSrc={headerImage}
      youTubeCode="o_AfNcjlOgU"
      chapterPrevText="Biomes Menu"
      chapterPrevUrl="/biomes/"
      chapterNextText="Tropical Forests"
      chapterNextUrl="/biomes/tropical-forests"
    >
      <p>
        When you look at pictures of the Earth from space, what’s the first thing that you see? A blue marble, as some have described it. A
        water world where oceans and swirls of white cloud dominate. But most of us will then have our attention drawn to the shapes
        imprinted on its surface – the continents. And they come in many colours. The white of Antarctica, the beige of the Sahara, the
        light green of grasslands and the darker shades of our planet’s forests in their many forms.
      </p>
      <p>
        So many of these colours that give the world its look are the result of trillions of individual plants growing upon the surface.
        Which plants grow where is determined by many factors, with climate being most dominant factor. Desert and scrubland, savannah,
        prairie and steppe, tundra and taiga, woodland and rainforest. They are at the base of almost every food chain of every animal – the
        habitats that sustain all other lifeforms, and ourselves. Collectively, they are known as biomes - the living landscapes of Earth.
      </p>
      <ChapterImageList images={imagesSampleBiomes} />
      <p>
        Landscapes have been an inspiration to us for centuries, the subject of countless painters, writers, photographers and movie
        directors. The natural vistas of our home countries and foreign lands, perhaps more than anything else, determine that area’s
        character. And while these landscapes are shaped underneath by topography – the presence of coastlines, hills and mountains - their
        surfaces are defined by the degree of plant life growing upon them.
      </p>
      <h3>Principle Biome Plant Groups</h3>
      <p>
        Earth’s vegetation is almost infinite in terms the variety of species that grow upon it. As plants are wholly dependent upon liquid
        water, what types of species grow where is determined primarily by annual patterns of temperature and rainfall in that area – the
        local climate. An abundance of heat and water produces the greatest growth potential, such as in the tropical rainforests, while
        lower temperatures or rainfall reduce such growth and shape the plant life in various ways so that it can survive drought and frost.
      </p>
      <p>The main groupings of plant varieties in this regard can be thought of in the following way:</p>
      <ol>
        <li>
          Trees – from the coniferous evergreens of the boreal forests, to mixed temperate woodlands and the evergreen broadleaf forests of
          the tropical regions
        </li>
        <li>
          Shrubs – occurring in almost every climate zone, either as a junior partner in forests, or dominant as they are in semi-arid
          areas, such as the Mediterranean
        </li>
        <li>
          Grasses – again occurring in almost all areas, but known especially in their singular biomes of the tropical savannah, and the
          temperate prairie and steppe
        </li>
        <li>Desert adaptations – with cacti being the most well known</li>
        <li>Very cold adaptations such as found in tundra</li>
      </ol>
      <p>
        These adaptations span the basic classes of plants in terms of evolutionary heritage. For instance, the class of flowering plants,
        known as angiosperms, are found in every category as just described.
      </p>
      <h3>Biome vs Climate Zone</h3>
      <p>
        The term biome is often confused with the concept of a climate zone, but these are different in nature. A climate zone is defined
        simply by the patterns of rainfall and temperature, and these are discussed in detail in my{" "}
        <A to="/climate/secrets">Secrets of World Climate Series</A>, which is linked top right. Biomes are the adaptations of plants,
        principally influenced by climate zones, but by other factors also.
      </p>
      <p>
        Perhaps the most interesting of these is the different ways in which plants evolve in the same climate types, but isolated on
        different continents. For instance, eucalyptus forest dominates the eastern seaboard of Australia, which has the same humid
        subtropical climate to that of the south-eastern United States and Southern China, and yet we do not find eucalyptus in those other
        continents, but instead oaks or bamboo. In the opposite way, many species have adapted to live in multiple climate zones, for
        instance, the pine tree family can be found from the subarctic to the tropics.
      </p>
      <ChapterImageList images={imagesClimateVsBiome} />
      <p>
        Another influence is the type of soil that might be present in a region, from sand through to silt and clay. As the soil affects
        drainage of water away from roots, this affects what species will be present within the same climate zone since plants have
        different sensitivities in this regard.
      </p>
      <p>
        So climate zones and biomes should never be thought of as synonymous. In fact the subject of biomes is significantly more complex
        because of life’s way of evolving along different paths through random mutations when presented with the same external conditions.
        This complexity is evidenced by the many different attempts to classify geographical distribution of biomes over the last century
        and a half with varying degrees of consensus. By comparison, climate classification has been dominated by a single figure in all
        this time – Vladimir Koppen.
      </p>
      <h3>The Holdridge Lifezones Chart</h3>
      <p>
        On that subject, of climate, let’s return to that, and focus on how and why exactly differing patterns of precipitation and
        temperature can so markedly affect the type of vegetation covering an area.
      </p>
      <p>
        In the 1940s, Leslie Holdridge developed this graph showing how a combination of temperature and rainfall could produce the
        different biome types observed across the planet, what he call Life Zones. At first this looks like a beast of complexity, but if we
        break it down, it will make sense. From top to bottom we have increasing temperature, either from the poles to the equator, or the
        tops of mountains to the sea. On the right side of the triangle we have annual precipitation, from dry in the centre to wet on the
        right.
      </p>
      <ChapterImageList images={imagesHoldridge} />
      <p>
        The combination of temperature and rainfall yield a factor called the aridity index. It is actually the ratio of potential
        evapotranspiration to total rainfall. Evapotranspiration is the combined effect of direct evaporation of water from the soil plus
        the outward transpiration of water from plants through the gas exchange cells in their bodies. Potential evapotranspiration is the
        maximum possible amount of water lost in this way, assuming there would a limitless amount of water available. The highest
        temperatures combined with the lowest rainfall produces the highest aridity index, and this is where we find deserts capable of
        supporting little if any plant growth. Both cooler temperatures with weaker sun and consequent lower evapotranspiration or a wetter
        climate alike allow for plants and soil to retain more moisture and thereby increasing plant growth, and so at the opposite end we
        find rainforests, either of the tropical or temperate variety.
      </p>
      <p>
        This concept is key in understanding how regions with differing rainfall and temperatures lead to different types of vegetation.
        It’s not simply about which areas have more or less rain. Take, for example, the boreal forests which have a similar total annual
        rainfall to the semi-arid Sahel just below the Sahara desert. Totally different biomes despite the similar rainfall totals. But
        because the boreal forests are much cooler, they can retain more moisture, leading to extensive biomass, whereas plants and soil in
        the hot Sahel lose much more moisture in comparison, leading to stunted shrub-like vegetation and plenty of bare earth.
      </p>
      <h3>The LONS08 Biome Classification System</h3>
      <p>
        In this series we’re going to use the recent classification known as LONS08, based on Dorman and Sellers’ work of the late 1980s.
        This global map shows the thirteen different biomes delineated by this classification. Starting at the equator, and moving roughly
        outward toward the poles, these are summarised as follows:
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Adapted World Map of LONS08 Biome Classification" />
      <ul>
        <li>
          <em>Tropical Evergreen Forest</em> – where tropical heat combined with year round rain permits broadleaf trees to retain their
          leaves permanently
        </li>
        <li>
          <em>Tropical Seasonal Forest</em> – where tropical wet and dry seasons cause broadleaved trees to lose their foliage in the dry
          season
        </li>
        <li>
          <em>Savannah</em> – where the tropical dry season is of sufficient length or severity that trees are largely replaced by grasses
        </li>
        <li>
          <em>Shrubland</em> – where the challenges of wet and dry seasons both in the tropics and more temperate latitudes lead to a
          domination of shrubs interspersed with grasses
        </li>
        <li>
          <em>Semi-desert</em> – similar to shrubland, but where the aridity index is so high that only hardy shrubs or specially adapted
          families such as cacti can grow
        </li>
        <li>
          <em>Desert</em> – where the climate is so dry that virtually no vegetation is possible – the biome of bare earth
        </li>
        <li>
          <em>Prairie and Steppe</em> – where a lack of rain combined with cooler temperatures in the mid-latitudes prevents tree growth and
          allows grasses to dominate
        </li>
        <li>
          <em>Temperate Forest</em> – the classic broadleaf woodlands of the mid-latitudes, most of trees therein losing leaves in winter
        </li>
        <li>
          <em>Mixed Temperate Forest</em> – where broadleaf and evergreen coniferous forests are mixed at the mid-latitudes
        </li>
        <li>
          <em>Boreal Evergreen Forest</em> – where cooler temperatures in more polar latitudes lead to coniferous tree growth dominating in
          the vast expanse of the taiga
        </li>
        <li>
          <em>Boreal Seasonal Forest</em> – like the taiga, but where winters are so severe that even the supposed evergreen conifers lose
          their needles in this season
        </li>
        <li>
          <em>Tundra</em> – where summer temperatures are too low to allow for the growth of trees, leading to cold-adapted low-lying
          shrub-like vegetation
        </li>
        <li>
          <em>Ice</em> – where no vegetation is possible due to year-round freezing conditions
        </li>
      </ul>
      <p>
        In this series, we’re going to look at each of these areas in more detail, showing real examples of places where these exist, and
        the dominant species found in each.
      </p>

      <h3>Coursework Questions</h3>

      <ol>
        <li>What is the difference between a climate zone and a biome?</li>
        <li>What are the five principle types of vegetation that exist for the purposes of biome classification?</li>
        <li>What is evapotranspiration and how does it determine the type of plants that will grow in a biome?</li>
        <li>Name 5 biomes, and briefly describe their nature.</li>
      </ol>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
//import ChapterImage from "../components/chapter-image";
import ChapterImageList from "../components/chapter-image-list";
import A from "../components/A";

export default function BiomesTropicalForests(props) {
  const headerImage = "tropical-forests-hero.jpg";
  const ImageEvergreenVsSeasonal = "tropical-forests-evergreen-seasonal.jpg";
  const Image1Floor = "tropical-forests-1-floor.jpg";
  const Image2UnderStorey = "tropical-forests-2-understorey.jpg";
  const Image3Canopy = "tropical-forests-3-canopy.jpg";
  const Image4Emergent = "tropical-forests-4-emergent.jpg";
  const ImageBiomesVsKoppen1 = "tropical-forests-biomes-vs-koppen-1.jpg";
  const ImageBiomesVsKoppen2 = "tropical-forests-biomes-vs-koppen-2.jpg";
  const ImageWitw1 = "tropical-forests-ecuador.jpg";
  const ImageWitw2 = "tropical-forests-costa-rica.jpg";
  const ImageWitw3 = "tropical-forests-martinique.jpg";
  const ImageWitw4 = "tropical-forests-guiana.jpg";
  const ImageWitw5 = "tropical-forests-guinea-kambadaga-falls.jpg";
  const ImageWitw6 = "tropical-forests-philippines-palawan.jpg";
  const ImageWitw7 = "tropical-forests-laos.jpg";
  const ImageWitw8 = "tropical-forests-australia.jpg";
  const ImageWitw9 = "tropical-forests-hawaii.jpg";

  const ImageFamilies = "tropical-forests-species-families.jpg";
  const ImageSpecies1 = "tropical-forests-species-south-america.jpg";
  const ImageSpecies2 = "tropical-forests-species-africa.jpg";
  const ImageSpecies3 = "tropical-forests-species-south-east-asia.jpg";

  const GlobalDistributionMap = "Biomes-World-Map-Tropical-Forests.jpg";
  const ImageHoldridge = "Holdridge-Lifezones.png";

  const images1 = [
    {
      src: ImageEvergreenVsSeasonal,
      caption:
        "Tropical Evergreen Forests exist in a climate with no dry season, while Tropical Seasonal Forests are subject to wet and dry seasons",
    },
    {
      src: ImageHoldridge,
      caption: "The Holdridge Lifezones chart shows that rainforest can exist in temperate as well as tropical climates",
    },
  ];

  const images2 = [
    { src: Image1Floor, caption: "The floor of a tropical forest, rich in decaying biomass" },
    { src: Image2UnderStorey, caption: "Between the canopy and floor lies the understorey" },
  ];

  const images3 = [
    { src: Image3Canopy, caption: "The canopy is where most of the biomass in a tropical forest is found" },
    { src: Image4Emergent, caption: "The emergent layer sees particularly tall and resilient specimens surviving above the canopy" },
  ];

  const images4 = [
    {
      src: ImageBiomesVsKoppen1,
      caption:
        "The Tropical Koppen Climate Zones Af &amp; Am closely match the extent of Tropical Forest Biomes, while Aw is a mix of Seasonal Forest and Savannah",
    },
    {
      src: ImageBiomesVsKoppen2,
      caption:
        "Tropical Forests are also found at higher altitudes in Subtropical Highland climate zones (Cfb/Cwb) and in Subtropical Monsoon (Cwa) areas",
    },
    { src: GlobalDistributionMap, caption: "Global distribution of Tropical Evergreen and Seasonal Forest" },
  ];

  const images5 = [
    { src: ImageFamilies, caption: "Tropical Forest species are broken down largely by continent" },
    { src: ImageSpecies1, caption: "Some South American Tropical Forest tree species" },
    { src: ImageSpecies2, caption: "An African Tropical Forest tree species" },
    { src: ImageSpecies3, caption: "Some SE Asian Tropical Forest tree species" },
  ];

  const imagesWitw1 = [
    { src: ImageWitw1, caption: "Tropical Rainforest in Ecuador" },
    { src: ImageWitw2, caption: "Tropical Rainforest in Costa Rica" },
    { src: ImageWitw3, caption: "Seasonal Forest on Martinique in the Caribbean" },
  ];

  const imagesWitw2 = [
    { src: ImageWitw4, caption: "Tropical Rainforest in French Guiana" },
    { src: ImageWitw5, caption: "Kambadaga Falls in Guinea, West Africa" },
    { src: ImageWitw6, caption: "Seasonal Forest on Palawan Island, Philippines" },
  ];

  const imagesWitw3 = [
    { src: ImageWitw7, caption: "Seasonal Forest in Laos" },
    { src: ImageWitw8, caption: "Tropical Rainforest in Queensland, Australia" },
    { src: ImageWitw9, caption: "Tropical Forest on Maui Island, Hawaii" },
  ];

  return (
    <Chapter
      series="biomes"
      seriesChapter="1"
      heading="Tropical Forests"
      subheading="EVERGREEN &amp; SEASONAL"
      documentTitle="The Tropical Forest Biomes"
      headerImageSrc={headerImage}
      youTubeCode="E6WdEyt93vA"
      chapterPrevText="Introduction"
      chapterPrevUrl="/biomes/introduction"
      chapterNextText="Savannah"
      chapterNextUrl="/biomes/savannah"
    >
      <p>
        It is fitting that we begin our journey across the biomes of Earth with the one that we perhaps hold the most dear in our hearts.
        The greatest biodiversity of any area of earth with over half of all plant and animal species. The lungs of the world – absorbing
        more CO<sub>2</sub> and producing more oxygen than any other. A product of constant heat and abundant rain, it’s no wonder that
        plants grow here more vigorously than in any other biome. But it is also the biome that is under the greatest threat. Known as
        jungle, selva or rainforest, these regions are in fact two distinct biomes – the evergreen and seasonal forests of the tropics.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        Encircling the equatorial regions of our planet, conditions of constant heat, and plentiful rainfall produce the richest biomes of
        all, since such conditions are the optimum for plant growth. The biomes of the tropical evergreen and seasonal forests have the same
        basic components of tall broadleaved, hardwood trees dominating the biomass. But beyond this, their characters diverge out from that
        essential character.
      </p>
      <p>
        The presence of evergreen or seasonal forest is determined mainly by the pattern of rainfall throughout the year. When there is a{" "}
        <A to="/climate/tropical-rainforest">minimal to no dry season</A> or where extensive flooding occurs after a wet season to help the
        trees through the dry, the trees retain their leaves and continue growing throughout the year. When there is a{" "}
        <A to="/climate/tropical-monsoon-and-tropical-savannah">noticeable dry season</A> and no flooding the trees shed their leaves to
        retain internal moisture, and cease growing. This is why they are known scientifically as tropical evergreen and seasonal forest.
      </p>
      <p>
        In popular understanding, we think of these as the rainforest, but it should be noted, as we can see in Holdridge’s Lifezones chart,
        that rainforests can also exist in more temperate latitudes where cooler temperatures exist. This same chart also shows that as
        overall rainfall is reduced in the tropical band, rainforest gives way to progressively drier forests, and then to trees
        interspersed with grasses and shrubs, that we can no longer truly call forest, but is its own concept – that of the tropical
        savannah. We’ll pick that one up in <A to="savannah">the next chapter</A>. For now, then, let’s address the forests of the tropical
        regions, and consider their common elements.
      </p>
      <ChapterImageList images={images1} />
      <h3>Anatomy in Cross Section</h3>
      <p>
        If we look at a cross-section of a typical tropical forest, we will find four distinct layers. Starting at the ground, and we have
        the forest floor, which is littered with fallen vegetation from upper layers. In the hot and wet conditions, and in the presence of
        huge numbers of scavenger insects, millipedes and the like, this litter is reduced rapidly. Combined with often heavy rainfall, this
        soil is further leached of nutrients, so despite the biodiversity going on above ground, the soil is remarkably poor. Due to the
        extensive leaf canopy above, only about 2% of sunlight reaches the ground in rainforest, and so undergrowth is generally quite
        sparse here, with selected low-lying shrubs with extra-large leaves battling to receive as much light as they can in the dim
        conditions. As we move out into seasonal forest, the canopy thins out, permitting more light to reach the ground, and with
        consequently more undergrowth. This trend continues until proper savannah conditions exist - a mix of trees interspersed at the
        ground level with thickets of shrubs and an abundance of grass.
      </p>
      <ChapterImageList images={images2} />
      <p>
        Above ground but under the canopy, we have the understorey, where still only 5% of light can penetrate the canopy in rainforest.
        Here are found shrubs as well as seedlings of the main tree species which will fully develop if a gap opens in the canopy due to the
        fall of another tree.
      </p>
      <p>
        At around 20-40m in height we arrive at the canopy itself – an almost continuous layer of leafed branches of the main tree species
        that have grown to this height in competition with other trees for sunlight. This layer will absorb over 90% of the light falling on
        the forest. It has by far the greatest biodiversity of all the layers, and it is estimated that it is home to about half of all
        plant species on earth, since the trees themselves are usually covered in other plants, such as air-rooted orchids, and
        ground-rooted liana, or vines, that usually feed upon the host tree. Considered parasitic, these liana often connect trees together
        to act as highways for wildlife to move from tree to tree - from ants and lizards to rodents, sloths and monkeys. This layer,
        comprising most of the life of the rainforest, makes this biome unique among all on earth, as being the only one that is so
        distinctly far above ground.
      </p>
      <p>
        The last layer, where very tall trees extend beyond the main canopy, growing up to 70 metres or more, is known as the emergent
        layer. Such trees, while basking in the fullest sunshine, have to be resilient to the fierce tropical sun, as well as resisting
        strong winds which are commonly found at this height.
      </p>
      <ChapterImageList images={images3} />
      <p>
        The tropical forests are believed to be the oldest of all earth’s biomes still in existence, being as much as 100 million years old.
        Compare this to the temperate forests at about 60 million years, and temperate grasslands at only 25 million years in origin.
      </p>
      <h3>Where in the world</h3>
      <p>
        So, where in the world do we find the tropical forests? As clued by their name, they occur only in the tropics, between 23 degrees
        north and 23 degrees south, where high temperature exist year round. In general there is a close relationship to the evergreen
        forests and the <A to="/climate/tropical-rainforest">Tropical Rainforest (Af)</A> and Tropical Monsoon (Am) Koppen climate zones,
        that have rain year round or for most of the year. Seasonal Forest is generally found on the periphery of these Koppen zones and
        most commonly within the Koppen <A to="/climate/tropical-monsoon-and-tropical-savannah">Tropical Savannah (Aw)</A> zone, which has
        longer or more severe dry <A to="/climate/seasons">seasons</A>. There are exceptions to this relationship, some of which can be
        explained by extensive flooding from the wet season sustaining growth and leaf retention throughout the dry season. In other cases,
        higher altitudes, such as in SE Brazil, Colombia and Ecuador push the climate zone into a Subtropical Highland type of warm instead
        of hot temperatures year round, which is nonetheless still favourable to trees maintaining their leaves throughout the year.
      </p>
      <ChapterImageList images={images4} />
      <p>
        So, region by region, and starting in the Americas, tropical forests dominate Central America, with southern and western Mexico
        having seasonal forest, and as we travel further south, these become evergreen, all the way to the Pacific coasts of Colombia and
        Ecuador. Most of the Caribbean islands have either evergreen or seasonal forests on them, wherever they are exposed to moist
        rain-bearing winds.
      </p>
      <ChapterImageList images={imagesWitw1} />
      <p>
        South America has the most extensive tropical forests in the world, with the Amazon basin being the most famous, covering an area
        equivalent to the continent of Europe. Most of this is within Brazil, but it extends into the adjoining countries of the Guyanas,
        Venezuela, Colombia, Ecuador, Peru and Bolivia. The vast majority of this is evergreen, with seasonal forest on the periphery as it
        morphs into the Savannahs of Central Brazil, Venezuela and Bolivia. South Eastern Brazil has extensive seasonal forest, which
        becomes evergreen along much of the coast owing to moist trade winds year round.
      </p>
      <p>
        Moving onto Africa, and we have the home of the second largest contiguous rainforest – that of the Congo basin. The entire coast of
        West Africa from Nigeria to Senegal has evergreen forest which, like the Congo, has an edge of seasonal forest before it transforms
        gradually into the extensive savannahs that dominate the continent. Evergreen and seasonal forests also exist in parts of Ethiopia
        where a subtropical highland climate exists, and the western and northern coasts of Madagascar, where favourable trade winds deliver
        plenty of rain year round.
      </p>
      <ChapterImageList images={imagesWitw2} />
      <p>
        Onto Asia, now, and the Indian Subcontinent, which would have the largest area of tropical seasonal forest in the world if so much
        of it had not been cut down for agricultural purposes to feed the world’s second largest population. This natural vegetation type
        dominates here due to the dramatic shifts in wet and dry seasons brought by the Indian Monsoon, the world’s most extreme. Evergreen
        forests exist on the western coast of India where the monsoon rain is so high that the ground stays wet for most of the year.
        Flooding in the lower Ganges basin throughout the dry season also keeps most of Bangladesh in the natural evergreen type, although
        again, most of these forests are gone due to extensive agriculture to feed its massive population. The island of Sri Lanka is home
        mostly to evergreen forests due to its exposure to moist trade winds all year round.
      </p>
      <p>
        The South East Asian nations of Myanmar, Thailand, Laos, Cambodia and Vietnam are home to a mix of seasonal and evergreen forests
        depending upon the drainage and retention of water as all these countries experience a significant dry season. The southern coasts
        of China all the way to Taiwan also have a mix of tropical forest types. As we move into the SE Asian archipelagos of the
        Philippines, Malaysia and Indonesia, however, the evergreen forest becomes dominant, and this, collectively, makes up the third
        largest rainforest in the world, with much of it still in its natural state, particularly on the world’s second and third largest
        islands – New Guinea and Borneo.
      </p>
      <ChapterImageList images={imagesWitw3} />
      <p>
        As we move into Oceania, and we have a narrow band of rainforest existing along the northern and north-western coasts of Australia,
        where exposure to most trade winds leads to plenty of rain allowing favourable conditions for evergreen and seasonal growth of
        tropical forest.
      </p>
      <p>
        Lastly, we come to the Pacific, and all islands that lie within the tropical zone have evergreen forests, from the Solomon Islands
        to Fiji, Micronesia and certain coasts of the Hawaiian Islands that are exposed to moist trade winds for much of the year.
      </p>
      <h3>Species</h3>
      <p>
        As is well known, the tropical rainforests are the most biodiverse habitats on Earth, and this biodiversity runs across all kingdoms
        of plants and animals. As an example in one area of Malaysia of less than a quarter of a square kilometre, 375 different tree
        species with significant trunk diameters were recorded.
      </p>
      <p>
        Most plants, including almost all of the trees are in the angiosperm group, namely, those plants that produce flowers. Among these
        are the more famous hardwoods such as mahogany, teak, ebony and rosewood. Hundreds of species within the palm family are also
        common. Coniferous trees, such as pine etc., on the other hand, are relatively rare in these parts.
      </p>
      <p>
        When we move into the seasonal or “dry” forest, where trees lose their leaves in the dry season, biodiversity is significantly less,
        and in contrast to the multi-species canopies of the rainforest, often the forests can be dominated by a single species over wide
        areas, such as the teak forests of Myanmar.
      </p>
      <p>
        Tree species distribution varies geographically, and is roughly divided into three subkingdoms. The Neotropical, African and
        Malenesian. In Central and South America, mahogany, cedar, myrtle, laurel, palm, acacia, rosewood, brazil nut are common. In Africa
        we find mahogany again, along with ebony, limba, wenge, agba, iroko and sapele. In SE Asia, in addition to the well known teak, a
        significant proportion of trees belong to the dipterocarpus family, while others include durian, sandalwood and ironwood.
      </p>
      <ChapterImageList images={images5} />
      <h3>Threats</h3>
      <p>
        In closing, we come, sadly, to the issue of the destruction of this biome – one practically all of us are aware of, especially in a
        year of unprecedented burning of forests in Brazil and Bolivia. At one time logging of prized tropical hardwoods was considered the
        greater threat. But, like so many other biomes worldwide, it is farming that is now the greatest threat to forests of the tropics.
        In Bolivia and Brazil, illegally burning the land to then claim it as “dead” land that can be used for cattle ranching is being
        carried out to circumvent the usual rules that prevent such claims. While in South East Asia, habitat destruction is now at a scale
        never been seen, much of it being due to the demand for palm oil. I have seen with my own eyes, from the air, vast swathes of the
        forests of Indonesia and Malaysia turned into the hexagonal monoculture of palm oil plantations. Many of you might think, no it’s
        not possible that the rainforests could disappear – they’re too big, and just too precious. Well, if you need any precedent for
        this, look at the forests that once covered England, or India, or the prairies that once covered a dozen states of the USA. You
        can’t, because they’re not there anymore. Destruction of natural habitats is very much in our history, stretching back thousands of
        years. And unless we learn how to accommodate the needs of a growing population throughout the tropics, then we may lose the most
        precious biome of all.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>What are the two types of tropical forest biomes and how do they differ?</li>
        <li>Why is the Tropical Seasonal Forest so named?</li>
        <li>Briefly describe the four vertical sections of a tropical forest.</li>
        <li>What Koppen Climate Zones are related to the tropical forests?</li>
        <li>List out some countries and regions that have tropical forests.</li>
        <li>What are the three broad families of tropical forest species, and where are they found?</li>
        <li>List out some tropical forest tree species.</li>
        <li>
          Are the tropical forests under threat? Is there an historical precedent for other biomes being almost wiped out? Briefly discuss
          each.
        </li>
      </ol>
    </Chapter>
  );
}

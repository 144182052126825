import React, { useState } from "react";

export default function ChapterImage(props) {
  const [isPoppedUp, setIsPoppedUp] = useState(false);

  let className = "chapter-image-cont";
  if (props.right) className += " float-right";
  else if (props.left) className += " float-left";

  const thumbPath = `${process.env.REACT_APP_IMAGES_DIR}/thumbs/${props.src}`;
  const fullSizePath = `${process.env.REACT_APP_IMAGES_DIR}/${props.src}`;

  return (
    <>
      <div className={className} onClick={() => setIsPoppedUp(true)}>
        <img className="chapter-image" src={thumbPath} alt={props.caption} />
        <div className="caption">{props.caption}</div>
      </div>
      {isPoppedUp && (
        <div className="image-popup" onClick={() => setIsPoppedUp(false)}>
          <img src={fullSizePath} alt={props.caption} />
          <div className="caption">{props.caption}</div>
        </div>
      )}
    </>
  );
}

import React, { useEffect } from "react";
import NavPanelThumb from "../components/nav-panel-thumb";
import Share from "../components/share";

export default function Biomes(props) {
  useEffect(() => {
    document.title = "A Course on Biomes - The Living Landscapes of Earth";
  }, []);

  const ImageIntro = "biomes-thumb-intro.jpg";
  const ImageTropicalForests = "biomes-thumb-tropical-forests.jpg";
  const ImageSavannah = "biomes-thumb-savannah.jpg";
  const ImageShrublands = "biomes-thumb-shrublands.jpg";
  const ImageDeserts = "biomes-thumb-deserts.jpg";
  const ImageGrasslands = "biomes-thumb-grasslands.jpg";
  const ImageTemperateForests = "biomes-thumb-temperate-forests.jpg";
  const ImageTaiga = "biomes-thumb-taiga.jpg";
  const ImagePolarBiomes = "biomes-thumb-polar-biomes.jpg";

  return (
    <>
      <div className="title-block">
        <div className="title">Biomes</div>
        <div className="summary">
          A full course for beginners to biomes, covering in detail every biome of earth according to the LONS08 specification. Includes the
          full video for each biome, in addition to the full script, for reference, along with key diagrams, exclusive hi-resolution maps
          and charts not found anywhere else, and sample coursework questions to test your understanding.
        </div>
        <div className="summary">
          It recommended that you study the course in order, beginning with "Introduction" and follow the link through on each page to take
          you to the next.
        </div>
        <div className="chapters-heading">Chapters</div>
      </div>
      <div className="chapters-list">
        <NavPanelThumb src={ImageIntro} to="/biomes/introduction" title="0" subtitle="Introduction" />
        <NavPanelThumb src={ImageTropicalForests} to="/biomes/tropical-forests" title="1" subtitle="Tropical Forests" />
        <NavPanelThumb src={ImageSavannah} to="/biomes/savannah" title="2" subtitle="Savannah" />
        <NavPanelThumb src={ImageShrublands} to="/biomes/shrublands" title="3" subtitle="Shrublands" />
        <NavPanelThumb src={ImageDeserts} to="/biomes/deserts" title="4" subtitle="Deserts" />
        <NavPanelThumb src={ImageGrasslands} to="/biomes/grasslands" title="5" subtitle="Grasslands" />
        <NavPanelThumb src={ImageTemperateForests} to="/biomes/temperate-forests" title="6" subtitle="Temperate Forests" />
        <NavPanelThumb src={ImageTaiga} to="/biomes/taiga" title="7" subtitle="Taiga" />
        <NavPanelThumb src={ImagePolarBiomes} to="/biomes/polar-biomes" title="8" subtitle="Polar Biomes" />
      </div>
      <Share />
    </>
  );
}

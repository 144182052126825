import React, { useEffect } from "react";
import NavPanelThumb from "../components/nav-panel-thumb";

export default function ClimateCasebook(props) {
  useEffect(() => {
    document.title = "The Climate Casebook - A Series of Articles on World Climate";
  }, []);

  const KoppenThumbPic = "Koppen-Tiles.gif";
  const PnwThumbPic = "PnwThumb.jpg";
  const AsianMonsoonThumbPic = "AsianMonsoonThumb.jpg";
  const PopulationThumbPic = "PopulationThumb.jpg";
  const SeasonsThumbPic = "SeasonsThumb.jpg";
  const LandAreasThumbPic = "LandAreasThumb.jpg";
  const HabitabilityThumbPic = "HabitabilityThumb.jpg";
  const LimaThumbPic = "LimaThumb.jpg";

  //const thisSeries = { name: "Climate Casebook", to: "/climate/casebook" };
  return (
    <>
      <div className="title-block">
        <div className="title">The Climate Casebook</div>
        <div className="summary">
          A series of individual topics on particular places around the world or more advanced concepts relating to climate
        </div>
        <div className="chapters-heading">Topics</div>
      </div>
      <div className="chapters-list">
        <NavPanelThumb src={KoppenThumbPic} to="/climate/koppen-classification" subtitle="Koppen Climate Classification" />
        <NavPanelThumb src={PnwThumbPic} to="/climate/pacific-northwest" subtitle="Pacific Northwest Climate" />
        <NavPanelThumb src={AsianMonsoonThumbPic} to="/climate/asian-monsoon" subtitle="Asian Monsoon" />
        <NavPanelThumb src={SeasonsThumbPic} to="/climate/seasons" subtitle="Climate &amp; Seasons" />
        <NavPanelThumb src={PopulationThumbPic} to="/climate/population" subtitle="Climate &amp; Population" />
        <NavPanelThumb src={LandAreasThumbPic} to="/climate/land-areas" subtitle="Climate Land Areas" />
        <NavPanelThumb src={HabitabilityThumbPic} to="/climate/habitability" subtitle="Climate &amp; Habitability" />
        <NavPanelThumb src={LimaThumbPic} to="/climate/lima" subtitle="Tropical Desert Metropolis" />
      </div>
    </>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function CC2AsianMonsoon(props) {
  
  const headerImage = "asian-monsoon-hero.jpg";
  const ImageArabiaJan = "asian-monsoon-arabia-january.jpg";
  const ImageArabiaJul = "asian-monsoon-arabia-july.jpg";
  const ImageAusJan = "asian-monsoon-australia-january.jpg";
  const ImageAusJul = "asian-monsoon-australia-july.jpg";
  const ImageBeijingHongKong = "asian-monsoon-beijing-hong-kong.jpg";
  const ImageCherrapunjiGraph = "asian-monsoon-cherrapunji-graph.jpg";
  const ImageConfusion = "Tropical-Monsoon-and-Savannah-Global-Distribution.jpg";
  const ImageDelhi = "asian-monsoon-delhi.jpg";
  const ImageEastGraphs = "asian-monsoon-east-asia-graphs.jpg";
  const ImageIndiaJan = "asian-monsoon-india-january.jpg";
  const ImageIndiaVsEast = "asian-monsoon-indian-vs-east-asian.jpg";
  const ImageIndiaPrecipApr = "asian-monsoon-india-precipitation-april.jpg";
  const ImageIndiaPrecipJul = "asian-monsoon-india-precipitation-july.jpg";
  const ImageIndiaPrecipJun = "asian-monsoon-india-precipitation-june.jpg";
  const ImageIndiaPrecipMay = "asian-monsoon-india-precipitation-may.jpg";
  const ImageIrkutsk = "asian-monsoon-irkutsk.jpg";
  const ImageItczJan = "asian-monsoon-itcz-january.jpg";
  const ImageItczJul = "asian-monsoon-itcz-july.jpg";
  const ImageJapanWinds = "asian-monsoon-japan-winds.jpg";
  const ImageKoppenZones = "asian-monsoon-koppen-zones.jpg";
  const ImageMonsoonLhasa = "asian-monsoon-lhasa.jpg";
  const ImageMawsCherr = "asian-monsoon-mawsynram-cherrapunji.jpg";
  const ImageMumbai = "asian-monsoon-mumbai.jpg";
  const ImageOceanCurrents = "asian-monsoon-ocean-current-change.jpg";
  const ImageOceansJan = "asian-monsoon-oceans-january.jpg";
  const ImageOceansJul = "asian-monsoon-oceans-july.jpg";
  const ImageOrographic = "asian-monsoon-orographic-lift.jpg";
  const ImagePrecipJan = "asian-monsoon-precipitation-january.jpg";
  const ImagePrecipJul = "asian-monsoon-precipitation-july.jpg";
  const ImagePressureJan = "asian-monsoon-pressure-january.jpg";
  const ImagePressureJul = "asian-monsoon-pressure-july.jpg";
  const ImageSapporoTokyo = "asian-monsoon-sapporo-tokyo.jpg";
  const ImageSeoul = "asian-monsoon-seoul.jpg";
  const ImageSiberianHigh = "asian-monsoon-siberian-high.jpg";
  const ImageTempsJan = "asian-monsoon-temps-january.jpg";
  const ImageWindsJan = "asian-monsoon-winds-january.jpg";
  const ImageWindsJul = "asian-monsoon-winds-july.jpg";

  const imagesWinds = [
    {
      src: ImageWindsJul,
      caption: "Winds in summer bring moist ocean winds and rain onto Asia ",
    },
    {
      src: ImageWindsJan,
      caption: "Winds in winter bring dry continental winds",
    },
  ];

  const imagesItcz = [
    {
      src: ImageItczJul,
      caption: "The Doldrums (ITCZ) in July bring low pressure and storms to Asia",
    },
    {
      src: ImageItczJan,
      caption: "The Doldrums (ITCZ) and its storms are in the southern hemisphere in January so conditions in Asia are generally dry",
    },
  ];

  const images3 = [
    {
      src: ImagePressureJul,
      caption: "In July, intense low pressure over the Tibetan plateau exists, further intensifying the pull of air off the Indian Ocean",
    },
    {
      src: ImageEastGraphs,
      caption: "The monsoon's incredible reach, as seen in graphs of northern inland cities",
    },
    {
      src: ImageConfusion,
      caption:
        "The Asian Monsoon should not be confused with the Koppen Climate Zone 'Tropical Monsoon', which occurs throughout the tropics",
    },
    {
      src: ImageKoppenZones,
      caption: "Monsoon type climate zones extend way beyond the tropics into subarctic regions",
    },
  ];

  const images4 = [
    {
      src: ImageTempsJan,
      caption: "Extremely cold temperatures over Siberia in winter lead to sinking air...",
    },
    {
      src: ImagePressureJan,
      caption: "... and very high pressure",
    },
  ];

  const imagesOceansAus = [
    {
      src: ImageAusJan,
      caption: "Low pressure over Australia...",
    },
    {
      src: ImageOceansJan,
      caption:
        "... and the Indian and Pacific Oceans in January act as counterpoles to Asian high pressure and intensifying winds blowing out of the continent",
    },
    {
      src: ImageAusJul,
      caption: "High pressure over Australia...",
    },
    {
      src: ImageOceansJul,
      caption: "... and the Indian and Pacific Oceans in July drive moist winds and heavy rain onto Asia",
    },
  ];

  const imagesPrecip = [
    {
      src: ImagePrecipJan,
      caption: "Average rainfall throughout the month of January is low...",
    },
    {
      src: ImagePrecipJul,
      caption: "... compared to the deluge in summer",
    },
  ];

  const imagesArabia = [
    {
      src: ImageArabiaJan,
      caption: "Wind directions in Arabia in both January...",
    },
    {
      src: ImageArabiaJul,
      caption: "... and July come off the land and so are dry, leading to desert",
    },
  ];

  const imagesIndia = [
    {
      src: ImageMumbai,
      caption: "In July, ocean storms lash the Western coast of India bringing torrential rain",
    },
    {
      src: ImageIndiaPrecipApr,
      caption: "The arrival of the Indian Monsoon begins in the south as early as April...",
    },
    {
      src: ImageIndiaPrecipMay,
      caption: "... and heads north into May...",
    },
    {
      src: ImageIndiaPrecipJun,
      caption: "... and further again in June...",
    },
    {
      src: ImageIndiaPrecipJul,
      caption: "... until the whole subcontinent is wet by July",
    },
    {
      src: ImageDelhi,
      caption: "Being further inland, Delhi receives significantly less rain than Mumbai",
    },
  ];

  const imagesMax = [
    {
      src: ImageOrographic,
      caption: "'Orographic Lift' is the mechanism by which rain is produced from the uplift of air over terrain",
    },
    {
      src: ImageMawsCherr,
      caption: "The two rainiest places on Earth are in India, just north of Bangladesh",
    },
    {
      src: ImageCherrapunjiGraph,
      caption: "The summer rainfall of Cherrapunji is just mindboggling... and the annual total dwarfs other notably wet world cities",
    },
  ];

  const images5 = [
    {
      src: ImageMonsoonLhasa,
      caption: "Lhasa, behind the highest peaks of the Himalayas, experiences significantly less rain than India",
    },
    {
      src: ImageIndiaJan,
      caption: "In winter, dry winds blow across India from the heart of the continent",
    },
    {
      src: ImageOceanCurrents,
      caption: "Such is the power of the monsoon, that changing wind directions changes the direction of ocean currents",
    },
  ];

  const images6 = [
    {
      src: ImageBeijingHongKong,
      caption:
        "Beijing, much further north than Hong Kong, is further from the ocean in terms of the summer monsoon winds, so sees much less rain",
    },
    {
      src: ImageSeoul,
      caption: "Seoul, South Korea, experiences a marked summer peak in rainfall as monsoon winds blow in from the south",
    },
  ];

  const imagesJapan = [
    {
      src: ImageJapanWinds,
      caption: "Because of the Sea of Japan, the seasonal reversal of monsoon winds bring rain in Summer and Snow in Winter",
    },
    {
      src: ImageSapporoTokyo,
      caption: "Sapporo receives deluges of snow in winter, while Tokyo, on the southern side of mountains, sees a summer rainfall peak",
    },
  ];

  const imagesSiberia = [
    {
      src: ImageSiberianHigh,
      caption: "The deep Siberian High Pressure zone is responsible for the entire Asian monsoon winter pattern",
    },
    {
      src: ImageIrkutsk,
      caption: "Even Irkutsk, in the subarctic of Siberia, feels the influence of summer monsoon rains",
    },
  ];

  return (
    <Chapter
      series="casebook"
      heading="The Asian Monsoon"
      subheading="THE WORLD'S LARGEST WEATHER SYSTEM"
      documentTitle="The Asian Monsoon - The World's Largest Weather System"
      headerImageSrc={headerImage}
      youTubeCode="1nstixjMS7A"
      chapterPrevText="Pacific Northwest Climate"
      chapterPrevUrl="/climate/pacific-northwest"
      chapterNextText="Seasons"
      chapterNextUrl="/climate/seasons"
    >
      <p>
        It’s the world’s largest weather system. Affecting almost half of the world’s population, its seasonal changes in winds and rain
        determine the cycles of nature and humankind, year after year. It is the story of the world’s largest continent, fighting with two
        oceans, played out in the atmosphere above. From the blistering heat of India, to the frigid north of Siberia, this is the Asian
        Monsoon.
      </p>
      <p>
        No discussion of Earth’s climate can go without some mention of the largest single pattern of weather on the globe. Because the
        Asian Monsoon covers so many individual climate zones, there was no room to give it a good treatment in any of the various chapters
        that covered Asia in my <A to="secrets">Secrets of World Climate series</A>. And so it gets an chapter of its own in my Climate
        Casebook.
      </p>
      <h3>Etymology</h3>
      <p>
        To begin our understanding of this enormous weather system, we should start at the beginning, and the word itself. Monsoon comes
        through Portuguese monção from the original Arabic word mawsim, which means “<A to="seasons">season</A> / change in wind direction”.
        You see, the Asian Monsoon even affects Arabia, even though that change in wind direction results in nothing yielding rain. A
        monsoon is, in its simplest form, always a change in wind direction, but in the case of the Asian Monsoon, this change in wind
        direction occurs around the whole of one side of the world’s largest continent, producing a wet summer and dry winter across a huge
        area of Earth.
      </p>
      <ChapterImageList images={imagesWinds} />
      <h3>Geographic Extent</h3>
      <p>
        Now those of you familiar with tropical climates will know something of wet and dry seasons, and how the trade winds of the tropics
        determine these. I covered this in my chapter on{" "}
        <A to="/climate/tropical-monsoon-and-tropical-savannah">Tropical Wet and Dry Climates</A> in my other series. They always occur
        within the tropics of Cancer and Capricorn, from 23°N to 23°C south. So wet and dry seasons related to changing wind direction are
        not unique to Asia. What makes the Asian Monsoon stand out are two things - the great intensity of the wet season in the Indian
        Subcontinent and the extension of the wet and dry season pattern in Eastern Asia way beyond the tropics into the temperate,
        continental and subarctic latitudes.
      </p>
      <p>So the Asian Monsoon has these two notable aspects, and each defines the two broad halves of the monsoon.</p>
      <p>
        The intense monsoon that affects India and Bangladesh is the most well known, and it determines the lives of the one and a half
        billion people that live there, more heavily than any other weather system in the world. The economic fortunes, even the survival
        itself, of these populations depends upon the timing and severity of the wet season that washes over these lands between June and
        September.
      </p>
      <ChapterImage right src={ImageIndiaVsEast} caption="The two broad subdivisions of the Asian Monsoon" />
      <p>
        Less well known is the East Asian Monsoon. Though less intense, its range is much more extensive, and as many people experience its
        annual rhythm, with Eastern Russia, Mongolia, Korea, Japan, Taiwan and most of China affected.
      </p>
      <h3>Dynamics - The Annual Movement of the ITCZ</h3>
      <p>
        Before we look at these areas in more detail, we should try to understand what dynamics are special to the Asian Monsoon. This
        weather system and its causes are complex and even to this day are not fully understood. However it is generally accepted that the
        two strongest influences are the seasonal march of the doldrums above and below the equator that affects all tropical wet and dry
        climates, magnified by the seasonal heating and cooling of the giant Asian landmass.
      </p>
      <p>
        The doldrums, also called the Intertropical Convergence Zone, or ITCZ, is the band of low pressure around the tropics where heating
        from the sun causes upward convection of hot air leading to a drawing in of air from the surrounding land or sea in the form of
        trade winds. As the global seasons alternate summer between the northern and southern hemispheres, the ITCZ follows, with it
        tracking north in the northern summer, and then south in the southern summer. This band is almost always accompanied by heavy
        thunderstorms and consequential rainfall as the hot and moist air is unstably thrust into the cold upper atmosphere.
      </p>
      <ChapterImageList images={imagesItcz} />
      <h3>Summer Monsoon</h3>
      <p>
        In the northern summer the ITCZ moves up into India and China, bringing with it southerly trade winds which blow onto the continent
        from the surrounding seas. This is what brings the rain with the monsoon, as it does with any tropical wet season. But this effect
        is strengthened further by the presence of the large Tibetan plateau, the heating of which leads to a deepening of the low pressure,
        strengthening the monsoon over India far beyond a standard tropical wet season.
      </p>
      <p>
        Additional summer heating of the central Asian land mass north of Tibet, far from the moderating ocean, is also strong, and extends
        as far as Siberia, leading to winds blowing in from the Pacific across all of Eastern Asia from Hong Kong to the Russian Pacific
        coast. This is the only part of the world where summer peaks in rainfall occur in the mid to high latitudes and special climate
        zones, the <A to="continental">Continental</A> and <A to="subarctic">Subarctic</A> Monsoon types, were designated by Vladimir Koppen
        to account for this extension into the far north of what is essentially a tropical weather system.
      </p>
      <ChapterImageList images={images3} />
      <h3>Koppen Climate Zones, Winter Monsoon, Effect of Australia and Ocean Pressure Zones</h3>
      <p>
        While we’re on the subject of Koppen, we shouldn’t confuse the Koppen Climate type Am – the Tropical Monsoon, with the Asian
        Monsoon, as this zone can occur all over the tropics, from Miami and Rio in the Americas, to Central Africa, Indonesia and the
        Philippines. The Asian Monsoon encompasses a multitude of Koppen Climate zones, all of which have peak rainfall during the summer.
        These are Am – Tropical Monsoon, Aw - Tropical Savannah, Cwa – Subtropical Monsoon, Cwb - Subtropical Highland, Dwa/Dwb –
        Continental Monsoon, and Dwc/Dwd – Subarctic Monsoon.
      </p>
      <p>
        So that is summer. But what about winter? Well, the opposite occurs. The ITCZ retreats back to below the equator while temperatures
        over Central Asia plummet below zero, leading to sinking, dense air and high pressure. This high pressure is the strongest on Earth
        and the continental winds that blow out from it to the oceans lead to dry winters across most of Asia. Only a few places, where such
        seasonal monsoon winds blow over water in both directions, receive rain or snow year round.
      </p>
      <ChapterImageList images={images4} />
      <p>
        So this, in essence, is the main mechanism driving the Asian Monsoon. There is more complexity to it of course. Australia is
        believed to have a strong influence on Asia, even though it is much smaller, because it acts as a pressure “counter-pole” – with
        Australian high pressure in the northern summer against the Asian low, and vice versa six months later. The Indian and Western
        Pacific oceans are the other “counter-poles” that either provide moist winds in the form of high pressure, or sink dry continental
        winds in the form of low pressure.
      </p>
      <ChapterImageList images={imagesOceansAus} />
      <p>
        The monsoon’s growth in summer and retreat in winter can be seen in this composite of monthly average rainfall across the continent.
        It’s quite a mesmerizing animation, isn’t it? I could watch it for hours… Ok, moving on. Let’s now journey through the lands
        affected by the monsoon and examine how local topography and other factors leads to particular effects from one region to another.
      </p>
      <ChapterImageList images={imagesPrecip} />
      <h3>Arabia</h3>
      <p>
        The journey starts in the Arabian peninsula, with the origin of the word itself, but it’s also the westernmost point under the
        direct monsoon influence. In summer, winds blow towards the intense low pressure over Central Asia, but because these winds have
        travelled over the continent of Africa, they bring little to no rain. In winter, the winds switch direction, blowing out from the
        central Asian high, and once again are dry, continental winds. This is one reason why the Arabian peninsula is a desert.
      </p>
      <ChapterImageList images={imagesArabia} />
      <h3>Indian Subcontinent</h3>
      <p>
        Moving east into India, and the same wind directions, SW in summer, NE in winter, produces a dramatically different result. Now the
        SW summer winds blow over the warm Arabian Sea throughout the summer months, bringing in storm after storm that lashes the western
        coast of India from Kerala in the south to Gujarat in the north. All along this coast sits one edge of the Deccan plateau, and these
        moist winds are pushed upward and cooled, releasing their moisture in spectacular fashion as this graph of Mumbai shows.
      </p>

      <p>
        The monsoon progresses across the Indian Subcontinent in stages, with the south receiving rains as early as late May, and the north
        as late as mid-July. Protected somewhat by the Deccan, much of the country experiences less severe monsoon rain. But where these
        strong, moisture-laden winds hit the Himalayas, they are thrust upward in a similar fashion to the west coast and dump almost all
        their moisture on the windward slopes. And with this mechanism, known as orographic lift, we come to a global superlative. The
        Indian Monsoon produces the wettest places in the world.
      </p>
      <ChapterImageList images={imagesIndia} />
      <p>
        Mawsynram and Cherrapunji, both in the Indian state of Meghalaya lie in the foothills of the Eastern Himalayas, and the world’s
        wettest title alternates from year to year between these two towns. Here’s the graph of Cherrapunji, with the scale kept at the
        standard used for all my charts, just so you can get your head around how much rain falls here. In one month alone at the peak of
        the summer monsoon, more rain falls here than in an entire year in Singapore, in the heart of the wet Tropical Rainforest, three
        times more rain than the annual total in rainy Vancouver, and six times more than the annual amount in supposedly rainy London. Such
        is the power of the monsoon.
      </p>
      <ChapterImageList images={imagesMax} />
      <p>
        This graph of Lhasa in Tibet shows the effect of the Himalayas. Being sheltered from the full brunt of the monsoon behind the
        highest peaks of those mountains, including Everest, comparatively little rain gets through, although the mark of the monsoon is
        still clearly visible in the difference between winter and summer.
      </p>
      <p>
        In the Indian winter, the dry NE wind blows from Central Asia out into the Indian Ocean, leading to completely dry conditions for
        months at a time, first with cool temperatures, and then very hot temperatures, which leads to the unique three seasons pattern of
        much of that country. A hot and wet rainy season, warm and dry “winter”, then very hot and dry “summer”.
      </p>
      <ChapterImageList images={images5} />
      <p>
        Such is the strength and consistency of the seasonal monsoon winds, that they are even able to change the direction of an ocean
        current. A current’s direction is primarily determined by the winds blowing over it, so in summer, the around India flows east from
        Arabia to Myanmar, and in winter it flows west. So the name of this current alternates between the SW Monsoon Drift and the NE
        Monsoon Drift. This is the only major ocean current where such a reversal occurs every year. Such is the power of the monsoon.
      </p>
      <h3>China &amp; Korea</h3>
      <p>
        Continuing further east, and we pass over South-East Asia. This area does feel the influence of the monsoon, but is within the
        normal latitudes where tropical wet and dry seasons occur, with Koppen types Am and Aw predominating, so does not require special
        mention in this article.
      </p>
      <p>
        As we continue north and east, however, we enter Southern China, and the beginning of the Eastern Asian Monsoon. The interplay
        between continent and ocean now switches to the Pacific, and so we get a different character to that of the Indian – less intense,
        but spread out over a much greater area. The monsoon pushes inland hundreds of miles into the heart of China, subtropical in the
        south, continental in the north, and as one travels further north, the amount of rain, and the number of months in which it falls
        reduces, as the Pacific moisture is lost over the land carried by southerly winds.
      </p>
      <ChapterImageList images={images6} />
      <p>
        Korea, like Northern China experiences the monsoon as a continental climate, with dry and cold winters blowing in from the Siberian
        High. But, being on the coast, the monsoon brings very wet summers, as this graph of Seoul shows.
      </p>
      <h3>Japan</h3>
      <p>
        Because Japan is an island archipelago, the change in wind direction produces rain or snow across all seasons, blowing in from the
        Pacific in summer and in winter, across the Sea of Japan that lies between this country and Russia. So one gets a more even
        distribution of precipitation across the year throughout the islands, although along this mountainous set of islands, the main
        population centres along the east and southern coasts experience summer rainfall peaks, while the north and west facing coasts get
        noticeable winter peaks, usually in the form of snow. In fact Sapporo, the largest city on the northern island of Hokkaido, is the
        snowiest city in the world, thanks to the winter monsoon winds.
      </p>
      <ChapterImageList images={imagesJapan} />
      <p>
        Typhoons in East Asia are another distinction between this part of the monsoon and that of the Indian part. These often ferocious
        storms account for a considerable proportion of total rainfall in the season as they lash cities from Taiwan and Hong Kong in the
        South to Japan in the north.
      </p>
      <h3>Siberia &amp; Conclusion</h3>
      <p>
        The East Asian Monsoon’s influence reaches its end in the far north of Eastern Siberia, where the influence of the winter Siberian
        High leads to very dry winters. In summer, southerly monsoon winds still penetrate this vast area, bringing rain, but by this stage,
        are weakened considerably and the amounts are little compared to those further south. Still it is a remarkable fact that a singly
        connected weather system can affect such a large area, across so many lines of latitude, from 20°N in India, through to 70°N in
        Siberia. Such is the power of the monsoon.
      </p>
      <ChapterImageList images={imagesSiberia} />
      <p>
        The Asian Monsoon varies in intensity from one year to another, affecting both India and China, so these average rainfall graphs, as
        always, can be deceptive in hiding the true pattern of annual variability. Too little rain, and effective drought can occur, since
        the rest of the year in many places is very dry already. Too much rain, and flooding occurs across large areas, in addition to
        landslides along the southern and eastern edges of the Himalayas. Too little and too much rain can lead to many thousands of deaths
        in this way. The monsoon is a taker as well as a bringer of life.
      </p>
    </Chapter>
  );
}

import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc3SubtropicalHighlands(props) {
  const headerImage = "subtropical-highlands-hero.jpg";
  const ComparisonImage1 = "subtropical-highlands-cfb-vs-af.jpg";
  const ComparisonImage2 = "subtropical-highlands-cwb-vs-aw.jpg";
  const TempVsAltImage = "subtropical-highlands-temperature-vs-altitude.jpg";
  const GlobalDistributionMap = "Subtropical-Highlands-Global-Distribution.jpg";
  const UnusualPlantsImage = "subtropical-highlands-unusual-plants.jpg";
  const MachuPicchuImage = "subtropical-highlands-machu-picchu.jpg";
  const ClimateGraphs = "climate-graphs-subtropical-highlands.gif";

  const imagesClimateComparison = [
    {
      src: ComparisonImage1,
      caption: "Subtropical Highland climate with no dry season (Cfb) is simply a high-altitude form of Tropical Rainforest (Af)",
    },
    {
      src: ComparisonImage2,
      caption: "Dry Winter Subtropical Highland climate (Cwb) is simply a high-altitude form of Tropical Savannah (Aw)",
    },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="3"
      heading="Subtropical Highlands"
      subheading="KOPPEN CODES: Cfb &amp; Cwb"
      documentTitle="The Subtropical Highland Climate - Koppen Climate Classification: Cfb Cwb Cwc"
      headerImageSrc={headerImage}
      youTubeCode="3vzP0kWm-AQ"
      chapterPrevText="Tropical Monsoon &amp; Savannah"
      chapterPrevUrl="/climate/tropical-monsoon-and-tropical-savannah"
      chapterNextText="Hot Deserts"
      chapterNextUrl="/climate/hot-deserts"
    >
      <p>
        There are places on this planet, where altitude and latitude combine to form a harmonious, temperate balance. High in the clouds of
        the tropics, there is an escape from the humidity and heat of the forests and plains below. These mountains, valleys and plateaux
        are places of flourishing cities, breath-taking beauty and the setting of unique cultures and history. Defined by their special
        climate, these are the world’s Subtropical Highlands.
      </p>
      <h3>Description and Dynamics</h3>
      <p>
        So far in this series, we’ve been looking at the hot and humid climates of the tropics – the Rainforest, the Monsoon and the
        Savannah. Before we leave the tropics and head to greater latitudes, however, there is one final type of climate here that we cannot
        overlook, and that is the one in the highland regions near the equator.
      </p>
      <ChapterImage right src={TempVsAltImage} caption="The relation between temperature and altitude" />
      <p>
        As one increases in altitude, the temperature of the air decreases – this is evident to anyone who looks up at a high mountain and
        sees snow on its summit. But in the tropics, where the temperature at sea level is high, there is a wide band of altitudes that
        produce mild and pleasant temperatures all year round.
      </p>
      <p>
        This year-round mildness is unique among all world climates, and has made these regions attractive for human settlement throughout
        history, offering increased comfort, and avoidance of tropical diseases, such as malaria.
      </p>
      <p>There are two variations within the Koppen Climate Classification, distinguished by rainfall patterns.</p>
      <p>
        The first, <em>Cwb</em>, is the most common, and this is basically a high-altitude cooler version of the{" "}
        <A to="tropical-monsoon-and-tropical-savannah">Tropical Monsoon and Savannah</A> climates that we covered in the last chapter. It is
        characterised by a distinct wet and dry season.
      </p>
      <p>
        The other, <em>Cfb</em>, is, like the first, a cooler high altitude equivalent of a lowland tropical climate, in this case, the{" "}
        <A to="tropical-rainforest">Tropical Rainforest</A>. With Cfb we find no dry season, and rain all year round. It is a quirk of
        geography, we find this climate very similar to that of the Oceanic climate of Britain and North-West Europe, in that it is cool and
        moderate, with rain all year round. More, much more about that one in a <A to="oceanic">future chapter</A>.
      </p>
      <ChapterImageList images={imagesClimateComparison} />
      <h3>Where in the world do we find the Subtropical Highland Climate Zones?</h3>
      <ChapterImage
        left
        src={MachuPicchuImage}
        caption="The Inca 'Lost City' of Machu Picchu in Peru, is found within a Subtropical Highland climate"
      />
      <p>
        So where in the world do we find the Sub-Tropical Highlands? In terms of latitude they between the Tropics of Cancer and Capricorn –
        23 degrees north and south of the equator. And these areas are high up, usually between 1500 and 4000m (5,000 to 13,000 feet).
      </p>
      <p>
        Starting in the Americas, we find the densely populated central valley of Mexico, home to that country’s capital and mega city of
        the same name, and once home to the Aztec Empire. Onto South America, and Colombia and Ecuador are the two countries that are
        dominated by the rarer Cfb variant of the Subtropical Highland climate that gives year-round rain. Almost all of the major cities in
        these countries are packed into the high valleys of the Andes that run through this area, including the capitals of Bogota and
        Quito. Heading further south, we encounter the highlands of Peru and Bolivia, once the home of the Inca Empire and now home to the
        highest capital city in the world – La Paz. Last in this continent is a highland area in a small part of Southern Brazil around the
        city of Curitiba.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global Distribution of Subtropical Highland Climate Zones" />
      <p>
        Moving across now to Africa, and almost all the lands within this climate zone follow a line north to south along the huge Rift
        Valley that dominates the eastern part of this continent. Starting in the north and we find the heart of Ethiopia and its capital
        Addis Ababa. As we head south, we encounter the high plains of Kenya and its capital Nairobi. Parts of Uganda, Congo, Burundi,
        Rwanda and Tanzania intersect this climate where the lands rise up high enough. Much of Malawi, Zambia and Zimbabwe are high enough
        to experience this mild climate, including all these countries’ capital cities. At the southern tip of the continent, we find the
        north eastern heart of South Africa coming within this climate zone, including that nation’s capital of Pretoria, and its largest
        city, Johannesburg. And if all that wasn’t enough we find another capital, that of the island of Madagascar, also living within this
        mild climate.
      </p>
      <p>
        The last continent to experience the Subtropical Highland climate is Asia, where, in the southern foothills of the Himalayan
        mountains, altitudes are in the sweet spot to provide an all year round spring to Kashmir in northern Pakistan, the northern
        extremes of India, much of Nepal, including its capital Kathmandu, and a large swathe of Yunnan Province in south-west China. A
        razor-thin part of New Guinea, almost on the equator, but with altitudes in excess of 4,000m, also experiences year-round mild
        temperatures.
      </p>
      <ChapterImage
        left
        src={UnusualPlantsImage}
        caption="Plants in the highlands around Mount Kenya (Image courtesy of Milosh Kitchovich)"
      />
      <h3>Landscapes, Biomes and Agriculture</h3>
      <p>
        So what kind of landscapes and agriculture do we find in the subtropical highlands? Well, that depends upon altitude, and how much
        rainfall we see.
      </p>
      <p>
        In uncultivated land, the lower reaches are covered in either thick forests, such as the well-known “cloud-forests” of the Andes
        that are extensions of the Tropical Rainforest, or grasslands that are merely extensions of the Savannah, most notably in Africa.
        Higher up we find unusual plants specifically designed to withstand the higher altitudes, and are unique to this climate.
      </p>
      <p>
        When it comes to land cultivated by humans, we find spectacular landscapes carved out of the hillsides - terraced farming in
        abundance, rice in Asia in the present day, and corn in the once-Inca Empire of South America.
      </p>
      <p>
        And the most remarkable fact I’ve saved until last. Because the Subtropical Highland climate has two very special plants. One
        produces a leaf, another a bean. Each of these makes up a drink, and between them they drive our working world. They are probably
        the most widely drunk beverages throughout the globe. I’m talking, of course, about tea and coffee, and they’re uniquely suited to
        this climate, not growing easily anywhere else.
      </p>
      <ChapterImage right src={ClimateGraphs} caption="Notable cities with Subtropical Highland Climates" />
      <h3>Notable Cities</h3>
      <p>
        Being an escape from the tropical heat of the equatorial regions, it's no suprise that we find many large and well-known cities
        here, including numerous national capitals. The adjacent image shows the most notable of these with their climate graphs.
      </p>
      <h3>Coursework Questions</h3>
      <ol>
        <li>What happens to air temperature when one rises in altitude? What effect would this have on a tropical climate?</li>
        <li>What are the Koppen codes for Subtropical Highland climates? How do they differ from each other?</li>
        <li>What types of natural landscapes do we find in regions with Subtropical Highland climates?</li>
        <li>What are the two world-famous crops that grow well only in this climate?</li>
        <li>List out some countries, regions and cities that experience Subtropical Highland climates.</li>
      </ol>
    </Chapter>
  );
}

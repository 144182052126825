import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";
import A from "../components/A";

export default function Sowc2TropicalMonsoonSavannah(props) {
  const headerImage = "tropical-monsoon-savannah-hero.jpg";
  const comparisonImage = "AmAw-comparison-of-monsoon-to-savannah.jpg";
  const tradeWindsImage1 = "AmAw-itcz-july.jpg";
  const tradeWindsImage2 = "AmAw-itcz-january.jpg";
  const tradeWindsImage3 = "AmAw-trade-winds-wet-season.jpg";
  const tradeWindsImage4 = "AmAw-trade-winds-dry-season.jpg";
  const landscapeImage1 = "AmAw-savannah-landscape.jpg";
  const landscapeImage2 = "AmAw-rice-paddies-landscape.jpg";
  const landscapeImage3 = "AmAw-herd-of-zebra-in-savannah.jpg";
  const GlobalDistributionMap = "Tropical-Monsoon-and-Savannah-Global-Distribution.jpg";
  const ClimateGraphs = "climate-graphs-tropical-savannah-monsoon.gif";

  const tradeWindsImages1 = [
    { src: tradeWindsImage1, caption: "Approximate position of Doldrums (ITCZ) in July" },
    { src: tradeWindsImage2, caption: "Approximate position of Doldrums (ITCZ) in January" },
  ];

  const tradeWindsImages2 = [
    { src: tradeWindsImage3, caption: "Trade winds in summer come from the ocean and bring rain" },
    { src: tradeWindsImage4, caption: "Trade winds in winter come from the land and are dry" },
  ];

  const landscapeImages = [
    { src: landscapeImage1, caption: "Mixed trees and grasslands landscape that is the definition of savannah" },
    { src: landscapeImage2, caption: "Rice paddies landscape in Asia" },
    { src: landscapeImage3, caption: "A herd of zebra on the African savannah" },
  ];

  return (
    <Chapter
      series="secrets"
      seriesChapter="2"
      heading="Tropical Monsoon &amp; Savannah"
      subheading="KOPPEN CODES: Am &amp; Aw"
      documentTitle="The Tropical Monsoon and Tropical Savannah Climates - Koppen Climate Classification: Am Aw"
      headerImageSrc={headerImage}
      youTubeCode="tqpA9tswQxM"
      chapterPrevText="Tropical Rainforest"
      chapterPrevUrl="/climate/tropical-rainforest"
      chapterNextText="Subtropical Highlands"
      chapterNextUrl="/climate/subtropical-highlands"
    >
      <p>
        For so many of us in the developed world, the seasons are defined by temperature. The freshness of spring, the heat of summer, the
        chill of autumn and the cold of winter. But for the billions of people on our planet that live in the tropics, the seasons are
        marked by the presence, and absence, of rain. The annual coming of the rains after months of drought, with the renewal of life from
        the giving water… And then, after months of rain and cloud, the sun reborn into blue skies as the next dry season begins.
      </p>
      <p>This is the domain of the tropical wet and dry climates. This is the Tropical Monsoon, and the Tropical Savannah. </p>
      <h3>Description and Dynamics</h3>
      <ChapterImage
        right
        src={comparisonImage}
        caption="Monsoon areas are
        overall wetter or have a shorter dry season, Savannahs are drier overall or have a longer dry season."
      />
      <p>
        In the last chapter we looked at the <A to="tropical-rainforest">Tropical Rainforest climate</A> zone, where rain occurs throughout
        the year. But most of the lands in the tropics experience some kind of drought, or reduction in rainfall during the year. The
        severity of this drought and the total amount of rain that falls in a year determines whether it comes under the classification of
        Monsoon or Savannah. Monsoon areas are overall wetter or have a shorter dry season, Savannahs are drier overall or have a longer dry
        season.
      </p>
      <p>
        So what accounts for this variation in rain? In the tropics, the sun will be directly above you at noon at some point in the year.
        And if the sun is directly above, it means it’s heating that part of the earth more than any other, and that means rising air, the
        pulling in of moist air from the surrounding seas and oceans, and lots of rain. The band of the earth, where these winds come
        together has for centuries been called The Doldrums by sailors, because there is very little wind at this confluence, and could lead
        to weeks or even months of being stranded in a sailing ship. The rains follow this band as it tracks the sun above and below the
        equator throughout the year. In July, it’s summer in the North, so this band is located to the north of the equator, while in
        January it is at its furthest extent south of the equator. A more scientific term for the Doldrums is the Inter Tropical Convergence
        Zone ("ITCZ").
      </p>
      <ChapterImageList images={tradeWindsImages1} />
      <p>
        The winds which come into this convergence are called the <em>trade winds</em>, named by those same sailors of centuries past, since
        they were so predictable, sailing ships could count on them to ply their trade. In the tropical wet and dry climate zones, these
        winds switch direction depending on whether the doldrums are to the north or south. When they originate from the ocean, they bring
        moisture, and rain. But when they come from the land, they are bone dry, and bring nothing to the land.
      </p>
      <ChapterImageList images={tradeWindsImages2} />
      <h3>Landscapes, Biomes and Agriculture</h3>
      <p>
        So what kind of landscapes do we find in these regions? Depending on the severity or longevity of the dry season, we can find
        anything from <A to="/biomes/tropical-forests">tropical forests</A> to open grasslands. On the wetter side of this spectrum, we find
        the much of the jungle of the Amazon and South-East Asia. But such forests do not have as rich a biodiversity as the Tropical
        Rainforest. As the rainfall reduces the further out we go, we find less dense, drier forests mixed with grasses,{" "}
        <A to="/biomes/savannah">the Savannah itself</A>.
      </p>
      <ChapterImageList images={landscapeImages} />
      <p>
        The tropical grasslands of Africa are perhaps some of the most iconic landscapes in the world. Here, the biodiversity of the
        Tropical Rainforest is exchanged for huge numbers of large herbivorous animals that trek for hundreds of miles each year to follow
        the rains and the green grass that feeds their vast numbers. And they themselves are followed by world famous predator species, who
        don’t want to let all that walking food go to waste.
      </p>
      <p>
        Much of South-East Asia and India is given over to the production of rice, which excels in this climate, producing two or more
        harvests each year. Such productivity is one reason why Asian nations have such large populations compared to other parts of the
        world.
      </p>
      <p>
        In Central America, the Caribbean and Brazil, sugar cane is the most famous of world crops that has dominated these areas for
        centuries. While in West Africa, we find much of the land given over to cocoa production. So… one could say we can thank the
        Savannah climate for that most favourite of foods – chocolate.
      </p>
      <ChapterImage right src={GlobalDistributionMap} caption="Global Distribution of the Tropical Wet &amp; Dry Climate Zones" />
      <h3>Where in the world do we find the Tropical Monsoon and Tropical Savannah Climate Zones?</h3>
      <p>
        So where in the world do we find the tropical wet and dry seasons? In the tropics, obviously, that is, between 23 degrees north and
        south of the equator.
      </p>
      <p>
        Starting in the Americas, this zone encompasses the southern tip of Florida, much of southern Mexico, and most of Central America
        and the Caribbean. Large tracts of Venezuela, Brazil and lowland Bolivia are given over to savannah and tropical forest, and this
        climate zone accounts for almost half of all land area in South America, such is its extent.
      </p>
      <p>
        In Africa, we find practically all of the West and Central African nations under the Savannah, along with Tanzania, Mozambique and
        Madagascar in the south east.
      </p>
      <p>
        <A to="asian-monsoon">In Asia</A>, most of southern India, Bangladesh and Sri Lanka is in this climate zone. The famous monsoon
        western coast of India experiences the most extreme wet and dry seasonal variation in the world, as you’ll see in the upcoming city
        graphs.
      </p>
      <p>All of Burma, Thailand, Laos and Cambodia live within these zones, along with most of Vietnam and the Philippines. </p>
      <p>Lastly, the northern coasts of Australia experience the monsoon at the opposite time of year to its more famous Indian brother.</p>
      <ChapterImage right src={ClimateGraphs} caption="Climate graphs of notable cities experiencing tropical wet &amp; dry climates" />
      <h3>Notable Cities</h3>
      <p>
        So we talked about all those billions of people living in this climate zone. Well, most of these live in developing countries, and
        yet, we find enormous cities here. Some of these are world famous. Here are the biggest and brightest.
      </p>

      <h3>Coursework Questions</h3>
      <ol>
        <li>What causes the Doldrums (or ITCZ) and why do they move across the tropics throughout the year?</li>
        <li>What are trade winds and what is their relationship to tropical wet and dry seasons?</li>
        <li>What are the Koppen codes for these climates? How do they differ from each other?</li>
        <li>What types of natural landscapes do we find in regions with tropical wet and dry climates? What exactly is savannah?</li>
        <li>How important are these regions for agriculture? List out some crops that grow here.</li>
        <li>List out some countries, regions and cities that experience tropical wet and dry climates.</li>
      </ol>
    </Chapter>
  );
}

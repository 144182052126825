import React from "react";
import Chapter from "../components/chapter";
import ChapterImageList from "../components/chapter-image-list";
import ChapterImage from "../components/chapter-image";

import A from "../components/A";

export default function CC1PnwClimate(props) {
  const headerImage = "pnw-hero.jpg";
  const ImageCitiesLocation = "pnw-cities-location.jpg";
  const ImageKoppenCodes = "pnw-koppen-codes.jpg";
  const ImageKoppenMap = "pnw-koppen-map.jpg";
  const ImageSunshineHours = "pnw-sunshine-hours.jpg";
  const ImageWeatherWinter = "pnw-weather-winter.jpg";
  const ImageWeatherSummer1 = "pnw-weather-summer-1.jpg";
  const ImageWeatherSummer2 = "pnw-weather-summer-2.jpg";
  const ImageCsMap = "Mediterranean-Climate-Global-Distribution.jpg";
  const ClimateGraphs = "pnw-climate-graphs.jpg";

  const imagesWeather = [
    {
      src: ImageWeatherWinter,
      caption: "Winters see regular storms moving in off the Pacific Ocean bringing plenty of rain",
    },
    {
      src: ImageWeatherSummer1,
      caption: "In the summer, the Rockies prevent Humid Subtropical air from the east moving into the whole of the Western United States",
    },
    {
      src: ImageWeatherSummer2,
      caption: "High pressure dominates, bringing fine weather for most of the summer",
    },
  ];

  const imagesData = [
    {
      src: ClimateGraphs,
      caption:
        "Climate Graphs of Pacific Northwest cities in comparison to classic Oceanic London and Hot Summer Mediterranean Los Angeles",
    },
    {
      src: ImageSunshineHours,
      caption: "Comparison of sunshine hours",
    },
  ];

  const imagesKoppen = [
    {
      src: ImageKoppenCodes,
      caption: "The Pacific Northwest Climate is a hybrid of Oceanic and Hot Summer Mediterranean climates",
    },
    {
      src: ImageKoppenMap,
      caption: "A detailed map of Koppen zones in the region, courtesy of Adam Peterson",
    },
    {
      src: ImageCsMap,
      caption: "Mediterranean climate zones across the world include the Pacific Northwest",
    },
  ];
  return (
    <Chapter
      series="casebook"
      heading="The Pacific Northwest Climate"
      subheading="OCEANIC OR MEDITERRANEAN?"
      documentTitle="The Pacific Northwest Climate - Mediterranean or Oceanic?"
      headerImageSrc={headerImage}
      youTubeCode="QeGKlY8XFP0"
      chapterPrevText="Koppen Climate Classification"
      chapterPrevUrl="/climate/koppen-classification"
      chapterNextText="Asian Monsoon"
      chapterNextUrl="/climate/asian-monsoon"
    >
      <p>
        The Pacific North West of the United States and Canada. A wonderland of nature. Epic scenery – breath-taking mountains, endless
        forests, windswept beaches, even volcanoes. And amazing cities that have influenced the wider world far beyond their isolated patch
        along the wild Pacific coast. And… it is somewhat well known for its weather too. Dominated by wet winter westerlies blowing in from
        the Pacific each year, its exposed coasts feature some of the highest rainfall in the world, and sport temperate rainforests.
      </p>
      <ChapterImage right src={ImageCitiesLocation} caption="Location of the principal cities of the Pacific Northwest" />
      <p>
        So when I briefly touched on this region in my <A to="secrets">Secrets of World Climate Series</A> in the{" "}
        <A to="mediterranean">Mediterranean chapter</A>, it created some controversy. “No way that’s Mediterranean! It’s Oceanic,{" "}
        <A to="oceanic">Maritime West Coast</A>, like Britain and Ireland!” And, like so many others, I have to confess, I had thought the
        same before I did my research into that series. So I thought I should devote an article to the Curious Case of The Pacific Northwest
        Climate, in this, the first of the cases, in my Climate Casebook.
      </p>
      <p>
        So the wet winters of the Pacific NW are pretty well known. It’s also fairly well known that Vancouver has the mildest of all
        winters out of all the Canadian cities. But much fewer people know about the summers in this area.
      </p>
      <p>
        Having lived, myself, for many years in the Mediterranean climate of Southern California and South Australia, and the Oceanic
        climate of Britain, I have a very strong understanding of these two climate zones borne of direct experience backed by my own
        research. Yes, it’s undoubtedly obvious to anyone who knows a little of geography, that those Mediterranean areas are significantly
        warmer than Britain, both in winter and summer. That’s one distinction. The other, still fairly well known distinction is that those
        Mediterranean areas have dry summers. For most of the years I lived there, I could remember most summers where it did not rain for
        4, 5 or even 6 months at all. To go without rain for even one month in Britain at any time of year is unusual, which was what made
        the British summer of 2018, where it was sunny every day and without rain for two months, all the more remarkable.
      </p>
      <p>
        Britain is famous for its rain, very much so, in fact. And because Seattle, Portland and Vancouver are too, and because they lie at
        a similar latitude facing west to an ocean, the assumption is that they must have the same climate. An assumption I also once made.
      </p>
      <p>
        But the truth is that the Pacific NW climate is actually a hybrid of these two climates I have lived under. It has very similar
        winter and summer temperatures to England, but it has the wet winter and dry summer patterns of California. Oceanic temperatures
        meets Mediterranean rainfall patterns.
      </p>
      <p>
        Compared to the dodgy summers of Britain, Pacific NW summers are consistently glorious affairs. Low rainfall and high sunshine hours
        are the norm.
      </p>
      <p>
        Let’s start with temperature and rainfall by comparing the three main cities of the Pacific Northwest with those of Los Angeles and
        London. As can be seen, the Pacific NW has very similar temperatures to London, with Portland in the south being slightly warmer,
        and Vancouver in the north being slightly cooler. LA is hotter than all the others year round – no surprises there.
      </p>
      <p>
        But when it comes to rainfall, here is where the eyebrows start to raise. All three Pacific Northwest cities experience less rain
        than London during the summer, but much heavier rain during the winter. London’s rain is consistent throughout the year, and
        incidentally is drier overall. London has the classic Oceanic year-round rain pattern, whereas the Pacific Northwest has distinctly
        wetter winters and drier summers, consistent with a Mediterranean climate like Los Angeles, albeit wetter.
      </p>
      <ChapterImageList images={imagesData} />
      <p>
        Equally telling is this graph comparing these same cities’ monthly sunshine hours. All three Pacific Northwest cities experience
        similarly low sunshine hours to London in winter, but in summer, clearer skies result in sunshine hours that are closer to that of
        Los Angeles, which is well known for being one of the sunniest cities in the world. In general we can see that there is a dramatic
        seasonal variation in sunshine in the Pacific Northwest, compared to the consistently sunny LA, and sadly rather dreary London.
      </p>
      <p>
        This climate type was actually known to Vladimir Koppen, and he designated this type with the letters Csb - C for mild temperatures,
        s for dry summer, b for warm summer. It shares two letters with Los Angeles Csa, and two letters with London’s Cfb, and so, it
        really is a hybrid of the two.
      </p>
      <ChapterImageList images={imagesKoppen} />
      <p>
        That’s not to say the whole region comes under this climate zone. Parts of the coast have so much rain, with some falling throughout
        the year, that they are in fact Oceanic, although with still a noticeable winter-summer rainfall contrast. Vancouver also fits this
        bill, and is on the border of Oceanic Cfb and Warm Summer Mediterranean Csb.
      </p>
      <p>
        Ok, so the climate data doesn’t lie. The question is why does this only occur in the Pacific NW? Well, actually it does occur in a
        few other places of the world, such as northern Portugal, southern Chile and the coast between Adelaide and Melbourne in Australia.
        But these other areas are either relatively underpopulated or don’t have the global reach that the Pacific NW hubs do, so their
        stories of similar heavy winter rains yet warm dry and sunny summers have gone unnoticed, drowned out by the much larger and well
        known Csa zones of the classic Mediterranean.
      </p>
      <p>
        Lashed by storm after storm from the Pacific in the winter, known locally as “The Pineapple Express”, the Cascade and Rocky
        Mountains further accentuate this rainfall from the west, dumping it on the coastal cities that lie on the western slopes. These
        same mountains blocks humid subtropical and continental air from bringing rains in the summer, influencing the Pacific NW high
        pressure to dominate the area during summer, guaranteeing relatively dry weather.
      </p>
      <ChapterImageList images={imagesWeather} />
      <p>
        And so we get very wet winters reminiscent of the Scottish and Irish coasts winter, but then summers almost as dry as those of
        California. It’s a bit schizoid, a cross-breed, a mongrel of a climate. But talk to the locals and they love it. Well, the summers
        at least. Opinion is still divided on the winters...
      </p>
    </Chapter>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function ImageFader(props) {
  return (
    <>
      {props.asset && (
        <Link to={props.asset.route} className="hero-cont">
          <div className="hero">
            <div className="text">
              <div className={`heading ${props.faderClass} ${props.zIndexClass}`}>{props.asset.heading}</div>
              <div className={`sub ${props.faderClass} ${props.zIndexClass}`}>{props.asset.sub}</div>
            </div>
            <div className={`crop-height ${props.faderClass} ${props.zIndexClass}`}>
              <img src={`${process.env.REACT_APP_IMAGES_DIR}/${props.asset.img}`} alt={props.asset.heading} />
            </div>
          </div>
        </Link>
      )}
    </>
  );
}

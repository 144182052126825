import React from "react";
import { Link } from "react-router-dom";

export default function Menu(props) {
  return (
    <div className="menu">
      <Link className="btn" to="/">Home</Link>
      <Link className="btn" to="/climate">Climate</Link>
      <Link className="btn" to="/biomes">Biomes</Link>
      <Link className="btn" to="/about">About</Link>
      <Link className="btn" to="/contact">Contact</Link>
    </div>
  );
}

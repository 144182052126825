import React from "react";
import { Route, Switch } from "react-router-dom";
import Climate from "../climate/climate";
import Home from "./home";
import Biomes from "../biomes/biomes";
import Sowc0Intro from "../climate/secrets-of-world-climate-introduction";
import Sowc1TropicalRainforest from "../climate/tropical-rainforest";
import Sowc2TropicalMonsoonSavannah from "../climate/tropical-monsoon-and-tropical-savannah";
import Sowc3SubtropicalHighlands from "../climate/subtropical-highlands";
import Sowc4HotDeserts from "../climate/hot-deserts";
import Sowc5HumidSubtropical from "../climate/humid-subtropical";
import Sowc6Mediterranean from "../climate/mediterranean";
import Sowc7Oceanic from "../climate/oceanic";
import Sowc8CoolDeserts from "../climate/cool-deserts";
import Sowc9Continental from "../climate/continental";
import Sowc10Subarctic from "../climate/subarctic";
import Sowc11Tundra from "../climate/tundra";
import Sowc12Icecap from "../climate/icecap";
import Sowc from "../climate/secrets-of-world-climate";
import ClimateCasebook from "../climate/climate-casebook";
import CC0Koppen from "../climate/koppen-classification";
import CC1PnwClimate from "../climate/pacific-northwest";
import CC2AsianMonsoon from "../climate/asian-monsoon";
import CC3Seasons from "../climate/seasons";
import CC4Population from "../climate/population";
import CC5LandAreas from "../climate/land-areas";
import CC6Habitability from "../climate/habitability";
import CC7Lima from "../climate/lima";
import CC8Microclimates from "../climate/microclimates";
import BiomesIntro from "../biomes/introduction";
import BiomesTemperateForests from "../biomes/temperate-forests";
import BiomesSavannah from "../biomes/savannah";
import BiomesShrublands from "../biomes/shrublands";
import BiomesDeserts from "../biomes/deserts";
import BiomesGrasslands from "../biomes/grasslands";
import BiomesTaiga from "../biomes/taiga";
import BiomesPolar from "../biomes/polar-biomes";
import BiomesTropicalForests from "../biomes/tropical-forests";
import About from "./about";
import Contact from "./contact";
import Support from "./support";
import BiomesWetlands from "../biomes/wetlands";

export default function Content(props) {
  return (
    <div className="content">
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/climate" exact>
          <Climate />
        </Route>
        <Route path="/climate/secrets">
          <Sowc />
        </Route>
        <Route path="/climate/casebook">
          <ClimateCasebook />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/support">
          <Support />
        </Route>

        <Route path="/climate/secrets-of-world-climate-introduction">
          <Sowc0Intro />
        </Route>
        <Route path="/climate/tropical-rainforest">
          <Sowc1TropicalRainforest />
        </Route>
        <Route path="/climate/tropical-monsoon-and-tropical-savannah">
          <Sowc2TropicalMonsoonSavannah />
        </Route>
        <Route path="/climate/subtropical-highlands">
          <Sowc3SubtropicalHighlands />
        </Route>
        <Route path="/climate/hot-deserts">
          <Sowc4HotDeserts />
        </Route>
        <Route path="/climate/humid-subtropical">
          <Sowc5HumidSubtropical />
        </Route>
        <Route path="/climate/mediterranean">
          <Sowc6Mediterranean />
        </Route>
        <Route path="/climate/oceanic">
          <Sowc7Oceanic />
        </Route>
        <Route path="/climate/cool-deserts">
          <Sowc8CoolDeserts />
        </Route>
        <Route path="/climate/continental">
          <Sowc9Continental />
        </Route>
        <Route path="/climate/subarctic">
          <Sowc10Subarctic />
        </Route>
        <Route path="/climate/tundra">
          <Sowc11Tundra />
        </Route>
        <Route path="/climate/icecap">
          <Sowc12Icecap />
        </Route>

        <Route path="/climate/koppen-classification">
          <CC0Koppen />
        </Route>
        <Route path="/climate/pacific-northwest">
          <CC1PnwClimate />
        </Route>
        <Route path="/climate/asian-monsoon">
          <CC2AsianMonsoon />
        </Route>
        <Route path="/climate/seasons">
          <CC3Seasons />
        </Route>
        <Route path="/climate/population">
          <CC4Population />
        </Route>
        <Route path="/climate/land-areas">
          <CC5LandAreas />
        </Route>
        <Route path="/climate/habitability">
          <CC6Habitability />
        </Route>
        <Route path="/climate/lima">
          <CC7Lima />
        </Route>
        <Route path="/climate/microclimates">
          <CC8Microclimates />
        </Route>

        <Route path="/biomes" exact>
          <Biomes />
        </Route>
        <Route path="/biomes/introduction">
          <BiomesIntro />
        </Route>
        <Route path="/biomes/tropical-forests">
          <BiomesTropicalForests />
        </Route>
        <Route path="/biomes/savannah">
          <BiomesSavannah />
        </Route>
        <Route path="/biomes/shrublands">
          <BiomesShrublands />
        </Route>
        <Route path="/biomes/deserts">
          <BiomesDeserts />
        </Route>
        <Route path="/biomes/grasslands">
          <BiomesGrasslands />
        </Route>
        <Route path="/biomes/temperate-forests">
          <BiomesTemperateForests />
        </Route>
        <Route path="/biomes/taiga">
          <BiomesTaiga />
        </Route>
        <Route path="/biomes/polar-biomes">
          <BiomesPolar />
        </Route>
        <Route path="/biomes/wetlands">
          <BiomesWetlands />
        </Route>
        {/* <Route path="/biomes/highlands">
          <BiomesHighlands />
        </Route>
        <Route path="/biomes/oceans">
          <BiomesOceans />
        </Route> */}
      </Switch>
    </div>
  );
}

import React, { useEffect } from "react";
import A from "./A";
import HomeHero from "./home-hero";
import NavPanelThumb from "./nav-panel-thumb";
import Share from "./share";

export default function Home(props) {
  useEffect(() => {
    document.title = "Geodiode - The Ultimate Resource for World Climate and Biomes";
  }, []);

  const KoppenThumbPic = "0-ALL.jpg";
  const ImageFrenchGuiana = "tropical-forest-guiana.jpg";
  const ImageClimateCasebook = "Koppen-Tiles.gif";

  return (
    <>
      <HomeHero />
      <div className="home-text">
        <h1>Welcome to the Universe of GEODIODE</h1>
        <p>Explore our beautiful planet through the lens of its varied climate zones and biomes.</p>
        <p>You have arrived at the gateway of learning for everything that concerns Earth's climate and its biosphere, including:</p>
        <ul>
          <li>
            Two complete courses that take you step by step through the subjects of <A to="/climate/secrets/">climate</A> and{" "}
            <A to="/biomes/">biomes</A>
          </li>
          <li>Videos along with accompanying text and images for each zone</li>
          <li>
            Every <A to="/climate/koppen-classification/">Koppen climate zone</A> covered
          </li>
          <li>Climate charts of all major cities in each zone</li>
          <li>See landscapes of real places in each zone</li>
          <li>Every biome around the world</li>
          <li>Example plant species in each biome</li>
          <li>
            <A to="/climate/climate-casebook">Special articles</A> on particular places or more advanced concepts relating to climate
          </li>
        </ul>
        <h2>Where do you want to begin your journey today?</h2>
        <div className="chapters-list">
          <NavPanelThumb src={KoppenThumbPic} to="/climate/secrets" subtitle="Secrets of World Climate Course" />
          <NavPanelThumb src={ImageFrenchGuiana} to="/biomes" subtitle="Biomes Course" />
          <NavPanelThumb src={ImageClimateCasebook} to="/climate/casebook" subtitle="Special Articles" />
        </div>
      </div>
      
      <Share />
    </>
  );
}
